import Dexie from 'dexie'
import { Observable, from } from 'rxjs'
import { IDirectFilingFormDr15, IDirectFilingFormDr15Ez } from '../models/imerchant-filing'

export class DirectFileDatabase extends Dexie {
  private formData: Dexie.Table<IDirectFilingFormDr15 | IDirectFilingFormDr15Ez, number>

  constructor() {
    super('DirectFileDB')
    this.version(2).stores({
      forms: 'filingIds,taxReturnType',
    })
    this.formData = this.table('forms')
  }

  public saveForm(form: IDirectFilingFormDr15 | IDirectFilingFormDr15Ez): Observable<number> {
    return from(this.formData.add(form))
  }

  public getForm<T>(
    filingIds: string
  ): Observable<IDirectFilingFormDr15 | IDirectFilingFormDr15Ez> {
    return from(this.formData.where('filingIds').startsWithAnyOf(filingIds.split(',')).first())
  }

  public clearForms(): Observable<void> {
    return from(this.formData.clear())
  }
}
