import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { IMail } from './models/imail'
import { IMailFilter } from './models/imailFilter'

export const adapter: EntityAdapter<IMail> = createEntityAdapter<IMail>({
  selectId: (model) => model.documentId,
  sortComparer: (a: IMail, b: IMail): number => {
    const aSort = `${a.discoveryDate}_${invertString(a.corpName)}`
    const bSort = `${b.discoveryDate}_${invertString(b.corpName)}`
    return bSort.localeCompare(aSort)
  },
})

function invertString(text: string): string {
  if (!text) {
    return text
  }

  const totalCharCount = 65535
  return text
    .toString()
    .toLowerCase()
    .split('')
    .map((char) => {
      return String.fromCharCode(Math.abs(char.charCodeAt(0) - totalCharCount))
    })
    .join()
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface State extends EntityState<IMail> {
  isLoading: boolean
  error?: any
  listLoaded: boolean
  filteredIds: string[]
  filter: IMailFilter
  nextPage: number
  pageLimit: number
  hasNext: boolean
}

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  error: null,
  listLoaded: false,
  filteredIds: [],
  filter: {},
  nextPage: 1,
  pageLimit: 50,
  hasNext: true,
})
