import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Store } from '@ngrx/store'
import { combineLatest } from 'rxjs'
import { first, tap } from 'rxjs/operators'
import { State as UserState } from 'src/app/user/user.state'
import { State as AccountState } from 'src/app/account/account.state'
import * as accountSelector from 'src/app/account/selectors/account.selectors'
import * as userSelector from 'src/app/user/selectors/user.selectors'

@Injectable({
  providedIn: 'root',
})
export class AttestationGuard implements CanActivate {
  constructor(
    private accountStore: Store<AccountState>,
    private userStore: Store<UserState>,

    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkNeedsAttestation(state.url)
  }

  checkNeedsAttestation(url: string): boolean {
    let activate = false
    const bypassWithCookie = document.cookie.includes('hideAddPaAttestationDialog=true')
    combineLatest([
      this.accountStore.select(accountSelector.selectNeedsPaAttestation),
      this.userStore.select(userSelector.selectUserIsMasquerading),
    ])
      .pipe(
        first(),
        tap(([needsAttestation, isMasquerading]) => {
          if (needsAttestation && !isMasquerading && !bypassWithCookie) {
            activate = false
            if (url.indexOf('states-management/direct-file-manager') > 0) {
              activate = true
              return
            }
            this.router.navigate(['/go/attestation/pennsylvania-attestation'])
          } else {
            activate = true
          }
        })
      )
      .subscribe()

    return activate
  }
}
