export const LoginPageMeta = [
  {
    name: 'description',
    description: 'Welcome back! Please log in to view your TaxCloud account.',
  },
  { name: 'og:locale', content: 'en_US' },
  { name: 'og:type', content: 'website' },
  { name: 'og:title', content: 'Log in to TaxCloud' },
  {
    name: 'og:description',
    content: 'Welcome back! Please log in to view your TaxCloud account.',
  },
  { name: 'og:url', content: 'https://app.taxcloud.com/sign-in' },
  { name: 'og:site_name', content: 'TaxCloud' },
  { name: 'article:publisher', content: 'https://www.facebook.com/TaxCloud' },
  { name: 'article:modified_time', content: '2024-01-16T15:54:26+00:00' },
  {
    name: 'og:image',
    content: 'https://taxcloud.com/wp-content/uploads/2023/02/Header-section.png',
  },
  { name: 'og:image:width', content: '2400' },
  { name: 'og:image:height', content: '1200' },
  { name: 'og:image:type', content: 'image/png' },
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:title', content: 'Home' },
]

export const SignUpPageMeta = [
  {
    name: 'description',
    description:
      'Create your free TaxCloud account. Join 4,000+ companies that have automated their sales tax with TaxCloud.',
  },
  { name: 'og:locale', content: 'en_US' },
  { name: 'og:type', content: 'website' },
  { name: 'og:title', content: 'Sign Up for TaxCloud - Create Your TaxCloud Account' },
  {
    name: 'og:description',
    content:
      'Create your free TaxCloud account. Join 4,000+ companies that have automated their sales tax with TaxCloud.',
  },
  { name: 'og:url', content: 'https://app.taxcloud.com/register' },
  { name: 'og:site_name', content: 'TaxCloud' },
  { name: 'article:publisher', content: 'https://www.facebook.com/TaxCloud' },
  { name: 'article:modified_time', content: '2024-01-16T15:54:26+00:00' },
  {
    name: 'og:image',
    content: 'https://taxcloud.com/wp-content/uploads/2023/02/Header-section.png',
  },
  { name: 'og:image:width', content: '2400' },
  { name: 'og:image:height', content: '1200' },
  { name: 'og:image:type', content: 'image/png' },
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:title', content: 'Home' },
]

export const PasswordRequirements = [
  {
    description: '6 characters',
    isValid: (password) => password?.length >= 6,
  },
  {
    description: 'One number (0-9).',
    isValid: (password) => /[0-9]/.test(password),
  },
  {
    description: 'One lowercase letter (a-z).',
    isValid: (password) => /[a-z]/.test(password),
  },
  {
    description: 'One uppercase letter (A-Z).',
    isValid: (password) => /[A-Z]/.test(password),
  },
  {
    description: 'One special character',
    isValid: (password) => /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(password),
  },
]
