import { Action } from '@ngrx/store'
import { Update } from '@ngrx/entity'
import { IMail as Mail } from '../models/imail'
import { IMailFilter } from './../models/imailFilter'
import { ILinks } from './../models/ilinks'

export enum MailActionTypes {
  InitMailProcess = '[Mail List Component] Init loading mail process',
  ResetMailListComplete = '[Mail List Component] Reset list complete',
  InitLoadMailComplete = '[Mail Effect] Mail list load complete',
  HasMail = '[Mail Effect] Mail db has mail',
  InitLoadMails = '[Mail Effect] Init loading mails',
  LoadMails = '[Mail Effect] Load Mails',
  InitAddMail = '[Mail List Component] Add mail with filters',
  AddMail = '[Mail] Add Mail',
  UpsertMail = '[Mail Effect] Upsert Mail',
  AddMails = '[Mail] Add Mails',
  UpsertMails = '[Mail] Upsert Mails',
  UpdateMail = '[Mail] Update Mail',
  UpdateMails = '[Mail] Update Mails',
  DeleteMail = '[Mail] Delete Mail',
  DeleteMails = '[Mail] Delete Mails',
  ClearMails = '[Mail] Clear Mails',
  GetMailIfMissing = '[Mail Detail Component] Get mail if list is empty',
  GetMail = '[Mail Effects] Get mail from api',
  UpdateMailSuccess = '[Mail Effects] Mail was succesfully updated',
  SaveMailFilters = '[Mail List Component] Save Filters',
  FilterMail = '[Mail List Componenet] Save Filtered State',
  GetFilteredMail = '[Mail List Component] Get filtered mail from api',
  UpdateLinks = '[Mail Effects] Update links',
  IncrementPage = '[Mail List Component] Increment page number',
  ResetIncrementValue = '[Mail List Component] Set next page to 1',
  SetHasNextMail = '[Mail Effects] Set has next mail value',
  SetLoading = '[Mail Effects] Set is loading',
  ResetMails = '[User Effects] Set mail init values',
  GetMailsFromLocal = '[Mail List Component] Get mails from local data',
  GetMailFromLocal = '[Mail Edit Component] Get mail from local data',
}

export class InitMailProcess implements Action {
  readonly type = MailActionTypes.InitMailProcess
  constructor() {}
}

export class ResetMailListComplete implements Action {
  readonly type = MailActionTypes.ResetMailListComplete
}

export class InitLoadMailComplete implements Action {
  readonly type = MailActionTypes.InitLoadMailComplete
}

export class HasMail implements Action {
  readonly type = MailActionTypes.HasMail
  constructor() {}
}

export class LoadMails implements Action {
  readonly type = MailActionTypes.LoadMails

  constructor(public payload: { mails: Mail[] }) {}
}

export class AddMail implements Action {
  readonly type = MailActionTypes.AddMail

  constructor(public payload: { mail: Mail }) {}
}

export class UpsertMail implements Action {
  readonly type = MailActionTypes.UpsertMail

  constructor(public payload: { mail: Mail }) {}
}

export class InitAddMail implements Action {
  readonly type = MailActionTypes.InitAddMail
  // constructor(public payload: { page: number }) {}
}

export class AddMails implements Action {
  readonly type = MailActionTypes.AddMails

  constructor(public payload: { mails: Mail[] }) {}
}

export class UpsertMails implements Action {
  readonly type = MailActionTypes.UpsertMails

  constructor(public payload: { mails: Mail[] }) {}
}

export class UpdateMail implements Action {
  readonly type = MailActionTypes.UpdateMail

  constructor(public payload: { mail: Update<Mail> }) {}
}

export class UpdateMailSuccess implements Action {
  readonly type = MailActionTypes.UpdateMailSuccess
  constructor() {}
}

export class UpdateMails implements Action {
  readonly type = MailActionTypes.UpdateMails

  constructor(public payload: { mails: Update<Mail>[] }) {}
}

export class DeleteMail implements Action {
  readonly type = MailActionTypes.DeleteMail

  constructor(public payload: { id: string }) {}
}

export class DeleteMails implements Action {
  readonly type = MailActionTypes.DeleteMails

  constructor(public payload: { ids: string[] }) {}
}

export class ClearMails implements Action {
  readonly type = MailActionTypes.ClearMails
}

export class GetMailIfMissing implements Action {
  readonly type = MailActionTypes.GetMailIfMissing
  constructor(public payload: { id: string }) {}
}

export class GetMail implements Action {
  readonly type = MailActionTypes.GetMail
  constructor(public payload: { id: string }) {}
}

export class SaveMailFilters implements Action {
  readonly type = MailActionTypes.SaveMailFilters
  constructor(public payload: { filter: IMailFilter }) {}
}

export class FilterMail implements Action {
  readonly type = MailActionTypes.FilterMail
  constructor(public payload: { ids: string[] }) {}
}

export class InitLoadMails implements Action {
  readonly type = MailActionTypes.InitLoadMails
  constructor(public payload: { filter: IMailFilter }) {}
}

export class GetFilteredMail implements Action {
  readonly type = MailActionTypes.GetFilteredMail
  constructor(public payload: { filter: IMailFilter; pageNumber: number }) {}
}

export class UpdateLinks implements Action {
  readonly type = MailActionTypes.UpdateLinks
  constructor(public payload: { links: ILinks }) {}
}

export class ResetIncrementValue implements Action {
  readonly type = MailActionTypes.ResetIncrementValue
}

export class IncrementPage implements Action {
  readonly type = MailActionTypes.IncrementPage
}

export class SetHasNextMail implements Action {
  readonly type = MailActionTypes.SetHasNextMail
  constructor(public payload: { hasNext: boolean }) {}
}

export class SetLoading implements Action {
  readonly type = MailActionTypes.SetLoading
  constructor(public payload: { isLoading: boolean }) {}
}

export class ResetMails implements Action {
  readonly type = MailActionTypes.ResetMails
}

export class GetMailsFromLocal implements Action {
  readonly type = MailActionTypes.GetMailsFromLocal
  constructor(public payload: { pageNumber: number }) {}
}

export class GetMailFromLocal implements Action {
  readonly type = MailActionTypes.GetMailFromLocal
  constructor(public payload: { documentId: string }) {}
}

export type MailActions =
  | InitMailProcess
  | InitLoadMailComplete
  | ResetMailListComplete
  | HasMail
  | InitLoadMails
  | LoadMails
  | AddMail
  | UpsertMail
  | InitAddMail
  | AddMails
  | UpsertMails
  | UpdateMail
  | UpdateMails
  | DeleteMail
  | DeleteMails
  | ClearMails
  | GetMailIfMissing
  | GetMail
  | UpdateMailSuccess
  | SaveMailFilters
  | FilterMail
  | GetFilteredMail
  | UpdateLinks
  | ResetIncrementValue
  | IncrementPage
  | SetHasNextMail
  | SetLoading
  | ResetMails
  | GetMailsFromLocal
  | GetMailFromLocal
