import { tap, first, map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { IMerchantReportDownload, IReportRequest } from '../models/imerchant-report-downloads.model'
import { DownloadService } from 'src/app/root/services/download.service'
import { DownloadCenterAdapter } from './../utilities/download-center-adapter.utility'

@Injectable({
  providedIn: 'root',
})
export class DownloadCenterService {
  private adapter = new DownloadCenterAdapter()

  constructor(
    private http: HttpClient,
    private downloadService: DownloadService
  ) {}

  /*****
   Endpoint does not currently support:
   - Individual states for `ReportType` = 2 (Jurisdiction Report)
   - "All States" for `ReportType` = 3 (Consolidated Transaction Detail Report)
   @param reportRequest Type `IReportRequest`.
   @returns An `Observable` of type `IMerchantReportDownload`.
   */
  public addReportRequest(reportRequest: IReportRequest): Observable<IMerchantReportDownload> {
    return this.http.post<IMerchantReportDownload>(
      'api/transactionsquery/requestreport',
      reportRequest
    )
  }
  public getReportRequests(): Observable<IMerchantReportDownload[]> {
    return this.http
      .get<IMerchantReportDownload[]>('api/transactionsquery/getavailablereports')
      .pipe(
        map((merchantReportDownloads) =>
          merchantReportDownloads.map((report) => this.adapter.mapReportRequest(report))
        )
      )
  }
  public downloadRequest(id: number, fileName: string): Observable<IMerchantReportDownload> {
    return this.http.get(`api/transactionsquery/getreport?ID=${id}`).pipe(
      first(),
      tap((response: any) => this.downloadService.downloadCSV(response.fileContents, fileName))
    )
  }

  /*****
   ### Endpoint currently only used for TaxCloud employees. ###
   @param count Accepts number of requests to process. If count is -1, and `taxCloudRequested` is false,
   this will process all queued reports.
   @param taxCloudRequested processes TaxCloud employee generated reports.
   @returns An `Observable` of the response, with the response body of type string
   (i.e. "Successfully queued 2 reports.").
   */
  public processRequests(count: number, taxCloudRequested = false): Observable<string> {
    return this.http.post<string>('api/transactionsquery/queuereports', {
      NumberOfReports: count,
      TaxCloudReports: taxCloudRequested,
    })
  }

  public processRequest(id: number): Observable<string> {
    return this.http.post<string>(`api/transactionsquery/queuereport?ID=${id}`, null)
  }

  public deleteRequest(id: number): Observable<string> {
    return this.http.post<string>(`api/transactionsquery/deletereport?ID=${id}`, null)
  }
}
