<div>
  <div class="flex flex-start topnav">
    <h3 class="logo-text ml-4 cursor-pointer" (click)="goHome()">
      <span class="logo-text-tax">Tax</span><span class="logo-text-cloud">Cloud</span>
    </h3>
  </div>
  <div class="pb-8">
    <router-outlet></router-outlet>
  </div>
</div>
