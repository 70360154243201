import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core'
import { MatButtonToggleChange } from '@angular/material/button-toggle'
import { Router } from '@angular/router'
import * as fromRouter from '@ngrx/router-store'
import { select, Store } from '@ngrx/store'
import moment, { Moment } from 'moment'
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs'
import { filter, map, pluck, tap } from 'rxjs/operators'
import { DateFormatEnum } from 'src/app/enums/date.enums'
import * as fromRouterSelectors from 'src/app/root/selectors/root.selectors'
import { SubSink } from 'subsink'

import * as fromAccount from '../../../../account/account.state'
import * as fromAccountSelect from '../../../../account/selectors/account.selectors'

interface IMonth {
  value: number
  label: string
  disabled: boolean
}

@Component({
  selector: 'tc-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.sass'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthPickerComponent implements OnInit, OnDestroy {
  startDate: Moment
  currentDate: BehaviorSubject<Moment> = new BehaviorSubject(null)
  year = moment().year()
  current: Moment = moment()
  subs = new SubSink()
  inputStartDate: BehaviorSubject<Moment> = new BehaviorSubject(null)
  shouldEmit = true

  months: Observable<IMonth[]> = of(
    Array.apply(0, Array(12)).map((_, i: number) => {
      return {
        label: moment().month(i).format('MMM'),
        value: i,
        disabled: false,
      }
    })
  )

  constructor(
    private accountStore: Store<fromAccount.State>,
    private router: Router,
    private route: Store<fromRouter.RouterReducerState<any>>
  ) {}

  accountStartDate$: Observable<Moment> = this.accountStore.select(
    fromAccountSelect.selectStartDate
  )

  selectedMonth$: Observable<number> = this.currentDate.pipe(
    map((date) => {
      if (!this.shouldEmit) {
        return null
      }
      return moment(date).month()
    })
  )

  urlMonth$: Observable<string> = this.route.pipe(
    select(fromRouterSelectors.selectRouteState),
    pluck('state', 'queryParams', 'month')
  )

  @Input('firstMonth')
  set setStartMonth(month: string) {
    this.inputStartDate.next(moment(month, DateFormatEnum.Month))
  }
  @Input('resetToCurrent')
  set resetToCurrent(isReset) {
    if (isReset) {
      this.year = moment().year()
      this.current = moment()
      const resetCurrent = moment(this.current, DateFormatEnum.Month)
      this.currentDate.next(resetCurrent)
    }
  }

  @Input() defaultMonth: string

  @Input('selectedMonth')
  set setselectedMonth(month: string) {
    if (this.updateUrl) {
      return
    }
    if (!month) {
      this.currentDate.next(null)
      return
    }
    const selected = moment(month, DateFormatEnum.Month)
    if (selected.isValid()) {
      this.currentDate.next(selected)
    } else {
      console.error(`Month: ${month} is not ${DateFormatEnum.Month}`)
    }
  }

  @Input() updateUrl: boolean
  @Input() allowFutureDate = false

  @Output() monthChange = new EventEmitter<string>()

  ngOnInit() {
    this.subs.add(
      this.currentDate
        .pipe(
          filter((month) => !!month),
          filter((_) => this.shouldEmit),
          map((month) => month.format(DateFormatEnum.Month)),
          tap((month) => {
            this.monthChange.emit(month)
          }),
          tap((month) => {
            if (!this.updateUrl) {
              return
            }
            this.router.navigate([], {
              queryParams: {
                month,
              },
              queryParamsHandling: 'merge',
            })
          })
        )
        .subscribe()
    )

    this.subs.add(
      combineLatest([this.accountStartDate$, this.inputStartDate])
        .pipe(
          map(([account, input]) => {
            if (input === null) {
              return account
            }
            if (account.isBefore(input)) {
              return input
            }
            return account
          }),
          tap((startDate) => (this.startDate = startDate))
        )
        .subscribe()
    )

    this.subs.add(
      this.urlMonth$
        .pipe(
          tap((month) => {
            let selectedMonth: Moment

            if (month) {
              selectedMonth = moment(month || this.defaultMonth, DateFormatEnum.Month)
            } else if (this.defaultMonth) {
              selectedMonth = moment(month || this.defaultMonth, DateFormatEnum.Month)
            } else {
              selectedMonth = moment().startOf('month')
            }

            if (selectedMonth.isValid()) {
              this.currentDate.next(selectedMonth)
            } else {
              console.error(`Month: ${month} is not ${DateFormatEnum.Month}`)
            }
          })
        )
        .subscribe()
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  incrementYear(event: Event) {
    event.stopPropagation()
    this.shouldEmit = false
    if (this.year >= moment().year() && !this.allowFutureDate) {
      return
    }

    this.year++
    if (!this.currentDate.value) {
      this.currentDate.next(moment().year(this.year))
      return
    }
    this.currentDate.next(moment(this.currentDate.value).year(this.year))
  }

  decrementYear(event: Event) {
    event.stopPropagation()
    this.shouldEmit = false

    if (this.year <= this.startDate.year()) {
      return
    }

    this.year--
    if (!this.currentDate.value) {
      this.currentDate.next(moment().year(this.year))
      return
    }
    this.currentDate.next(moment(this.currentDate.value).year(this.year))
  }

  chosenMonthHandler(event: MatButtonToggleChange) {
    let current: Moment
    this.shouldEmit = true
    if (this.currentDate.value) {
      current = moment(this.currentDate.value)
    } else {
      current = moment()
    }
    if (!current.isValid) {
      current = moment()
    }
    const next = current.month(event.value)
    const beginning = moment(this.startDate)
    this.currentDate.next(this.setNewDate(next, current, beginning))
  }

  private setNewDate(next: Moment, current: Moment, beginning: Moment): Moment {
    if (next.isBefore(beginning)) {
      return moment(beginning).date(1)
    }
    if (next.isAfter(moment()) && !this.allowFutureDate) {
      return moment().date(1)
    }

    return moment(next).date(1)
  }
}
