import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Dictionary } from '@ngrx/entity'
import * as fromRouter from '@ngrx/router-store'
import { select, Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'
import { filter, map, pluck } from 'rxjs/operators'
import { State as AccountState } from 'src/app/account/account.state'
import * as accountSelector from 'src/app/account/selectors/account.selectors'
import { FilingResponsibilityEnum, FilingResponsibilityEnumLabel } from 'src/app/enums/filing.enums'
import { ServicePlanTypeEnum } from 'src/app/enums/service-plan.enums'
import { SupportCategoryEnum } from 'src/app/enums/support.enums'
import { UsStateCollectionTypeEnum, UsStateEnum } from 'src/app/enums/us-state.enums'
import { IRouterState } from 'src/app/root/models/irouterstate'
import * as fromRouterSelectors from 'src/app/root/selectors/root.selectors'
import * as stateActions from 'src/app/us-states/actions/us-states.actions'
import * as stateSelector from 'src/app/us-states/selectors/us-state.selector'
import { State as UsStateStore } from 'src/app/us-states/us-states.store'
import * as userSelector from 'src/app/user/selectors/user.selectors'
import { State as UserState } from 'src/app/user/user.state'

import { IUSState } from '../../models/ius-state'

@Component({
  templateUrl: './us-state-detail.page.html',
  styleUrls: ['./us-state-detail.page.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsStateDetailPage implements OnInit {
  public supportCategory = SupportCategoryEnum.ManageUsStates

  constructor(
    private usStateStore: Store<UsStateStore>,
    private accountStore: Store<AccountState>,
    private userStore: Store<UserState>,
    private route: Store<fromRouter.RouterReducerState<IRouterState>>,
    private router: Router,
    private currentRoute: ActivatedRoute
  ) {
    usStateStore.dispatch(new stateActions.InitStatesCollectingDetail())
  }

  public isMasquerading$: Observable<boolean> = this.userStore.pipe(
    select(userSelector.selectUserIsMasquerading)
  )

  public working$: Observable<boolean> = this.usStateStore.select(stateSelector.selectIsWorking)

  public workingMessage$: Observable<string> = this.usStateStore.select(
    stateSelector.selectWorkingMessage
  )

  private servicePlan$: Observable<ServicePlanTypeEnum> = this.accountStore
    .select(accountSelector.selectServicePlan)
    .pipe(
      filter((plan) => !!plan),
      map((plan) => plan.type)
    )

  public isAdmin$: Observable<boolean> = this.userStore.select(
    userSelector.selectUserHasAdminAuthority
  )

  public hasAcceptedLatestTOS$: Observable<boolean> = this.userStore.select(
    userSelector.selectHasLatestTermsOfService
  )

  public isAutmatedCompliance$: Observable<boolean> = this.servicePlan$.pipe(
    map((plan) => plan === ServicePlanTypeEnum.Automated)
  )

  private abbreviation$: Observable<UsStateEnum> = this.route.pipe(
    select(fromRouterSelectors.selectRouteState),
    pluck('state', 'params', 'id')
  )

  private allStates$: Observable<Dictionary<IUSState>> = this.usStateStore.select(
    stateSelector.selectEntities
  )

  public state$: Observable<IUSState> = combineLatest([this.abbreviation$, this.allStates$]).pipe(
    map(([id, states]) => states[id])
  )

  public stateIsSsuta$: Observable<boolean> = this.state$.pipe(map((state) => state?.isSSUTA))

  public isDirectFiling$: Observable<boolean> = this.state$.pipe(
    map((state) => state?.filingResponsibility === FilingResponsibilityEnum.Direct) // TODO state?.autoFile)
  )

  public filingResponsibility$: Observable<string> = this.state$.pipe(
    map((state) => FilingResponsibilityEnumLabel.get(state?.filingResponsibility))
  )

  public shouldCollect$: Observable<boolean> = this.state$.pipe(
    map((state) => state?.collectingType === UsStateCollectionTypeEnum.NeedToCollect)
  )

  public isSstExcluded$: Observable<boolean> = this.state$.pipe(
    map((state) => !!state?.isSstExcluded)
  )
  //This is our best guess based on current data needs to be updated when we have a better system for this.
  public filingEffectiveDate$: Observable<string> = this.state$.pipe(
    map((state) =>
      this.DateFormat(
        state?.autoFileStartDate !== undefined
          ? state?.autoFileStartDate
          : state?.collectingStartDate
      )
    )
  )
  public collectingEffectiveDate$: Observable<string> = this.state$.pipe(
    map((state) => this.DateFormat(state?.collectingStartDate))
  )

  ngOnInit(): void {}

  public backToList(): void {
    this.router.navigate(['../'], {
      relativeTo: this.currentRoute,
    })
  }
  public DateFormat(x: string): string {
    return x?.replace('T00:00:00', '').replace('(g)-', '/')
  }
}
