export enum UsStateCollectionTypeEnum {
  Collecting = 'collecting',
  NeedToCollect = 'need_to_collect',
  NotCollecting = 'not_collecting',
}

export enum UsStateEnum {
  ArmedForcesAmericas = 'AA',
  ArmedForces = 'AE',
  Alabama = 'AL',
  Alaska = 'AK',
  AmericanSamoa = 'AS',
  Arizona = 'AZ',
  Arkansas = 'AR',
  ArmedForcesPacific = 'AP',
  California = 'CA',
  Colorado = 'CO',
  Connecticut = 'CT',
  Delaware = 'DE',
  Florida = 'FL',
  Georgia = 'GA',
  Guam = 'GU',
  Hawaii = 'HI',
  Idaho = 'ID',
  Illinois = 'IL',
  Indiana = 'IN',
  Iowa = 'IA',
  Kansas = 'KS',
  Kentucky = 'KY',
  Louisiana = 'LA',
  Maine = 'ME',
  MarshallIslands = 'MH',
  Maryland = 'MD',
  Massachusetts = 'MA',
  Michigan = 'MI',
  Minnesota = 'MN',
  Mississippi = 'MS',
  Missouri = 'MO',
  Montana = 'MT',
  Nebraska = 'NE',
  Nevada = 'NV',
  NewHampshire = 'NH',
  NewJersey = 'NJ',
  NewMexico = 'NM',
  NewYork = 'NY',
  NorthCarolina = 'NC',
  NorthDakota = 'ND',
  NorthernMarianaIslands = 'MP',
  Ohio = 'OH',
  Oklahoma = 'OK',
  Oregon = 'OR',
  Palau = 'PW',
  Pennsylvania = 'PA',
  PuertoRico = 'PR',
  RhodeIsland = 'RI',
  SouthCarolina = 'SC',
  SouthDakota = 'SD',
  Tennessee = 'TN',
  Texas = 'TX',
  Utah = 'UT',
  Vermont = 'VT',
  VirginIslands = 'VI',
  Virginia = 'VA',
  Washington = 'WA',
  WashingtonDC = 'DC',
  WestVirginia = 'WV',
  Wisconsin = 'WI',
  Wyoming = 'WY',
}
