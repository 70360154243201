import moment from 'moment'
import { UsStateCollectionTypeEnum, UsStateEnum } from 'src/app/enums/us-state.enums'
import { validateDate } from 'src/app/utilities/date.utilities'

import { IApiPublicUsState } from '../models/iapi-public-states'
import { IApiUsState } from '../models/iapi-state'
import { IPublicUsState } from '../models/ipublic-us-states'
import { IEconomicNexus, IFormOptions, IUSState } from '../models/ius-state'

export class UsStateAdapter {
  public mapStateApiToStateModel(api: IApiUsState): IUSState {
    const formOptions = api.stateLicenseFormOptions || []

    const stateModel: Omit<IUSState, 'collectingType'> = {
      name: api.name,
      abbreviation: api.abbreviation,
      fips: api.fipsCode,
      isSSUTA: api.isSSUTA,
      displayFilingBySERCODE: api.displayFilingBySERCODE,
      reverseCalculateFiling: api.reverseCalculateFiling,
      dbSaysCollecting: api.dbSaysCollecting,
      sstDate: validateDate(api.sstDate),
      sstEndDate: validateDate(api.sstEndDate),
      isSstExcluded: api.isExcluded,
      useOriginSourcing: api.useOriginSourcing,
      autoFile: api.autoFile,
      collecting: api.collecting,
      economicNexus: {
        dateStart: validateDate(api.economicNexusDate),
        hasLaws: api.economicNexusLaws,
        ordersThreshold: api.economicNexusOrdersThreshold,
        salesThreshold: api.economicNexusSalesThreshold,
        totalOrders: api.totalEconomicNexusOrders,
        totalSales: api.totalEconomicNexusSales,
        useTrailingTwelveMonths: api.useTrailingTwelveMonths,
        orThresholds: api.orThresholds,
        previousYearOrders: api.previousYearEconomicNexusOrders,
        previousYearSales: api.previousYearEconomicNexusSales,
        currentYearOrders: api.currentYearEconomicNexusOrders,
        currentYearSales: api.currentYearEconomicNexusSales,
      },
      hasPhysicalNexus: api.physicalNexus,
      stateLicense: {
        filingFrequency: api.stateLicenseFilingFrequency,
        id: api.stateLicenseID,
        locationId: api.stateLicenseLocationID,
        formSelected: api.stateLicenseFormID,
        stateId: api.stateLicenseStateID,
        type: api.stateLicenseTypeID,
        password: api.pw,
        login: api.lid,
        formOptions: formOptions.map((options) => ({
          form: options.form,
          frequency: options.frequency,
          id: options?.id.toString(),
          name: options.name,
        })),
      },
      fileUrl: api.fileUrl,
      filingResponsibility: api.filingResponsibility,
      hasCredentials: api.hasCredentials,
      lid: api.lid,
      locationLicenses: api.locationLicenses,
      pw: api.pw,
      registerUrl: api.registerUrl,
      collectingStartDate: api?.collectingStartDate,
    }
    // TODO Remove
    // const addEconomicNexus = {
    //     ...stateModel,
    //     hasEconomicNexus: this.calculateEconomicNexus(stateModel.economicNexus),
    // };

    const addCollectingType = {
      ...stateModel,
      collectingType: this.mapCollectingType(stateModel),
    }

    return addCollectingType
  }

  public mapCollectingType(state: Omit<IUSState, 'collectingType'>): UsStateCollectionTypeEnum {
    if (
      state.abbreviation === UsStateEnum.Montana ||
      state.abbreviation === UsStateEnum.Oregon ||
      state.abbreviation === UsStateEnum.Delaware ||
      state.abbreviation === UsStateEnum.NewHampshire ||
      state.abbreviation === UsStateEnum.AmericanSamoa ||
      state.abbreviation === UsStateEnum.NorthernMarianaIslands
    ) {
      return UsStateCollectionTypeEnum.NotCollecting
    }

    if (state.collecting) {
      return UsStateCollectionTypeEnum.Collecting
    }

    if (state.dbSaysCollecting) {
      return UsStateCollectionTypeEnum.NeedToCollect
    }

    return UsStateCollectionTypeEnum.NotCollecting
  }

  // TODO Remove
  private calculateEconomicNexus(nexus: IEconomicNexus): boolean {
    const collectDate = moment(nexus.dateStart)
    const today = moment()

    if (!nexus.hasLaws) {
      return false
    }

    if (collectDate.isAfter(today)) {
      return false
    }

    if (nexus.totalSales <= 0) {
      return false
    }

    if (nexus.totalOrders <= 0) {
      return false
    }

    // sales only
    if (nexus.ordersThreshold === 0) {
      if (!nexus.useTrailingTwelveMonths) {
        if (
          nexus.currentYearSales > nexus.salesThreshold ||
          nexus.previousYearSales > nexus.salesThreshold
        ) {
          return true
        }
      } else if (nexus.totalSales >= nexus.salesThreshold) {
        return true
      }
    }

    // sales or order threshold
    if (nexus.orThresholds && nexus.ordersThreshold > 0) {
      if (!nexus.useTrailingTwelveMonths) {
        if (
          nexus.previousYearSales > nexus.salesThreshold ||
          nexus.previousYearOrders > nexus.ordersThreshold ||
          nexus.currentYearSales > nexus.salesThreshold ||
          nexus.currentYearOrders > nexus.ordersThreshold
        ) {
          return true
        }
      } else if (
        nexus.totalSales >= nexus.salesThreshold ||
        nexus.totalOrders >= nexus.ordersThreshold
      ) {
        return true
      }
    }

    // sales and order threshold
    if (!nexus.orThresholds && nexus.ordersThreshold > 0) {
      if (!nexus.useTrailingTwelveMonths) {
        if (
          (nexus.previousYearSales > nexus.salesThreshold &&
            nexus.previousYearOrders > nexus.ordersThreshold) ||
          (nexus.currentYearSales > nexus.salesThreshold &&
            nexus.currentYearOrders > nexus.ordersThreshold)
        ) {
          return true
        }
      } else if (
        nexus.totalSales >= nexus.salesThreshold &&
        nexus.totalOrders >= nexus.ordersThreshold
      ) {
        return true
      }
    }

    return false
  }

  mapPublicApiToUsState(result: IApiPublicUsState): IPublicUsState {
    const f = result.stateFIPSCode.toString()
    const mapped: IPublicUsState = {
      collectionDate: validateDate(result.collectionDate),
      abbreviation: result.stateAbbr,
      fips: f.length === 1 ? `0${f}` : f,
      guidanceURL: result.guidanceURL,
      hasMarketplaceLaws: result.hasMarketplaceLaws,
      isSsuta: result.isSSUTA,
      issuedGuidance: result.issuedGuidance,
      marketplaceDate: validateDate(result.marketplaceDate),
      name: result.name,
      orThresholds: result.orThresholds,
      registerUrl: result.registerURL,
      salesThreshold: result.salesThreshold,
      transactionThreshold: result.transactionThreshold,
      updated: validateDate(result.updated),
      useTrailingTwelveMonths: result.useTrailingTwelveMonths,
    }
    return mapped
  }

  public stateLicensePattern(stateAbbr: string): [RegExp, string] {
    switch (stateAbbr) {
      case 'FL':
        return [/^(\d{13})?$/, 'License Id must be exactly 13 numbers (no dashes)']
    }
  }
  public needRegAddress(statesAbbr: string) {
    const requiresAddress = ['CA', 'FL']
    return requiresAddress.includes(statesAbbr)
  }
}
