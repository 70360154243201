import Dexie from 'dexie'
import { from, Observable, of } from 'rxjs'

import { IUSState } from './../models/ius-state'

export class UsStateDatabase extends Dexie {
  private usStates: Dexie.Table<IUSState, number>

  constructor() {
    super('UsStateDB')
    this.version(1).stores({
      usStates: 'fips,abbreviation,name',
    })
    this.usStates = this.table('usStates')
  }

  public saveUsStates(usStates: IUSState[]): Observable<number> {
    return from(this.usStates.clear().then(() => this.usStates.bulkPut(usStates)))
  }

  public updateUsState(fips: string, changes: any) {
    return from(this.usStates.where('fips').equals(fips).modify(changes))
  }

  public clearUsStates(): Observable<void> {
    return from(this.usStates.clear())
  }

  public getUsStates(): Observable<IUSState[]> {
    return from(this.usStates.orderBy('name').toArray())
  }

  public getUsState(fips: string): Observable<IUSState | null> {
    if (!fips) {
      return of(null)
    }

    return from(this.usStates.get({ fips }))
  }

  public getUsStateByAbbreviation(abbreviation: string): Observable<IUSState | undefined> {
    if (!abbreviation) {
      return of(null)
    }

    return from(this.usStates.get({ abbreviation: abbreviation.toUpperCase() }))
  }

  public getSsutaStates(): Observable<IUSState[]> {
    return from(this.usStates.filter((state) => state.isSSUTA).toArray())
  }

  public findUsStateByName(name: string): Observable<IUSState[]> {
    return from(this.usStates.where('name').startsWithIgnoreCase(name).toArray())
  }

  public getCollectingStates(): Observable<IUSState[]> {
    return from(this.usStates.filter((state) => state.collecting).toArray())
  }

  public getAutoFile(): Observable<IUSState[]> {
    return from(this.usStates.filter((state) => state.autoFile).toArray())
  }

  public getAutoFillNonSSuta(): Observable<IUSState[]> {
    return from(this.usStates.filter((state) => state.isSSUTA).toArray())
  }

  public getCollectingNonSSuta(): Observable<IUSState[]> {
    return from(
      this.usStates
        .filter((state) => state.collecting)
        .filter((state) => !state.isSSUTA)
        .toArray()
    )
  }
}
