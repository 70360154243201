import { IApiContactBody } from '../models/iapi-contact-body.model'
import { IApiContactResponse } from '../models/iapi-contact-response.model'
import { IContact } from '../models/icontact.model'
import { IEditContact } from '../models/iedit-contact.model'
import { validateDate } from 'src/app/utilities/date.utilities'

export class ContactAdapter {
  public mapContactApiResponseToContact(response: IApiContactResponse): IContact {
    return {
      email: response.email,
      firstName: response.firstName,
      lastName: response.lastName,
      fullName: response.fullName,
      contactId: response.contactID.toString(),
      authority: response.titleID,
      authorityTitle: response.titleString,
      phone: response.phone,
      lastLogin: validateDate(response.lastLogin),
    }
  }

  public mapIEditContactToContactBody(contact: IEditContact): IApiContactBody {
    return {
      contactEmailAddress: contact.email,
      contactFirstName: contact.firstName,
      contactLastName: contact.lastName,
      contactPhoneNumber: contact.phone,
      titleID: contact.authority.toString(),
      contactID: contact.id,
    }
  }
}
