import moment from 'moment'
import { ReportTypeEnumLabel } from 'src/app/enums/report-type.enum'
import { IMerchantReportDownload } from '../models/imerchant-report-downloads.model'

export class DownloadCenterAdapter {
  constructor() {}
  public mapReportRequest(merchantReport: IMerchantReportDownload) {
    const { reportType, periodsRequested } = merchantReport
    const reportTypeLabel = ReportTypeEnumLabel.get(reportType)
    const periodsDisplay = periodsRequested.map((period) => moment(period.toString()).toString())
    const merchantReportDownload: IMerchantReportDownload = {
      ...merchantReport,
      reportTypeLabel,
      periodsDisplay,
    }
    return merchantReportDownload
  }
}
