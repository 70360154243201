import { Update } from '@ngrx/entity'
import { Action } from '@ngrx/store'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'

import { IUSState } from './../models/ius-state'

export enum UsStateActionTypes {
  InitStatesGoShell = '[Go Shell] Init States',
  InitStatesGetStartedShell = '[Get-Started Shell] Init States',
  InitStatesCollecting = '[State Collecting Component] Init States',
  InitStatesCollectingDetail = '[State Detail Component] Init States',

  InitStatesSuccess = '[States Effects] Init states success',
  InitStatesNotNeeded = '[States Effects] Init state not needed',

  RefreshUsStates = '[Account Effect] Refresh States',
  RefreshUsStatesSuccess = '[State Effect] Refresh State Success',

  ClearUsStates = '[UsState Service] Clear UsStates',

  UpdateBasicFilingLocal = '[US State Filing Form] Toggle collecting',

  UpdateDirectFilingLocal = '[Direct Filing Form] Update direct filing information',
  UpdateDirectFilingDatabase = '[State Effects] Update direct filing information',

  UpdateBasicFilingDatabase = '[US State Service] Update collecting',
  UpdateDatabaseSuccess = '[US State Service] Update collecting success',
  UpdateDatabaseFailure = '[US State Service] Update collecting failure',
}

export class InitStatesGoShell implements Action {
  readonly type = UsStateActionTypes.InitStatesGoShell
}

export class InitStatesGetStartedShell implements Action {
  readonly type = UsStateActionTypes.InitStatesGetStartedShell
}

export class InitStatesCollecting implements Action {
  readonly type = UsStateActionTypes.InitStatesCollecting
}

export class InitStatesCollectingDetail implements Action {
  readonly type = UsStateActionTypes.InitStatesCollectingDetail
}

export class InitStatesNotNeeded implements Action {
  readonly type = UsStateActionTypes.InitStatesNotNeeded
}

export class InitStatesSuccess implements Action {
  readonly type = UsStateActionTypes.InitStatesSuccess
  constructor(public payload: { usStates: IUSState[]; source: DataSourceEnum }) {}
}

export class RefreshUsStates implements Action {
  readonly type = UsStateActionTypes.RefreshUsStates
}

export class RefreshUsStatesSuccess implements Action {
  readonly type = UsStateActionTypes.RefreshUsStatesSuccess
  constructor(public payload: { usStates: IUSState[] }) {}
}

export class UpdateBasicFilingLocal implements Action {
  readonly type = UsStateActionTypes.UpdateBasicFilingLocal
  constructor(public payload: { update: Update<IUSState> }) {}
}

export class UpdateDirectFilingLocal implements Action {
  readonly type = UsStateActionTypes.UpdateDirectFilingLocal
  constructor(public payload: { update: Update<IUSState> }) {}
}

export class UpdateBasicFilingDatabase implements Action {
  readonly type = UsStateActionTypes.UpdateBasicFilingDatabase
  constructor(public payload: { id: string }) {}
}

export class UpdateDirectFilingDatabase implements Action {
  readonly type = UsStateActionTypes.UpdateDirectFilingDatabase
  constructor(public payload: { id: string }) {}
}

export class UpdateDatabaseSuccess implements Action {
  readonly type = UsStateActionTypes.UpdateDatabaseSuccess
}

export class UpdateDatabaseFailure implements Action {
  readonly type = UsStateActionTypes.UpdateDatabaseFailure
}

export class ClearUsStates implements Action {
  readonly type = UsStateActionTypes.ClearUsStates
}

export type UsStateActions =
  | InitStatesGoShell
  | InitStatesGetStartedShell
  | InitStatesCollecting
  | InitStatesCollectingDetail
  | InitStatesNotNeeded
  | InitStatesSuccess
  | RefreshUsStates
  | RefreshUsStatesSuccess
  | UpdateBasicFilingLocal
  | UpdateBasicFilingDatabase
  | UpdateDirectFilingLocal
  | UpdateDirectFilingDatabase
  | UpdateDatabaseSuccess
  | UpdateDatabaseFailure
  | ClearUsStates
