<section>
  <ng-container *ngIf="is404$ | async; else showPage ">
    <div class="margin_sm">
      <div class="mat-display-2">Missing Page</div>
      <div>Sorry, there is no support page for this topic</div>
    </div>
  </ng-container>
  <ng-template #showPage>
    <div class="margin_h_sm" *ngIf="page$ | async as page">
      <div class="margin_h_sm">
        <div class="mat-display-2">{{page.name}}</div>

        <ng-container *ngIf="page.fields; then showBody else showError"></ng-container>

        <ng-template #showBody>
          <div
            class="support-page"
            style="overflow-y: hidden; word-wrap: break-word"
            [innerHTML]="page.fields.body"
          ></div>
        </ng-template>

        <ng-template #showError>
          <div tcAlert="error">
            Please contact customer service, the information for this page is missing.
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
</section>
