import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { combineLatest, merge, Observable } from 'rxjs'
import { filter, switchMap } from 'rxjs/operators'
import { State as AppStatusState } from 'src/app/app-status/app-status.state'
import * as fromAppStatusSelectors from 'src/app/app-status/selectors/app-status.selectors'
import * as fromRouter from '@ngrx/router-store'
import * as fromRouterSelectors from 'src/app/root/selectors/root.selectors'
import { IRouterState } from 'src/app/root/models/irouterstate'
import { LoginEnum } from 'src/app/enums/login.enum'
import * as authSelectors from 'src/app/user/selectors/user.selectors'
import { State as AuthState } from 'src/app/user/user.state'
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { LatestTermsOfServiceDialog } from '../dialogs/latest-terms-of-service/latest-terms-of-service.dialog'
import { ShellEnum } from 'src/app/enums/shell.enums'

@Injectable({
  providedIn: 'root',
})
export class TosService {
  private bottomSheetRef: MatBottomSheetRef

  constructor(
    private authStore: Store<AuthState>,
    private appStatus: Store<AppStatusState>,
    private http: HttpClient,
    private bottomSheet: MatBottomSheet,
    private route: Store<fromRouter.RouterReducerState<IRouterState>>
  ) {}

  public getTermsOfService(): Observable<Blob> {
    return merge(this.getPublicTermsOfService(), this.getPersonalizedTermsOfService())
  }

  public getLatestTermsOfService(): Observable<Blob> {
    return this.http.post(
      'api/account/tos',
      {},
      {
        responseType: 'blob',
      }
    )
  }

  public openNeedLatestTosDialog(): void {
    this.bottomSheetRef = this.bottomSheet.open(LatestTermsOfServiceDialog)
  }
  public closeNeedLatestTosDialog(): void {
    if (this.bottomSheetRef) {
      this.bottomSheetRef.dismiss()
    }
  }

  private getPublicTermsOfService(): Observable<Blob> {
    return combineLatest([
      this.authStore.select(authSelectors.selectLoginStatus),
      this.appStatus.select(fromAppStatusSelectors.selectOnline),
    ]).pipe(
      filter(([authorized, isOnline]) => {
        if (authorized !== LoginEnum.LoggedIn && isOnline) {
          return true
        } else {
          return false
        }
      }),
      switchMap(() =>
        this.http.post(
          'api/account/tos',
          {},
          {
            responseType: 'blob',
          }
        )
      )
    )
  }

  private getPersonalizedTermsOfService(): Observable<Blob> {
    return combineLatest([
      this.authStore.select(authSelectors.selectLoginStatus),
      this.appStatus.select(fromAppStatusSelectors.selectOnline),
      this.route.select(fromRouterSelectors.selectShell),
    ]).pipe(
      filter(([authorized, isOnline, shell]) => {
        if (authorized === LoginEnum.LoggedIn && isOnline) {
          return true
        } else {
          return false
        }
      }),
      switchMap(([authorized, isOnline, shell]) => {
        let body = {}
        if (shell === ShellEnum.GetStarted) {
          body = { ...body, addTOSIfMissing: true }
        }
        return this.http.post('api/account/tosbytoken', body, {
          responseType: 'blob',
        })
      })
    )
  }
}
