import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { SubSink } from 'subsink'

import { ChangePasswordRequest } from '../../actions/login.actions'
import { State as AuthState } from '../../user.state'
import { passwordConfirmValidator } from '../../validators/pasword-confirm.validator'
import { BehaviorSubject, tap } from 'rxjs'
import { OverlayOptions } from 'primeng/api'
import { PasswordRequirements } from '../../constants/user.constants'
@Component({
  selector: 'tc-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordFormComponent implements OnInit, OnDestroy {
  @ViewChild('password', { read: ElementRef, static: true }) passwordRef: ElementRef

  formGroup: UntypedFormGroup
  uploading = new BehaviorSubject<boolean>(false)
  private pattern = '((?=.*d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{0,32})'
  password: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.pattern('.*[0-9].*'),
    Validators.pattern('.*[a-z].*'),
    Validators.pattern('.*[A-Z].*'),
    Validators.pattern(/.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~].*/),
  ])

  confirm: UntypedFormControl = new UntypedFormControl('')
  sub = new SubSink()

  public passWordStrength = new BehaviorSubject<string>(null)
  public overlayOptions: OverlayOptions = {}
  public overlayVisible: boolean = false
  public passwordRequirements = PasswordRequirements

  constructor(
    private fb: UntypedFormBuilder,
    private authStore: Store<AuthState>,
    private router: Router
  ) {
    this.formGroup = this.fb.group(
      {
        password: this.password,
        confirm: this.confirm,
      },
      { validators: passwordConfirmValidator }
    )
    this.overlayOptions = {
      responsive: { direction: 'bottom' },
      appendTo: this.passwordRef?.nativeElement,
    }
  }

  ngOnInit(): void {
    this.sub.add(
      this.formGroup
        .get('password')
        .valueChanges.pipe(
          tap(() => {
            const allRequirementsValid = this.checkPasswordRequirementsValid()
            this.overlayVisible = !allRequirementsValid
          })
        )
        .subscribe()
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }
  private checkPasswordRequirementsValid() {
    return this.passwordRequirements.every((req) => req.isValid(this.password.value))
  }

  public submitPasswordChange() {
    if (this.formGroup.invalid) {
      return
    }

    const password = this.password.value
    const confirmPassword = this.confirm.value
    this.uploading.next(true)
    this.authStore.dispatch(new ChangePasswordRequest({ password, confirmPassword }))
  }

  public goHome(): void {
    this.router.navigate(['/go'])
  }

  onPasswordFocus() {
    if (!this.checkPasswordRequirementsValid()) {
      this.overlayVisible = true
    }
  }
  onPasswordBlur() {
    this.overlayVisible = false
  }
  public onStrengthChange(strength: number) {
    const strengthText =
      strength === 0
        ? 'Poor'
        : strength === 1
          ? 'Fair'
          : strength === 2
            ? 'Sufficient'
            : strength === 3
              ? 'Good'
              : 'Strong'
    this.passWordStrength.next(strengthText)
  }
}
