import { SubscriptionStateEnum } from '../enums/subscription-state.enums'
import { SubscriptionPlanEnum } from '../manage-account/enums/suscriptions.enums'
import { PlanSubscription, SubscriptionItem } from '../manage-account/models/billing.model'

/**
 * Function to determine if a given subscription is current and active.
 * Should not be confused with the specific chargebee 'active' status.
 * @param subscription
 * @returns
 */
export function hasCurrentActiveSubscriptionStatus(subscription: PlanSubscription): boolean {
  return (
    subscription?.status === SubscriptionStateEnum.InTrial ||
    subscription?.status === SubscriptionStateEnum.Active ||
    subscription?.status === SubscriptionStateEnum.Future ||
    subscription?.status === SubscriptionStateEnum.NonRenewing
  )
}

/**
 * Function to determine if a given subscription is non-trial, current, and active.
 * Should not be confused with the specific chargebee 'active' status.
 */
export function hasCurrentNonTrialActiveSubscriptionStatus(
  subscription: PlanSubscription
): boolean {
  return (
    subscription?.status === SubscriptionStateEnum.Active ||
    subscription?.status === SubscriptionStateEnum.Future ||
    subscription?.status === SubscriptionStateEnum.NonRenewing
  )
}

export function isOtherSubscriptionType(sub: SubscriptionItem): boolean {
  return (
    sub?.itemPriceId?.toLowerCase()?.includes(SubscriptionPlanEnum.ENTERPRISE) ||
    sub?.itemPriceId?.toLowerCase()?.includes(SubscriptionPlanEnum.LEGACY_FLAT_FEE) ||
    sub?.itemPriceId?.toLowerCase()?.includes(SubscriptionPlanEnum.LEGACY_USAGE)
  )
}
