<svg
  *ngIf="combine$ | async as stateData"
  [@fadeIn]
  enable_background="new 0 0 1000 589"
  style="stroke-linejoin: round; fill: none"
  [style.stroke]="stateData.length > 0 ? '#9E9E9E' : '#FFFFFF'"
  viewBox="120 0 900 589"
>
  <path
    *ngFor="let state of stateData"
    inkscape:connector-curvature="0"
    id="{{ state.abbreviation }}"
    style.fill="{{ state.fillColor }}"
    [matTooltip]="state?.toolTip"
    (mouseenter)="mouseEnter(state.abbreviation)"
    (mouseleave)="mouseLeave(state.abbreviation)"
    (click)="onClick(state.abbreviation)"
    [attr.d]="state.d"
    style="stroke-width: 0.97063118000000004; fill: #e2e2e2"
  />
</svg>
