import { animate, style, transition, trigger } from '@angular/animations'
import { ChangeDetectionStrategy, Component } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { State as AccountState } from 'src/app/account/account.state'
import * as accountSelector from 'src/app/account/selectors/account.selectors'
import { ServicePlanTypeEnum } from 'src/app/enums/service-plan.enums'
import { UsStateCollectionTypeEnum } from 'src/app/enums/us-state.enums'
import * as fromUserSelectors from 'src/app/user/selectors/user.selectors'
import { State as UserState } from 'src/app/user/user.state'

import { IUSState } from '../../models/ius-state'
import * as fromUsStateSelector from './../../selectors/us-state.selector'
import { State as UsStateStore } from './../../us-states.store'

@Component({
  selector: 'tc-edit-us-states',
  templateUrl: './edit-us-states.component.html',
  styleUrls: ['./edit-us-states.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class EditUsStatesComponent {
  isCollecting = UsStateCollectionTypeEnum.Collecting
  shouldCollect = UsStateCollectionTypeEnum.NeedToCollect

  constructor(
    private usStateStore: Store<UsStateStore>,
    private userStore: Store<UserState>,
    private accountStore: Store<AccountState>
  ) {}

  public allStates$: Observable<IUSState[]> = this.usStateStore.select(
    fromUsStateSelector.selectAll
  )

  isLoading$: Observable<boolean> = this.allStates$.pipe(map((usStates) => usStates.length === 0))

  isAutomatedCompliance$: Observable<boolean> = this.accountStore
    .select(accountSelector.selectServicePlan)
    .pipe(map((plan) => plan.type === ServicePlanTypeEnum.Automated))

  hasTermsOfService$ = this.userStore.select(fromUserSelectors.selectHasAcceptedTermsOfService)

  isAdmin$ = this.userStore.select(fromUserSelectors.selectUserHasAdminAuthority)

  public trackByFn(index: number, item: IUSState) {
    return item.abbreviation
  }
}
