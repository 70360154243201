import Dexie from 'dexie'
import { Observable, from } from 'rxjs'
import { IContact } from './../models/icontact.model'

export class ContactDatabase extends Dexie {
  private contacts: Dexie.Table<IContact, string>

  constructor() {
    super('ContactDB')
    this.version(2).stores({
      contacts: 'contactId,confirmedEmailDate,lastName,fullName',
    })
    this.contacts = this.table('contacts')
  }

  public saveContacts(contacts: IContact[]): Observable<string> {
    return from(this.contacts.clear().then((_) => this.contacts.bulkPut(contacts)))
  }

  public updateContact(contact: IContact): Observable<string> {
    return from(this.contacts.put(contact))
  }

  public clearDatabase(): Observable<void> {
    return from(this.contacts.clear())
  }

  public getContacts(): Observable<IContact[]> {
    return from(this.contacts.orderBy('fullName').toArray())
  }

  public getContact(contactId: string): Observable<IContact | undefined> {
    return from(this.contacts.get({ contactId }))
  }

  public removeContact(contactId: string): Observable<any> {
    return from(this.contacts.delete(contactId))
  }
}
