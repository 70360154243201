import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

import { IPaymentMethodApi } from '../models/iapi-payment-method'
import { IBank } from '../models/ibank'
import { IBankAccount } from '../models/ibank-account'
import { ICreditCard } from '../models/icreditcard'
import { PaymentMethodAdapter } from '../utilities/payment-method-adapter.utilities'
import * as fromPaymentActions from './../actions/payment-method.actions'
import { IPaymentMethod } from './../models/ipayment-method.model'
import * as fromPayment from './../payment-method.store'

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService {
  adapter = new PaymentMethodAdapter()
  constructor(
    private http: HttpClient,
    private paymentMethodStore: Store<fromPayment.State>
  ) {}

  getPaymentMethods(): Observable<IPaymentMethod[]> {
    return this.http
      .post('api/billing/paymentInstruments', null, {
        headers: new HttpHeaders({ ContentType: 'application/json' }),
      })
      .pipe(
        map((results: IPaymentMethodApi[]) =>
          results.map((result) => this.adapter.mapApiResultsToPaymentMethod(result))
        )
      )
  }

  saveCreditCard(cc: ICreditCard): Observable<object> {
    return this.http.post(
      'api/billing/addPaymentInstrument',
      this.adapter.mapCreditCardToPaymentMethodBody(cc),
      { headers: new HttpHeaders({ ContentType: 'application/json' }) }
    )
  }

  saveBankAccount(bankAccount: IBankAccount): Observable<object> {
    return this.http.post(
      'api/billing/addPaymentInstrument',
      this.adapter.mapBankAccountToPaymentMethodBody(bankAccount),
      { headers: new HttpHeaders({ ContentType: 'application/json' }) }
    )
  }

  makePaymentMethodDefault(paymentMethod: IPaymentMethod): Observable<object> {
    return this.http.post(
      'api/billing/defaultPaymentInstrument',
      this.adapter.mapPaymentMethodToKryptonPi(paymentMethod),
      { headers: new HttpHeaders({ ContentType: 'application/json' }) }
    )
  }

  removePaymentMethod(paymentMethod: IPaymentMethod): Observable<object> {
    return this.http.post(
      'api/billing/removePaymentInstrument',
      this.adapter.mapPaymentMethodToKryptonPi(paymentMethod),
      { headers: new HttpHeaders({ ContentType: 'application/json' }) }
    )
  }

  validateRoutingNumber(routingNumber: string): Observable<IBank | null> {
    if (routingNumber.toString().length !== 9) {
      return of(null)
    }

    return this.http
      .post(
        'api/billing/find-bank',
        { routingNumber },
        { headers: new HttpHeaders({ ContentType: 'application/json' }) }
      )
      .pipe(map((result: IBank[]) => (result.length ? result[0] : null)))
  }

  clearPaymentMethods(): void {
    this.paymentMethodStore.dispatch(new fromPaymentActions.ClearPaymentMethods())
  }
}
