import {
  Customer,
  CustomerUsage,
  CustomerUsageReport,
  Invoice,
  PaymentMethod,
  PlanDetail,
  PlanSubscription,
  SubscriptionEntitlements,
} from './models/billing.model'

export interface State {
  loadingPaymentMethods: boolean
  loadingSubscription: boolean
  paymentMethods: PaymentMethod[]
  subscription: PlanSubscription
  pendingSubscription: PlanSubscription
  isSubscriptionsLoaded: boolean
  loadingPutSubscription: boolean
  billingRequestFlowId: string
  plans: PlanDetail[]
  isEditingPlan: boolean
  customer: Customer
  invoices: Invoice[]
  isLoadingInvoices: boolean
  isLoadingPaymentRole: boolean
  isLoadingCustomerProfile: boolean
  customerUsage: CustomerUsage
  usageReports: CustomerUsageReport[]
  usageReportDetails: CustomerUsageReport
  isUpdatingBillingInfo: boolean
  updatingBillingInfoSuccess: boolean
  showWelcomeToTrial: boolean
  subscriptionEntitlements: SubscriptionEntitlements[]
}

export const initialState: State = {
  loadingPaymentMethods: false,
  loadingSubscription: false,
  paymentMethods: [],
  subscription: null,
  pendingSubscription: null,
  isSubscriptionsLoaded: false,
  loadingPutSubscription: false,
  billingRequestFlowId: null,
  plans: null,
  isEditingPlan: false,
  customer: null,
  invoices: [],
  isLoadingInvoices: false,
  isLoadingPaymentRole: false,
  isLoadingCustomerProfile: false,
  customerUsage: null,
  usageReports: null,
  usageReportDetails: null,
  isUpdatingBillingInfo: false,
  updatingBillingInfoSuccess: false,
  showWelcomeToTrial: false,
  subscriptionEntitlements: [],
}
