import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects'
import { Action } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import * as appStatusActions from './../../app-status/actions/app-status.actions'
import * as loginActions from './../../user/actions/login.actions'

@Injectable()
export class RootEffects {
  constructor(private actions$: Actions) {}

  initUserData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      map(() => new loginActions.RequestUserAndAuthFromLocal())
    )
  )

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      map((_) => new appStatusActions.LoadAppStatus())
    )
  )
}
