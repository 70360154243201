import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { merge, Observable } from 'rxjs'
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import * as fromAppStatus from 'src/app/app-status/app-status.state'
import * as fromAppStatusSelectors from 'src/app/app-status/selectors/app-status.selectors'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'

import * as fromUser from '../../user/user.state'
import { IApiContactResponse } from '../models/iapi-contact-response.model'
import { IEditContact } from '../models/iedit-contact.model'
import { ContactAdapter } from '../utilities/contact-adapter.utilities'
import * as fromContactActions from './../actions/contact.actions'
import { State as ContactState } from './../contact.store'
import { ContactDatabase } from './../db/contact.db'
import { IContact } from './../models/icontact.model'
import { selectContactsDataSource } from './../selectors/contact.selectors'

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private localDb = new ContactDatabase()
  private adapter = new ContactAdapter()

  constructor(
    private http: HttpClient,
    private store: Store<fromUser.State>,
    private appStatus: Store<fromAppStatus.State>,
    private contactStore: Store<ContactState>
  ) {}

  public getContacts(): Observable<[IContact[], DataSourceEnum]> {
    return merge(this.getContactsFromApiWhenOnline(), this.getContactsFromLocal())
  }

  public getContact(contactId: string) {
    return this.http.get(`api/contact/${contactId}`)
  }

  public refreshContacts(): Observable<IContact[]> {
    return this.getContactsFromApi().pipe(map(([contacts, source]) => contacts))
  }

  public saveNewContact(contact: IEditContact): Observable<IContact[]> {
    return this.http
      .post('api/contacts/write', this.adapter.mapIEditContactToContactBody(contact))
      .pipe(
        map((contacts: IApiContactResponse[]) =>
          contacts.map((results) => this.adapter.mapContactApiResponseToContact(results))
        ),
        tap((contacts: IContact[]) => this.localDb.saveContacts(contacts))
      )
  }

  public saveEditedContact(contact: IEditContact): Observable<IContact[]> {
    return this.http
      .post('api/contacts/write', this.adapter.mapIEditContactToContactBody(contact))
      .pipe(
        map((contacts: IApiContactResponse[]) =>
          contacts.map((results) => this.adapter.mapContactApiResponseToContact(results))
        ),
        tap((contacts: IContact[]) => this.localDb.saveContacts(contacts))
      )
  }

  public removeContact(id: string): Observable<IContact[]> {
    return this.http.post('api/contacts/remove', { ContactID: id }).pipe(
      map((contacts: IApiContactResponse[]) =>
        contacts.map((results) => this.adapter.mapContactApiResponseToContact(results))
      ),
      tap((contacts: IContact[]) => this.localDb.saveContacts(contacts))
    )
  }

  public clearContacts(): Observable<void> {
    this.store.dispatch(new fromContactActions.ClearContacts())

    return this.localDb.clearDatabase()
  }

  private getContactsFromApiWhenOnline(): Observable<[IContact[], DataSourceEnum]> {
    return this.appStatus.select(fromAppStatusSelectors.selectOnline).pipe(
      filter((online) => online),
      switchMap(() => this.getContactsFromApi())
    )
  }

  private getContactsFromApi(): Observable<[IContact[], DataSourceEnum]> {
    return this.http.post('api/contacts', null).pipe(
      map((contacts: IApiContactResponse[]) =>
        contacts.map((contact) => this.adapter.mapContactApiResponseToContact(contact))
      ),
      tap((contacts: IContact[]) => this.localDb.saveContacts(contacts)),
      map((contacts) => [contacts, DataSourceEnum.API])
    )
  }

  private getContactsFromLocal(): Observable<[IContact[], DataSourceEnum]> {
    return this.localDb.getContacts().pipe(
      withLatestFrom(this.contactStore.select(selectContactsDataSource)),
      filter(([contacts, source]) => {
        if (source === DataSourceEnum.API) {
          return false
        }
        if (contacts.length === 0) {
          return false
        }
        return true
      }),
      map(([contacts, _]) => [contacts, DataSourceEnum.IndexedDB])
    )
  }
}
