import { MaterialModule } from './../material/material.module'
import { ReactiveFormsModule } from '@angular/forms'
import { UiModule } from 'src/app/ui/ui/ui.module'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { DownloadCenterRoutingModule } from './routes/download-center.routes'
import { HomePage } from './pages/home/home.page'
import { UsStatesModule } from 'src/app/us-states/us-states.module'
import { SharedPrimeNgModule } from '../primeng/shared-primeng.module'
import { EffectsModule } from '@ngrx/effects'
import { DownloadCenterEffects } from './effects/download-center.effects'
import { StoreModule } from '@ngrx/store'
import { FeatureKeys } from '../enums/store.enums'
import * as downloadCenterReducer from './reducers/download-center.reducer'

@NgModule({
  declarations: [HomePage],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DownloadCenterRoutingModule,
    UiModule,
    MaterialModule,
    UsStatesModule,
    SharedPrimeNgModule,
    StoreModule.forFeature(FeatureKeys.DownloadCenter, downloadCenterReducer.reducer),
    EffectsModule.forFeature([DownloadCenterEffects]),
  ],
})
export class DownloadCenterModule {}
