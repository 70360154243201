export enum LoginSuccessEnum {
  Success = 'success',
  Fail = 'fail',
  Error = 'error',
}

export enum LoginEnum {
  LoggedIn = 'logged_in',
  LoggedOut = 'logged_out',
  Locked = 'locked',
}

export enum LoginTypeEnum {
  Default = 1,
  Auto = 2,
  Masquerade = 3,
  SingleUse = 4,
}
