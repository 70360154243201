import { DeviceEnum } from 'src/app/enums/device.enums'
import { OnlineEnum } from './../enums/online.enums'
import { AppTypeEnum } from '../enums/app.enums'
import { ReturnUrl } from './models/return-url'

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface State {
  online: OnlineEnum
  device: DeviceEnum
  installable: boolean
  installed: boolean
  returnUrl?: ReturnUrl
  appType: AppTypeEnum
}

export const initialState: State = {
  online: OnlineEnum.Online,
  device: DeviceEnum.Computer,
  installable: false,
  installed: true,
  appType: AppTypeEnum.Full,
}
