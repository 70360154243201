import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MessageService } from 'primeng/api'
import { combineLatest, Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { BlogService } from 'src/app/blog/services/blog.service'
import { AlertTypeEnum } from 'src/app/enums/alert.enums'
import { INotification } from '../models/inotification.model'
import { BlogCategoryEnum } from 'src/app/enums/blog.enums'

const DEFAULT_TOAST_DURATION = 5000

enum ToastSeverity {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warn = 'warn',
}

interface ToastOptions {
  title: string
  description?: string
  duration?: number
  sticky?: boolean
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private TOAST_KEY: string = 'global-toast'

  constructor(
    private http: HttpClient,
    private blogService: BlogService,
    private messageService: MessageService
  ) {}

  showSuccess(options: ToastOptions): void {
    this.showToast(ToastSeverity.Success, options)
  }

  showError(options: ToastOptions): void {
    this.showToast(ToastSeverity.Error, options)
  }

  showInfo(options: ToastOptions): void {
    this.showToast(ToastSeverity.Info, options)
  }

  showWarning(options: ToastOptions): void {
    this.showToast(ToastSeverity.Warn, options)
  }

  private showToast(
    severity: ToastSeverity,
    { title, description, duration, sticky }: ToastOptions
  ): void {
    this.messageService.add({
      key: this.TOAST_KEY,
      severity,
      summary: title,
      detail: description,
      life: duration ?? DEFAULT_TOAST_DURATION,
      sticky,
    })
  }

  getNotifications(): Observable<INotification[]> {
    return combineLatest([
      this.getPaAttestestation(),
      this.blogService.getBlogAlerts(BlogCategoryEnum.InternalNews),
    ]).pipe(
      map(([paAttestation, blogAlert]) => {
        const messages = [...paAttestation, ...blogAlert]
        return messages
      })
    )
  }

  ping(): Observable<INotification[]> {
    return of([
      {
        id: 'test_message',
        label: 'Test message',
        link: '/go',
        type: AlertTypeEnum.Primary,
        message: 'This is a test message',
      },
    ])
  }

  private getPaAttestestation(): Observable<INotification[] | any[]> {
    return this.http.get('api/messages/attest/pa-1843').pipe(
      map((result) => {
        if (result) {
          return []
        }
        return [
          {
            id: 'pa_attestation',
            label: 'Pennsylvania Attestation',
            link: '/go/attestation/pennsylvania-attestation',
            type: AlertTypeEnum.Warning,
            message:
              'Before we can remit your Pennsylvania taxes, you must complete the Attestation form.',
          },
        ]
      })
    )
  }
}
