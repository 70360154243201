import * as fromAppStatus from '../app-status.state'
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store'
import { FeatureKeys } from 'src/app/enums/store.enums'
import { OnlineEnum } from 'src/app/enums/online.enums'
import { AppTypeEnum } from 'src/app/enums/app.enums'
import { DeviceEnum } from 'src/app/enums/device.enums'
import { ReturnUrl } from '../models/return-url'

const getOnline = (state: fromAppStatus.State): boolean => {
  return state.online === OnlineEnum.Online
}

const getReturnUrl = (state: fromAppStatus.State): ReturnUrl => {
  return {
    pathname: state.returnUrl?.pathname || '/',
    queryParams: state.returnUrl?.queryParams,
  }
}

const getAppType = (state: fromAppStatus.State): AppTypeEnum => {
  if (state) {
    return state.appType || AppTypeEnum.Full
  }
  return AppTypeEnum.Full
}

const getIsEmbedded = (state: fromAppStatus.State): boolean => {
  return state.appType === AppTypeEnum.Embedded
}

const getIsInstallable = (state: fromAppStatus.State): boolean => {
  if (state.installed) {
    return false
  }
  if (state.device === DeviceEnum.Iphone) {
    return true
  }
  if (state.appType === AppTypeEnum.Embedded) {
    return false
  }
  return state.installable
}

export const selectAppStatusState: MemoizedSelector<object, fromAppStatus.State> =
  createFeatureSelector<fromAppStatus.State>(FeatureKeys.AppStatus)

export const selectOnline: MemoizedSelector<object, boolean> = createSelector(
  selectAppStatusState,
  getOnline
)

export const selectReturnUrl: MemoizedSelector<object, ReturnUrl> = createSelector(
  selectAppStatusState,
  getReturnUrl
)

export const selectAppType: MemoizedSelector<object, AppTypeEnum> = createSelector(
  selectAppStatusState,
  getAppType
)

export const selectIsEmbedded: MemoizedSelector<object, boolean> = createSelector(
  selectAppStatusState,
  getIsEmbedded
)

export const selectIsInstallable: MemoizedSelector<object, boolean> = createSelector(
  selectAppStatusState,
  getIsInstallable
)
