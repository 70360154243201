import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ViewTermsOfServicePage } from '../pages/view-terms-of-service/view-terms-of-service.page'
import { ViewLatestTermsOfServicePage } from './../pages/view-latest-terms-of-service/view-latest-terms-of-service.page'

const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: '',
    },
    component: ViewTermsOfServicePage,
  },
  {
    path: 'latest',
    data: {
      breadcrumb: 'Updated Terms of Service',
    },
    component: ViewLatestTermsOfServicePage,
  },
]

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TosRoutesModule {}
