<ng-template #content>
  <div class="text-center px-5 sm:px-3">
    <ng-template #isWorking>
      <p-progressSpinner></p-progressSpinner>
    </ng-template>

    <ng-container *ngIf="token$ | async; else showTokenError">
      <ng-container *ngIf="(working$ | async) === false; else isWorking">
        <ng-container *ngIf="verified$ | async; else verifiedFailed">
          <tc-result-message
            title="Email Confirmation Success"
            [body]="successBody"
            severity="success"
            iconType="emoji"
            [footer]="successFooter"
          ></tc-result-message>
          <ng-template #successFooter>
            <button
              pButton
              type="button"
              label="Back to Login"
              class="p-button-sm w-full"
              [routerLink]="'/sign-in'"
            ></button
          ></ng-template>
          <ng-template #successBody>
            We're pleased to inform you that your email address has been successfully verified.<br /><br />
            Thank you for confirming your account
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #showTokenError>
      <tc-result-message
        title="Email Confirmation Error"
        [body]="tokenErrorBody"
        severity="error"
        iconType="emoji"
      ></tc-result-message>
      <ng-template #tokenErrorBody>
        <!-- TODO: Should this error message be different? -->
        We regret to inform you that we encountered an issue confirming your email address. <br />
        <br />Please double-check the email link we sent you and try again. If the issue persists,
        please <a href="mailto:service@taxcloud.com">contact our support</a> team for assistance.
      </ng-template>
    </ng-template>

    <ng-template #verifiedFailed>
      <tc-result-message
        title="Email Confirmation Error"
        [body]="verifiedFailedBody"
        severity="error"
        iconType="emoji"
      ></tc-result-message>
      <ng-template #verifiedFailedBody>
        We regret to inform you that we encountered an issue confirming your email address. <br />
        <br />Please double-check the email link we sent you and try again. If the issue persists,
        please <a href="mailto:service@taxcloud.com">contact our support</a> team for assistance.
      </ng-template>
    </ng-template>
  </div>
</ng-template>

<section class="flex justify-content-center h-full pt-8">
  <!-- Mobile view -->
  <div class="block sm:hidden">
    <ng-template [ngTemplateOutlet]="content"></ng-template>
  </div>

  <!-- Desktop view -->
  <p-card class="hidden sm:block sm:w-24rem">
    <ng-template [ngTemplateOutlet]="content"></ng-template>
  </p-card>
</section>
