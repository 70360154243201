import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { forkJoin, Observable, of } from 'rxjs'
import { filter, map, switchMap, tap } from 'rxjs/operators'
import { State as AppState } from 'src/app/app-status/app-status.state'
import * as appStatusSelector from 'src/app/app-status/selectors/app-status.selectors'
import { DateFormatEnum } from 'src/app/enums/date.enums'
import { IApiApiError } from '../models/iapi-api-error'
import { IApiError } from '../models/iapi-error'

import { IApiPublicServiceReport } from '../models/iapi-public-service-report.models'
import { IApiSystemHealth } from '../models/iapi-system-health'
import { IPublicServiceReport } from '../models/ipublic-service-report'
import { ISystemHealth } from '../models/isystem-health'
import { DeveloperAdapter } from '../utilities/developer-adapter'

@Injectable({
  providedIn: 'root',
})
export class DeveloperService {
  adapter = new DeveloperAdapter()
  cache: Map<string, IPublicServiceReport> = new Map()

  constructor(
    private http: HttpClient,
    private appStatus: Store<AppState>
  ) {}

  public getReportsIfOnline(numberMonths: number = 6): Observable<IPublicServiceReport[]> {
    const reports: Observable<IPublicServiceReport>[] = []

    for (let i = 0; i < numberMonths; i++) {
      reports.push(this.getApiActivity(this.getMonth(i), i > 0))
    }

    return this.appStatus.select(appStatusSelector.selectOnline).pipe(
      filter((online) => online),
      switchMap(() => forkJoin([...reports]))
    )
  }

  public getCurrentApiActivity(): Observable<IPublicServiceReport> {
    return this.getApiActivity(this.getMonth())
  }

  public getSystemHealth(): Observable<ISystemHealth> {
    return this.http
      .get<IApiSystemHealth>('api/system-health')
      .pipe(map((results) => this.adapter.mapApiSystemHealthToSystemHealth(results)))
  }

  public getApiActivity(month: string, cache: boolean = false): Observable<IPublicServiceReport> {
    if (cache) {
      if (this.cache.has(month)) {
        return of(this.cache.get(month))
      }
    }
    return this.http.post(`api/dashboard/api-activity/${month}01`, null).pipe(
      map((results: IApiPublicServiceReport) => this.adapter.mapApiResultToReport(results)),
      tap((data) => {
        if (!cache) {
          return
        }
        this.cache.set(month, data)
      })
    )
  }

  public getLatestApiErrors(): Observable<IApiError[]> {
    return this.http
      .get<IApiApiError[]>(`api/merchant/errors`)
      .pipe(map((result) => this.adapter.mapApiApiErrorsToApiErrors(result)))
  }

  public deleteCache(): void {
    this.cache.clear()
  }

  private getMonth(countBack: number = 0): string {
    return moment().startOf('month').subtract(countBack, 'months').format(DateFormatEnum.Month)
  }
}
