import { Component, OnDestroy } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { BehaviorSubject } from 'rxjs'
import { AlertResultEnum, AlertTypeEnum } from 'src/app/enums/alert.enums'
import * as fromUserActions from 'src/app/user/actions/login.actions'
import { State as UserState } from 'src/app/user/user.state'
import { SubSink } from 'subsink'

export interface IConfirmDialog {
  type: AlertTypeEnum
  message: string
  title: string
  confirmButtonText?: string
  cancelButtonText?: string
}

@Component({
  selector: 'tc-terms-of-service-dialog',
  templateUrl: './terms-of-service.dialog.html',
  styleUrls: ['./terms-of-service.dialog.sass'],
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class TermsOfServiceDialog implements OnDestroy {
  confirmButton: string
  cancelButton: string
  borderCss: string
  colorCss: string
  icon: string
  agree = false
  subs = new SubSink()
  acceptTermsOfService$ = new BehaviorSubject(false)

  constructor(
    public dialogRef: MatDialogRef<TermsOfServiceDialog>,
    private userStore: Store<UserState>
  ) {
    this.confirmButton = 'CONFIRM'
    this.cancelButton = 'CANCEL'
    this.colorCss = 'information'
    this.borderCss = 'information-border'
    this.icon = 'check'
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  onAcceptChange(acceptTermsOfService: boolean) {
    this.acceptTermsOfService$.next(acceptTermsOfService)
  }

  cancel(): void {
    this.dialogRef.close({ result: AlertResultEnum.Cancel })
  }

  confirm(): void {
    this.userStore.dispatch(new fromUserActions.AcceptTermsOfService())
    this.dialogRef.close({ result: AlertResultEnum.Confirm })
  }
}
