import { Component } from '@angular/core'

@Component({
  selector: 'tc-card-title',
  templateUrl: './card-title.component.html',
  styleUrls: ['./card-title.component.sass'],
})
export class CardTitleComponent {
  constructor() {}
}
