import {
  DirectFilingAcceptanceStatusEnumLabel,
  TaxReturnTypeEnum,
  TaxReturnTypeEnumLabel,
} from 'src/app/enums/direct-filing.enums'
import { validateDate } from 'src/app/utilities/date.utilities'
import {
  IApiDirectFilingFormDr15,
  IDirectFilingFormDr15,
  IApiDirectFilingFormDr15Ez,
  IDirectFilingFormDr15Ez,
  IMerchantFiling,
} from './../models/imerchant-filing'

export class DirectFilingAdapter {
  public mapMerchantFilingApiResponseToMerchantFiling(response: IMerchantFiling): IMerchantFiling {
    return {
      merchantId: response.merchantId,
      merchantName: response.merchantName,
      filingFrequency: response.filingFrequency,
      filingForm: response.filingForm,
      filingFormLabel: TaxReturnTypeEnumLabel.get(response.filingForm),
      totalTax: response.totalTax,
      filingPeriod: response.filingPeriod,
      // Need a way to prevent resubmission of a submitted row until acceptance status result
      acceptanceStatusProd: response.acceptanceStatusProd,
      acceptanceStatusProdLabel: DirectFilingAcceptanceStatusEnumLabel.get(
        response.acceptanceStatusProd
      ),
      filingIds: response.filingIds,
      lastSubmissionToProd: validateDate(response.lastSubmissionToProd),
      lastSubmissionToTest: validateDate(response.lastSubmissionToTest),
      acceptanceStatusTest: response.acceptanceStatusTest,
      acceptanceStatusTestLabel: DirectFilingAcceptanceStatusEnumLabel.get(
        response.acceptanceStatusTest
      ),
    }
  }
  public mapApiFormDr15DetailstoFormDetails(
    apiFormDetails: IApiDirectFilingFormDr15,
    filingIds: string,
    taxReturnType: TaxReturnTypeEnum
  ): IDirectFilingFormDr15 {
    return {
      ...apiFormDetails,
      filingIds,
      taxReturnType,
    }
  }
  public mapApiFormDr15EzDetailstoFormDetails(
    apiFormDetails: IApiDirectFilingFormDr15Ez,
    filingIds: string,
    taxReturnType: TaxReturnTypeEnum
  ): IDirectFilingFormDr15Ez {
    return {
      ...apiFormDetails,
      filingIds,
      taxReturnType,
    }
  }

  public mapFilingIdsForApi(
    formType: TaxReturnTypeEnum,
    merchantFilings: IMerchantFiling[]
  ): string[] {
    return merchantFilings
      .filter((filing) => filing.filingForm === formType)
      .map((filing) => filing.filingIds)
  }
}
