import { ChangeDetectionStrategy, Component } from '@angular/core'
import { MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { Router } from '@angular/router'

import { AttestationService } from '../../services/attestation.service'
import moment from 'moment'

@Component({
  templateUrl: './add-pa-attestation.dialog.html',
  styleUrls: ['./add-pa-attestation.dialog.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPaAttestationDialog {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<AttestationService>,
    private router: Router
  ) {}

  goToAttestation(): void {
    this.bottomSheetRef.dismiss()
    this.router.navigate(['/go/attestation/pennsylvania-attestation'])
  }

  remindMeLater(): void {
    const expiresDate = moment().add(7, 'd').toDate()
    document.cookie = `hideAddPaAttestationDialog=true; expires = ${expiresDate.toUTCString()}`
    this.close()
  }

  close(): void {
    this.bottomSheetRef.dismiss()
  }
}
