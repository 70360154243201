import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import { Action, Store } from '@ngrx/store'
import * as fromUsStateActions from './../actions/us-states.actions'
import { UsStateActionTypes } from './../actions/us-states.actions'
import { USStateService } from '../services/us-state.service'
import { switchMap, map, filter, mergeMap, withLatestFrom, catchError, tap } from 'rxjs/operators'
import { State } from './../us-states.store'
import * as fromUsStateSelectors from './../selectors/us-state.selector'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'
import { NotificationService } from 'src/app/notification/services/notification.service'

@Injectable()
export class UsStatesEffects {
  constructor(
    private actions$: Actions,
    private usStateService: USStateService,
    private usStateStore: Store<State>,
    private notificationService: NotificationService
  ) {}

  requestStates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<
        | fromUsStateActions.InitStatesGoShell
        | fromUsStateActions.InitStatesGetStartedShell
        | fromUsStateActions.InitStatesCollecting
        | fromUsStateActions.InitStatesCollectingDetail
      >(
        UsStateActionTypes.InitStatesGoShell,
        UsStateActionTypes.InitStatesGetStartedShell,
        UsStateActionTypes.InitStatesCollecting,
        UsStateActionTypes.InitStatesCollectingDetail
      ),
      withLatestFrom(this.usStateStore.select(fromUsStateSelectors.selectDataSource)),
      switchMap(([_, dataSource]) => {
        if (dataSource === DataSourceEnum.API) {
          return of(new fromUsStateActions.InitStatesNotNeeded())
        }
        return this.usStateService
          .getUsStates()
          .pipe(
            map(
              ([usStates, source]) => new fromUsStateActions.InitStatesSuccess({ usStates, source })
            )
          )
      })
    )
  )

  refreshStatesFromApi$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromUsStateActions.RefreshUsStates>(UsStateActionTypes.RefreshUsStates),
      switchMap(() => {
        return this.usStateService
          .refreshUsStates()
          .pipe(map((usStates) => new fromUsStateActions.RefreshUsStatesSuccess({ usStates })))
      })
    )
  )

  updateBasicFiling$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromUsStateActions.UpdateBasicFilingLocal>(UsStateActionTypes.UpdateBasicFilingLocal),
      map((action) => {
        return new fromUsStateActions.UpdateBasicFilingDatabase({
          id: action.payload.update.id.toString(),
        })
      })
    )
  )

  updateDBasicFilingDatabase$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromUsStateActions.UpdateBasicFilingDatabase>(
        UsStateActionTypes.UpdateBasicFilingDatabase
      ),
      withLatestFrom(this.usStateStore.select(fromUsStateSelectors.selectEntities)),
      mergeMap(([action, entities]) => {
        const usState = entities[action.payload.id]
        return this.usStateService
          .updateBasicFiling(usState)
          .pipe(catchError(() => of(new fromUsStateActions.UpdateDatabaseFailure())))
      }),
      map(() => new fromUsStateActions.UpdateDatabaseSuccess())
    )
  )

  updateDirectFiling$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromUsStateActions.UpdateDirectFilingLocal>(
        UsStateActionTypes.UpdateDirectFilingLocal
      ),
      map((action) => {
        return new fromUsStateActions.UpdateDirectFilingDatabase({
          id: action.payload.update.id.toString(),
        })
      })
    )
  )

  updateDBasicDirectFilingDatabase$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromUsStateActions.UpdateDirectFilingDatabase>(
        UsStateActionTypes.UpdateDirectFilingDatabase
      ),
      withLatestFrom(this.usStateStore.select(fromUsStateSelectors.selectEntities)),
      mergeMap(([action, entities]) => {
        const usState = entities[action.payload.id]
        return this.usStateService
          .updateDirectFiling(usState)
          .pipe(catchError(() => of(new fromUsStateActions.UpdateDatabaseFailure())))
      }),
      map(() => new fromUsStateActions.UpdateDatabaseSuccess())
    )
  )

  updateDatabaseSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromUsStateActions.UpdateDatabaseSuccess>(UsStateActionTypes.UpdateDatabaseSuccess),
      map(() => new fromUsStateActions.RefreshUsStates())
    )
  )

  updateDatabaseFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromUsStateActions.UpdateDatabaseFailure>(UsStateActionTypes.UpdateDatabaseFailure),
      tap(() => this.notificationService.showError({ title: 'Update Collection Failed' })),
      map(() => new fromUsStateActions.RefreshUsStates())
    )
  )
}
