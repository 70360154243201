import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AddressModule } from '../address/address.module'
import { InitMaterialModule } from '../material/init-material.module'
import { InitUiModule } from '../ui/init-ui/init-ui.module'
import { UsStatesModule } from '../us-states/us-states.module'
import { ContactFormComponent } from './components/contact-form/contact-form.component'
import { FooterComponent } from './components/footer/footer.component'
import { TestimonialComponent } from './components/testimonial/testimonial.component'
import { HomeVideoDialog } from './dialogs/home-video/home-video.dialog'
import { AboutTaxcloudPage } from './pages/about-taxcloud/about-taxcloud.page'
import { BrandPage } from './pages/brand/brand.page'
import { ContactTaxcloudPage } from './pages/contact-taxcloud/contact-taxcloud.page'
import { FindRatePage } from './pages/find-rate/find-rate.page'
import { HomePage } from './pages/home/home.page'
import { PressPage } from './pages/press/press.page'
import { PrivacyPolicyPage } from './pages/privacy-policy/privacy-policy.page'
import { SalesTaxPolicyPage } from './pages/sales-tax-policy/sales-tax-policy.page'
import { SecurityPolicyPage } from './pages/security-policy/security-policy.page'
import { StatesGuidesPage } from './pages/state-guides/state-guides.page'
import { PublicRoutesModule } from './routes/public.routes'
import { ShellPublicComponent } from './shell-public/shell-public.component'
import { FeaturesPage } from './pages/features/features.page'
import { ShellPublicBasicComponent } from './shell-public-basic/shell-public-basic.component'

@NgModule({
  declarations: [
    ShellPublicComponent,
    AboutTaxcloudPage,
    HomePage,
    FindRatePage,
    StatesGuidesPage,
    HomeVideoDialog,
    ContactTaxcloudPage,
    ContactFormComponent,
    FooterComponent,
    TestimonialComponent,
    PressPage,
    BrandPage,
    PrivacyPolicyPage,
    SecurityPolicyPage,
    SalesTaxPolicyPage,
    FeaturesPage,
    ShellPublicBasicComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    InitMaterialModule,
    InitUiModule,
    PublicRoutesModule,
    AddressModule,
    UsStatesModule,
  ],
})
export class PublicModule {}
