import { Action } from '@ngrx/store'
import {
  Customer,
  CustomerUsage,
  CustomerUsageReport,
  Entitlement,
  Invoice,
  PaymentMethod,
  PlanDetail,
  PlanSubscription,
  SubscriptionEntitlements,
} from '../models/billing.model'
import { PaymentMethodType, PaymentRole } from '../enums/payments.enums'
import { SubscriptionPlanTier } from '../enums/suscriptions.enums'

export enum BillingActionTypes {
  InitPaymentMethods = 'Init loading payment methods',
  InitPaymentMethodsSuccess = 'Init loading payment methods Success',
  InitPaymentMethodsFailed = 'Init loading payment methods Failed',

  AddPaymentMethod = 'Add payment method',
  AddPaymentMethodSuccess = 'Add payment method success',
  AddPaymentMethodFailed = 'Add payment method failed',

  DeletePaymentMethod = 'Delete payment method',
  DeletePaymentMethodSuccess = 'Delete payment method success',
  DeletePaymentMethodFailed = 'Delete payment method failed',

  InitSubscription = 'Init loading subscription',
  InitSubscriptionSuccess = 'Init loading subscription Success',
  InitSubscriptionFailed = 'Init loading subscription Failed',

  InitPendingSubscription = 'Init loading pending subscription',
  InitPendingSubscriptionSuccess = 'Init loading pending subscription Success',
  InitPendingSubscriptionFailed = 'Init loading pending subscription Failed',

  PutSubscription = 'Put Subscription',
  PutSubscriptionSuccess = 'Put Subscription Success',
  PutSubscriptionFailed = 'Put Subscription Failed',

  CancelSubscription = 'Cancel Subscription',
  CancelSubscriptionSuccess = 'Cancel Subscription Success',
  CancelSubscriptionFailed = 'Cancel Subscription Failed',

  SubscribeToTrial = 'Subscribe To Trial',
  CloseWelcomeToTrial = 'Close Welcome To Trial',

  InitAchUploadPaymentSession = 'Init ACH Payment Session',
  InitAchUploadPaymentSessionSuccess = 'Init ACH Payment Session Success',
  InitAchUploadPaymentSessionFailed = 'Init ACH Payment Session Failed',

  InitPlan = 'Init plan',
  InitPlanSuccess = 'Init plan Success',
  InitPlanFailed = 'Init plan Failed',

  setIsEditingPlan = 'Set Is Loading Plan',

  InitCustomerProfile = 'Init Customer profile',
  InitCustomerProfileSuccess = 'Init Customer profile success',
  InitCustomerProfileFailed = 'Init Customer profile failed',

  InitInvoices = 'Init Invoices',
  InitInvoicesSuccess = 'InitInvoices success',
  InitInvoicesFailed = 'InitInvoices failed',

  AssignPaymentRole = 'Assign Payment Role',
  AssignPaymentRoleSuccess = 'Assign Payment Role success',
  AssignPaymentRoleFailed = 'Assign Payment Role failed',

  InitInvoicePdfDownload = 'Init Invoice PDF Download',
  InitInvoicePdfDownloadSuccess = 'Init Invoice PDF Download success',
  InitInvoicePdfDownloadFailed = 'Init Invoice PDF Download failed',

  ClearBillingState = 'Clear Billing state',

  InitCustomerUsage = 'Init Customer usage',
  InitCustomerUsageSuccess = 'Init Customer usage success',
  InitCustomerUsageFailed = 'Init Customer usage failed',

  InitUsageReports = 'Init usage reports',
  InitUsageReportsSuccess = 'Init usage reports success',
  InitUsageReportsFailed = 'Init usage reports failed',

  InitUsageReportDetails = 'Init usage report details',
  InitUsageReportDetailsSuccess = 'Init usage report details success',
  InitUsageReportDetailsFailed = 'Init usage report details failed',

  UpdateBillingInfo = 'Update Billing Info',
  UpdateBillingInfoSuccess = 'Update Billing Info success',
  UpdateBillingInfoFailed = 'Update Billing Info failed',

  InitSubscriptionEntitlements = 'Init subscription entitlements',
  InitSubscriptionEntitlementsSuccess = 'Init subscription entitlements Success',
  InitSubscriptionEntitlementsFailed = 'Init subscription entitlements Failed',

  InitEntitlements = 'Init entitlements',
  InitEntitlementsSuccess = 'Init entitlements Success',
  InitEntitlementsFailed = 'Init entitlements Failed',
}

export class InitPaymentMethods implements Action {
  readonly type = BillingActionTypes.InitPaymentMethods
}

export class InitPaymentMethodsSuccess implements Action {
  readonly type = BillingActionTypes.InitPaymentMethodsSuccess
  constructor(public payload: { paymentMethods: PaymentMethod[] }) {}
}

export class InitPaymentMethodsFailed implements Action {
  readonly type = BillingActionTypes.InitPaymentMethodsFailed
}

export class AddPaymentMethod implements Action {
  readonly type = BillingActionTypes.AddPaymentMethod
  constructor(
    public payload: { customerId: number; tokenId: string; paymentSourceType: PaymentMethodType }
  ) {}
}

export class AddPaymentMethodSuccess implements Action {
  readonly type = BillingActionTypes.AddPaymentMethodSuccess
  constructor(public payload: { paymentMethod: PaymentMethod }) {}
}

export class AddPaymentMethodFailed implements Action {
  readonly type = BillingActionTypes.AddPaymentMethodFailed
}

export class DeletePaymentMethod implements Action {
  readonly type = BillingActionTypes.DeletePaymentMethod
  constructor(public payload: { id: string }) {}
}

export class DeletePaymentMethodSuccess implements Action {
  readonly type = BillingActionTypes.DeletePaymentMethodSuccess
  constructor(public payload: { id: string }) {}
}

export class DeletePaymentMethodFailed implements Action {
  readonly type = BillingActionTypes.DeletePaymentMethodFailed
}

export class InitSubscription implements Action {
  readonly type = BillingActionTypes.InitSubscription
  constructor(public payload: { suppress404Error: boolean }) {}
}

export class InitSubscriptionSuccess implements Action {
  readonly type = BillingActionTypes.InitSubscriptionSuccess
  constructor(public payload: { subscription: PlanSubscription }) {}
}

export class InitSubscriptionFailed implements Action {
  readonly type = BillingActionTypes.InitSubscriptionFailed
}

export class InitPendingSubscription implements Action {
  readonly type = BillingActionTypes.InitPendingSubscription
  constructor(public payload: { suppress404Error: boolean }) {}
}

export class InitPendingSubscriptionSuccess implements Action {
  readonly type = BillingActionTypes.InitPendingSubscriptionSuccess
  constructor(public payload: { subscription: PlanSubscription }) {}
}

export class InitPendingSubscriptionFailed implements Action {
  readonly type = BillingActionTypes.InitPendingSubscriptionFailed
}

export class PutSubscription implements Action {
  readonly type = BillingActionTypes.PutSubscription
  constructor(public payload: { priceId: string; quantity: number; isFreeTrial?: boolean }) {}
}

export class PutSubscriptionSuccess implements Action {
  readonly type = BillingActionTypes.PutSubscriptionSuccess
  constructor(public payload: { subscription: PlanSubscription }) {}
}

export class PutSubscriptionFailed implements Action {
  readonly type = BillingActionTypes.PutSubscriptionFailed
}

export class CancelSubscription implements Action {
  readonly type = BillingActionTypes.CancelSubscription
  constructor(public payload: { priceId: string }) {}
}

export class CancelSubscriptionSuccess implements Action {
  readonly type = BillingActionTypes.CancelSubscriptionSuccess
  constructor(public payload: { subscription: PlanSubscription }) {}
}

export class CancelSubscriptionFailed implements Action {
  readonly type = BillingActionTypes.CancelSubscriptionFailed
}

export class SubscribeToTrial implements Action {
  readonly type = BillingActionTypes.SubscribeToTrial
}

export class CloseWelcomeToTrial implements Action {
  readonly type = BillingActionTypes.CloseWelcomeToTrial
}

export class InitAchUploadPaymentSession implements Action {
  readonly type = BillingActionTypes.InitAchUploadPaymentSession
}

export class InitAchUploadPaymentSessionSuccess implements Action {
  readonly type = BillingActionTypes.InitAchUploadPaymentSessionSuccess
  constructor(public payload: { billingRequestFlowId: string }) {}
}

export class InitAchUploadPaymentSessionFailed implements Action {
  readonly type = BillingActionTypes.InitAchUploadPaymentSessionFailed
}

export class InitPlan implements Action {
  readonly type = BillingActionTypes.InitPlan
  constructor(public payload: { tierId: SubscriptionPlanTier }) {}
}

export class InitPlanSuccess implements Action {
  readonly type = BillingActionTypes.InitPlanSuccess
  constructor(public payload: { plan: PlanDetail }) {}
}

export class InitPlanFailed implements Action {
  readonly type = BillingActionTypes.InitPlanFailed
}

export class SetIsEditingPlan implements Action {
  readonly type = BillingActionTypes.setIsEditingPlan
  constructor(public payload: { isEditingPlan: boolean }) {}
}

export class InitCustomerProfile implements Action {
  readonly type = BillingActionTypes.InitCustomerProfile
}

export class InitCustomerProfileSuccess implements Action {
  readonly type = BillingActionTypes.InitCustomerProfileSuccess
  constructor(public payload: { customer: Customer }) {}
}

export class InitCustomerProfileFailed implements Action {
  readonly type = BillingActionTypes.InitCustomerProfileFailed
}

export class InitInvoices implements Action {
  readonly type = BillingActionTypes.InitInvoices
}

export class InitInvoicesSuccess implements Action {
  readonly type = BillingActionTypes.InitInvoicesSuccess
  constructor(public payload: { invoices: Invoice[] }) {}
}

export class InitInvoicesFailed implements Action {
  readonly type = BillingActionTypes.InitInvoicesFailed
}

export class AssignPaymentRole implements Action {
  readonly type = BillingActionTypes.AssignPaymentRole
  constructor(public payload: { paymentSourceId: string; paymentRole: PaymentRole }) {}
}

export class AssignPaymentRoleSuccess implements Action {
  readonly type = BillingActionTypes.AssignPaymentRoleSuccess
}

export class AssignPaymentRoleFailed implements Action {
  readonly type = BillingActionTypes.AssignPaymentRoleFailed
}

export class InitInvoicePdfDownload implements Action {
  readonly type = BillingActionTypes.InitInvoicePdfDownload
  constructor(public payload: { invoiceId: string }) {}
}

export class InitInvoicePdfDownloadSuccess implements Action {
  readonly type = BillingActionTypes.InitInvoicePdfDownloadSuccess
  constructor(public payload: { invoiceLink: string }) {}
}

export class InitInvoicePdfDownloadFailed implements Action {
  readonly type = BillingActionTypes.InitInvoicePdfDownloadFailed
}

export class ClearBillingState implements Action {
  readonly type = BillingActionTypes.ClearBillingState
  constructor(public payload: { fetchNewData: boolean }) {}
}

export class InitGetCustomerUsage implements Action {
  readonly type = BillingActionTypes.InitCustomerUsage
}

export class InitGetCustomerUsageSuccess implements Action {
  readonly type = BillingActionTypes.InitCustomerUsageSuccess
  constructor(public payload: { customerUsage: CustomerUsage }) {}
}

export class InitGetCustomerUsageFailed implements Action {
  readonly type = BillingActionTypes.InitCustomerUsageFailed
}

export class InitGetUsageReports implements Action {
  readonly type = BillingActionTypes.InitUsageReports
}

export class InitGetUsageReportsSuccess implements Action {
  readonly type = BillingActionTypes.InitUsageReportsSuccess
  constructor(public payload: { usageReports: CustomerUsageReport[] }) {}
}

export class InitGetUsageReportsFailed implements Action {
  readonly type = BillingActionTypes.InitUsageReportsFailed
}

export class InitGetUsageReportDetails implements Action {
  readonly type = BillingActionTypes.InitUsageReportDetails
  constructor(public payload: { usageReportId: string }) {}
}

export class InitGetUsageReportDetailsSuccess implements Action {
  readonly type = BillingActionTypes.InitUsageReportDetailsSuccess
  constructor(public payload: { usageReportDetails: CustomerUsageReport }) {}
}

export class InitGetUsageReportDetailsFailed implements Action {
  readonly type = BillingActionTypes.InitUsageReportDetailsFailed
}

export class UpdateBillingInfo implements Action {
  readonly type = BillingActionTypes.UpdateBillingInfo
  constructor(public payload: { customer: Partial<Customer> }) {}
}

export class UpdateBillingInfoSuccess implements Action {
  readonly type = BillingActionTypes.UpdateBillingInfoSuccess
  constructor(public payload: { customer: Customer }) {}
}

export class UpdateBillingInfoFailed implements Action {
  readonly type = BillingActionTypes.UpdateBillingInfoFailed
}

export class InitSubscriptionEntitlements implements Action {
  readonly type = BillingActionTypes.InitSubscriptionEntitlements
  constructor(public payload: { subscriptionId: string }) {}
}

export class InitSubscriptionEntitlementsSuccess implements Action {
  readonly type = BillingActionTypes.InitSubscriptionEntitlementsSuccess
  constructor(public payload: { entitlements: SubscriptionEntitlements[] }) {}
}

export class InitSubscriptionEntitlementsFailed implements Action {
  readonly type = BillingActionTypes.InitSubscriptionEntitlementsFailed
}

export class InitEntitlements implements Action {
  readonly type = BillingActionTypes.InitEntitlements
  constructor(public payload: { subscriptionPlanTier: SubscriptionPlanTier }) {}
}

export class InitEntitlementsSuccess implements Action {
  readonly type = BillingActionTypes.InitEntitlementsSuccess
  constructor(public payload: { entitlements: Entitlement[] }) {}
}

export class InitEntitlementsFailed implements Action {
  readonly type = BillingActionTypes.InitEntitlementsFailed
}

export type BillingActions =
  | InitPaymentMethods
  | InitPaymentMethodsSuccess
  | InitPaymentMethodsFailed
  | AddPaymentMethod
  | AddPaymentMethodSuccess
  | AddPaymentMethodFailed
  | DeletePaymentMethod
  | DeletePaymentMethodSuccess
  | DeletePaymentMethodFailed
  | InitSubscription
  | InitSubscriptionSuccess
  | InitSubscriptionFailed
  | InitPendingSubscription
  | InitPendingSubscriptionSuccess
  | InitPendingSubscriptionFailed
  | PutSubscription
  | PutSubscriptionSuccess
  | PutSubscriptionFailed
  | CancelSubscription
  | CancelSubscriptionSuccess
  | CancelSubscriptionFailed
  | SubscribeToTrial
  | CloseWelcomeToTrial
  | InitAchUploadPaymentSession
  | InitAchUploadPaymentSessionSuccess
  | InitAchUploadPaymentSessionFailed
  | InitPlan
  | InitPlanSuccess
  | InitPlanFailed
  | SetIsEditingPlan
  | InitCustomerProfile
  | InitCustomerProfileSuccess
  | InitCustomerProfileFailed
  | InitInvoices
  | InitInvoicesSuccess
  | InitInvoicesFailed
  | AssignPaymentRole
  | AssignPaymentRoleSuccess
  | AssignPaymentRoleFailed
  | InitInvoicePdfDownload
  | InitInvoicePdfDownloadSuccess
  | InitInvoicePdfDownloadFailed
  | ClearBillingState
  | InitGetCustomerUsage
  | InitGetCustomerUsageSuccess
  | InitGetCustomerUsageFailed
  | InitGetUsageReports
  | InitGetUsageReportsSuccess
  | InitGetUsageReportsFailed
  | InitGetUsageReportDetails
  | InitGetUsageReportDetailsSuccess
  | InitGetUsageReportDetailsFailed
  | UpdateBillingInfo
  | UpdateBillingInfoSuccess
  | UpdateBillingInfoFailed
  | InitSubscriptionEntitlements
  | InitSubscriptionEntitlementsSuccess
  | InitSubscriptionEntitlementsFailed
