import { DirectFileManagerPage } from './../pages/direct-file-manager/direct-file-manager.page'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { ListCollectingUsStatesPage } from '../pages/list-collecting-us-states/list-collecting-us-states.page'
import { UsStateDetailPage } from '../pages/us-state-detail/us-state-detail.page'

const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: '',
    },
    component: ListCollectingUsStatesPage,
  },
  {
    path: 'direct-file-manager',
    data: {
      breadcrumb: 'direct file manager',
    },
    component: DirectFileManagerPage,
  },
  {
    path: ':id',
    data: {
      breadcrumb: 'detail',
    },
    component: UsStateDetailPage,
  },
]

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsStatesRoutesModule {}
