<div
  fxLayout="column"
  fxLayoutAlign="flex-start center"
  fxLayoutGap="1rem"
  class="margin_t_sm mat-display-1"
>
  <div data-cy="merchantName">AGREED ON BEHALF OF {{ merchantName$ | async }} ("MERCHANT")</div>
  <div>Agreed {{ today | date }}</div>
  <div data-cy="userName">by: {{ userName$ | async }}</div>

  <div>
    <form [formGroup]="agreeForm" class="margin_t_sm">
      <mat-checkbox formControlName="agree"> I UNDERSTAND AND AGREE </mat-checkbox>
    </form>
  </div>
</div>
