import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'
import { FeatureKeys } from 'src/app/enums/store.enums'

import { adapter, State } from './../merchant-store.store'

const getDataSource = (state: State): DataSourceEnum => {
  return state.source
}

const getIsLoaded = (state: State): boolean => {
  return state.initialLoadComlete
}

const getIsWorking = (state: State): boolean => {
  return !!state.working
}

const getWorkingMessage = (state: State): string => {
  return state.working
}

export const selectMerchantStoreState: MemoizedSelector<object, State> =
  createFeatureSelector<State>(FeatureKeys.MerchantStores)

export const selectDataSource: MemoizedSelector<object, DataSourceEnum> = createSelector(
  selectMerchantStoreState,
  getDataSource
)

export const selectStoresLoaded: MemoizedSelector<object, boolean> = createSelector(
  selectMerchantStoreState,
  getIsLoaded
)

export const selectIsWorking: MemoizedSelector<object, boolean> = createSelector(
  selectMerchantStoreState,
  getIsWorking
)

export const selectWorkingMessage: MemoizedSelector<object, string> = createSelector(
  selectMerchantStoreState,
  getWorkingMessage
)

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectMerchantStoreState)
