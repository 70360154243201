import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects'
import * as fromAppStatus from './../app-status.state'
import { AppStatusService } from '../services/app-status.service'
import { Store, Action } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import * as AppStatusActions from '../actions/app-status.actions'
import { AppStatusActionTypes } from '../actions/app-status.actions'
import { switchMap, map, catchError, tap } from 'rxjs/operators'
import { OnlineEnum } from 'src/app/enums/online.enums'
import { NotificationService } from 'src/app/notification/services/notification.service'

@Injectable()
export class AppStatusEffects {
  constructor(
    private actions$: Actions,
    private appStatusService: AppStatusService,
    public notificationService: NotificationService,
    private store: Store<fromAppStatus.State>
  ) {}

  loadCurrentOnline$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<AppStatusActions.LoadAppStatus>(AppStatusActionTypes.LoadAppStatus),
      switchMap((_) =>
        this.appStatusService.getOnlineStatus().pipe(
          map((onlineStatus) => {
            return onlineStatus === OnlineEnum.Online
              ? new AppStatusActions.AppOnline()
              : new AppStatusActions.AppOffline()
          })
          // catchError(() => of(new AppStatusActions.SetAppStatusFailed(new ServiceMessage.CurrentAppStatus().errorMessage)))
        )
      )
    )
  )

  loadCurrentDevice$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<AppStatusActions.LoadAppStatus>(AppStatusActionTypes.LoadAppStatus),
      switchMap((_) =>
        this.appStatusService
          .getDevice()
          .pipe(map((device) => new AppStatusActions.SetDevice(device)))
      )
    )
  )

  loadCurrentStandalone$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<AppStatusActions.LoadAppStatus>(AppStatusActionTypes.LoadAppStatus),
      switchMap((_) =>
        this.appStatusService
          .getStandaloneStatus()
          .pipe(map((standalone) => new AppStatusActions.SetInstalled(standalone)))
      )
    )
  )

  offline$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AppStatusActions.Offline>(AppStatusActionTypes.Offline),
        tap((_) => {
          this.notificationService.showError({ title: 'Offline' })
        })
      ),
    { dispatch: false }
  )

  online$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AppStatusActions.Online>(AppStatusActionTypes.Online),
        tap((_) => {
          this.notificationService.showSuccess({ title: 'Back Online' })
        })
      ),
    { dispatch: false }
  )

  installApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AppStatusActions.Online>(AppStatusActionTypes.InstallApp),
        tap((_) => {
          this.appStatusService.installApp()
        })
      ),
    { dispatch: false }
  )
}
