import { LocationTypeEnum, LocationTypeLabel } from 'src/app/enums/location.enums'
import { USStateService } from 'src/app/us-states/services/us-state.service'
import { validateDate } from 'src/app/utilities/date.utilities'

import { IApiLocation } from '../models/iapi-location'
import { IApiLocationEdit } from '../models/iapi-location-edit'
import { ILocation } from '../models/ilocation'
import { ILocationUpdate } from '../models/ilocationupdate'
import { splitZip } from './location.utility'

export class LocationAdapter {
  constructor(private stateService: USStateService) {}

  public mapLocationToLocationApi(location: ILocation): IApiLocationEdit {
    let zip: string
    const zip5 = location.address.zip5 ? location.address.zip5.toString() : ''
    const zip4 = location.address.zip4 ? location.address.zip4.toString() : ''
    const address2 = location.address.address2 || ''

    if (zip4.length > 1) {
      zip = `${zip5}-${zip4}`
    } else {
      zip = zip5
    }
    return {
      locationId: location.locationId.toString(),
      name: location.name,
      type: location.type,
      address1: location.address.address1,
      address2,
      city: location.address.city,
      state: location.address.stateAbbreviation,
      zip5,
      zip4,
      country: null,
      companyName: null,
      county: null,
      zip,
    }
  }

  public mapApiToLocation(location: IApiLocation): ILocation {
    const [zip5, zip4] = splitZip(location.zipPostal)

    let label = 'Unknown Type'
    if (LocationTypeLabel.has(location.typeID as LocationTypeEnum)) {
      label = LocationTypeLabel.get(location.typeID as LocationTypeEnum)
    }
    const item: ILocation = {
      address: {
        address1: location.address1,
        address2: location.address2,
        city: location.city,
        country: location.countryCode,
        stateAbbreviation: location.stateAbbr,
        stateFIPSCode: location.stateFIPSCode,
        zip5,
        zip4,
      },
      canEdit: location.canEdit,
      countryTaxLicense: location.countryTaxLicense,
      createdOn: validateDate(location.createdOn),
      deletedOn: validateDate(location.deletedOn),
      hasCountryTaxLicense: location.hasCountryTaxLicense,
      hasLocalTaxLicense: location.hasLocalTaxLicense,
      hasStateTaxLicense: location.hasStateTaxLicense,
      label,
      localLicenseRequired: location.localLicenseRequired,
      localTaxLicense: location.localTaxLicense,
      locationId: location.locationID,
      name: location.name,
      stateTaxLicense: location.stateTaxLicense,
      type: location.typeID,
    }
    return item
  }

  public mapLocationUpdateToLocationApi(location: ILocationUpdate): IApiLocationEdit {
    let zip: string
    const zip5 = location.address?.zip5 ? location.address.zip5.toString() : ''
    const zip4 = location.address?.zip4 ? location.address.zip4.toString() : ''
    const address2 = location.address?.address2 || ''

    if (zip4.length > 1) {
      zip = `${zip5}-${zip4}`
    } else {
      zip = zip5
    }

    const mapped: IApiLocationEdit = {
      name: location.name,
      type: location.type,
      address1: location.address?.address1,
      address2,
      city: location.address?.city,
      state: location.address?.stateAbbreviation,
      zip5,
      zip4,
      country: null,
      companyName: null,
      county: null,
      zip,
    }

    return mapped
  }
}
