import { PaTaxNumberTypeEnum } from 'src/app/enums/attestations.enums'

import { IApiPaAttestation, IPaAttestation } from '../models/pa-attestation.model'

export class PaAdapterUtility {
  constructor() {}

  public mapFormToApi(form: IPaAttestation): IApiPaAttestation {
    const { address, physicalNexus, taxId } = form
    const { otherService, employees, property } = physicalNexus
    const api: IApiPaAttestation = {
      AuthorizedSigner: form.printName,
      BusinessName: form.businessName,
      BusinessStreetAddress: address.address1,
      City: address.city,
      DaytimeTelephoneNumber: form.phone,
      Email: form.email,
      Employees: employees,
      FEIN: taxId.type === PaTaxNumberTypeEnum.FEIN,
      FiveDigitZipCode: address.zip5,
      HasNexus: form.hasPhysicalNexus,
      OtherServices: otherService,
      PrintName: form.printName,
      Property: property,
      SSN: taxId.type === PaTaxNumberTypeEnum.SSN,
      State: address.stateAbbreviation,
      StateID: form.registrationId,
      TaxIdentificationNumber: taxId.number,
      Title: form.title,
    }
    return api
  }
}
