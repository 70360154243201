export enum BillingMessageEnum {
  ShopifyPluginV1 = 'Charges for your Shopify extractor are invoiced and paid to Shopify.',
  LegacyShopify = ' After you install and subscribe to the Simple Sales Tax for Shopify app, billing will take place in your Shopify invoice.',
}

export enum BillingFrequencyEnum {
  Annually = 'annually',
  Monthly = 'monthly',
}
export const BillingMessageEnumLabel = new Map<BillingMessageEnum, string>([
  [BillingMessageEnum.ShopifyPluginV1, 'ShopifyPluginV1'],
  [BillingMessageEnum.LegacyShopify, 'LegacyShopify'],
])

export const BillingFrequencyEnumLabel = new Map<BillingFrequencyEnum, string>([
  [BillingFrequencyEnum.Annually, 'Bill Yearly'],
  [BillingFrequencyEnum.Monthly, 'Bill Monthly'],
])
