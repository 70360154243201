import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import * as fromRouter from '@ngrx/router-store'
import { select, Store } from '@ngrx/store'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  switchMap,
  tap,
} from 'rxjs/operators'
import { ShellEnum } from 'src/app/enums/shell.enums'
import { SupportCategoryEnum, SupportCategoryEnumLabel } from 'src/app/enums/support.enums'
import { IRouterState } from 'src/app/root/models/irouterstate'
import * as fromRouterSelectors from 'src/app/root/selectors/root.selectors'
import { getOptionsFromLabel, IOption } from 'src/app/utilities/select-box.utilities'
import { SubSink } from 'subsink'

import { ISupportPage } from '../../models/isupport-page'
import { SupportService } from '../../services/support.service'

@Component({
  templateUrl: './list-support.page.html',
  styleUrls: ['./list-support.page.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListSupportPage implements OnInit, OnDestroy {
  public options: IOption[] = getOptionsFromLabel(SupportCategoryEnumLabel)
  public formGroup: UntypedFormGroup
  public category: UntypedFormControl = new UntypedFormControl(SupportCategoryEnum.All)
  public categoryName: string
  public search: UntypedFormControl = new UntypedFormControl('')
  private pages$: Subject<ISupportPage[]> = new Subject()
  public working$: BehaviorSubject<boolean> = new BehaviorSubject(false)

  private subs = new SubSink()

  constructor(
    private supportService: SupportService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: Store<fromRouter.RouterReducerState<IRouterState>>
  ) {
    this.formGroup = fb.group({
      category: this.category,
      search: this.search,
    })
  }

  private dictOfPages$: Observable<Map<SupportCategoryEnum, ISupportPage[]>> =
    this.supportService.getAllPages()

  public allPages$: Observable<[string, SupportCategoryEnum, ISupportPage[]][]> =
    this.dictOfPages$.pipe(
      map((dict) => Array.from(dict.entries())),
      map(
        (allPages: [SupportCategoryEnum, ISupportPage[]][]) =>
          allPages.map((mapped) => {
            return [SupportCategoryEnumLabel.get(mapped[0]), mapped[0], mapped[1]]
          }) as [string, SupportCategoryEnum, ISupportPage[]][]
      ),
      map((allPages: [string, SupportCategoryEnum, ISupportPage[]][]) =>
        allPages.sort(this.sortByTitle)
      )
    )

  public sortedPages$ = this.pages$.pipe(map((pages) => pages.sort(this.compare)))

  public categoryParam$: Observable<SupportCategoryEnum> = this.route.pipe(
    select(fromRouterSelectors.selectRouteState),
    pluck('state', 'queryParams', 'category'),
    filter((category) => !!category),
    distinctUntilChanged()
  )

  public shell$: Observable<ShellEnum> = this.route.select(fromRouterSelectors.selectShell)

  ngOnInit(): void {
    this.subs.add(
      this.categoryParam$
        .pipe(
          filter((category) => !!category),
          distinctUntilChanged(),
          delay(500),
          tap((category) => {
            this.category.setValue(category)
          })
        )
        .subscribe()
    )

    this.subs.add(
      this.category.valueChanges
        .pipe(
          filter((category) => !!category),
          distinctUntilChanged(),
          tap((category) => (this.categoryName = SupportCategoryEnumLabel.get(category))),
          tap(() => this.working$.next(true)),
          tap(() => this.pages$.next([])),
          tap(() => this.search.setValue('')),
          tap((category) => {
            this.router.navigate([], { queryParams: { category } })
          }),
          switchMap((category) => this.supportService.getPagesByCategory(category)),
          tap((pages) => this.pages$.next(pages)),
          tap(() => this.working$.next(false))
        )
        .subscribe()
    )

    this.subs.add(
      this.search.valueChanges
        .pipe(
          map((search) => search.trim()),
          distinctUntilChanged(),
          filter((search: string) => search.length > 2),
          tap(() => this.category.setValue('')),
          tap((category) => {
            this.router.navigate([], {})
          }),
          debounceTime(1000),
          tap(() => this.working$.next(true)),
          tap(() => this.pages$.next([])),
          switchMap((search) => this.supportService.searchSupport(search)),
          tap((pages) => this.pages$.next(pages)),
          tap(() => this.working$.next(false))
        )
        .subscribe()
    )

    this.subs.add(
      this.formGroup.valueChanges
        .pipe(
          filter((form) => !form.category && !form.search),
          tap(() => this.category.setValue(SupportCategoryEnum.All))
        )
        .subscribe()
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  private compare(a: ISupportPage, b: ISupportPage) {
    const nameA = a.name ? a.name.toLocaleUpperCase() : ''
    const nameB = b.name ? b.name.toLocaleUpperCase() : ''
    let comparison = 0
    if (nameA > nameB) {
      comparison = 1
    } else if (nameA < nameB) {
      comparison = -1
    }
    return comparison
  }

  private sortByTitle(a: any, b: any) {
    const titleA = a[0] ? a[0].toLowerCase() : ''
    const titleB = b[0] ? b[0].toLowerCase() : ''

    let comparison = 0
    if (titleA > titleB) {
      comparison = 1
    } else if (titleA < titleB) {
      comparison = -1
    }
    return comparison
  }
}
