import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core'
import { AlertTypeEnum } from 'src/app/enums/alert.enums'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { tap } from 'rxjs/operators'

@Directive({
  selector: '[tcColor]',
})
export class ColorDirective implements OnInit {
  alertType$: BehaviorSubject<AlertTypeEnum> = new BehaviorSubject(AlertTypeEnum.Information)
  isDark$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(false)

  constructor(
    private el: ElementRef,
    private render: Renderer2
  ) {}

  @Input('tcColor')
  set message(message: string) {
    this.alertType$.next(message as AlertTypeEnum)
  }

  @Input('tcColorDark')
  set dark(_: null) {
    this.isDark$.next(true)
  }

  @Input('disabled')
  set disabled(disabled: null) {
    this.isDisabled$.next(disabled)
  }

  ngOnInit() {
    const style = this.el.nativeElement.style

    combineLatest([this.alertType$, this.isDark$, this.isDisabled$])
      .pipe(
        tap(([alertType, dark, isDisabled]) => {
          if (isDisabled) {
            this.render.addClass(this.el.nativeElement, 'disabled-background')
            style.color = 'var(--disabled-text-color)'
            return
          }

          this.render.removeClass(this.el.nativeElement, 'disabled-background')

          switch (alertType) {
            case AlertTypeEnum.Information:
            case AlertTypeEnum.Primary:
              if (dark) {
                this.render.addClass(this.el.nativeElement, 'primary-background-dark')
                style.color = 'white'
                break
              }
              style.color = 'var(--information-text-color)'
              break
            case AlertTypeEnum.Accent:
              if (dark) {
                this.render.addClass(this.el.nativeElement, 'accent-background-dark')
                style.color = 'white'
                break
              }
              style.color = 'var(--accent-text-color)'
              break

            case AlertTypeEnum.Error:
            case AlertTypeEnum.Danger:
              if (dark) {
                this.render.addClass(this.el.nativeElement, 'error-background-dark')
                style.color = 'white'
                break
              }
              style.color = 'var(--error-text-color)'
              break

            case AlertTypeEnum.Success:
              if (dark) {
                this.render.addClass(this.el.nativeElement, 'success-background-dark')
                style.color = 'white'
                break
              }
              style.color = 'var(--success-text-color)'
              break

            case AlertTypeEnum.Question:
              if (dark) {
                this.render.addClass(this.el.nativeElement, 'question-background-dark')
                style.color = 'white'
                break
              }
              style.color = 'var(--question-text-color)'
              break

            case AlertTypeEnum.Secondary:
              if (dark) {
                this.render.addClass(this.el.nativeElement, 'secondary-background-dark')
                style.color = 'white'
                break
              }
              style.color = 'var(--secondary-text-color)'
              break

            case AlertTypeEnum.Warning:
              if (dark) {
                this.render.addClass(this.el.nativeElement, 'warning-background-dark')
                style.color = 'white'
                break
              }
              style.color = 'var(--warning-text-color)'
              break

            default:
              if (dark) {
                this.render.addClass(this.el.nativeElement, 'information-background-dark')
                style.color = 'white'
                break
              }
              style.color = 'var(--information-text-color)'
          }
        })
      )
      .subscribe()
  }
}
