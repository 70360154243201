import { ISalesTaxHoliday, IListTicItems } from './../models/idashboard-tax-holidays'
import moment from 'moment'
import { DateFormatEnum } from 'src/app/enums/date.enums'
import { validateDate } from 'src/app/utilities/date.utilities'
import { getStateNameFromFips } from 'src/app/utilities/state-from-fip.utilities'

import {
  IApiDashboardApiDailyReport,
  IApiDashboardApiReport,
} from '../models/iapi-dashboard-api.models'
import { IAPIDashboardState, IApiStatesData } from '../models/iapi-dashboard-states.models'
import { IApiDashboardSummaryAttributes } from '../models/iapi-dashboard-summary.models'
import { IApiDailyReport } from '../models/idashboard-api.models'
import { IDashboardTax } from '../models/idashboard-summary.models'
import { IDashboardState } from '../models/idashboard-us-states.models'
import { IDashboard } from '../models/idashboard.models'

export class DashboardAdapter {
  public mapApiDashbordSummaryToDashboardSummary(
    month: string,
    result: IApiDashboardSummaryAttributes,
    stateData: IApiStatesData,
    api: IApiDashboardApiReport
  ): IDashboard {
    const { remittance, tax: rawTax, total } = result
    const { totals, states, month: stateMonthRaw } = stateData
    const { month: apiMonthRaw, data: apiData } = api
    const reportMonth = moment(`${month}1`, DateFormatEnum.Day)
    const stateMonth = moment(`${stateMonthRaw}1`, DateFormatEnum.Day)
    const apiMonth = moment(`${stateMonthRaw}1`, DateFormatEnum.Day)

    const tax: IDashboardTax = { ...rawTax, total: rawTax.other + rawTax.ssuta }

    if (!reportMonth.isSame(stateMonth)) {
      throw Error('Dashboard returned different months')
    }

    if (!reportMonth.isSame(apiMonth)) {
      throw Error('Dashboard returned different months')
    }

    const report: IApiDailyReport[] = apiData.report.map((item) =>
      this.mapApiApiResultsToApiResults(item)
    )
    const map: IDashboard = {
      id: reportMonth.format(DateFormatEnum.Day),
      month: reportMonth.format(),
      summary: {
        remittance,
        tax,
        total,
      },
      usStates: {
        totals,
        states: states.map((usState) => this.mapApiUsStateToUsState(usState)),
      },
      api: {
        overLimit: apiData.overLimit,
        servicePlan: apiData.servicePlan,
        servicePlanDescription: apiData.servicePlanDescription,
        report,
        summary: this.getSummaryReport(report),
      },
    }
    return map
  }

  private mapApiApiResultsToApiResults(api: IApiDashboardApiDailyReport): IApiDailyReport {
    const convert: IApiDailyReport = {
      addressVerifications: api.addressVerifications,
      day: api.day,
      label: moment(api.day).format(DateFormatEnum.DisplayMonthDay),
      duplicate: api.duplicateLookups,
      exempt: api.exemptionCertificates,
      live: api.liveLookups + api.liveCaptures,
      returns: api.returnedCaptures,
      test: api.testLookups + api.testCaptures,
      value: api.value,
    }

    return convert
  }

  private getSummaryReport(reports: IApiDailyReport[]): IApiDailyReport {
    const defaultReport: IApiDailyReport = {
      addressVerifications: 0,
      day: '',
      duplicate: 0,
      exempt: 0,
      label: '',
      live: 0,
      returns: 0,
      test: 0,
      value: 0,
    }
    return reports.reduce((acc, curr) => {
      return {
        addressVerifications: acc.addressVerifications + curr.addressVerifications,
        day: 'Total',
        duplicate: acc.duplicate + curr.duplicate,
        exempt: acc.exempt + curr.exempt,
        label: 'Total',
        live: acc.live + curr.live,
        returns: acc.returns + curr.returns,
        test: acc.test + curr.test,
        value: acc.value + curr.value,
      }
    }, defaultReport)
  }

  private mapApiUsStateToUsState(api: IAPIDashboardState): IDashboardState {
    const economicNexusDate = validateDate(api.economicNexusDate)

    const convert: IDashboardState = {
      autoFile: api.autoFile,
      collecting: api.collecting,
      exemptSales: api.exemptSales,
      filingResponsibility: api.filingResponsibility,
      nexus: {
        currentOrders: api.currentEconomicNexusOrders,
        currentSales: api.currentEconomicNexusSales,
        date: economicNexusDate,
        laws: api.economicNexusLaws,
        ordersThreshold: api.economicNexusOrdersThreshold,
        salesThreshold: api.economicNexusSalesThreshold,
        totalOrders: api.totalEconomicNexusOrders,
        totalSales: api.totalEconomicNexusSales,
      },
      isSSUTA: api.isSSUTA,
      orThresholds: api.orThresholds,
      overWayfairThreshold: api.overWayfairThreshold,
      physicalNexus: api.physicalNexus,
      stateAbbr: api.stateAbbr,
      taxableSales: api.taxableSales,
      totalSales: api.totalSales,
      totalTax: api.totalTax,
      untaxedSales: api.untaxedSales,
      useTrailingTwelveMonths: api.useTrailingTwelveMonths,
    }

    return convert
  }
  public mapSalesTaxHolidays(api) {
    const { lstTicItems } = api
    const taxHoliday: ISalesTaxHoliday = {
      holidayId: api.holidayId,
      holidayLabel: api.holidayLabel,
      stateFipsCode: api.stateFipsCode,
      stateName: getStateNameFromFips(api.stateFipsCode),
      startDayAsPeriod: validateDate(moment(api.startDayAsPeriod, DateFormatEnum.Day).format()),
      endDayAsPeriod: validateDate(moment(api.endDayAsPeriod, DateFormatEnum.Day).format()),
      url: api.url,
      listTicItems: lstTicItems.map((ticItem) => this.mapTicItems(ticItem)),
    }
    return taxHoliday
  }
  public mapTicItems(ticItem) {
    const listTicItem: IListTicItems = {
      stateAbbr: ticItem.stateAbbr,
      jurFIPSCode: ticItem.jurFIPSCode,
      tic: ticItem.tic,
      ticLabel: ticItem.ticLabel,
      beginDate: ticItem.beginDate, // "2022-08-26"
      endDate: ticItem.endDate, // "2022-08-29"
      percentTaxable: ticItem.percentTaxable, // "0.00000"
      rate: ticItem.rate,
      exemptUnder: ticItem.exemptUnder,
      exemptOver: ticItem.exemptOver,
      taxablePortionOver: ticItem.taxablePortionOver,
    }
    return listTicItem
  }
}
