import { ChangeDetectionStrategy, Component } from '@angular/core'
import { MatSelectChange } from '@angular/material/select'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { AuthorityEnum, AuthorityLabel } from 'src/app/enums/authority.enums'
import * as userActions from 'src/app/user/actions/login.actions'
import * as userSelector from 'src/app/user/selectors/user.selectors'
import { State as UserState } from 'src/app/user/user.state'
import { getOptionsFromLabel, IOption } from 'src/app/utilities/select-box.utilities'

@Component({
  selector: 'tc-select-user-auth',
  templateUrl: './select-user-auth.component.html',
  styleUrls: ['./select-user-auth.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectUserAuthComponent {
  public options: IOption[] = getOptionsFromLabel(AuthorityLabel)
  constructor(private userStore: Store<UserState>) {}

  public auth$: Observable<AuthorityEnum> = this.userStore.select(userSelector.selectUserAuthority)

  public toggleUserAuth(event: MatSelectChange) {
    const auth = event.value as AuthorityEnum
    this.userStore.dispatch(new userActions.UpdateUserAuth({ auth }))
  }
}
