import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core'
import { MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { Router } from '@angular/router'
import { TosService } from './../../services/terms-of-service.service'
@Component({
  templateUrl: './latest-terms-of-service.dialog.html',
  styleUrls: ['./latest-terms-of-service.dialog.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LatestTermsOfServiceDialog implements OnInit {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<TosService>,
    private router: Router
  ) {}

  ngOnInit(): void {}
  goToLatestTos() {
    this.bottomSheetRef.dismiss()
    this.router.navigate(['/tos/latest'])
  }
}
