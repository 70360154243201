import Dexie from 'dexie'
import { from, Observable } from 'rxjs'

import { IMerchantStore } from '../models/imerchant-store.model'

export class MerchantStoreDatabase extends Dexie {
  private stores: Dexie.Table<IMerchantStore, number>

  constructor() {
    super('MerchantStoreDB')
    this.version(1).stores({
      stores: 'id, name, url',
    })
    this.stores = this.table('stores')
  }

  public saveStores(stores: IMerchantStore[]): Observable<number> {
    return from(this.stores.clear().then((_) => this.stores.bulkPut(stores)))
  }

  public updateStore(store: IMerchantStore) {
    return from(this.stores.put(store))
  }

  public getStores(): Observable<IMerchantStore[]> {
    return from(this.stores.orderBy('name').toArray())
  }

  public getStore(id: string): Observable<IMerchantStore | undefined> {
    return from(this.stores.get({ id }))
  }

  public clearStores(): Observable<void> {
    return from(this.stores.clear())
  }
}
