<section>
  <ng-container *ngIf="(isLoading$ | async); then loading else loaded"></ng-container>

  <ng-template #loading>
    <ngx-skeleton-loader
      count="20"
      appearance="line"
      [theme]="{ 
                height: '150px',
                border: '1rem solid white'
            }"
    >
    </ngx-skeleton-loader>
  </ng-template>

  <ng-template #loaded>
    <tc-working-spinner [message]="'loading'" [opened]="working$ | async"> </tc-working-spinner>
    <tc-alert *ngIf="hasError$ |async" type="error"> {{errorMessage$ | async}} </tc-alert>
    <div
      *ngIf="(successMessage$ | async)"
      style="text-align: center"
      fxLayout="column"
      fxLayoutAlign="start center"
      fxLayoutGap="1rem"
      class="mat-body-bold"
    >
      <tc-alert type="success">
        <div>
          <div>
            Thank you for your interest in TaxCloud Direct File. We will review your request and
            will reach out shortly.
          </div>
        </div>
      </tc-alert>
      <button mat-button tcColor="secondary" [routerLink]="'/go'">GO TO DASHBOARD</button>
    </div>

    <div *ngIf="(successMessage$ | async) === false">
      <tc-alert *ngIf="(latestRequest$ | async)?.processed === false" type="warning">
        <div
          style="text-align: center"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxLayoutGap="1rem"
          class="mat-body-bold"
        >
          <div>Request pending. Questions? <a [routerLink]="'/contact'">Contact Us</a></div>
        </div>
      </tc-alert>
      <div fxLayout="column" fxLayoutAlign="center center">
        <div class="mat-display-3">
          Let TaxCloud file your sales taxes for you. Just select your states
        </div>
        <span>Starting at $30 per return</span>
      </div>
      <ul
        *ngIf="nonSsutaUsStates$ | async as nonSsutaState"
        fxLayout="row wrap"
        fxLayoutAlign="center start"
        tcList
      >
        <ng-container *ngFor="let usState of nonSsutaState; last as isLast; index as index">
          <li style="min-width: 450px" tcListItem *ngIf="usState.issuedGuidance">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
              <mat-checkbox
                [disabled]="usState.isEnrolledInDf || (latestRequest$ | async)?.processed === false"
                [checked]="usState.requestDirectFile"
                (change)="updateStateSelection($event.checked, usState.abbreviation)"
              >
              </mat-checkbox>
              <div class="mat-display-1">{{usState.name}}</div>
            </div>
          </li>
        </ng-container>
      </ul>
      <tc-alert *ngIf="hasError$ |async" type="error"> {{errorMessage$ | async}} </tc-alert>

      <div fxLayout="row" fxLayoutGap="2rem" fxLayoutAlign="center center" class="padding_sm">
        <button mat-button tcColor="secondary" tcColorDark [routerLink]="'/go'">CANCEL</button>
        <button
          [disabled]="(latestRequest$ | async)?.processed === false"
          mat-button
          tcColor="primary"
          tcColorDark
          (click)="submit()"
        >
          SUBMIT
        </button>
      </div>
    </div>
  </ng-template>
</section>
