import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store'
import { FeatureKeys } from 'src/app/enums/store.enums'

import {
  ITransactionFilterQuery,
  ITransactionDownloadFilterQuery,
  ITransactionWorking,
} from '../models/itransaction-filter-query.model'
import { ITransactionFilter } from '../models/itransaction-filter.model'
import { adapter, State } from './../transaction.store'

const getTransactionsLoaded = (state: State): boolean => state.initialLoadComlete
const getMessage = (state: State): { title: string; paragraphs: string[] } => state.message
const getFilings = (state: State): any[] => state.filingIds
const getFilter = (state: State): ITransactionFilter => state.filter
const getComplete = (state: State): boolean => state.complete
const getWorking = (state: State): boolean => state.working
const getDownloading = (state: State): boolean => state.downloading
const getDetailId = (state: State): string | null => state.detailId

const getQuery = (state: State): ITransactionFilterQuery => {
  return {
    filter: state.filter,
    page: state.page,
    limit: state.limit,
  }
}

const getDownloadQuery = (state: State): ITransactionDownloadFilterQuery => {
  return {
    filter: state.filter,
    downloading: state.downloading,
  }
}

const getWorkingStatus = (state: State): ITransactionWorking => {
  return {
    working: state.working,
    downloading: state.downloading,
    complete: state.complete,
    initialLoadComlete: state.initialLoadComlete,
  }
}

const getLastUpdated = (state: State): string => {
  return state.lastUpdated
}

export const selectTransactionState: MemoizedSelector<object, State> = createFeatureSelector<State>(
  FeatureKeys.Transactions
)

export const initialTransactionDataLoaded: MemoizedSelector<object, boolean> = createSelector(
  selectTransactionState,
  getTransactionsLoaded
)

export const selectWorking: MemoizedSelector<object, boolean> = createSelector(
  selectTransactionState,
  getWorking
)

export const selectDownloading: MemoizedSelector<object, boolean> = createSelector(
  selectTransactionState,
  getDownloading
)

export const selectMessage: MemoizedSelector<object, { title: string; paragraphs: string[] }> =
  createSelector(selectTransactionState, getMessage)

export const selectFilings: MemoizedSelector<object, any[]> = createSelector(
  selectTransactionState,
  getFilings
)

export const selectFilter: MemoizedSelector<object, ITransactionFilter> = createSelector(
  selectTransactionState,
  getFilter
)

export const selectDownloadFilter: MemoizedSelector<object, ITransactionDownloadFilterQuery> =
  createSelector(selectTransactionState, getDownloadQuery)

export const selectQuery: MemoizedSelector<object, ITransactionFilterQuery> = createSelector(
  selectTransactionState,
  getQuery
)

export const selectComplete: MemoizedSelector<object, boolean> = createSelector(
  selectTransactionState,
  getComplete
)

export const selectLastViewedDetailId: MemoizedSelector<object, string | null> = createSelector(
  selectTransactionState,
  getDetailId
)

export const selectLastUpdated: MemoizedSelector<object, string | null> = createSelector(
  selectTransactionState,
  getLastUpdated
)

export const selectWorkingStatus: MemoizedSelector<object, ITransactionWorking> = createSelector(
  selectTransactionState,
  getWorkingStatus
)

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors<State>(selectTransactionState)
