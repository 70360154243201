<section
  *ngIf="(loginStatus | async) as status"
  class="flex justify-content-center max-w-28rem pt-8"
>
  <ng-container *ngIf="(isLoading | async); then working; else showLogin"></ng-container>
  <ng-template #working>
    <div class="progress-spinner">
      <p-progressSpinner animationDuration="1s" styleClass="text-primary"></p-progressSpinner>
    </div>
  </ng-template>

  <ng-template #showLogin>
    <ng-container *ngIf="status ==='logged_in'; else notLoggedIn">
      <div class="flex flex-column gap-4 align-items-center">
        <button
          (click)="goDashboard()"
          pButton
          pRipple
          label="Go to dashboard"
          class="p-button"
        ></button>
        <button pButton pRipple label="Sign out" class="p-button-text" (click)="logout()"></button>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #notLoggedIn>
    <ng-container *ngIf="status ==='locked'; then locked; else loggedOut"></ng-container>
    <ng-template #locked>
      <p-card class="login-card w-full" styleClass="shadow-none sm:shadow-4">
        <h3 class="mt-0 font-bold text-center login-title">
          <i class="pi pi-unlock" style="font-size: 1rem"></i> Log in to TaxCloud
        </h3>
        <p-divider></p-divider>
        <button
          class="gsi-material-button my-2"
          (click)="handleGoogleLogin()"
          [disabled]="(validForm$ | async) === (false)"
          data-cy="submit-google"
        >
          <div class="gsi-material-button-state"></div>
          <div class="gsi-material-button-content-wrapper">
            <div class="gsi-material-button-icon">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                style="display: block"
              >
                <path
                  fill="#EA4335"
                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                ></path>
                <path
                  fill="#4285F4"
                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                ></path>
                <path
                  fill="#FBBC05"
                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                ></path>
                <path
                  fill="#34A853"
                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                ></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
            </div>
            <span class="gsi-material-button-contents">Sign in with Google</span>
            <span style="display: none">Sign in with Google</span>
          </div>
        </button>
        <p-divider align="center">or</p-divider>
        <form [formGroup]="unlockForm" data-cy="lockedForm">
          <div class="formgrid grid">
            <div class="field col-12 mb-4">
              <input
                pInputText
                class="w-full"
                [ngClass]="{'error': (errorMessage$ | async) !== null}"
                placeholder="{{(user | async)?.username}}"
                autocomplete="email"
                disabled
              />
            </div>
            <div class="field col-12 mb-4">
              <input
                pInputText
                class="w-full"
                [ngClass]="{'error': (errorMessage$ | async) !== null}"
                autofocus
                type="password"
                placeholder="Password"
                data-cy="unlock-password"
                autocomplete="current-password"
                formControlName="lockPassword"
                (keydown.enter)="submitUnlock()"
                data-private="lipsum"
              />
            </div>
          </div>
          <div tcLink class="my-2" (click)="forgotPassword()">Forgot your password?</div>
          <p-message
            *ngIf="errorMessage$ | async as errorMessage"
            styleClass="mt-2"
            severity="error"
            text="{{errorMessage}}"
          ></p-message>
          <button
            [disabled]="(isLoading | async)"
            pButton
            pRipple
            data-cy="unlock-submit"
            type="submit"
            label="Unlock"
            class="p-button w-full mt-4 mb-2"
            (click)="submitUnlock()"
          ></button>
          <button
            [disabled]="(isLoading | async)"
            pButton
            pRipple
            label="Sign out"
            class="p-button-outlined w-full mb-4"
            (click)="logout()"
          ></button>
        </form>
      </p-card>
    </ng-template>
    <ng-template #loggedOut>
      <p-card class="login-card w-full" styleClass="shadow-none sm:shadow-4">
        <h3 class="mt-0 font-bold text-center login-title">Log in to TaxCloud</h3>
        <p-divider></p-divider>
        <button
          class="gsi-material-button my-2"
          (click)="handleGoogleLogin()"
          [disabled]="(validForm$ | async) === (false)"
          data-cy="submit-google"
        >
          <div class="gsi-material-button-state"></div>
          <div class="gsi-material-button-content-wrapper">
            <div class="gsi-material-button-icon">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                style="display: block"
              >
                <path
                  fill="#EA4335"
                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                ></path>
                <path
                  fill="#4285F4"
                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                ></path>
                <path
                  fill="#FBBC05"
                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                ></path>
                <path
                  fill="#34A853"
                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                ></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
            </div>
            <span class="gsi-material-button-contents">Sign in with Google</span>
            <span style="display: none">Sign in with Google</span>
          </div>
        </button>
        <p-divider align="center">or</p-divider>
        <form [formGroup]="form" (ngSubmit)="submitLogin()" data-cy="loginForm">
          <div class="formgrid grid">
            <div class="field col-12 mb-4">
              <input
                pInputText
                class="w-full"
                [ngClass]="{'error': (errorMessage$ | async) !== null}"
                autofocus
                required
                data-cy="email"
                type="email"
                placeholder="Email"
                autocomplete="email"
                formControlName="username"
              />
            </div>
            <div class="field col-12 mb-4">
              <input
                pInputText
                class="w-full"
                [ngClass]="{'error': (errorMessage$ | async) !== null}"
                type="password"
                data-cy="password"
                placeholder="Password"
                autocomplete="current-password"
                formControlName="password"
                (keydown.enter)="submitLogin()"
                data-private="lipsum"
              />
            </div>
          </div>
          <p-message
            *ngIf="errorMessage$ | async as errorMessage"
            styleClass="mb-2 w-full justify-content-start"
            severity="error"
            text="{{errorMessage}}"
          ></p-message>
          <div tcLink class="my-2" (click)="forgotPassword()">Forgot your password?</div>
          <button
            [disabled]="(validForm$ | async) === (false)"
            pButton
            pRipple
            data-cy="submit"
            type="submit"
            label="Log In"
            class="p-button w-full my-4"
          ></button>
          <div class="text-center">
            Not a customer?
            <span tcLink class="margin_v_sm" (click)="registerNewAccount()">Register here</span>
          </div>
        </form>
      </p-card>
    </ng-template>
  </ng-template>
</section>
