<button
  *ngIf="(stepperActiveIndex$ | async) !== 0"
  pButton
  pRipple
  (click)="back()"
  type="button"
  label="Back"
  class="p-button-text p-button-secondary h-3rem max-w-16rem w-full align-self-center"
></button>
<button
  pButton
  pRipple
  (click)="next()"
  type="button"
  [label]="nextButtonText$ | async"
  class="h-3rem max-w-16rem w-full align-self-center"
  [disabled]="(currentFormValid$ | async) === false"
></button>
