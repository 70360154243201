import { AccountStateEnum } from 'src/app/enums/account.enums'
import { AttestationEnum } from 'src/app/enums/attestations.enums'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'

import { IAccount } from '../models/iaccount'
import { initialState, State } from './../account.state'
import { AccountActions, AccountActionTypes } from './../actions/account.actions'

export function reducer(state = initialState, action: AccountActions): State {
  switch (action.type) {
    case AccountActionTypes.GetAccountDataSuccess:
    case AccountActionTypes.GetAccountForAutoLoginSuccess:
    case AccountActionTypes.GetAccountDataSuccessRedirect:
    case AccountActionTypes.SaveAccountToStore:
    case AccountActionTypes.UpdateServicePlanSuccess:
    case AccountActionTypes.AddPrepaySuccess:
    case AccountActionTypes.UpdateIndustryClassificationSuccess:
    case AccountActionTypes.CancelAccountSuccess:
    case AccountActionTypes.ReactivateAcountSuccess:
      return {
        ...state,
        account: action.payload.account,
        source: DataSourceEnum.API,
        working: '',
        error: '',
      }

    case AccountActionTypes.RefreshAccountData: {
      return {
        ...state,
        working: 'Refreshing Account',
        error: '',
      }
    }

    case AccountActionTypes.UpdateAccountState:
    case AccountActionTypes.UpdateAccountStateFromMerchantStore:
      return {
        ...state,
        account: {
          ...state.account,
          accountState: action.payload.state,
        },
        working: '',
        error: '',
      }

    case AccountActionTypes.UpdateConversionStatus:
      return {
        ...state,
        account: {
          ...state.account,
          conversionStatus: action.payload.conversionStatus,
        },
        working: '',
      }

    case AccountActionTypes.UpdateProfileFromAccount:
    case AccountActionTypes.UpdateProfileFromNewUser:
      return {
        ...state,
        working: 'Updating profile',
        error: '',
      }

    case AccountActionTypes.SubmitPaAttestation:
      return {
        ...state,
        working: 'Updating PA Attestation',
        error: '',
      }

    case AccountActionTypes.ExtendConfigurationWindow:
      return {
        ...state,
        working: 'Extending configuration window',
        error: '',
      }

    case AccountActionTypes.CancelAccount:
      return {
        ...state,
        working: 'Cancelling Account',
        error: '',
      }

    case AccountActionTypes.ReactivateAccount:
      return {
        ...state,
        working: 'Reactivating Account',
        error: '',
      }

    case AccountActionTypes.AddPrepay:
      return {
        ...state,
        working: 'Adding prepay amount',
        error: '',
      }

    case AccountActionTypes.AddPaAttestation:
      const account: IAccount = {
        ...state.account,
        attestations: [AttestationEnum.Pennsylvania],
        accountState: AccountStateEnum.Attestation,
      }
      return {
        ...state,
        account,
        working: '',
        error: '',
      }

    case AccountActionTypes.UpdateIndustryClassification:
      return {
        ...state,
        working: 'Updating industry classification',
        error: '',
      }

    case AccountActionTypes.ExtendConfigurationWindowFailed:
      return {
        ...state,
        working: '',
        error: action.payload.error,
      }

    case AccountActionTypes.ClearAccountData:
      return {
        ...initialState,
        source: DataSourceEnum.Local,
      }

    case AccountActionTypes.SwitchAccountDataSourceToLocal:
      return {
        ...state,
        source: DataSourceEnum.Local,
      }

    default:
      return state
  }
}
