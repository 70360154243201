import { Action } from '@ngrx/store'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'

import { IEditContact } from '../models/iedit-contact.model'
import { IContact as Contact } from './../models/icontact.model'

export enum ContactActionTypes {
  InitContactsFromSelectComponent = '[Contact Select Component] Init Contacts',
  InitContactsFromList = '[Contact List Component] Init Contacts',
  InitContactsFailure = '[Contact Effects] Init contact failure',

  RefreshContacts = '[Contact List Component] Refresh Contacts',
  RefreshContactsSuccess = '[Contact Effects] Refresh contact success',
  RefreshContactsFailure = '[Contact Effects] Refresh contact failure',

  SaveContact = '[Add Contact Page] Add contact',
  SaveContactSuccess = '[Contact Effects] Add contact success',
  SaveContactFailure = '[Contact Effects] Add contact failure',

  EditContact = '[Edit Contact Page] Edit contact',
  EditContactSuccess = '[Contact Effects] Edit contact success',
  EditContactFailure = '[Contact Effects] Edit contact failure',

  RemoveContact = '[Edit Contact Page] Remove contact',
  RemoveContactSuccess = '[Contact Effects] Edit contact success',
  RemoveContactFailure = '[Contact Effects] Edit contact failure',

  UpsertContacts = '[Contact Effects] Upsert Contacts',
  ClearContacts = '[Contact Services] Clear Contacts',
}

export class InitContactsFromSelectComponent implements Action {
  readonly type = ContactActionTypes.InitContactsFromSelectComponent
}

export class InitContactsFromList implements Action {
  readonly type = ContactActionTypes.InitContactsFromList
}

export class InitContactsFailure implements Action {
  readonly type = ContactActionTypes.InitContactsFailure
  constructor(public payload: { error: string }) {}
}

export class RefreshContacts implements Action {
  readonly type = ContactActionTypes.RefreshContacts
}

export class RefreshContactsSuccess implements Action {
  readonly type = ContactActionTypes.RefreshContactsSuccess
  constructor(public payload: { contacts: Contact[] }) {}
}

export class RefreshContactsFailure implements Action {
  readonly type = ContactActionTypes.RefreshContactsFailure
  constructor(public payload: { error: string }) {}
}

export class SaveContact implements Action {
  readonly type = ContactActionTypes.SaveContact
  constructor(public payload: { contact: IEditContact }) {}
}

export class SaveContactSuccess implements Action {
  readonly type = ContactActionTypes.SaveContactSuccess
  constructor(public payload: { contacts: Contact[] }) {}
}

export class SaveContactFailure implements Action {
  readonly type = ContactActionTypes.SaveContactFailure
  constructor(public payload: { error: string }) {}
}

export class EditContact implements Action {
  readonly type = ContactActionTypes.EditContact
  constructor(public payload: { contact: IEditContact }) {}
}

export class EditContactSuccess implements Action {
  readonly type = ContactActionTypes.EditContactSuccess
  constructor(public payload: { contacts: Contact[] }) {}
}

export class EditContactFailure implements Action {
  readonly type = ContactActionTypes.EditContactFailure
  constructor(public payload: { error: string }) {}
}

export class RemoveContact implements Action {
  readonly type = ContactActionTypes.RemoveContact
  constructor(public payload: { id: string }) {}
}

export class RemoveContactSuccess implements Action {
  readonly type = ContactActionTypes.RemoveContactSuccess
  constructor(public payload: { contacts: Contact[] }) {}
}

export class RemoveContactFailure implements Action {
  readonly type = ContactActionTypes.RemoveContactFailure
  constructor(public payload: { error: string }) {}
}

export class UpsertContacts implements Action {
  readonly type = ContactActionTypes.UpsertContacts
  constructor(public payload: { contacts: Contact[]; source: DataSourceEnum }) {}
}

export class ClearContacts implements Action {
  readonly type = ContactActionTypes.ClearContacts
}

export type ContactActions =
  | InitContactsFromSelectComponent
  | InitContactsFromList
  | InitContactsFailure
  | RefreshContacts
  | RefreshContactsSuccess
  | RefreshContactsFailure
  | SaveContact
  | SaveContactSuccess
  | SaveContactFailure
  | EditContact
  | EditContactSuccess
  | EditContactFailure
  | RemoveContact
  | RemoveContactSuccess
  | RemoveContactFailure
  | UpsertContacts
  | ClearContacts
