import moment from 'moment'
import { DateFormatEnum } from 'src/app/enums/date.enums'
import { FundingSourceEnum, FundingSourceEnumLabel } from 'src/app/enums/funding.enums'
import { validateDate } from 'src/app/utilities/date.utilities'

import {
  IApiTaxFunding,
  IApiTaxFundingItem,
  IApiTaxFundingUsState,
  IApiTaxSummary,
} from '../models/iapi-tax-funding.models'
import {
  ITaxFunding,
  ITaxFundingItem,
  ITaxFundingSummary,
  ITaxFundingUsState,
} from '../models/itax-funding.models'

export class TaxFundingAdapter {
  public mapApiTaxFundingToTaxFunding(result: IApiTaxFunding): ITaxFunding {
    const { amount, convenienceFee, total, period } = result
    const month = moment(period.toString(), DateFormatEnum.Day)

    const fundings: ITaxFundingItem[] = result.fundings
      .filter((funding) => funding.states.length > 0)
      .map((funding, index) => this.mapApiFundingEventToFundingEvent(funding, index))
      .sort(this.sortBySubmittedTimestamp)

    const converted: ITaxFunding = {
      amount,
      convenienceFee,
      id: month.format(DateFormatEnum.Month),
      fundings,
      month: month.format(),
      total,
    }

    return converted
  }

  private mapApiFundingEventToFundingEvent(
    api: IApiTaxFundingItem,
    index: number
  ): ITaxFundingItem {
    const { states, summary } = api
    const remap: ITaxFundingItem = {
      usStates: states.map((usState) => this.mapApiUsStatesToUsStates(usState)),
      summary: this.mapApiSummaryToSummary(summary, index),
    }
    return remap
  }

  private mapApiUsStatesToUsStates(api: IApiTaxFundingUsState): ITaxFundingUsState {
    const f = api.stateFIPsCode.toString()
    const fipsCode = f.length === 1 ? `0${f}` : f
    const remap: ITaxFundingUsState = {
      amount: api.amount,
      filingId: api.filingId,
      deltaId: api.id,
      usState: {
        abbreviation: api.stateAbbr,
        fipsCode,
        name: api.stateName,
      },
    }
    return remap
  }

  private mapApiSummaryToSummary(api: IApiTaxSummary, index: number): ITaxFundingSummary {
    const createdTimestamp = validateDate(api.createdOn)
    const name = `${moment(createdTimestamp).format(DateFormatEnum.MonthDayYear)}`
    const fundingSource: FundingSourceEnum = api.fundingSource
    const fundingSourceLabel = FundingSourceEnumLabel.get(fundingSource)

    const remap: ITaxFundingSummary = {
      amount: api.amount,
      convenienceFee: api.convenienceFee,
      deltaId: api.remittanceId,
      createdTimestamp: validateDate(api.createdOn),
      fundingSource,
      fundingSourceLabel,
      lastFour: api.lastFour,
      name,
      period: api.period,
      rejectedTimestamp: validateDate(api.rejectedOn),
      remittanceId: api.remittanceId,
      remittancePeriod: api.remittancePeriod,
      resolvedTimestamp: validateDate(api.resolvedOn),
      sentTimestamp: validateDate(api.sentOn),
      sentTo: api.sentTo,
      submittedTimestamp: validateDate(api.submittedOn),
      total: api.total,
    }
    return remap
  }

  private sortBySubmittedTimestamp(a: ITaxFundingItem, b: ITaxFundingItem) {
    const createdA = a.summary.createdTimestamp
    const createdB = b.summary.createdTimestamp
    if (createdA < createdB) {
      return -1
    }
    if (createdA > createdB) {
      return 1
    }
    return 0
  }
}
