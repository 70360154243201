<ng-container *ngIf="(uploading | async) === true; then showSpinner; else showCard"></ng-container>
<ng-template #showSpinner>
  <div class="progress-spinner">
    <p-progressSpinner animationDuration="1s" styleClass="text-primary"></p-progressSpinner>
  </div>
</ng-template>
<ng-template #showCard>
  <p-card class="w-full shadow-4">
    <h3 class="mt-0 font-bold text-center login-title">Create new password</h3>
    <p-divider></p-divider>
    <p class="mb-5 text-center">Please enter your new password</p>
    <form class="m-3" [formGroup]="formGroup">
      <div class="formgrid grid">
        <div class="field col-12 mb-4">
          <input
            #password
            (focus)="onPasswordFocus()"
            (blur)="onPasswordBlur()"
            class="w-full"
            pInputText
            placeholder="Password"
            type="password"
            autocomplete="new-password"
            formControlName="password"
            required
            data-private="lipsum"
          />
        </div>
        <p-overlay
          *ngIf="overlayVisible"
          [visible]="overlayVisible"
          [options]="overlayOptions"
          contentStyleClass="p-2 surface-overlay shadow-2 border-round"
        >
          <tc-password-requirements [password]="password.value"></tc-password-requirements>
        </p-overlay>
        <div class="field col-12 mb-4">
          <input
            class="w-full"
            pInputText
            placeholder="Confirm Password"
            type="password"
            autocomplete="none"
            formControlName="confirm"
            required
            data-private="lipsum"
          />
        </div>
      </div>
      <p-message
        *ngIf="formGroup.errors?.notConfirmed && (confirm.touched || confirm.dirty)"
        styleClass="mb-4"
        severity="error"
        text="Passwords do not match"
      ></p-message>
      <button
        pButton
        pRipple
        label="Save New Password"
        class="p-button w-full"
        data-cy="edit-location-button"
        [disabled]="formGroup.invalid"
        (click)="submitPasswordChange()"
      ></button>
    </form>
  </p-card>
</ng-template>
