export enum DateFormatEnum {
  Month = 'YYYYMM',
  Day = 'YYYYMMDD',
  Locale = 'L', // 09/04/1986
  Local = 'LL', // September 4, 1986
  MonthDayYear = 'M/D/YYYY h:mm:ss a',
  DisplayMonthDay = 'MMMM Do',
  DisplayMonthYear = 'MMMM YYYY',
  DisplayDay = 'MMM Do YYYY',
  DisplayMonthShort = 'MMM YY',
  SqlTime = 'YYYY-MM-DD[T]hh:mm:ss',
  SqlTime24Hrs = 'YYYY-MM-DD[T]HH:mm:ss',
  YearMonthDay = 'YYYY-MM-DD',
}
