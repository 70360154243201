import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'

import { InitMaterialModule } from '../material/init-material.module'
import { UiModule } from '../ui/ui/ui.module'
import { UsStatesModule } from '../us-states/us-states.module'
import { AddressFormComponent } from './components/address-form/address-form.component'
import { VerifyAddressFormComponent } from './components/verify-address-form/verify-address-form.component'
import { InitUiModule } from '../ui/init-ui/init-ui.module'

@NgModule({
  declarations: [AddressFormComponent, VerifyAddressFormComponent],
  imports: [CommonModule, ReactiveFormsModule, InitUiModule, UsStatesModule, InitMaterialModule],
  exports: [AddressFormComponent, VerifyAddressFormComponent],
})
export class AddressModule {}
