import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

declare var Beacon

@Injectable({
  providedIn: 'root',
})
export class HelpScoutBeaconService {
  constructor() {}

  async init() {
    if (await this.waitForBeaconAvailable()) {
      ;(window as any).Beacon?.('init', environment.helpScout.token)
    }
  }

  async destroy() {
    if (await this.waitForBeaconAvailable()) {
      ;(window as any).Beacon?.('destroy')
    }
  }

  async showBeacon() {
    if (await this.waitForBeaconAvailable()) {
      ;(window as any).Beacon?.('open')
    }
  }

  async hideBeacon() {
    if (await this.waitForBeaconAvailable()) {
      ;(window as any).Beacon?.('open')
    }
  }

  async identityUser(id, name, email, merchantId) {
    if (await this.waitForBeaconAvailable()) {
      ;(window as any).Beacon?.('identify', {
        id,
        name,
        email,
        merchantId,
      })
    }
  }

  private async waitForBeaconAvailable(): Promise<boolean> {
    try {
      await this.waitFor(() => !!window['Beacon'], 2000)
      return true
    } catch (e) {
      return false
    }
  }

  /**
   * Function to wait for predicates.
   * @param {function() : Boolean} predicate - A function that returns a bool
   * @param {Number} [timeout] - Optional maximum waiting time in ms after rejected
   */
  private waitFor(predicate, timeout) {
    return new Promise<void>((resolve, reject) => {
      const check = () => {
        if (!predicate()) return
        clearInterval(interval)
        resolve()
      }
      const interval = setInterval(check, 100)
      check()

      if (!timeout) return
      setTimeout(() => {
        clearInterval(interval)
        reject()
      }, timeout)
    })
  }
}
