import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import * as fromRouter from '@ngrx/router-store'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { filter, first, map, pluck, tap } from 'rxjs/operators'
import { LoginTypeEnum } from 'src/app/enums/login.enum'
import * as fromRouterSelectors from 'src/app/root/selectors/root.selectors'

import { OneTimeLoginRequest } from '../../actions/login.actions'
import * as authSelectors from '../../selectors/user.selectors'
import { State as AuthState } from '../../user.state'

@Component({
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordPage implements OnInit {
  constructor(
    private route: Store<fromRouter.RouterReducerState<any>>,
    private router: Router,
    private authStore: Store<AuthState>
  ) {}

  token$: Observable<string> = this.route.pipe(
    select(fromRouterSelectors.selectRouteState),
    pluck('state', 'queryParams', 'token'),
    filter((token: string) => !!token)
  )

  isLoggedIn$: Observable<boolean> = this.authStore
    .select(authSelectors.selectAuthToken)
    .pipe(map((token) => !!token))

  isLoading$: Observable<boolean> = this.authStore.select(authSelectors.selectAuthorizeIsLoading)

  isOneTimeLogin$: Observable<boolean> = this.authStore
    .select(authSelectors.selectLoginType)
    .pipe(map((type) => type === LoginTypeEnum.SingleUse))

  errorMessage$: Observable<string> = this.authStore.select(authSelectors.selectAuthorizeError)

  ngOnInit(): void {
    this.token$
      .pipe(
        first(),
        tap((token) => {
          this.authStore.dispatch(new OneTimeLoginRequest({ token }))
        })
      )
      .subscribe()
  }

  public goSignIn(): void {
    this.router.navigate(['/sign-in'])
  }
}
