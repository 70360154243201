export enum SubscriptionPlanEnum {
  STARTER_PLAN = 'Starter-Plan',
  PREMIUM_PLAN = 'Premium-Plan',

  /**
   * These plans are manually assigned (admin / sales) and should not be self-assigned via self-serve.
   */
  ENTERPRISE = 'enterprise',
  LEGACY_FLAT_FEE = 'legacy-flat-fee',
  LEGACY_USAGE = 'legacy-usage',
}

export enum SubscriptionPlanTier {
  FREE = 'Free-Plan',
  STARTER = 'Starter-Plan',
  PREMIUM = 'Premium-Plan',
}
