import { ConversionStatusDialog } from './../dialog/conversion-status/conversion-status.dialog'
import { Platform } from '@angular/cdk/platform'
import { Injectable } from '@angular/core'
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import * as appStatusActions from 'src/app/app-status/actions/app-status.actions'
import { State as AppState } from 'src/app/app-status/app-status.state'
import { DeviceEnum } from 'src/app/enums/device.enums'
import { LoggingService } from 'src/app/root/services/logging.service'
import { IosInstallDialog } from 'src/app/ui/init-ui/dialogs/ios-install/ios-install.dialog'

import { OnlineEnum } from './../../enums/online.enums'

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed'
    platform: string
  }>
  prompt(): Promise<void>
}

@Injectable({
  providedIn: 'root',
})
export class AppStatusService {
  private bottomSheetRef: MatBottomSheetRef

  private promptEvent: IBeforeInstallPromptEvent

  // constructor(private bottomSheet: MatBottomSheet) {}

  // openBottomSheet(): void {
  //   this.bottomSheet.open(BottomSheetOverviewExampleSheet);
  // }

  constructor(
    private platform: Platform,
    private logger: LoggingService,
    private appStateStore: Store<AppState>,
    private bottomSheet: MatBottomSheet
  ) {
    window.addEventListener('beforeinstallprompt', (event: IBeforeInstallPromptEvent) => {
      event.preventDefault()

      this.promptEvent = event
      this.appStateStore.dispatch(new appStatusActions.SetInstallable(true))

      this.logger.logEvent('Prompt Install')
    })

    window.addEventListener('appinstalled', () => {
      this.promptEvent = undefined
      this.appStateStore.dispatch(new appStatusActions.SetInstalled(true))
      this.logger.logEvent('App Installed')
    })

    window.addEventListener('offline', () => appStateStore.dispatch(new appStatusActions.Offline()))

    window.addEventListener('online', () => appStateStore.dispatch(new appStatusActions.Online()))
  }

  public openConversionStatusDialog(): void {
    this.bottomSheetRef = this.bottomSheet.open(ConversionStatusDialog)
  }
  public closeConversionStatusDialog(): void {
    if (this.bottomSheetRef) {
      this.bottomSheetRef.dismiss()
    }
  }

  private get isIos(): boolean {
    return this.platform.IOS
  }

  private get isAndroid(): boolean {
    return this.platform.ANDROID
  }

  private get isStandalone(): boolean {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const isInStandaloneMode = 'standalone' in window.navigator && window.navigator['standalone']
    return isInStandaloneMode
  }

  public async installApp() {
    if (this.platform.IOS) {
      this.bottomSheet.open(IosInstallDialog)
      return
    }

    const beforeInstallEvent = this.promptEvent
    if (!beforeInstallEvent) {
      return
    }

    beforeInstallEvent.prompt()

    // Wait for the user to accept or dismiss the install prompt
    const { outcome } = await beforeInstallEvent.userChoice

    this.logger.logEvent('App Installed')

    // If the prompt was dismissed
    if (outcome === 'dismissed') {
    }
  }

  public getOnlineStatus(): Observable<OnlineEnum> {
    return new Observable((observer) => {
      const navigator = window.navigator
      if (!navigator) {
        return observer.error(new Error('Offline unavailable'))
      }
      const status = navigator.onLine ? OnlineEnum.Online : OnlineEnum.Offline
      return observer.next(status)
    })
  }

  public getDevice(): Observable<any> {
    return new Observable((observer) => {
      const navigator = window.navigator
      if (!navigator) {
        return observer.error(new Error('Device not available'))
      }
      const device = this.isIos
        ? DeviceEnum.Iphone
        : this.isAndroid
          ? DeviceEnum.Android
          : DeviceEnum.Computer
      return observer.next(device)
    })
  }

  public getStandaloneStatus(): Observable<any> {
    return new Observable((observer) => {
      const navigator = window.navigator
      if (!navigator) {
        return observer.error(new Error('Stand alone not available'))
      }
      return observer.next(this.isStandalone)
    })
  }

  public initPwaPrompt() {
    if (this.isAndroid) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault()
        this.promptEvent = event
        // this.openPromptComponent('android');
      })
    }
    if (this.isIos) {
      if (!this.isStandalone) {
        // this.openPromptComponent('ios');
      }
    }
  }
}
