import Dexie from 'dexie'
import { from, Observable } from 'rxjs'

import { ITaxFunding } from '../models/itax-funding.models'

export class TaxFundingDatabase extends Dexie {
  private events: Dexie.Table<ITaxFunding, string>

  constructor() {
    super('TaxFundingDB')
    this.version(1).stores({
      events: 'id, month',
    })
    this.events = this.table('events')
  }

  public saveEvents(events: ITaxFunding[]): Observable<string> {
    return from(this.events.clear().then((_) => this.events.bulkPut(events)))
  }

  public clearDatabase(): Observable<void> {
    return from(this.events.clear())
  }

  public getEvents(): Observable<ITaxFunding[]> {
    return from(this.events.orderBy('month').reverse().toArray())
  }

  public getEvent(id: string): Observable<ITaxFunding | undefined> {
    return from(this.events.get({ id }))
  }

  public removeEvent(id: string): Observable<any> {
    return from(this.events.delete(id))
  }
}
