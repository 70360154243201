import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, of, combineLatest } from 'rxjs'
import { map, exhaustMap, first, tap, pluck } from 'rxjs/operators'
import { IMail } from './../models/imail'
import { IMailFilter } from './../models/imailFilter'
import { Store, select } from '@ngrx/store'
import { USStateService } from './../../us-states/services/us-state.service'
import * as fromMailSelector from './../selectors/mail.selectors'
import * as fromMail from './../mail.store'
import * as fromMailActions from './../actions/mail.actions'
import * as fromContacts from './../../contact/contact.store'
import * as fromContactsSelctor from './../../contact/selectors/contact.selectors'
import { MailDatabase } from './../db/local-mail.db'
import * as _moment from 'moment'
// eslint-disable-next-line no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment'
import { DateFormatEnum } from 'src/app/enums/date.enums'
import { State as UsStateState } from 'src/app/us-states/us-states.store'
import * as usStateSelector from 'src/app/us-states/selectors/us-state.selector'
import { IUSState } from 'src/app/us-states/models/ius-state'

const moment = _rollupMoment || _moment

interface IApiMailFilter {
  documentMerchantId?: string
  documentTypeIds?: string
  stateFIPSCode?: string
  priorityIds?: string
  assignedContactId?: string
  statusIds?: string
  page: string
  limit: string
  createdAtMin?: string
  createdAtMax?: string
}

export interface IApiMail {
  discoveryDate: string
  documentId: string
  documentMerchantId: number
  documentName: string
  note: string
  documentText: string
  documentTypeId: number
  documentUri: string
  stateFIPSCode: string
  stateName?: string
  merchantName?: string
  merchantCorpName?: string
  priorityId?: number
  value?: number
  assignedContactId?: number
  statusId?: number
  sstpid: string
}

interface IApiMailReturn {
  documents: IApiMail[]
  links: any
}

@Injectable({
  providedIn: 'root',
})
export class MailService {
  constructor(
    private http: HttpClient,
    private store: Store<fromMail.State>,
    private contacts: Store<fromContacts.State>,
    private state: USStateService,
    private usStateStore: Store<UsStateState>
  ) {}
  nextLink: URL
  db = new MailDatabase()

  allStates$: Observable<IUSState[]> = this.usStateStore.select(usStateSelector.selectAll)

  isMailLoaded(): Observable<boolean> {
    return this.store.select(fromMailSelector.selectListDataLoaded)
  }

  getOfflineMails(page: number = 1, pageLimit: number = 50): Observable<[IMail[], boolean]> {
    return this.db
      .getMails(page, pageLimit)
      .pipe(map((mails) => [mails, mails.length >= pageLimit]))
  }

  getOfflineMail(documentId: string): Observable<IMail | undefined> {
    return this.db.getMail(documentId)
  }

  getMailsWithFilter(
    filter: IMailFilter = {},
    page: number = 1,
    pageLimit: number = 50
  ): Observable<[IMail[], boolean]> {
    const getData = this.http
      .get('api/scapper', {
        headers: new HttpHeaders({
          ContentType: 'application/x-www-form-urlencoded',
        }),
        params: new HttpParams({
          fromObject: {
            ...this.mapFilterToApiFilter(filter, page, pageLimit),
          },
        }),
      })
      .pipe(
        map((apiMail: IApiMailReturn) =>
          apiMail.documents.map((mail) => this.mapApiMailToMail(mail))
        )
      ) as Observable<IMail[]>

    const getContacts = this.contacts.pipe(select(fromContactsSelctor.selectEntities))

    return combineLatest([getData, this.allStates$, getContacts]).pipe(
      map(([mails, states, contacts]) => {
        return mails.map((mail) => {
          const findState = states.find((state) => state.fips === mail.stateFIPSCode)
          const stateName = findState ? findState.name : 'Unknown state'
          const contactName =
            mail.contactId === null || mail.contactId === '0'
              ? 'Non assigned'
              : contacts[mail.contactId]
                ? contacts[mail.contactId].fullName
                : 'Working...'
          return { ...mail, stateName, contactName }
        })
      }),
      tap((processedMail) => new MailDatabase().saveMails(processedMail)),
      map((processedMail) => [processedMail, processedMail.length >= pageLimit])
    )
  }

  getMail(id: string): Observable<IMail> {
    return this.http
      .get(`api/scapper/${id}`, {
        headers: new HttpHeaders({
          ContentType: 'application/x-www-form-urlencoded',
        }),
      })
      .pipe(map((apiMail: IApiMail) => this.mapApiMailToMail(apiMail))) as Observable<IMail>
  }

  getPdf(id: string): Observable<any> {
    return this.http
      .get(`api/scapper/get-from-storage?filename=${id.toLowerCase()}`, {
        responseType: 'blob',
      })
      .pipe() as Observable<any>
  }

  updateMail(id: string) {
    const getMail = this.store.pipe(select(fromMailSelector.selectEntities), first(), pluck(id))

    const getContacts = this.contacts.pipe(select(fromContactsSelctor.selectEntities))

    return combineLatest([getMail, getContacts, this.allStates$]).pipe(
      first(),
      map(([mail, contacts, states]) => {
        const findState = states.find((state) => state.fips === mail.stateFIPSCode)
        const stateName = findState ? findState.name : 'Unknown state'
        const contactName = contacts[mail.contactId]
          ? contacts[mail.contactId].fullName
          : 'None Assigned'
        return { ...mail, stateName, contactName }
      }),
      tap((item: IMail) => {
        this.db.updateMail(item)
      }),
      map((mail) => {
        const formData = new FormData()
        formData.set('documentName', mail.documentName)
        formData.set('documentTypeId', mail.type.toString())
        formData.set('stateFIPSCode', mail.stateFIPSCode)
        formData.set('documentMerchantId', mail.merchantId.toString())
        formData.set('priorityId', mail.priority.toString())
        formData.set('statusId', mail.status ? mail.status.toString() : '0')
        formData.set('AssignedContactId', mail.contactId ? mail.contactId.toString() : '0')
        formData.set('note', mail.documentNote ? mail.documentNote.toString() : '')
        return formData
      }),
      exhaustMap((form) => {
        return this.http.post(`api/scapper/${id}`, form, {
          headers: new HttpHeaders({
            ContentType: 'application/x-www-form-urlencoded',
          }),
        })
      })
    )
  }

  clearMails() {
    this.db.clearMails()
    this.store.dispatch(new fromMailActions.ResetMails())
  }

  private mapApiMailToMail(api: IApiMail): IMail {
    return {
      contactId: api.assignedContactId.toString(),
      discoveryDate: api.discoveryDate,
      documentId: api.documentId,
      merchantId: api.documentMerchantId,
      documentName: api.documentName,
      documentNote: api.note || '',
      documentText: api.documentText,
      type: api.documentTypeId,
      documentUri: api.documentUri,
      stateFIPSCode: api.stateFIPSCode,
      stateName: api.stateName,
      merchantName: api.merchantName || 'Unknown',
      corpName: api.merchantCorpName || 'Unknown',
      priority: api.priorityId,
      value: api.value,
      status: api.statusId,
      sstpId: api.sstpid || 'NO ID',
    }
  }

  private mapFilterToApiFilter(
    filter: IMailFilter,
    page: number = 1,
    pageLimit: number = 50
  ): IApiMailFilter {
    const apiFilter: IApiMailFilter = { page: page.toString(), limit: pageLimit.toString() }
    if (filter.contactId) {
      apiFilter.assignedContactId = filter.contactId.toString()
    }
    if (filter.merchantId) {
      apiFilter.documentMerchantId = filter.merchantId.toString()
    }
    if (filter.priority) {
      apiFilter.priorityIds = filter.priority.toString()
    }
    if (filter.stateFIPSCode) {
      apiFilter.stateFIPSCode = filter.stateFIPSCode.toString()
    }
    if (filter.status) {
      apiFilter.statusIds = filter.status.toString()
    }
    if (filter.type) {
      apiFilter.documentTypeIds = filter.type.toString()
    }
    if (filter.month) {
      const month = moment(filter.month, DateFormatEnum.Month)
      apiFilter.createdAtMin = month.startOf('month').format(DateFormatEnum.Day)
      apiFilter.createdAtMax = month.endOf('month').format(DateFormatEnum.Day)
    }

    return apiFilter
  }
}
