export enum DirectFilingFrequencyEnum {
  Monthly = 1,
  Quarterly = 2,
  SemiAnnual = 4,
  Annual = 3,
}

export const DirectFilingFrequencyEnumLabel = new Map<DirectFilingFrequencyEnum, string>([
  [DirectFilingFrequencyEnum.Monthly, 'Monthly'],
  [DirectFilingFrequencyEnum.Quarterly, 'Quarterly'],
  [DirectFilingFrequencyEnum.SemiAnnual, 'Semi Annual'],
  [DirectFilingFrequencyEnum.Annual, 'Annual'],
])

export enum DirectFilingTaxTypeEnum {
  SalesTax = 1,
  UseTax = 2,
}

export const DirectFilingTaxTypeEnumLabel = new Map<DirectFilingTaxTypeEnum, string>([
  [DirectFilingTaxTypeEnum.SalesTax, 'Sales Tax'],
  [DirectFilingTaxTypeEnum.UseTax, 'Use Tax'],
])

export enum DirectFilingRequestLimit {
  LIMIT_NOT_REACHED = 'limit not reached',
  LIMIT_REACHED = 'limit reached',
}
export enum DirectFilingAcceptanceStatusEnum {
  NOT_ACCEPTED = 0,
  SUCCESSFULLY_ACCEPTED = 1,
  ACCEPTANCE_ERROR = 2,
}

export const DirectFilingAcceptanceStatusEnumLabel = new Map<
  DirectFilingAcceptanceStatusEnum,
  string
>([
  [DirectFilingAcceptanceStatusEnum.NOT_ACCEPTED, 'Not Accepted'],
  [DirectFilingAcceptanceStatusEnum.SUCCESSFULLY_ACCEPTED, 'Successfully Accepted'],
  [DirectFilingAcceptanceStatusEnum.ACCEPTANCE_ERROR, 'Acceptance Error(s)'],
])

export enum TaxReturnTypeEnum {
  DR_15 = 'DR-15',
  DR_15_EZ = 'DR-15EZ',
  ST_8 = 'ST-8',
  ST_9 = 'ST-9',
}

export const TaxReturnTypeEnumLabel = new Map<TaxReturnTypeEnum, string>([
  [TaxReturnTypeEnum.DR_15, 'DR-15'],
  [TaxReturnTypeEnum.DR_15_EZ, 'DR-15EZ'],
  [TaxReturnTypeEnum.ST_8, 'ST-8'],
  [TaxReturnTypeEnum.ST_9, 'ST-9'],
])

export enum DirectFilePathEnum {
  Florida = 'FlFiler',
  Virginia = 'VaFiler',
}
