import { ChangeDetectionStrategy, Component } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { TermsOfServiceDialog } from '../../dialogs/terms-of-service/terms-of-service.dialog'

@Component({
  selector: 'tc-terms-of-service-warning',
  templateUrl: './terms-of-service-warning.component.html',
  styleUrls: ['./terms-of-service-warning.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsOfServiceWarningComponent {
  constructor(public termsOfServiceDialog: MatDialog) {}

  openTermsOfService() {
    this.termsOfServiceDialog.open(TermsOfServiceDialog, {
      panelClass: 'confirm-dialog',
      restoreFocus: false,
    })
  }
}
