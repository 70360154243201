import { animate, keyframes, style, transition, trigger } from '@angular/animations'
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { delay, filter, tap } from 'rxjs/operators'

@Component({
  selector: 'tc-working-spinner',
  templateUrl: './working-spinner.component.html',
  styleUrls: ['./working-spinner.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fade', [
      transition(':enter', [
        animate(
          '.2s ease-in',
          keyframes([
            style({ transform: 'scale(.3)', offset: 0, opacity: 0.5 }),
            style({ transform: 'scale(1)', offset: 1, opacity: 1 }),
          ])
        ),
      ]),
      transition(':leave', [
        animate(
          '.6s ease-out',
          keyframes([
            style({ transform: 'scale(1)', offset: 0, opacity: 1 }),
            style({ transform: 'scale(1.2)', offset: 0.4, opacity: 0.6 }),
            style({ transform: 'scale(0)', offset: 1, opacity: 0 }),
          ])
        ),
      ]),
    ]),
    trigger('slide-close', [
      transition(':leave', [
        animate('.2s', keyframes([style({ offset: 0 }), style({ width: 0, offset: 1 })])),
      ]),
    ]),
  ],
})
export class WorkingSpinnerComponent implements OnInit {
  showMessage$ = new BehaviorSubject(true)
  message$ = new BehaviorSubject('')
  opened$ = new BehaviorSubject(false)
  constructor() {}

  @Input('message')
  set message(message: string) {
    this.message$.next(message)
  }

  @Input('opened')
  set opened(opened: boolean) {
    this.opened$.next(opened)
    this.showMessage$.next(opened)
  }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.showMessage$.next(false);
    // }, 2500);

    this.showMessage$
      .pipe(
        filter((show) => show),
        delay(2500),
        tap((_) => this.showMessage$.next(false))
      )
      .subscribe()
  }
}
