import { DataSourceEnum } from 'src/app/enums/dataSource.enums'
import { LoginTypeEnum } from 'src/app/enums/login.enum'

import { LoginActions, LoginActionTypes } from '../actions/login.actions'
import { initialState, State } from '../user.state'

export function reducer(state = initialState, action: LoginActions): State {
  switch (action.type) {
    case LoginActionTypes.AutoLoginRequest:
      return {
        ...initialState,
        error: null,
        isLoading: true,
        loginType: LoginTypeEnum.Auto,
        working: 'Logging in',
      }

    case LoginActionTypes.UpdateUser:
      return {
        ...state,
        isLoading: true,
        working: 'Updating user',
      }

    case LoginActionTypes.UpdateUserAndAccountForAutoLogin:
      return {
        ...state,
        auth: action.payload.auth,
        working: '',
      }

    case LoginActionTypes.UpdateUserAuth:
      return {
        ...state,
        isLoading: false,
        working: '',
        user: { ...state.user, authority: action.payload.auth },
      }

    case LoginActionTypes.UpdateSingleUseAuthInStore:
      return {
        ...state,
        isLoading: false,
        working: '',
        auth: action.payload.auth,
      }

    case LoginActionTypes.MasqueradeLoginRequest:
      return {
        ...state,
        error: null,
        isLoading: true,
        working: 'Requesting Masquerade',
        loginType: LoginTypeEnum.Masquerade,
      }

    case LoginActionTypes.OneTimeLoginRequest:
      return {
        ...initialState,
        error: null,
        isLoading: true,
        working: 'Logging in',
        loginType: LoginTypeEnum.SingleUse,
      }

    case LoginActionTypes.LoginRequest:
    case LoginActionTypes.Login2Request:
      return {
        ...initialState,
        error: null,
        isLoading: true,
        working: 'Logging in',
      }

    case LoginActionTypes.LoginFailure:
    case LoginActionTypes.Login2Failure:
    case LoginActionTypes.RequestUserAndAuthFromLocalFailure:
      return {
        ...state,
        isLoading: false,
        working: '',
        error: action.payload.error,
      }

    case LoginActionTypes.MasqueradeSuccess:
    case LoginActionTypes.LoginSuccess:
    case LoginActionTypes.RequestUserAndAuthFromLocalSuccess:
      return {
        ...state,
        working: '',
        error: null,
        user: action.payload.user,
        auth: action.payload.auth,
        dataSource: DataSourceEnum.API,
      }

    case LoginActionTypes.Login2Success:
      return {
        ...state,
        working: '',
        error: null,
        dataSource: DataSourceEnum.API,
      }

    case LoginActionTypes.CleanUpIsLoading:
      return {
        ...state,
        isLoading: false,
        working: '',
      }

    case LoginActionTypes.LockSuccess:
      return {
        ...state,
        error: null,
        user: action.payload.user,
        auth: action.payload.auth,
        isLoading: false,
        working: '',
        dataSource: DataSourceEnum.Local,
      }

    case LoginActionTypes.LogoutFromRegistraton:
    case LoginActionTypes.Logout:
      return {
        ...initialState,
        isLoading: false,
        working: '',
        dataSource: DataSourceEnum.Local,
        error: null,
        user: null,
      }

    case LoginActionTypes.UpdateStoreUserData:
      return {
        ...state,
        isLoading: false,
        working: '',
        error: null,
        user: {
          ...state.user,
          ...action.payload.user,
        },
        dataSource: DataSourceEnum.API,
      }

    case LoginActionTypes.ResetPasswordRequest:
      return {
        ...state,
        isLoading: true,
        working: 'Resetting password',
        error: null,
        passwordReset: { email: action.payload.email },
      }

    case LoginActionTypes.ResetPasswordRequestSuccess:
      return {
        ...state,
        isLoading: false,
        working: '',
        error: null,
      }

    case LoginActionTypes.RegisterRequest:
      return {
        ...state,
        isLoading: true,
        working: 'Registering new account',
        error: null,
      }

    case LoginActionTypes.RegisterRequestSuccess:
      return {
        ...state,
        isLoading: false,
        working: '',
        error: null,
        emailToVerify: action.payload.emailToVerify,
      }

    case LoginActionTypes.RegisterRequestFailure:
    case LoginActionTypes.ResetPasswordFailure:
      return {
        ...state,
        isLoading: false,
        working: '',
        error: action.payload.error,
      }

    case LoginActionTypes.AcceptTermsOfService:
    case LoginActionTypes.AcceptLatestTermsOfService:
    case LoginActionTypes.AcceptTermsOfServiceExtendConfig:
    case LoginActionTypes.AcceptTermsOfServiceFailure:
      return {
        ...state,
        working: 'Accepting Terms of Service',
        user: { ...state.user, ...{ acceptedTermsOfService: false } },
      }

    case LoginActionTypes.AcceptTermsOfServiceSuccess:
      return {
        ...state,
        working: '',
        user: { ...state.user, ...{ acceptedTermsOfService: true } },
      }

    default:
      return state
  }
}
