import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { first, tap } from 'rxjs/operators'

import { TosService } from '../../services/terms-of-service.service'

@Component({
  selector: 'tc-latest-terms-of-service',
  templateUrl: './latest-terms-of-service.component.html',
  styleUrls: ['./latest-terms-of-service.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LatestTermsOfServiceComponent implements OnInit {
  private pdfBlob: Blob
  public pdf$ = new BehaviorSubject<string>(null)
  public height = '400px'

  constructor(private tosService: TosService) {}

  ngOnInit(): void {
    this.tosService
      .getLatestTermsOfService()
      .pipe(
        first(),
        tap((file) => {
          if (file) {
            this.pdfBlob = new Blob([file], { type: 'application/pdf' })
            if (typeof FileReader !== 'undefined') {
              const reader = new FileReader()
              reader.onload = (e: any) => {
                this.pdf$.next(e.target.result)
              }
              reader.readAsArrayBuffer(this.pdfBlob)
            }
          }
        })
      )
      .subscribe()
  }

  public showPdfInNewWindow(): void {
    const fileURL = URL.createObjectURL(this.pdfBlob)
    window.open(fileURL)
  }
}
