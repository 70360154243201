import { TransactionActions, TransactionActionTypes } from './../actions/transaction.actions'
import { adapter, initialState, State } from './../transaction.store'

export function reducer(state = initialState, action: TransactionActions): State {
  switch (action.type) {
    case TransactionActionTypes.UpdateTransactionDetail: {
      return {
        ...state,
        detailId: action.payload.id,
      }
    }

    case TransactionActionTypes.SaveLastUpdated: {
      return {
        ...state,
        lastUpdated: action.payload.lastUpdated,
      }
    }

    case TransactionActionTypes.InitTransactions:
    case TransactionActionTypes.InitTransactions: {
      return {
        ...adapter.removeAll(state),
        filter: {},
        working: true,
        initialLoadComlete: false,
        detailId: '',
      }
    }

    case TransactionActionTypes.RefreshTransactions: {
      return {
        ...adapter.removeAll(state),
        working: true,
        initialLoadComlete: false,
        detailId: '',
      }
    }

    case TransactionActionTypes.InitTransactionsSuccess:
    case TransactionActionTypes.InitTestTransactionsSuccess:
    case TransactionActionTypes.RefreshTransactionsSuccess:
    case TransactionActionTypes.LoadAdditionalTransactionsSuccess:
    case TransactionActionTypes.GetFilteredTransacgtionsSuccess: {
      return {
        ...state,
        ...adapter.upsertMany(action.payload.transactions, state),
        initialLoadComlete: true,
        complete: action.payload.complete,
        working: false,
        message: action.payload.message,
        filingIds: action.payload.filingIds,
      }
    }

    case TransactionActionTypes.UpdateTransactionDetailSuccess: {
      return adapter.updateOne(action.payload.transaction, state)
    }

    case TransactionActionTypes.DownloadTransactions: {
      return {
        ...state,
        downloading: true,
      }
    }

    case TransactionActionTypes.DownloadTransactionsComplete: {
      return {
        ...state,
        downloading: false,
      }
    }

    case TransactionActionTypes.ClearTransactions: {
      return {
        ...adapter.removeAll(state),
        // ...initialState,
        working: true,
        initialLoadComlete: false,
      }
    }

    case TransactionActionTypes.GetFilteredTransactions: {
      return {
        ...state,
        ...adapter.removeAll(state),
        filter: action.payload.filter,
        working: true,
        initialLoadComlete: false,
        detailId: '',
      }
    }

    case TransactionActionTypes.LoadAdditionalTransactions: {
      return {
        ...state,
        working: true,
        page: state.page + 1,
      }
    }

    default: {
      return state
    }
  }
}
