export interface IOption {
  value: string | number
  label: string
}
export function getOptionsFromLabel(label: Map<string | number, string>): IOption[] {
  return Array.from(label.entries())
    .map((entry) => ({
      value: entry[0],
      label: entry[1],
    }))
    .sort(compare)
}

function compare(a: IOption, b: IOption) {
  const labelA = a.label ? a.label.toLocaleUpperCase() : ''
  const labelB = b.label ? b.label.toLocaleUpperCase() : ''
  let comparison = 0
  if (labelA > labelB) {
    comparison = 1
  } else if (labelA < labelB) {
    comparison = -1
  }
  return comparison
}
