import { AccountHolderTypeEnum } from './../../enums/payment-method.enums'
import { IPaymentMethod } from '../models/ipayment-method.model'
import {
  PaymentMethodTypeEnum,
  PaymentMethodTypeEnumLabel,
  PaymentTypeEnum,
  PaymentTypeEnumLabel,
} from 'src/app/enums/payment-method.enums'
import { ICreditCard } from '../models/icreditcard'
import moment from 'moment'
import { IBankAccount } from '../models/ibank-account'
import { IPaymentMethodApi } from '../models/iapi-payment-method'
import { IPaymentMethodBody } from '../models/iapi-payment-method-body'

export class PaymentMethodAdapter {
  public mapApiResultsToPaymentMethod(result: IPaymentMethodApi): IPaymentMethod {
    const methodType = result.isCreditCard
      ? PaymentMethodTypeEnum.CreditCard
      : result.isChecking
        ? PaymentMethodTypeEnum.Checking
        : PaymentMethodTypeEnum.Saving
    let displayNumber: string
    let displayType: string
    let accountHolderType: AccountHolderTypeEnum

    switch (methodType) {
      case PaymentMethodTypeEnum.CreditCard:
        displayNumber = result.creditCardNumber
        displayType = PaymentMethodTypeEnumLabel.get(PaymentMethodTypeEnum.CreditCard)
        accountHolderType = AccountHolderTypeEnum.Unknown
        break
      case PaymentMethodTypeEnum.Checking:
        displayNumber = result.accountNumber
        displayType = PaymentMethodTypeEnumLabel.get(PaymentMethodTypeEnum.Checking)
        accountHolderType = result.accountHolderType
        break
      case PaymentMethodTypeEnum.Saving:
        displayNumber = result.accountNumber
        displayType = PaymentMethodTypeEnumLabel.get(PaymentMethodTypeEnum.Saving)
        accountHolderType = result.accountHolderType
        break
      default:
        displayNumber = 'unknown account number'
        displayType = 'unknown payment type'
        accountHolderType = AccountHolderTypeEnum.Unknown
    }

    const paymentType = result.feeOnly
      ? PaymentTypeEnum.Fee
      : result.taxRemittanceOnly
        ? PaymentTypeEnum.Remittance
        : PaymentTypeEnum.Both

    return {
      id: result.paymentInformationID.toString(),
      paymentProfileId: result.customerPaymentProfileID > 0 ? result.customerPaymentProfileID : 1,
      profileId: result.customerProfileID,
      isDefault: result.isDefault,
      type: methodType,
      createdOn: result.createdOn,
      isDeleted: false,
      name: result.friendlyName,
      displayNumber,
      displayType,
      expiration: result.creditCardExpiration,
      csc: result.creditCardSecurity,
      routing: result.routingNumber,
      paymentInformationId: result.paymentInformationID,
      paymentType,
      paymentTypeLabel: PaymentTypeEnumLabel.get(paymentType),
      accountHolderType,
    }
  }

  public mapCreditCardToPaymentMethodBody(cc: ICreditCard): IPaymentMethodBody {
    const expiration = moment()
      .month(parseInt(cc.monthExpire, 10) - 1)
      .year(parseInt(cc.yearExpire, 10))
      .startOf('month')
      .format('M/YY')

    return {
      friendlyName: cc.name,
      isBankAccount: false,
      isDefault: false,
      account: '',
      routing: '',
      number: cc.number,
      code: cc.csc,
      billingState: cc.stateAbbr,
      billingPostalCode: cc.zipCode,
      expiration,
      isCreditCard: true,
      isChecking: false,
      accountHolderType: AccountHolderTypeEnum.Unknown,
    }
  }

  public mapBankAccountToPaymentMethodBody(bankAccount: IBankAccount): IPaymentMethodBody {
    return {
      friendlyName: bankAccount.businessName,
      isBankAccount: true,
      isDefault: false,
      account: bankAccount.accountNumber,
      routing: bankAccount.routingNumber,
      number: '',
      code: '',
      billingState: '',
      billingPostalCode: '',
      expiration: '',
      isCreditCard: false,
      isChecking: bankAccount.isChecking,
      accountHolderType: bankAccount.accountHolderType,
    }
  }

  public mapPaymentMethodToKryptonPi(paymentMethod: IPaymentMethod): object {
    // eslint-disable-next-line one-var
    let routing = null,
      account = null,
      ccNumber = null,
      expiration = null,
      code = null
    // eslint-disable-next-line one-var
    let isBankAccount = false,
      isCreditCard = false

    if (paymentMethod.type === PaymentMethodTypeEnum.CreditCard) {
      ccNumber = paymentMethod.displayNumber
      expiration = paymentMethod.expiration
      code = paymentMethod.csc
      isCreditCard = true
    } else {
      routing = paymentMethod.displayNumber
      account = paymentMethod.routing
      ccNumber = null
      account = paymentMethod.displayNumber
      isBankAccount = true
    }

    return {
      paymentInformationId: paymentMethod.paymentInformationId,
      customerProfileId: paymentMethod.profileId,
      customerPaymentProfileId: paymentMethod.paymentProfileId,
      friendlyName: paymentMethod.name,
      routing,
      account,
      isBankAccount,
      number: ccNumber,
      expiration,
      code,
      isCreditCard,
      isDefault: paymentMethod.isDefault,
    }
  }
}
