<ul class="breadcrumb">
  <li *ngFor="let breadcrumb of breadcrumbs$ | async">
    <a
      [routerLink]="[breadcrumb.path]"
      aria-current="page"
      attr.aria-label="{{ breadcrumb.path.slice(1) }}"
    >
      {{ breadcrumb.label }}
    </a>
  </li>
</ul>
