import { AccountStateEnum } from 'src/app/enums/account.enums'
import { LocalStorageNameSpaceEnum } from 'src/app/enums/local-storage.enums'
import { UserDb } from 'src/app/user/db/user.db'

import { IAccount } from '../models/iaccount'

export class AccountDatabase {
  constructor() {}

  public saveAccount(account: IAccount): IAccount {
    localStorage.setItem(LocalStorageNameSpaceEnum.Account, JSON.stringify(account))
    return account
  }

  public getAccount(): IAccount {
    try {
      const accountString = localStorage.getItem(LocalStorageNameSpaceEnum.Account)
      if (typeof accountString !== 'string') {
        return null
      }
      const user = new UserDb().getUser()
      if (!user) {
        return this.clearAccount()
      }

      const account: IAccount = JSON.parse(accountString)
      if (user.merchantId !== account.merchantId) {
        return this.clearAccount()
      }

      return this.cleanupAccountData(account)
    } catch (e) {
      return this.clearAccount()
    }
  }

  public getAccountState(): AccountStateEnum {
    const account = this.getAccount()
    if (!account) {
      return null
    }

    return account.accountState
  }

  public updateAccount(update: object): IAccount {
    const current = this.getAccount()
    this.saveAccount({ ...current, ...update })
    return this.getAccount()
  }

  public clearAccount(): null {
    localStorage.removeItem(LocalStorageNameSpaceEnum.Account)
    return null
  }

  private cleanupAccountData(account: IAccount): IAccount {
    const mapped: IAccount = {
      attestations: account.attestations,
      configurationWindow: account.configurationWindow,
      accountState: account.accountState,
      closeWindow: account.closeWindow,
      prepayBlance: account.prepayBlance,
      conversionStatus: account.conversionStatus,
      createdTimestamp: account.createdTimestamp,
      essProfileId: account.essProfileId,
      lastModifiedTimestamp: account.lastModifiedTimestamp,
      merchantId: account.merchantId,
      servicePlan: account.servicePlan,
      phone: account.phone,
      profile: account.profile,
      serviceFee: account.serviceFee,
      streamlined: account.streamlined,
      useInvoicing: account.useInvoicing,
      uspsID: account.uspsID,
      appIDs: account.appIDs,
    }
    return mapped
  }
}
