import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store'
import { State as BillingState } from '../manage-account.state'
import {
  Customer,
  CustomerUsage,
  CustomerUsageReport,
  Invoice,
  PaymentMethod,
  PlanDetail,
  PlanSubscription,
  SubscriptionEntitlements,
  SubscriptionItem,
} from '../models/billing.model'
import { SubscriptionPlanEnum } from '../enums/suscriptions.enums'
import moment from 'moment'
import { SubscriptionStateEnum } from 'src/app/enums/subscription-state.enums'

const getPaymentMethods = (state: BillingState): PaymentMethod[] => {
  return state?.paymentMethods
}

const getPaymentMethodsLoading = (state: BillingState): boolean => {
  return state?.loadingPaymentMethods
}

const getSubscription = (state: BillingState): PlanSubscription => {
  return state?.subscription
}

const getIsFreeTrialSubscription = (state: BillingState): boolean => {
  return state?.subscription?.status === SubscriptionStateEnum.InTrial
}

export const getFreeTrialRemainingDays = (state: BillingState): number => {
  const subscription = state?.subscription
  const isFreeTrial = getIsFreeTrialSubscription(state)
  if (!subscription || !isFreeTrial) {
    return null
  }
  const expiresAt = moment(subscription.nextBillingAt * 1000)
  const now = moment().utc()
  const remainingHours = expiresAt.diff(now, 'hours')
  const remainingDays = remainingHours / 24

  return Math.ceil(remainingDays)
}

const getPendingSubscription = (state: BillingState): PlanSubscription => {
  return state?.pendingSubscription
}

const getSubscriptionLoading = (state: BillingState): boolean => {
  return state?.loadingSubscription
}

const getIsSubscriptionsLoaded = (state: BillingState): boolean => {
  return state?.isSubscriptionsLoaded
}

const getLoadingPutSubscription = (state: BillingState): boolean => {
  return state?.loadingPutSubscription
}

const getPrimarySubscriptionPlanItem = (state: BillingState): SubscriptionItem => {
  return state?.subscription?.subscriptionItems?.find(
    (sub: SubscriptionItem) =>
      sub.itemType === 'plan' &&
      (sub?.itemPriceId.toLowerCase()?.includes('starter') ||
        sub?.itemPriceId?.toLowerCase()?.includes('premium') ||
        sub?.itemPriceId?.toLowerCase()?.includes('free') ||
        sub?.itemPriceId?.toLowerCase()?.includes(SubscriptionPlanEnum.ENTERPRISE) ||
        sub?.itemPriceId?.toLowerCase()?.includes(SubscriptionPlanEnum.LEGACY_FLAT_FEE) ||
        sub?.itemPriceId?.toLowerCase()?.includes(SubscriptionPlanEnum.LEGACY_USAGE))
  )
}

const getPrimaryPendingSubscriptionPlanItem = (state: BillingState): SubscriptionItem => {
  return state?.pendingSubscription?.subscriptionItems?.find(
    (sub: SubscriptionItem) =>
      sub.itemType === 'plan' &&
      (sub?.itemPriceId.toLowerCase()?.includes('starter') ||
        sub?.itemPriceId?.toLowerCase()?.includes('premium') ||
        sub?.itemPriceId?.toLowerCase()?.includes('free') ||
        sub?.itemPriceId?.toLowerCase()?.includes(SubscriptionPlanEnum.ENTERPRISE) ||
        sub?.itemPriceId?.toLowerCase()?.includes(SubscriptionPlanEnum.LEGACY_FLAT_FEE) ||
        sub?.itemPriceId?.toLowerCase()?.includes(SubscriptionPlanEnum.LEGACY_USAGE))
  )
}

const getBillingRequestFlowId = (state: BillingState): string => {
  return state.billingRequestFlowId
}

const getFreePlan = (state: BillingState): PlanDetail => {
  return state.plans?.find?.((plan: PlanDetail) => plan.name.toLocaleLowerCase().includes('free'))
}

const getStarterPlan = (state: BillingState): PlanDetail => {
  return state.plans?.find?.((plan: PlanDetail) =>
    plan.name.toLocaleLowerCase().includes('starter')
  )
}

const getPremiumPlan = (state: BillingState): PlanDetail => {
  return state.plans?.find?.((plan: PlanDetail) =>
    plan.name.toLocaleLowerCase().includes('premium')
  )
}

const getIsEditingPlan = (state: BillingState): boolean => {
  return state?.isEditingPlan
}

const getCustomerProfile = (state: BillingState): Customer => {
  return state.customer
}

const getInvoices = (state: BillingState): Invoice[] => {
  return state.invoices
}

const getIsLoadingPaymentRole = (state: BillingState) => {
  return state.isLoadingPaymentRole
}

const getIsLoadingCustomerProfile = (state: BillingState) => {
  return state.isLoadingCustomerProfile
}

const getCustomerUsage = (state: BillingState) => {
  return state.customerUsage
}

const getUsageReports = (state: BillingState) => {
  return state.usageReports
}

const getUsageReportDetails = (state: BillingState) => {
  return state.usageReportDetails
}

const getIsUpdatingBillingInfo = (state: BillingState) => {
  return state.isUpdatingBillingInfo
}

const getUpdatingBillingInfoSuccess = (state: BillingState) => {
  return state.updatingBillingInfoSuccess
}

const getShowWelcomeToTrial = (state: BillingState) => {
  return state.showWelcomeToTrial
}

const getSubscriptionEntitlements = (state: BillingState) => {
  return state.subscriptionEntitlements
}

export const selectBillingState: MemoizedSelector<object, BillingState> =
  createFeatureSelector<BillingState>('billing')

export const selectPaymentMethods: MemoizedSelector<object, PaymentMethod[]> = createSelector(
  selectBillingState,
  getPaymentMethods
)

export const selectPaymentMethodsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectBillingState,
  getPaymentMethodsLoading
)

export const selectSubscription: MemoizedSelector<object, PlanSubscription> = createSelector(
  selectBillingState,
  getSubscription
)

export const selectIsFreeTrialSubscription: MemoizedSelector<object, boolean> = createSelector(
  selectBillingState,
  getIsFreeTrialSubscription
)

export const selectFreeTrialRemainingDays: MemoizedSelector<object, number> = createSelector(
  selectBillingState,
  getFreeTrialRemainingDays
)

export const selectPendingSubscription: MemoizedSelector<object, PlanSubscription> = createSelector(
  selectBillingState,
  getPendingSubscription
)

export const selectSubscriptionLoading: MemoizedSelector<object, boolean> = createSelector(
  selectBillingState,
  getSubscriptionLoading
)

export const selectIsSubscriptionsLoaded: MemoizedSelector<object, boolean> = createSelector(
  selectBillingState,
  getIsSubscriptionsLoaded
)

export const selectLoadingPutSubscription: MemoizedSelector<object, boolean> = createSelector(
  selectBillingState,
  getLoadingPutSubscription
)

export const selectPrimarySubscriptionItem: MemoizedSelector<object, SubscriptionItem> =
  createSelector(selectBillingState, getPrimarySubscriptionPlanItem)

export const selectPrimaryPendingSubscriptionItem: MemoizedSelector<object, SubscriptionItem> =
  createSelector(selectBillingState, getPrimaryPendingSubscriptionPlanItem)

export const selectBillingRequestFlowId: MemoizedSelector<object, string> = createSelector(
  selectBillingState,
  getBillingRequestFlowId
)

export const selectFreePlan: MemoizedSelector<object, PlanDetail> = createSelector(
  selectBillingState,
  getFreePlan
)

export const selectStarterPlan: MemoizedSelector<object, PlanDetail> = createSelector(
  selectBillingState,
  getStarterPlan
)

export const selectPremiumPlan: MemoizedSelector<object, PlanDetail> = createSelector(
  selectBillingState,
  getPremiumPlan
)

export const selectIsEditingPlan: MemoizedSelector<object, boolean> = createSelector(
  selectBillingState,
  getIsEditingPlan
)

export const selectCustomerProfile: MemoizedSelector<object, Customer> = createSelector(
  selectBillingState,
  getCustomerProfile
)

export const selectInvoices: MemoizedSelector<object, Invoice[]> = createSelector(
  selectBillingState,
  getInvoices
)

export const selectIsLoadingPaymentRole: MemoizedSelector<object, boolean> = createSelector(
  selectBillingState,
  getIsLoadingPaymentRole
)

export const selectIsLoadingCustomerProfile: MemoizedSelector<object, boolean> = createSelector(
  selectBillingState,
  getIsLoadingCustomerProfile
)

export const selectCustomerUsage: MemoizedSelector<object, CustomerUsage> = createSelector(
  selectBillingState,
  getCustomerUsage
)

export const selectUsageReports: MemoizedSelector<object, CustomerUsageReport[]> = createSelector(
  selectBillingState,
  getUsageReports
)

export const selectUsageReportDetails: MemoizedSelector<object, CustomerUsageReport> =
  createSelector(selectBillingState, getUsageReportDetails)

export const selectIsUpdatingBillingInfo: MemoizedSelector<object, boolean> = createSelector(
  selectBillingState,
  getIsUpdatingBillingInfo
)

export const selectUpdatingBillingInfoSuccess: MemoizedSelector<object, boolean> = createSelector(
  selectBillingState,
  getUpdatingBillingInfoSuccess
)

export const selectShowWelcomeToTrial: MemoizedSelector<object, boolean> = createSelector(
  selectBillingState,
  getShowWelcomeToTrial
)

export const selectSubscriptionEntitlements: MemoizedSelector<object, SubscriptionEntitlements[]> =
  createSelector(selectBillingState, getSubscriptionEntitlements)
