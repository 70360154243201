import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core'
import { MatSelectChange } from '@angular/material/select'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { State as AccountState } from 'src/app/account/account.state'
import { AddPaAttestation, UpdateAccountState } from 'src/app/account/actions/account.actions'
import * as fromSelectors from 'src/app/account/selectors/account.selectors'
import { AccountStateEnum, AccountStateLabel } from 'src/app/enums/account.enums'
import { getOptionsFromLabel, IOption } from 'src/app/utilities/select-box.utilities'

@Component({
  selector: 'tc-toggle-account-state',
  templateUrl: './toggle-account-state.component.html',
  styleUrls: ['./toggle-account-state.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleAccountStateComponent implements OnInit {
  options: IOption[] = getOptionsFromLabel(AccountStateLabel)

  constructor(
    private accountStore: Store<AccountState>,
    private router: Router
  ) {}

  @Output() accountStatusChanged = new EventEmitter<AccountStateEnum>()

  accountState$ = this.accountStore.select(fromSelectors.selectAccountStatus)

  ngOnInit(): void {}

  public changeAccountState(event: MatSelectChange) {
    if (event.value === AccountStateEnum.Attestation) {
      this.accountStore.dispatch(new AddPaAttestation())
      return
    }

    this.accountStore.dispatch(new UpdateAccountState({ state: event.value }))
    this.accountStatusChanged.emit(event.value)

    if (event.value === AccountStateEnum.Converted) {
      this.router.navigate(['/go'])
      return
    }

    if (event.value === AccountStateEnum.Frozen) {
      this.router.navigate(['/go/payment-methods'])
      return
    }

    if (event.value === AccountStateEnum.Prospective) {
      this.router.navigate(['/complete-account'])
      return
    }
  }
}
