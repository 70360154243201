import Dexie from 'dexie'
import { from, Observable } from 'rxjs'

import { IDashboard } from '../models/idashboard.models'

export class DashboardDatabase extends Dexie {
  private dashboard: Dexie.Table<IDashboard, number>

  constructor() {
    super('DashboardDb')
    this.version(1).stores({
      dashboard: 'id',
    })
    this.dashboard = this.table('dashboard')
  }

  public saveDashboards(dashboard: IDashboard[]): Observable<number> {
    return from(this.dashboard.bulkPut(dashboard))
  }

  public updateDashboard(dashboard: IDashboard) {
    return from(this.dashboard.put(dashboard))
  }

  public clearDatabase(): Observable<void> {
    return from(this.dashboard.clear())
  }

  public getDashboards(): Observable<IDashboard[]> {
    return from(this.dashboard.orderBy('dashboard').toArray())
  }

  public getDashboard(id: string): Observable<IDashboard | undefined> {
    return from(this.dashboard.get({ id }))
  }
}
