import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'
import { FeatureKeys } from 'src/app/enums/store.enums'

import { adapter } from './../location.store'
import * as fromLocation from './../location.store'

const getLocationsLoaded = (state: fromLocation.State): boolean => {
  return state.initialLoadComlete
}

const getLocationsDataSource = (state: fromLocation.State): DataSourceEnum => {
  return state.source
}

const getLocationsWorkingMessage = (state: fromLocation.State): string => {
  return state.working
}

const getLocationsIsWorking = (state: fromLocation.State): boolean => {
  return !!state.working
}

export const selectLocationState: MemoizedSelector<object, fromLocation.State> =
  createFeatureSelector<fromLocation.State>(FeatureKeys.Locations)

export const initialLocationDataLoaded: MemoizedSelector<object, boolean> = createSelector(
  selectLocationState,
  getLocationsLoaded
)

export const selectLocationDataSource: MemoizedSelector<object, DataSourceEnum> = createSelector(
  selectLocationState,
  getLocationsDataSource
)

export const selectIsWorking: MemoizedSelector<object, boolean> = createSelector(
  selectLocationState,
  getLocationsIsWorking
)

export const selectWorkingMessage: MemoizedSelector<object, string> = createSelector(
  selectLocationState,
  getLocationsWorkingMessage
)

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectLocationState)
