import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PasswordPage } from 'src/app/user/pages/password/password.page'
import { RegisterPage } from 'src/app/user/pages/register/register.page'
import { ResetPasswordPage } from 'src/app/user/pages/reset-password/reset-password.page'
import { SetupPage } from 'src/app/user/pages/setup/setup.page'
import { ShellPublicComponent } from '../shell-public/shell-public.component'
import { ShellPublicBasicComponent } from '../shell-public-basic/shell-public-basic.component'
import { ConfirmEmailPage } from 'src/app/user/pages/confirm-email/confirm-email.page'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full',
  },
  {
    path: 'home',
    redirectTo: 'sign-in',
    pathMatch: 'full',
  },
  {
    path: 'setup',
    data: {
      breadcrumb: '',
    },
    component: ShellPublicBasicComponent,
    children: [
      {
        path: '',
        data: {
          breadcrumb: 'set-password',
        },
        component: SetupPage,
      },
      {
        path: 'email',
        data: {
          breadcrumb: '',
        },
        component: SetupPage,
      },
      {
        path: 'confirm-email',
        data: {
          breadcrumb: 'confirm-email',
        },
        component: ConfirmEmailPage,
      },
    ],
  },
  {
    path: 'password',
    data: {
      title: 'Reset Your Password',
      breadcrumb: 'password',
    },
    component: ShellPublicBasicComponent,
    children: [
      {
        path: '',
        data: {
          breadcrumb: '',
        },
        component: PasswordPage,
      },
      {
        path: 'reset',
        data: {
          breadcrumb: 'reset',
        },
        component: ResetPasswordPage,
      },
    ],
  },
  {
    path: 'register',
    data: {
      breadcrumb: 'register',
      title: '',
      description: '',
    },
    component: RegisterPage,
  },
  {
    path: 'tic',
    data: {
      breadcrumb: 'tic',
      title: 'Taxability Information Codes',
      description: `Learn more about TaxCloud's taxibility information codes`,
    },
    component: ShellPublicComponent,
    loadChildren: () =>
      import('src/app/public-tic-view/public-tic-view.module').then((m) => m.PublicTicViewModule),
  },
  {
    path: 'tos',
    data: {
      breadcrumb: 'terms of service',
      title: 'Terms Of Service',
      description: `View TaxCloud's Terms of Service`,
    },
    component: ShellPublicComponent,
    loadChildren: () =>
      import('src/app/terms-of-service/terms-of-service.module').then(
        (m) => m.TermsOfServiceModule
      ),
  },
  {
    path: 'verify-linked-account',
    data: {
      breadcrumb: 'verify',
      title: 'Verify link',
      description: 'Verify linked accout',
    },
    component: ShellPublicComponent,
    loadChildren: () =>
      import('src/app/verify-user/verify-user.module').then((m) => m.VerifyUserModule),
  },
  // OLD PUBLIC UI ROUTES - LEAVE FOR VALIDATION 01.10.2023 KB
  // {
  //   path: '',
  //   data: {
  //     breadcrumb: 'home',
  //     title: 'TaxCloud: Sales Tax Reporting and Filing',
  //     description: `TaxCloud is the Internet's only sales tax compliance service with a free,
  //        state-paid option into 25 member states — because the states pay us.`
  //   },
  //   component: ShellPublicComponent,

  //   children: [
  //     {
  //       path: '',
  //       redirectTo: 'home',
  //       pathMatch: 'full',
  //       data: {
  //         breadcrumb: 'home',
  //       },
  //     },
  //     {
  //       path: 'home',
  //       data: {
  //         breadcrumb: 'home',
  //         title: 'Home',
  //         description: 'We make sales tax compliance easy, accurate and inexpensive. See how.'
  //       },
  //       component: HomePage
  //     },
  //     {
  //       path: 'ecommbytes',
  //       data: {
  //         breadcrumb: 'home',
  //         title: 'Home',
  //         description: 'We make sales tax compliance easy, accurate and inexpensive. See how.'
  //       },
  //       component: HomePage
  //     },
  //     {
  //       path: 'about',
  //       data: {
  //         breadcrumb: 'about',
  //         title: 'About TaxCloud',
  //         description: 'We work to serve online businesses of all sizes with accuracy and integrity by
  //            offering the most reliable and affordable sales tax compliance service on the internet.'
  //       },
  //       component: AboutTaxcloudPage
  //     },
  //     {
  //       path: 'features',
  //       data: {
  //         breadcrumb: 'features',
  //         title: 'TaxCloud Features',
  //       },
  //       component: FeaturesPage
  //     },
  //     {
  //       path: 'blog',
  //       data: {
  //         breadcrumb: 'blogs',
  //         title: 'TaxCloud Blog',
  //         description: 'Weekly articles about state sales tax'
  //       },
  //       loadChildren: () => import('src/app/blog/blog.module').then(m => m.BlogModule)
  //     },
  //     {
  //       path: 'contact',
  //       data: {
  //         breadcrumb: 'contact',
  //         title: 'Contact TaxCloud',
  //         description: 'Contact our customer service'
  //       },
  //       component: ContactTaxcloudPage
  //     },
  //     {
  //       path: 'find-a-rate',
  //       data: {
  //         breadcrumb: 'find a rate',
  //         title: 'Find a rate',
  //         description: 'Quick free tool to lookup up  a tax rate'
  //       },
  //       component: FindRatePage
  //     },
  //     {
  //       path: 'pricing',
  //       data: {
  //         breadcrumb: 'pricing',
  //         title: 'Pricing',
  //         description: 'Pricing plans for Taxcloud, including Enhanced Services'
  //       },
  //       loadChildren: () => import('src/app/pricing/pricing.module').then(m => m.PricingModule)
  //     },
  //     {
  //       path: 'privacy',
  //       data: {
  //         breadcrumb: 'privacy policy',
  //         title: 'Privacy Policy',
  //         description: 'Privacy policy for Taxcloud'
  //       },
  //       component: PrivacyPolicyPage,
  //     },
  //     {
  //       path: 'security',
  //       data: {
  //         breadcrumb: 'security policy',
  //         title: 'Security Policy',
  //         description: 'Security policy for Taxcloud'
  //       },
  //       component: SecurityPolicyPage,
  //     },
  //     {
  //       path: 'salestaxpolicy',
  //       data: {
  //         breadcrumb: 'sales tax policy',
  //         title: 'Sales Tax Policy',
  //         description: 'Sales tax policy for Taxcloud'
  //       },
  //       component: SalesTaxPolicyPage,
  //     },
  //     {
  //       path: 'integrations',
  //       data: {
  //         breadcrumb: 'integrations',
  //         title: 'Cart Integrations',
  //         description: 'Learn about shopping cart integrations'
  //       },
  //       loadChildren: () => import('src/app/integrations/integrations.module').then(m => m.IntegrationsModule)
  //     },
  //     {
  //       path: IntegrationCartEnum.AbilityCommerce,
  //       pathMatch: 'full',
  //       redirectTo: `integrations/${IntegrationCartEnum.AbilityCommerce}`
  //     },
  //     {
  //       path: IntegrationCartEnum.BigCommerce,
  //       pathMatch: 'full',
  //       redirectTo: `integrations/${IntegrationCartEnum.BigCommerce}`
  //     },
  //     {
  //       path: IntegrationCartEnum.Ebay,
  //       pathMatch: 'full',
  //       redirectTo: `integrations/${IntegrationCartEnum.Ebay}`
  //     },
  //     {
  //       path: IntegrationCartEnum.Etsy,
  //       pathMatch: 'full',
  //       redirectTo: `integrations/${IntegrationCartEnum.Etsy}`
  //     },
  //     {
  //       path: IntegrationCartEnum.Quickbooks,
  //       pathMatch: 'full',
  //       redirectTo: `integrations/${IntegrationCartEnum.Quickbooks}`
  //     },
  //     {
  //       path: IntegrationCartEnum.Shopify,
  //       pathMatch: 'full',
  //       redirectTo: `integrations/${IntegrationCartEnum.Shopify}`
  //     },
  //     {
  //       path: IntegrationCartEnum.SquareUp,
  //       pathMatch: 'full',
  //       redirectTo: `integrations/${IntegrationCartEnum.SquareUp}`
  //     },
  //     {
  //       path: IntegrationCartEnum.Stripe,
  //       pathMatch: 'full',
  //       redirectTo: `integrations/${IntegrationCartEnum.Stripe}`
  //     },
  //     {
  //       path: IntegrationCartEnum.WooCommerce,
  //       pathMatch: 'full',
  //       redirectTo: `integrations/${IntegrationCartEnum.WooCommerce}`
  //     },
  //     {
  //       path: 'press',
  //       data: {
  //         breadcrumb: 'Press',
  //         name: 'TaxCloud News',
  //         description: 'Latest news from TaxCloud'
  //       },
  //       component: PressPage,
  //     },
  //     {
  //       path: 'state-guides',
  //       data: {
  //         breadcrumb: 'state-guides',
  //         name: 'State Guides',
  //         description: 'On June 21, 2018, the United States Supreme Court issued its decision in South Dakota vs. Wayfair,
  //           clearing the way for many states to compel sales tax collections by remote retailers'
  //       },
  //       component: StatesGuidesPage,
  //     },
  //     {
  //       path: 'setup',
  //       data: {
  //         breadcrumb: ''
  //       },
  //       children: [
  //         {
  //           path: '',
  //           data: {
  //             breadcrumb: 'set-password'
  //           },
  //           component: SetupPage
  //         },
  //         {
  //           path: 'email',
  //           data: {
  //             breadcrumb: ''
  //           },
  //           component: SetupPage
  //         },
  //         {
  //           path: 'confirm-email',
  //           data: {
  //             breadcrumb: 'confirm-email'
  //           },
  //           component: ConfirmEmailPage
  //         },
  //       ]
  //     },
  //     {
  //       path: 'password',
  //       data: {
  //         breadcrumb: 'password'
  //       },
  //       children: [
  //         {
  //           path: '',
  //           data: {
  //             breadcrumb: ''
  //           },
  //           component: PasswordPage
  //         },
  //         {
  //           path: 'reset',
  //           data: {
  //             breadcrumb: 'reset'
  //           },
  //           component: ResetPasswordPage
  //         },
  //       ]
  //     },
  //     {
  //       path: 'register',
  //       data: {
  //         breadcrumb: 'register',
  //         title: '',
  //         description: ''
  //       },
  //       component: RegisterPage,
  //     },
  //     {
  //       path: 'support',
  //       data: {
  //         breadcrumb: 'support'
  //       },
  //       loadChildren: () => import('src/app/support/support.module').then(m => m.SupportModule)
  //     },
  //     {
  //       path: 'tic',
  //       data: {
  //         breadcrumb: 'tic',
  //         title: 'Taxability Information Codes',
  //         description: `Learn more about TaxCloud's taxibility information codes`
  //       },
  //       loadChildren: () => import('src/app/public-tic-view/public-tic-view.module').then(m => m.PublicTicViewModule)
  //     },
  //     {
  //       path: 'tos',
  //       data: {
  //         breadcrumb: 'terms of service',
  //         title: 'Terms Of Service',
  //         description: `View TaxCloud's Terms of Service`
  //       },
  //       loadChildren: () => import('src/app/terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule)
  //     },
  //     {
  //       path: 'brand',
  //       data: {
  //         breadcrumb: 'brand',
  //         title: 'TaxCloud branding',
  //         description: 'Learn about embedding the TaxCloud brand on your website'
  //       },
  //       component: BrandPage,
  //     },
  //     {
  //       path: 'verify-linked-account',
  //       data: {
  //         breadcrumb: 'verify',
  //         title: 'Verify link',
  //         description: 'Verify linked accout'
  //       },
  //       loadChildren: () => import('src/app/verify-user/verify-user.module').then(m => m.VerifyUserModule)
  //     },
  //   ]
  // }
]

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutesModule {}
