/* Base Modules */
import { HttpClientModule } from '@angular/common/http'
import { ErrorHandler, NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { BrowserModule, HammerModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { UrlSerializer } from '@angular/router'
import { ServiceWorkerModule } from '@angular/service-worker'
import { EffectsModule } from '@ngrx/effects'
import { environment } from 'src/environments/environment'

import { AccountModule } from './account/account.module'
import { AccountEffects } from './account/effects/account.effects'
import { AppStatusEffects } from './app-status/effects/app-status.effects'
import { AppComponent } from './app.component'
import { InitMaterialModule } from './material/init-material.module'
import { NotificationEffects } from './notification/effects/notification.effects'
import { PublicModule } from './public/public.module'
import { RootEffects } from './root/effects/root.effects'
import { RootErrorHandler } from './root/error-handler/root-error-handler'
import { FirstPassRoutesModule } from './root/router/fist-pass.routes'
import { RootRoutingModule } from './root/router/root-routing.routes'
import { RootStoreModule } from './root/store/root-store.module'
import { TransactionEffects } from './transaction/effects/transaction.effects'
import { InitUiModule } from './ui/init-ui/init-ui.module'
import { UsStatesEffects } from './us-states/effects/us-states.effects'
import { LoginEffects } from './user/effects/login.effects'
import { UserModule } from './user/user.module'
import { LowerCaseUrlSerializer } from './root/router/lower-case-serializer'
import { DownloadCenterModule } from './download-center/download-center.module'
import { BillingEffects } from './manage-account/effects/billing.effects'
import { SharedPrimeNgModule } from './primeng/shared-primeng.module'
import { SetupEffects } from './setup/effects/setup.effects'
import { MessageService } from 'primeng/api'

/* Feature Modules */
/* Components */

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HammerModule,
    HttpClientModule,
    ReactiveFormsModule,
    InitMaterialModule,
    InitUiModule,
    UserModule,
    BrowserAnimationsModule,
    RootStoreModule,
    EffectsModule.forRoot([
      RootEffects,
      LoginEffects,
      AccountEffects,
      AppStatusEffects,
      NotificationEffects,
      UsStatesEffects,
      TransactionEffects,
      BillingEffects,
      SetupEffects,
    ]),
    FirstPassRoutesModule,
    PublicModule,
    AccountModule,
    RootRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:5000',
    }),
    DownloadCenterModule,
    SharedPrimeNgModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: RootErrorHandler },
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    MessageService,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
