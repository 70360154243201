import { ChangeDetectionStrategy, Component } from '@angular/core'
import * as fromRouter from '@ngrx/router-store'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { AlertTypeEnum } from 'src/app/enums/alert.enums'
import { ShellEnum } from 'src/app/enums/shell.enums'
import { IRouterState } from 'src/app/root/models/irouterstate'
import * as selectors from 'src/app/root/selectors/root.selectors'

@Component({
  selector: 'tc-dev-docs-button',
  templateUrl: './dev-docs-button.component.html',
  styleUrls: ['./dev-docs-button.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevDocsButtonComponent {
  constructor(private route: Store<fromRouter.RouterReducerState<IRouterState>>) {}

  public color$: Observable<AlertTypeEnum> = this.route.pipe(
    select(selectors.selectShell),
    map((shell) => (shell === ShellEnum.GetStarted ? AlertTypeEnum.Accent : AlertTypeEnum.Primary))
  )
}
