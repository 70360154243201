import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  featureOff(featureName: string) {
    if (environment.features.hasOwnProperty(featureName)) {
      return !environment.features[featureName]
    }
    return true
  }

  featureOn(featureName: string) {
    return !this.featureOff(featureName)
  }
}
