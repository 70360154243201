import { Component, ChangeDetectionStrategy, OnDestroy, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, filter, pluck, switchMap, tap } from 'rxjs/operators'
import { IRouterState } from 'src/app/root/models/irouterstate'
import { SubSink } from 'subsink'
import * as fromRouter from '@ngrx/router-store'
import { RegistrationService } from '../../services/registration.service'
import * as fromRouterSelectors from 'src/app/root/selectors/root.selectors'

@Component({
  templateUrl: './confirm-email.page.html',
  styleUrls: ['./confirm-email.page.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmEmailPage implements OnInit, OnDestroy {
  public verified$ = new BehaviorSubject<boolean>(false)
  public working$ = new BehaviorSubject<boolean>(true)
  public sub = new SubSink()

  constructor(
    private route: Store<fromRouter.RouterReducerState<IRouterState>>,
    private registrationService: RegistrationService
  ) {}

  token$: Observable<string> = this.route.pipe(
    select(fromRouterSelectors.selectRouteState),
    pluck('state', 'queryParams', 'token'),
    filter((token: string) => !!token)
  )

  ngOnInit() {
    this.sub.add(
      this.token$
        .pipe(
          filter((token) => !!token),
          switchMap((token) =>
            this.registrationService.confirmEmail(token).pipe(
              tap(() => {
                this.working$.next(false)
                this.verified$.next(true)
              }),
              catchError((error) => {
                this.working$.next(false)
                this.verified$.next(false)
                return of()
              })
            )
          )
        )
        .subscribe()
    )
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }
}
