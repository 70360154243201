import { ChangeDetectionStrategy, Component } from '@angular/core'
import * as fromRouter from '@ngrx/router-store'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map, pluck, switchMap } from 'rxjs/operators'
import { CmsPageTypeEnum } from 'src/app/enums/cms.enums'
import * as fromRouterSelectors from 'src/app/root/selectors/root.selectors'

import { ISupportPage } from '../../models/isupport-page'
import { SupportService } from '../../services/support.service'

@Component({
  templateUrl: './support-detail.page.html',
  styleUrls: ['./support-detail.page.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportDetailPage {
  constructor(
    private supportService: SupportService,
    private route: Store<fromRouter.RouterReducerState<any>>
  ) {}

  slug$: Observable<string> = this.route
    .select(fromRouterSelectors.selectRouteState)
    .pipe(pluck('state', 'params', 'slug'))

  page$: Observable<ISupportPage> = this.slug$.pipe(
    switchMap((slug) => this.supportService.getPage(slug))
  )

  is404$: Observable<boolean> = this.page$.pipe(
    map((page) => page.type === CmsPageTypeEnum.Missing)
  )
}
