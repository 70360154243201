<div fxLayout="row" fxLayoutAlign="center center" class="margin_t_sm">
  <div style="width: 100%; max-width: 500px">
    <div
      *ngIf="state$ | async as state"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="2rem"
    >
      <form [formGroup]="formGroup">
        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1rel">
          <div class="mat-display-2">Manage Tax Filing</div>
          <mat-form-field style="width: 100%">
            <mat-label>{{ state.name }} Login ID</mat-label>
            <input matInput type="text" formControlName="login" required />
            <mat-error>{{ state.name }} Login ID Required</mat-error>
          </mat-form-field>

          <mat-form-field style="width: 100%">
            <mat-label>{{ state.name }} Login Password</mat-label>
            <input
              data-private="lipsum"
              matInput
              type="password"
              formControlName="password"
              required
            />
            <mat-error>{{ state.name }} Password Required</mat-error>
          </mat-form-field>

          <div *ngIf="masquerading && !state.autoFile" style="width: 100%">
            <mat-label>{{ state.name }} Tax Filing Start Date</mat-label>
            <tc-month-picker
              [allowFutureDate]="true"
              (monthChange)="this.startDate.setValue($event)"
            >
            </tc-month-picker>
            <p><em>Start date will default to the first day of the selected month</em></p>
          </div>

          <div *ngIf="masquerading && state.autoFile" style="width: 100%">
            <mat-label>{{ state.name }} Tax Filing End Date</mat-label>
            <tc-month-picker [allowFutureDate]="true" (monthChange)="this.endDate.setValue($event)">
            </tc-month-picker>
            <p>
              <em>End date will default to the last day of the month before the selected month</em>
            </p>
          </div>
        </div>
      </form>

      <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="1rem">
        <button mat-button (click)="cancel()">CANCEL</button>
        <button
          *ngIf="state.autoFile || masquerading"
          mat-button
          tcColor="primary"
          [disabled]="formGroup.invalid || state.collecting === false"
          tcColorDark
          (click)="updateCurrentDirectFile()"
        >
          SAVE
        </button>

        <button
          *ngIf="state.autoFile === false && masquerading"
          mat-button
          tcColor="primary"
          [disabled]="formGroup.invalid || state.collecting === false"
          tcColorDark
          (click)="turnOnDirectFile()"
        >
          TURN ON
        </button>

        <button
          *ngIf="state.autoFile && masquerading"
          mat-button
          tcColor="danger"
          tcColorDark
          (click)="turnOffDirectFile()"
        >
          TURN OFF
        </button>
      </div>
    </div>
  </div>
</div>
