import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { HomePage } from '../pages/home/home.page'

const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: 'Home',
    },
    component: HomePage,
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DownloadCenterRoutingModule {}
