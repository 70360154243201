import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store'
import { State as SetupState } from '../setup.state'
import { OnboardingDetails, Platforms, TaxNeeds } from '../models/onboarding-details.model'

const getStepperActiveIndex = (state: SetupState): number => {
  return state.activeIndex
}

const getSetupCompleted = (state: SetupState): boolean => {
  return state.setupComplete
}

const getPendingUsers = (state: SetupState): any[] => {
  return state.pendingUsers
}

const getStepLoading = (state: SetupState): boolean => {
  return state.stepLoading
}

const getFinalizeLoading = (state: SetupState): boolean => {
  return state.finalizeLoading
}

const getCurrentFormValid = (state: SetupState): boolean => {
  return state.currentFormValid
}

const getOnboardingDetails = (state: SetupState): OnboardingDetails => {
  return state.onboardingDetails
}

const getTaxNeeds = (state: SetupState): TaxNeeds[] => {
  return state.taxNeeds
}

const getPlatforms = (state: SetupState): Platforms[] => {
  return state.platforms
}

const getIsIntegrationsOnboarding = (state: SetupState) => {
  return state.isIntegrationsOnboarding
}

const getIsOnboardingCompleted = (state: SetupState) => {
  return state.isOnboardingCompleted
}

export const selectSetupState: MemoizedSelector<object, SetupState> =
  createFeatureSelector<SetupState>('setup')

export const selectStepperActiveIndex: MemoizedSelector<object, number> = createSelector(
  selectSetupState,
  getStepperActiveIndex
)

export const selectSetupComplete: MemoizedSelector<object, boolean> = createSelector(
  selectSetupState,
  getSetupCompleted
)

export const selectStepLoading: MemoizedSelector<object, boolean> = createSelector(
  selectSetupState,
  getStepLoading
)

export const selectFinalizeLoading: MemoizedSelector<object, boolean> = createSelector(
  selectSetupState,
  getFinalizeLoading
)

export const selectPendingUsers: MemoizedSelector<object, any[]> = createSelector(
  selectSetupState,
  getPendingUsers
)

export const selectCurrentFormValid: MemoizedSelector<object, boolean> = createSelector(
  selectSetupState,
  getCurrentFormValid
)

export const selectOnboardingDetails: MemoizedSelector<object, OnboardingDetails> = createSelector(
  selectSetupState,
  getOnboardingDetails
)

export const selectTaxNeeds: MemoizedSelector<object, TaxNeeds[]> = createSelector(
  selectSetupState,
  getTaxNeeds
)

export const selectPlatforms: MemoizedSelector<object, Platforms[]> = createSelector(
  selectSetupState,
  getPlatforms
)

export const selectIsIntegrationsOnboarding: MemoizedSelector<object, boolean> = createSelector(
  selectSetupState,
  getIsIntegrationsOnboarding
)

export const selectIsOnboardingCompleted: MemoizedSelector<object, boolean> = createSelector(
  selectSetupState,
  getIsOnboardingCompleted
)
