import { UsStateDatabase } from 'src/app/us-states/db/local-us-states.db'
import { validateDate } from 'src/app/utilities/date.utilities'

import {
  IAPIAddExemptionCertificate,
  IApiExemptState,
} from '../models/iapi-add-exemption-certificate'
import { IApiExemptionCertificate } from '../models/iapi-exemption-certificate'
import { IDisplayExemptionCertificate } from '../models/idisplay-exemption-certificate.model'
import {
  IExemptionCertificate,
  IExemptionCertificateUsState,
} from '../models/iexemption-certificate'
import { IMerchantStore } from 'src/app/merchant-store/models/imerchant-store.model'

export class ExemptionCertificateAdapter {
  constructor() {}
  db = new UsStateDatabase()

  mapCertificateToApiAddCertificate(
    cert: Omit<IExemptionCertificate, 'id'>
  ): IAPIAddExemptionCertificate {
    const { store, customer, business, states } = cert
    const { address } = customer
    const { taxId } = business

    const ExemptStates: IApiExemptState[] = states.map((item) => ({
      IdentificationNumber: item.id,
      ReasonForExemption: item.reason,
      StateAbbr: item.stateAbbreviation,
    }))

    const api: IAPIAddExemptionCertificate = {
      apiKey: cert.store.key,
      apiLoginID: cert.store.loginId,
      customerID: cert.customer.id,
      exemptCert: {
        CertificateID: cert.certificateId,
        Detail: {
          CreatedDate: cert.createdDate,
          PurchaserAddress1: address.address1,
          PurchaserAddress2: address.address2 || '',
          PurchaserCity: address.city,
          PurchaserState: address.stateAbbreviation,
          PurchaserZip: address.zip5,
          PurchaserFirstName: customer.firstName,
          PurchaserLastName: customer.lastName,
          PurchaserBusinessType: business.businessType,
          PurchaserBusinessTypeOtherValue: business.businessTypeOther || '',
          PurchaserExemptionReason: cert.reason,
          PurchaserExemptionReasonOtherValue: cert.reasonOther || '',
          PurchaserTitle: customer.title,
          PurchaserTaxID: {
            IDNumber: taxId.number,
            StateOfIssue: taxId.state,
            TaxType: taxId.type,
          },
          ExemptStates,
        },
      },
    }

    return api
  }

  mapApiCertificateToCertificate(
    api: IApiExemptionCertificate,
    certStore: IMerchantStore
  ): IDisplayExemptionCertificate {
    const { detail } = api
    const { purchaserTaxID, exemptStates } = detail

    const states: IExemptionCertificateUsState[] = exemptStates.map((state) => ({
      id: state.identificationNumber,
      reason: state.reasonForExemption,
      stateAbbreviation: state.stateAbbr,
    }))

    const displayStates = states
      .map((usState) => usState.stateAbbreviation)
      .sort()
      .join(', ')
    const disabledDate = validateDate(api.disabledDate)

    const cert: IDisplayExemptionCertificate = {
      id: api.certificateID,
      businessType: detail.purchaserBusinessType,
      businessTypeLabel: detail.purchaserBusinessTypeLabel ?? '',
      businessTypeOther: detail.purchaserBusinessTypeOtherValue ?? '',
      createdDate: validateDate(api.createdDate),
      customerId: api.customerID,
      disabledDate,
      isDisabled: !!disabledDate,
      title: detail.purchaserTitle ?? '',
      firstName: detail.purchaserFirstName,
      lastName: detail.purchaserLastName,
      reason: detail.purchaserExemptionReason,
      reasonLabel: detail.purchaserExemptionReasonLabel ?? '',
      reasonOther: detail.purchaserExemptionReasonOtherValue ?? '',
      singleUse: detail.singleUse,
      states,
      displayStates,
      address: {
        address1: detail.purchaserAddress1,
        address2: detail.purchaserAddress2,
        city: detail.purchaserCity,
        stateAbbreviation: detail.purchaserState,
        zip: detail.purchaserZip,
      },
      taxId: {
        id: purchaserTaxID.idNumber,
        stateOfIssue: purchaserTaxID.stateOfIssue,
        taxType: purchaserTaxID.taxType,
      },
      searchText: `${api.customerID}
                ${api.certificateID}
                ${detail.purchaserFirstName}
                ${detail.purchaserLastName}${displayStates}
                ${certStore.name}
                ${certStore.url}`.toLowerCase(),
      urlId: api.urlId,
      storeName: certStore.name,
      storeUrl: certStore.url,
    }

    return cert
  }
}
