import { Action } from '@ngrx/store'
import { Update } from '@ngrx/entity'
import { INotification } from '../models/inotification.model'

export enum NotificationActionTypes {
  InitNotifications = '[Load Effects] Init Notifications',
  UpsertNotification = '[Notification] Upsert Notification',
  UpsertNotifications = '[Notification] Upsert Notifications',
  UpdateNotification = '[Notification] Update Notification',
  DeleteNotification = '[Notification] Delete Notification',
  ClearNotifications = '[Notification] Clear Notifications',
}

export class InitNotifications implements Action {
  readonly type = NotificationActionTypes.InitNotifications
}

export class UpsertNotification implements Action {
  readonly type = NotificationActionTypes.UpsertNotification

  constructor(public payload: { message: INotification }) {}
}

export class UpsertNotifications implements Action {
  readonly type = NotificationActionTypes.UpsertNotifications

  constructor(public payload: { messages: INotification[] }) {}
}

export class UpdateNotification implements Action {
  readonly type = NotificationActionTypes.UpdateNotification

  constructor(public payload: { message: Update<INotification> }) {}
}

export class DeleteNotification implements Action {
  readonly type = NotificationActionTypes.DeleteNotification

  constructor(public payload: { id: string }) {}
}

export class ClearNotifications implements Action {
  readonly type = NotificationActionTypes.ClearNotifications
}

export type MessageActions =
  | InitNotifications
  | UpsertNotification
  | UpsertNotifications
  | UpdateNotification
  | DeleteNotification
  | ClearNotifications
