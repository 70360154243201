import moment from 'moment'
import { DateFormatEnum } from 'src/app/enums/date.enums'
import { validateDate } from 'src/app/utilities/date.utilities'

import { IApiApiError } from '../models/iapi-api-error'
import { IApiError } from '../models/iapi-error'
import { IApiPublicServiceReport } from '../models/iapi-public-service-report.models'
import { IApiSystemHealth } from '../models/iapi-system-health'
import {
  IPublicServiceDetail,
  IPublicServiceReport,
  IPublicServiceTotalDetail,
} from '../models/ipublic-service-report'
import { ISystemHealth } from '../models/isystem-health'

export class DeveloperAdapter {
  public mapApiResultToReport(api: IApiPublicServiceReport): IPublicServiceReport {
    const reports = api?.data?.attributes?.report
    if (reports === undefined) {
      throw Error('Cannot parse public api report')
    }

    let period = api?.meta?.month
    if (period === undefined) {
      throw Error('Cannot parse report month')
    }

    period = `${period}1`

    const initDetail = {
      number: 0,
      sales: 0,
      tax: 0,
    }

    const initValue: IPublicServiceTotalDetail = {
      addressVerifications: 0,
      duplicateLookups: 0,
      exemptionCertificates: 0,
      live: {
        captured: { ...initDetail },
        lookup: { ...initDetail },
        number: 0,
      },
      returnedCaptures: 0,
      test: {
        captured: { ...initDetail },
        lookup: { ...initDetail },
        number: 0,
      },
      total: 0,
    }

    const total: IPublicServiceTotalDetail = reports.reduce((acc, cur) => {
      return {
        addressVerifications: acc.addressVerifications + cur.addressVerifications,
        duplicateLookups: acc.duplicateLookups + cur.duplicateLookups,
        exemptionCertificates: acc.exemptionCertificates + cur.exemptionCertificates,
        returnedCaptures: acc.returnedCaptures + cur.returnedCaptures,
        total: acc.total + cur.value,
        test: {
          captured: {
            number: acc.test.captured.number + cur.testCaptures,
            sales: acc.test.captured.sales + cur.testCapturedSales,
            tax: acc.test.captured.tax + cur.testCapturedTax,
          },
          lookup: {
            number: acc.test.lookup.number + cur.testLookups,
            sales: acc.test.lookup.sales + cur.testLookupSales,
            tax: acc.test.lookup.tax + cur.testLookupTax,
          },
          number: acc.test.number + cur.testLookups + cur.testCaptures,
        },
        live: {
          captured: {
            number: acc.live.captured.number + cur.liveCaptures,
            sales: acc.live.captured.sales + cur.liveCapturedSales,
            tax: acc.live.captured.tax + cur.liveCapturedTax,
          },
          lookup: {
            number: acc.live.lookup.number + cur.liveLookups,
            sales: acc.live.lookup.sales + cur.liveLookupSales,
            tax: acc.live.lookup.tax + cur.liveLookupTax,
          },
          number: acc.live.number + cur.liveLookups + cur.liveCaptures,
        },
      }
    }, initValue)

    const detail: IPublicServiceDetail[] = reports.map((item) => ({
      day: item.day,
      label: moment(item.day).format(DateFormatEnum.DisplayDay),
      addressVerifications: item.addressVerifications,
      duplicateLookups: item.duplicateLookups,
      exemptionCertificates: item.exemptionCertificates,
      returnedCaptures: item.returnedCaptures,
      total: item.value,
      test: {
        captured: {
          number: item.testCaptures,
          sales: item.testCapturedSales,
          tax: item.testCapturedTax,
        },
        lookup: {
          number: item.testLookups,
          sales: item.testLookupSales,
          tax: item.testLookupTax,
        },
        number: item.testLookups + item.testCaptures,
      },
      live: {
        captured: {
          number: item.liveCaptures,
          sales: item.liveCapturedSales,
          tax: item.liveCapturedTax,
        },
        lookup: {
          number: item.liveLookups,
          sales: item.liveLookupSales,
          tax: item.liveLookupTax,
        },
        number: item.liveLookups + item.liveCaptures,
      },
    }))

    const result: IPublicServiceReport = {
      total,
      month: moment(period, DateFormatEnum.Day).format(DateFormatEnum.Month),
      label: moment(period, DateFormatEnum.Day).format(DateFormatEnum.DisplayMonthYear),
      detail,
    }

    return result
  }

  mapApiApiErrorsToApiErrors(results: IApiApiError[]): IApiError[] {
    return results
      .map((result) => ({
        apiId: result.apiid,
        errorId: result.errorId,
        message: result.message,
        sourceIp: result.sourceIP,
        timestamp: validateDate(result.timestamp),
      }))
      .filter((item) => !!item.apiId)
  }

  mapApiSystemHealthToSystemHealth(results: IApiSystemHealth): ISystemHealth {
    return {
      connections: {
        fedtax: results.currentFedTaxConnections,
        inUse: results.currentInUse,
        reports: results.currentReportsConnections,
        stats: results.currentStatsConnections,
      },
    }
  }
}
