export enum LocationTypeEnum {
  Standard = 1,
  Primary = 2,
  Mailing = 3,
  PrimaryAndMailing = 4,
  SalesAgent = 5,
}

export const LocationTypeLabel = new Map<LocationTypeEnum, string>([
  [LocationTypeEnum.Standard, 'Standard'],
  [LocationTypeEnum.Primary, 'Primary'],
  [LocationTypeEnum.Mailing, 'Mailing'],
  [LocationTypeEnum.PrimaryAndMailing, 'Primary and Mailing'],
  [LocationTypeEnum.SalesAgent, 'Sales Agent'],
])
