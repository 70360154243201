export enum ServicePlanTypeEnum {
  Automated = 'premium',
  Selective = 'starter',
  OldSelective = 'grandfather_selective',
}

export const ServicePlanTypeEnumLabel = new Map<ServicePlanTypeEnum, string>([
  [ServicePlanTypeEnum.Automated, 'Premium Services'],
  [ServicePlanTypeEnum.Selective, 'Starter Services'],
  [ServicePlanTypeEnum.OldSelective, 'Grandfathered Selective Compliance'],
])

export enum ApiPlanLevelEnum {
  Level_1 = 1,
  Level_2 = 2,
  Level_3 = 3,
  Level_4 = 4,
  Level_5 = 5,
  Level_6 = 6,
  Level_7 = 7,
}

export const ApiPlanLevelEnumLabel = new Map<ApiPlanLevelEnum, string>([
  [ApiPlanLevelEnum.Level_1, 'Level 1'],
  [ApiPlanLevelEnum.Level_2, 'Level 2'],
  [ApiPlanLevelEnum.Level_3, 'Level 3'],
  [ApiPlanLevelEnum.Level_4, 'Level 4'],
  [ApiPlanLevelEnum.Level_5, 'Level 5'],
  [ApiPlanLevelEnum.Level_6, 'Level 6'],
  // [ApiPlanLevelEnum.Level_7, 'Level 7'],
])

export enum ServiceFeeLevelEnum {
  Level_1 = 1,
  Level_2 = 2,
  Level_3 = 3,
  Level_4 = 4,
  Level_5 = 5,
  Level_6 = 6,
  Level_7 = 7,
}

export const ServiceFeeLevelEnumLabel = new Map<ServiceFeeLevelEnum, string>([
  [ServiceFeeLevelEnum.Level_1, '$3 million'],
  [ServiceFeeLevelEnum.Level_2, '$10 million'],
  [ServiceFeeLevelEnum.Level_3, '$18 million'],
  [ServiceFeeLevelEnum.Level_4, '$30 million'],
  [ServiceFeeLevelEnum.Level_5, '$50 million'],
  [ServiceFeeLevelEnum.Level_6, '$75 million'],
  [ServiceFeeLevelEnum.Level_7, 'More than $75 million'],
])
