import { AppTypeEnum } from 'src/app/enums/app.enums'
import { LocalStorageNameSpaceEnum } from 'src/app/enums/local-storage.enums'
import { LoginTypeEnum } from 'src/app/enums/login.enum'

import { IAuth } from '../models/iauth'
import { IUser } from '../models/iuser'

export class UserDb {
  public saveUser(user: IUser): IUser {
    localStorage.setItem(LocalStorageNameSpaceEnum.User, JSON.stringify(user))
    return user
  }

  public getUserId(): string {
    const id = this.getUser().id
    return id ? id.toString() : ''
  }

  public getUser(): IUser {
    try {
      const userData = localStorage.getItem(LocalStorageNameSpaceEnum.User)
      if (typeof userData !== 'string') {
        return null
      }
      const user = JSON.parse(userData)

      if (user === null) {
        return null
      }

      return this.cleanUpUser(user)
    } catch (e) {
      return null
    }
  }

  public updateUser(update: object): IUser {
    const current = this.getUser()
    return this.saveUser({ ...current, ...update })
  }

  public removeUser(): void {
    localStorage.removeItem(LocalStorageNameSpaceEnum.User)
  }

  public saveLoginType(loginType: LoginTypeEnum): LoginTypeEnum {
    localStorage.setItem(LocalStorageNameSpaceEnum.LoginType, loginType.toString())
    return loginType
  }

  public getLoginType(): LoginTypeEnum {
    const type = localStorage.getItem(LocalStorageNameSpaceEnum.LoginType)
    if (type === null) {
      return null
    }
    return parseInt(type, 10)
  }

  public saveAuth(auth: IAuth): IAuth {
    localStorage.setItem(LocalStorageNameSpaceEnum.Auth, JSON.stringify(auth))
    return auth
  }

  public getAuth(): IAuth {
    const authData = localStorage.getItem(LocalStorageNameSpaceEnum.Auth)
    const auth = authData ? JSON.parse(authData) : null
    return auth
  }

  public removeAuth(): void {
    localStorage.removeItem(LocalStorageNameSpaceEnum.Auth)
  }

  public saveUserAndAuth(user: IUser, auth: IAuth): [IUser, IAuth] {
    return [this.saveUser(user), this.saveAuth(auth)]
  }

  public getUserAndAuth(): [IUser, IAuth] {
    return [this.getUser(), this.getAuth()]
  }

  public saveAppType(appType: AppTypeEnum) {
    localStorage.setItem(LocalStorageNameSpaceEnum.AppType, appType)
  }

  public getAppType(): AppTypeEnum {
    const type = localStorage.getItem(LocalStorageNameSpaceEnum.AppType)
    if (!type) {
      return null
    }
    return type as AppTypeEnum
  }

  public getToken(): string | null {
    const authData = localStorage.getItem(LocalStorageNameSpaceEnum.Auth)
    const auth = authData ? (JSON.parse(authData) as IAuth) : null
    if (auth) {
      return auth.accessToken
    }
    return null
  }

  private cleanUpUser(user: IUser): IUser {
    const map: IUser = {
      ...user,
      isShopify: user.isShopify ?? false,
    }
    return map
  }
}
