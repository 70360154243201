import { Action } from '@ngrx/store'
import { SocialUser } from '@abacritt/angularx-social-login'
import { AuthorityEnum } from 'src/app/enums/authority.enums'

import { IAuth } from '../models/iauth'
import { IRegister } from '../models/iregister.model'
import { IUpdateUser, IUser } from '../models/iuser'

export enum LoginActionTypes {
  LoginRequest = '[Login Page] Login request',
  LoginSuccess = '[Login Effects] Login success',
  LoginFailure = '[Login Effects] Login failure',

  Login2Request = '[Login Page] Login 2 request',
  Login2Success = '[Login Effects] Login 2 success',
  Login2Failure = '[Login Effects] Login 2 failure',

  // NOTE: This action no longer does anything as its been replaced by the in component
  // method in login.page.ts
  FederatedLoginRequest = '[Login Page] Federated Login Request',

  FederatedLoginSuccess = '[Login Effect] Federated Login Success',
  FederatedLoginFailure = '[Login Effect] Federated Login Failure',

  MasqueradeLoginRequest = '[Masquerade Page] Masquerade login request',
  MasqueradeSuccess = '[Login Effects] Masquerade login success',

  OneTimeLoginRequest = '[Password-Reset Page] One-time login request',

  AutoLoginRequest = '[Login Page] Auto login request',
  AutoLoginFail = '[Login Effects] AutoLoginFail',
  UpdateUserAndAccountForAutoLogin = '[Login Effects] Update user and account data from api',

  UpdateStoreUserData = '[Login Effects] Update user information in store',
  UpdateSingleUseAuthInStore = '[Login Effects] Update auth in store for single use',

  UpdateUser = '[Update User Page] Update user locally',
  UpdateUserSuccess = '[Auth Effect] Update user success',

  RequestUserAndAuthFromLocal = '[Root Effects] Request user data',
  RequestUserAndAuthFromLocalSuccess = '[Login Effects] Request user data success',
  RequestUserAndAuthFromLocalFailure = '[Login Effects] Request user data failure',

  SaveUserLocal = '[Login Effects] Save user data to local storage',
  SaveUserLocalSuccess = '[Login Effects] Save user Data to local storage success',
  UpdateLocalStorage = '[Login Effects] Update login local storage',

  LockApplication = '[Shell Component] Lock application',
  LockSuccess = '[Login Effects] Lock application successful',
  UnlockApplication = '[Login Page] Unlock appliction',

  Logout = '[Logout Page] Log out',
  LogoutFromRegistraton = '[Registration page] Logout out',

  LogoutSuccess = '[Logout Effects] Log out completed',

  ResetPasswordRequest = '[Password Page] Reset password request',
  ResetPasswordRequestSuccess = '[Login Effects] Password reset request was successful',
  ResetPasswordFailure = '[Login Effects] Password reset request failed',

  ChangePasswordRequest = '[Password reset form] Change password request',
  ChangePasswordSuccess = '[Login Effects] Change password request',

  RegisterRequest = '[Registration Page] Register new account request',
  RegisterRequestSuccess = '[Auth Effects] Registeration success',
  RegisterRequestFailure = '[Auth Effects] Registration fail',

  RegisterWithFederated = '[Registration Page] Register new account with federated request',
  RegisterWithFederatedSuccess = '[Auth Effects] Get federated account success',
  RegisterWithFederatedFailure = '[Auth Effects] Get federated account fail',

  AcceptTermsOfService = '[Go Live Page] Accept terms of service',
  AcceptLatestTermsOfService = '[View latest terms of service page] Accept latest terms of service',
  AcceptTermsOfServiceExtendConfig = '[Extend Configuration Page] Accept terms of service',
  AcceptTermsOfServiceSuccess = '[Auth Effects] Accept terms of service success',
  AcceptTermsOfServiceFailure = '[Auth Effects] Accept terms of service fail',

  CleanUpIsLoading = '[Login Page] Confirm is-loading is false',

  UpdateUserAuth = '[QA Select User Auth Component] Update user auth',
}

export class AutoLoginRequest implements Action {
  readonly type = LoginActionTypes.AutoLoginRequest
  constructor(public payload: { token: string }) {}
}

export class AutoLoginRequestFail implements Action {
  readonly type = LoginActionTypes.AutoLoginFail
  constructor(public payload: { message: string }) {}
}

export class OneTimeLoginRequest implements Action {
  readonly type = LoginActionTypes.OneTimeLoginRequest
  constructor(public payload: { token: string }) {}
}

export class UpdateUserAndAccountForAutoLogin implements Action {
  readonly type = LoginActionTypes.UpdateUserAndAccountForAutoLogin
  constructor(public payload: { auth: IAuth; contactId: string }) {}
}

export class UpdateSingleUseAuthInStore implements Action {
  readonly type = LoginActionTypes.UpdateSingleUseAuthInStore
  constructor(public payload: { auth: IAuth }) {}
}

export class RequestUserAndAuthFromLocal implements Action {
  readonly type = LoginActionTypes.RequestUserAndAuthFromLocal
  constructor() {}
}

export class RequestUserAndAuthFromLocalSuccess implements Action {
  readonly type = LoginActionTypes.RequestUserAndAuthFromLocalSuccess
  constructor(public payload: { user: IUser; auth: IAuth }) {}
}

export class RequestUserAndAuthFromLocalFailureAction implements Action {
  readonly type = LoginActionTypes.RequestUserAndAuthFromLocalFailure
  constructor(public payload: { error: string }) {}
}

export class LoginRequestAction implements Action {
  readonly type = LoginActionTypes.LoginRequest
  constructor(public payload: { userName: string; password: string; storeInfoGuid?: string }) {}
}

export class MasqueradeLoginRequest implements Action {
  readonly type = LoginActionTypes.MasqueradeLoginRequest
  constructor(public payload: { merchantId: string; impersonatorId: string }) {}
}

export class MasqueradeSuccess implements Action {
  readonly type = LoginActionTypes.MasqueradeSuccess
  constructor(public payload: { user: IUser; auth: IAuth }) {}
}

export class LoginSuccess implements Action {
  readonly type = LoginActionTypes.LoginSuccess
  constructor(public payload: { user: IUser; auth: IAuth }) {}
}

export class LoginFailure implements Action {
  readonly type = LoginActionTypes.LoginFailure
  constructor(public payload: { error: string }) {}
}

export class Login2RequestAction implements Action {
  readonly type = LoginActionTypes.Login2Request
  constructor(public payload: { userName: string; password: string; storeInfoGuid?: string }) {}
}

export class Login2Success implements Action {
  readonly type = LoginActionTypes.Login2Success
  constructor(public payload: { userName: string; password: string }) {}
}

export class Login2Failure implements Action {
  readonly type = LoginActionTypes.Login2Failure
  constructor(public payload: { error: string }) {}
}

export class SaveUserToLocalAction implements Action {
  readonly type = LoginActionTypes.SaveUserLocal
  constructor(public payload: { user: IUser; auth: IAuth }) {}
}

export class SaveUserLocalSuccess implements Action {
  readonly type = LoginActionTypes.SaveUserLocalSuccess
  constructor(public payload: { user: IUser; auth: IAuth }) {}
}

export class LockApplication implements Action {
  readonly type = LoginActionTypes.LockApplication
}

export class UnLockApplication implements Action {
  readonly type = LoginActionTypes.UnlockApplication
  constructor(public payload: { password: string }) {}
}

export class LockSuccess implements Action {
  readonly type = LoginActionTypes.LockSuccess
  constructor(public payload: { user: IUser; auth: IAuth }) {}
}

export class Logout implements Action {
  readonly type = LoginActionTypes.Logout
}

export class LogoutFromRegistraton implements Action {
  readonly type = LoginActionTypes.LogoutFromRegistraton
}

export class LogoutSuccess implements Action {
  readonly type = LoginActionTypes.LogoutSuccess
}

export class AcceptTermsOfService implements Action {
  readonly type = LoginActionTypes.AcceptTermsOfService
}
export class AcceptLatestTermsOfService implements Action {
  readonly type = LoginActionTypes.AcceptLatestTermsOfService
}
export class AcceptTermsOfServiceExtendConfig implements Action {
  readonly type = LoginActionTypes.AcceptTermsOfServiceExtendConfig
}
export class AcceptTermsOfServiceSuccess implements Action {
  readonly type = LoginActionTypes.AcceptTermsOfServiceSuccess
}
export class AcceptTermsOfServiceFailure implements Action {
  readonly type = LoginActionTypes.AcceptTermsOfServiceFailure
}

export class UpdateLocalStoraage implements Action {
  readonly type = LoginActionTypes.UpdateLocalStorage
  constructor(public payload: any) {}
}

export class CleanUpIsLoading implements Action {
  readonly type = LoginActionTypes.CleanUpIsLoading
}

export class UpdateStoreUserData implements Action {
  readonly type = LoginActionTypes.UpdateStoreUserData
  constructor(public payload: { user: IUpdateUser }) {}
}

export class UpdateUser implements Action {
  readonly type = LoginActionTypes.UpdateUser
  constructor(public payload: { updateUser: IUpdateUser }) {}
}

export class UpdateUserSuccess implements Action {
  readonly type = LoginActionTypes.UpdateUserSuccess
}

export class ResetPasswordRequest implements Action {
  readonly type = LoginActionTypes.ResetPasswordRequest
  constructor(public payload: { email: string }) {}
}

export class ResetPasswordRequestSuccess implements Action {
  readonly type = LoginActionTypes.ResetPasswordRequestSuccess
}

export class ResetPasswordFailure implements Action {
  readonly type = LoginActionTypes.ResetPasswordFailure
  constructor(public payload: { error: string }) {}
}

export class ChangePasswordRequest implements Action {
  readonly type = LoginActionTypes.ChangePasswordRequest
  constructor(public payload: { password: string; confirmPassword: string }) {}
}

export class ChangePasswordSuccess implements Action {
  readonly type = LoginActionTypes.ChangePasswordSuccess
}

export class RegisterRequest implements Action {
  readonly type = LoginActionTypes.RegisterRequest
  constructor(
    public payload: {
      autoLogin?: boolean
      form: IRegister
    }
  ) {}
}

export class RegisterRequestSuccess implements Action {
  readonly type = LoginActionTypes.RegisterRequestSuccess
  constructor(public payload: { emailToVerify: string }) {}
}

export class RegisterRequestFailure implements Action {
  readonly type = LoginActionTypes.RegisterRequestFailure
  constructor(public payload: { error: string }) {}
}

export class UpdateUserAuth implements Action {
  readonly type = LoginActionTypes.UpdateUserAuth
  constructor(public payload: { auth: AuthorityEnum }) {}
}

export class FederatedLoginRequest implements Action {
  readonly type = LoginActionTypes.FederatedLoginRequest
}

export class FederatedLoginSuccess implements Action {
  readonly type = LoginActionTypes.FederatedLoginSuccess
  constructor(public payload: { socialUser: SocialUser }) {}
}

export class FederatedLoginFailure implements Action {
  readonly type = LoginActionTypes.FederatedLoginFailure
}

export class RegisterWithFederated implements Action {
  readonly type = LoginActionTypes.RegisterWithFederated
}

export class RegisterWithFederatedSuccess implements Action {
  readonly type = LoginActionTypes.RegisterWithFederatedSuccess
  constructor(public payload: { socialUser: SocialUser }) {}
}

export class RegisterWithFederatedFailure implements Action {
  readonly type = LoginActionTypes.RegisterWithFederatedFailure
}

export type LoginActions =
  | RequestUserAndAuthFromLocal
  | AutoLoginRequestFail
  | AutoLoginRequest
  | UpdateUserAndAccountForAutoLogin
  | UpdateSingleUseAuthInStore
  | RequestUserAndAuthFromLocalSuccess
  | RequestUserAndAuthFromLocalFailureAction
  | LoginRequestAction
  | MasqueradeLoginRequest
  | MasqueradeSuccess
  | LoginFailure
  | LoginSuccess
  | SaveUserToLocalAction
  | SaveUserLocalSuccess
  | LockApplication
  | UnLockApplication
  | LockSuccess
  | Logout
  | LogoutFromRegistraton
  | LogoutSuccess
  | AcceptTermsOfService
  | AcceptLatestTermsOfService
  | AcceptTermsOfServiceExtendConfig
  | AcceptTermsOfServiceSuccess
  | AcceptTermsOfServiceFailure
  | UpdateLocalStoraage
  | CleanUpIsLoading
  | UpdateStoreUserData
  | ResetPasswordRequest
  | ResetPasswordRequestSuccess
  | ResetPasswordFailure
  | OneTimeLoginRequest
  | ChangePasswordRequest
  | ChangePasswordSuccess
  | RegisterRequest
  | RegisterRequestSuccess
  | RegisterRequestFailure
  | RegisterWithFederated
  | RegisterWithFederatedSuccess
  | RegisterWithFederatedFailure
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserAuth
  | FederatedLoginRequest
  | FederatedLoginSuccess
  | FederatedLoginFailure
  | Login2RequestAction
  | Login2Success
  | Login2Failure
