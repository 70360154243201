import moment from 'moment'
import { DateFormatEnum } from 'src/app/enums/date.enums'
import { UploadTransactionErrorType } from 'src/app/enums/transaction.enums'

import { IApiUpload } from '../models/iapi-uploads.models'
import { IUpload, IUploadError } from '../models/iupload.models'

export class UploadsAdapter {
  static mapApiUploadToUpload(result: IApiUpload): IUpload {
    const { attributes, id } = result.data
    const { jobReference, jobId, status } = attributes
    const { statistics, errorResult } = status
    const { errors, totalRows, totalRowsProcessed } = statistics

    const er = errors ? this.parseApiError(errors) : []
    const upload: IUpload = {
      completionRatio: statistics.completionRatio,
      creationTime: moment(statistics.creationTime, DateFormatEnum.MonthDayYear).format(),
      endTime: statistics.endTime,
      errors: er,
      errorMessage: this.createErrorMessage(er),
      id,
      label: jobReference,
      startTime: statistics.startTime,
      status: status.state,
      success: er.length === 0,
      totalRows,
      rowsComplete: totalRowsProcessed,
      percentComplete: this.getPercentageDone(totalRowsProcessed, totalRows),
      transactionsInMap: statistics.transactionsInMap,
    }
    return upload
  }

  private static parseApiError(errors): IUploadError[] {
    return Array.from(errors).map((e: any) => {
      let uploadError: IUploadError

      if (typeof e === 'string') {
        const error1 = JSON.parse(e)
        uploadError = {
          code: UploadTransactionErrorType.UNKNOWN_ERROR,
          message: error1.message,
          row: error1.line,
        }
      } else {
        uploadError = {
          code: UploadTransactionErrorType.UNKNOWN_ERROR,
          message: e.message,
          row: e.location,
        }
      }
      return uploadError
    })
  }

  private static createErrorMessage(errors: IUploadError[]): string {
    return errors.map((error) => `1. ORDER: ${error.row}: ${error.message}`).join('\n')
  }

  private static getPercentageDone(finished: number, total: number): number {
    if (finished === 0) {
      return 0
    }
    if (total === 0) {
      return 1
    }
    return Math.round((finished / total) * 100)
  }
}
