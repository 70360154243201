import { ScrollingModule } from '@angular/cdk/scrolling'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { AngularResizeEventModule } from 'angular-resize-event'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { InitMaterialModule } from 'src/app/material/init-material.module'

import { AlertExpandComponent } from '../ui/components/alert-expand/alert-expand.component'
import { SafePipe } from '../ui/pipes/safe/safe.pipe'
import { AlertComponent } from './components/alert/alert.component'
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component'
import { CardActionsComponent } from './components/card/card-actions/card-actions.component'
import { CardContentComponent } from './components/card/card-content/card-content.component'
import { CardHeaderComponent } from './components/card/card-header/card-header.component'
import { CardSubtitleComponent } from './components/card/card-subtitle/card-subtitle.component'
import { CardTitleComponent } from './components/card/card-title/card-title.component'
import { CardComponent } from './components/card/card.component'
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component'
import { SelectUserAuthComponent } from './components/select-user-auth/select-user-auth.component'
import { ToggleAccountStateComponent } from './components/toggle-account-state/toggle-account-state.component'
import { ToggleAppTypeComponent } from './components/toggle-app-type/toggle-app-type.component'
import { WorkingSpinnerComponent } from './components/working-spinner/working-spinner.component'
import { IosInstallDialog } from './dialogs/ios-install/ios-install.dialog'
import { AlertDirective } from './directives/alert/alert.directive'
import { ColorDirective } from './directives/color/color.directive'
import { EmbeddedOffDirective } from './directives/embedded-flag/embedded-flag-off.directive'
import { FeatureOffDirective } from './directives/feature-flag/feature-flag-off.directive'
import { FeatureOnDirective } from './directives/feature-flag/feature-flag-on.directive'
import { LazyLoadImageDirective } from './directives/image/lazy-load-image.directive'
import { LinkDirective } from './directives/link/link.directive'
import { ListItemDirective } from './directives/list/list-item.directive'
import { ListDirective } from './directives/list/list.directive'
import { ShellGetStartedComponent } from './shells/shell-get-started/shell-get-started.component'
import { ShellGoComponent } from './shells/shell-go/shell-go.component'
import { SignOutButtonComponent } from './components/sign-out-button/sign-out-button.component'
import { DirectFileInformationComponent } from './components/direct-file-information/direct-file-information.component'
import { OnlyNumberDirective } from './directives/only-number/only-number.directive'
import { OnlyFloatDirective } from './directives/only-float/only-float.directive'
import { ShellSetupComponent } from './shells/shell-setup/shell-setup.component'
import { SharedPrimeNgModule } from 'src/app/primeng/shared-primeng.module'
import { SetupStepperActionsComponent } from 'src/app/setup/components/setup-stepper-actions/setup-stepper-actions.component'

@NgModule({
  declarations: [
    CardComponent,
    CardHeaderComponent,
    CardContentComponent,
    CardActionsComponent,
    CardSubtitleComponent,
    CardTitleComponent,
    DirectFileInformationComponent,
    ListDirective,
    ListItemDirective,
    AlertDirective,
    AlertComponent,
    AlertExpandComponent,
    BreadcrumbComponent,
    SafePipe,
    ColorDirective,
    FeatureOffDirective,
    FeatureOnDirective,
    EmbeddedOffDirective,
    HowItWorksComponent,
    LinkDirective,
    IosInstallDialog,
    ShellGetStartedComponent,
    ShellGoComponent,
    ToggleAppTypeComponent,
    ToggleAccountStateComponent,
    WorkingSpinnerComponent,
    LazyLoadImageDirective,
    SelectUserAuthComponent,
    SignOutButtonComponent,
    OnlyNumberDirective,
    OnlyFloatDirective,
    ShellSetupComponent,
  ],
  imports: [
    InitMaterialModule,
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    ScrollingModule,
    RouterModule,
    AngularResizeEventModule,
    InitMaterialModule,
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    SharedPrimeNgModule,
    SetupStepperActionsComponent,
  ],
  exports: [
    CardComponent,
    CardHeaderComponent,
    CardContentComponent,
    CardActionsComponent,
    CardSubtitleComponent,
    CardTitleComponent,
    DirectFileInformationComponent,
    FlexLayoutModule,
    ListDirective,
    ListItemDirective,
    AlertDirective,
    AlertComponent,
    AlertExpandComponent,
    BreadcrumbComponent,
    SafePipe,
    ColorDirective,
    FeatureOffDirective,
    FeatureOnDirective,
    EmbeddedOffDirective,
    LinkDirective,
    IosInstallDialog,
    WorkingSpinnerComponent,
    LazyLoadImageDirective,
    NgxSkeletonLoaderModule,
    SelectUserAuthComponent,
    HowItWorksComponent,
    SignOutButtonComponent,
    OnlyNumberDirective,
    OnlyFloatDirective,
  ],
})
export class InitUiModule {}
