import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { PasswordRequirements } from '../../constants/user.constants'
@Component({
  selector: 'tc-password-requirements',
  templateUrl: './password-requirements.component.html',
  styleUrls: ['./password-requirements.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordRequirementsComponent implements OnInit {
  @Input() password!: string
  public passwordRequirements = PasswordRequirements
  constructor() {}

  ngOnInit(): void {}
}
