import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store'
import moment from 'moment'

import { AuthorityEnum, AuthorityLabel } from '../../enums/authority.enums'
import { FeatureKeys } from '../../enums/store.enums'
import { IUser } from '../models/iuser'
import { State } from '../user.state'
import { LoginEnum, LoginTypeEnum } from './../../enums/login.enum'

const INTERNAL_MERCHANT_ID = 4
const getError = (state: State): string => state.error
const getIsLoading = (state: State): boolean => state.isLoading
const getUser = (state: State): IUser => state.user
const getUserId = (state: State): string => {
  if (!state.user) {
    return null
  }
  return state.user.id
}
const getImpersonatorId = (state: State): string => {
  if (!state.user) {
    return null
  }
  return state.user.impersonatorId || null
}

const getEmail = (state: State): string => {
  if (state && state.user && state.user.username) {
    return state.user.username
  }

  return ''
}

const getLoginStatus = (state: State): LoginEnum => {
  if (!state) {
    return LoginEnum.LoggedOut
  }
  if (!state.user) {
    return LoginEnum.LoggedOut
  }

  if (!state.user.username) {
    return LoginEnum.LoggedOut
  }

  if (!state.auth) {
    return LoginEnum.Locked
  }

  if (!state.auth.validUntil) {
    return LoginEnum.Locked
  }

  if (!moment(state.auth.validUntil).isAfter(moment())) {
    return LoginEnum.Locked
  }

  return LoginEnum.LoggedIn
}

const getAuthToken = (state: State): string => {
  if (!state.auth) {
    return ''
  }
  return state.auth.accessToken
}

const getTokenClaims = (state: State): object => {
  if (!state.auth) {
    return null
  }
  let claims = {}
  const token = state.auth.accessToken
  const tokenBody = token.split('.')[1]
  if (tokenBody) {
    claims = JSON.parse(atob(tokenBody))
  }
  return claims
}

const getMasqueradeStatus = (state: State): boolean => {
  if (!state.user) {
    return false
  }
  return state.user.loginType === LoginTypeEnum.Masquerade
}

const userAuthorityLabel = (state: State): string => {
  if (!state.user) {
    return ''
  }
  return AuthorityLabel.get(state.user.authority as AuthorityEnum)
}

const getUserAuthority = (state: State): AuthorityEnum => {
  if (!state.user) {
    return AuthorityEnum.User
  }
  return state.user.authority
}

const userHasAcceptedTermsOfService = (state: State): boolean => {
  if (state && state.user) {
    return state.user.acceptedTermsOfService
  }
  return false
}

const userHasLatestTermsOfService = (state: State): boolean => {
  if (state && state.user) {
    return state.user.hasLatesestTermsOfService
  }
  return false
}

const userHasAdminAuthority = (state: State): boolean => {
  if (!state.user) {
    return false
  }
  return state.user.authority <= AuthorityEnum.Administrator
}

const userHasFinancialAuthority = (state: State): boolean => {
  if (!state.user) {
    return false
  }
  return state.user.authority <= AuthorityEnum.Financial
}

const userHasTechnicalAuthority = (state: State): boolean => {
  if (!state.user) {
    return false
  }
  return state.user.authority <= AuthorityEnum.Technical
}

const userHasAuditAuthority = (state: State): boolean => {
  if (!state.user) {
    return false
  }
  return state.user.authority <= AuthorityEnum.Auditor
}

const getFullName = (state: State): string => {
  if (!state.user) {
    return ''
  }
  return `${state.user.firstName} ${state.user.lastName}`
}

const getIsInternal = (state: State): boolean => {
  if (!state.user) {
    return false
  }
  return state.user.merchantId === INTERNAL_MERCHANT_ID
}

const getLoginType = (state: State): LoginTypeEnum => {
  return state.loginType
}

const getPasswordResetEmail = (state: State): string => {
  if (state && state.passwordReset && state.passwordReset.email) {
    return state.passwordReset.email
  }
  return null
}

const getPasswordResetSuccess = (state: State): boolean => {
  if (state && state.passwordReset && state.passwordReset.email && !state.error) {
    return true
  }
  return false
}

const getWorking = (state: State): boolean => {
  if (state && state.working) {
    return true
  }
  return false
}

const getWorkingMessage = (state: State): string => {
  if (state && state.working) {
    return state.working
  }
  return ''
}

const getEmailToVerify = (state: State): string => {
  return state.emailToVerify
}

export const selectAuthorizeState: MemoizedSelector<object, State> = createFeatureSelector<State>(
  FeatureKeys.Authorize
)

export const selectAuthorizeError: MemoizedSelector<object, string> = createSelector(
  selectAuthorizeState,
  getError
)

export const selectAuthorizeIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectAuthorizeState,
  getIsLoading
)

export const selectAuthorizeUser: MemoizedSelector<object, IUser> = createSelector(
  selectAuthorizeState,
  getUser
)

export const selectUserId: MemoizedSelector<object, string> = createSelector(
  selectAuthorizeState,
  getUserId
)

export const selectImpersonatorId: MemoizedSelector<object, string> = createSelector(
  selectAuthorizeState,
  getImpersonatorId
)
export const selectLoginEmail: MemoizedSelector<object, string> = createSelector(
  selectAuthorizeState,
  getEmail
)

export const selectPasswordResetEmail: MemoizedSelector<object, string> = createSelector(
  selectAuthorizeState,
  getPasswordResetEmail
)

export const selectPasswordRequestSuccess: MemoizedSelector<object, boolean> = createSelector(
  selectAuthorizeState,
  getPasswordResetSuccess
)

export const selectAuthToken: MemoizedSelector<object, string> = createSelector(
  selectAuthorizeState,
  getAuthToken
)

export const selectTokenClaims: MemoizedSelector<object, object> = createSelector(
  selectAuthorizeState,
  getTokenClaims
)

export const selectLoginStatus: MemoizedSelector<object, LoginEnum> = createSelector(
  selectAuthorizeState,
  getLoginStatus
)

export const selectFullName: MemoizedSelector<object, string> = createSelector(
  selectAuthorizeState,
  getFullName
)

export const selectHasAcceptedTermsOfService: MemoizedSelector<object, boolean> = createSelector(
  selectAuthorizeState,
  userHasAcceptedTermsOfService
)

export const selectHasLatestTermsOfService: MemoizedSelector<object, boolean> = createSelector(
  selectAuthorizeState,
  userHasLatestTermsOfService
)

export const selectUserAuthority: MemoizedSelector<object, AuthorityEnum> = createSelector(
  selectAuthorizeState,
  getUserAuthority
)

export const selectUserAuthorityLabel: MemoizedSelector<object, string> = createSelector(
  selectAuthorizeState,
  userAuthorityLabel
)

export const selectUserIsMasquerading: MemoizedSelector<object, boolean> = createSelector(
  selectAuthorizeState,
  getMasqueradeStatus
)

export const selectUserHasAdminAuthority: MemoizedSelector<object, boolean> = createSelector(
  selectAuthorizeState,
  userHasAdminAuthority
)

export const selectUserHasFiscalAuthority: MemoizedSelector<object, boolean> = createSelector(
  selectAuthorizeState,
  userHasFinancialAuthority
)

export const selectUserHasTechicalAuthority: MemoizedSelector<object, boolean> = createSelector(
  selectAuthorizeState,
  userHasTechnicalAuthority
)

export const selectUserHasAuditAuthority: MemoizedSelector<object, boolean> = createSelector(
  selectAuthorizeState,
  userHasAuditAuthority
)

export const selectIsInternalUser: MemoizedSelector<object, boolean> = createSelector(
  selectAuthorizeState,
  getIsInternal
)

export const selectLoginType: MemoizedSelector<object, LoginTypeEnum> = createSelector(
  selectAuthorizeState,
  getLoginType
)

export const selectWorking: MemoizedSelector<object, boolean> = createSelector(
  selectAuthorizeState,
  getWorking
)

export const selectWorkingMessage: MemoizedSelector<object, string> = createSelector(
  selectAuthorizeState,
  getWorkingMessage
)

export const selectEmailToVerify: MemoizedSelector<object, string> = createSelector(
  selectAuthorizeState,
  getEmailToVerify
)
