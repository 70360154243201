import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store'
import moment, { Moment } from 'moment'
import { AccountStateEnum } from 'src/app/enums/account.enums'
import { AttestationEnum } from 'src/app/enums/attestations.enums'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'
import { FeatureKeys } from 'src/app/enums/store.enums'

import * as fromAccount from '../account.state'
import { IAccountWindow, IConversionStatus } from '../models/iaccount'
import { IProfile } from '../models/iprofile.model'
import { IServicePlan } from '../models/iservice-plan'
import { IStreamlined } from '../models/istreamline.model'

const getStartDate = (state: fromAccount.State): Moment => {
  if (state && state.account) {
    return moment(state.account.createdTimestamp)
  }
  return moment().subtract(1, 'y')
}

const getConfiguration = (state: fromAccount.State): IAccountWindow => {
  if (state && state.account) {
    return state.account.configurationWindow
  }
  return null
}

const getConversionStatus = (state: fromAccount.State): IConversionStatus => {
  if (state && state.account) {
    return state.account.conversionStatus
  }
  return {}
}

const getProfile = (state: fromAccount.State): IProfile => {
  if (state && state.account) {
    return state.account.profile
  }
  return null
}

const getServicePlan = (state: fromAccount.State): IServicePlan => {
  if (state && state.account && state.account.servicePlan) {
    return state.account.servicePlan
  }
  return null
}

const getFipsCode = (state: fromAccount.State): string => {
  if (state && state.account) {
    return state.account.profile.stateIncorporated
  }
  return ''
}

const getAccountStatus = (state: fromAccount.State): AccountStateEnum => {
  if (state && state.account) {
    return state.account.accountState
  }
  return null
}

const getAppIds = (state: fromAccount.State): string[] => {
  if (state && state.account) {
    return state.account.appIDs
  }
  return null
}

const getLeaving = (state: fromAccount.State): boolean => {
  if (state && state.account) {
    return state.account.accountState === AccountStateEnum.Leaving
  }
  return false
}

const getNeedsPenAttestation = (state: fromAccount.State): boolean => {
  if (state && state.account) {
    return state.account.attestations.includes(AttestationEnum.Pennsylvania)
  }
  return false
}

const getMerchantNumber = (state: fromAccount.State): number => {
  if (state && state.account) {
    return state.account.merchantId
  }
  return null
}

const getMerchantName = (state: fromAccount.State): string => {
  if (state && state.account && state.account.profile) {
    return state.account.profile.businessName
  }
  return ''
}

const getPhone = (state: fromAccount.State): string => {
  if (state && state.account) {
    return state.account.phone
  }
  return ''
}

const getUseSSUTA = (state: fromAccount.State): boolean => {
  return !!state?.account?.streamlined?.id
}

const getSSUTA = (state: fromAccount.State): IStreamlined => {
  if (state && state.account && state.account.streamlined) {
    return state.account.streamlined
  }
  return null
}

const getDataSource = (state: fromAccount.State): DataSourceEnum => {
  return state.source
}

const getError = (state: fromAccount.State): string => {
  if (state && state.error) {
    return state.error
  }
  return null
}

const getWorking = (state: fromAccount.State): boolean => {
  if (state && state.working) {
    return state.working.length > 0
  }
  return false
}

const getWorkingMessage = (state: fromAccount.State): string => {
  if (state && state.working) {
    return state.working
  }
  return ''
}

const getPrepayBalance = (state: fromAccount.State): number => {
  if (state && state.account && state.account.prepayBlance) {
    return state.account.prepayBlance
  }
  return 0
}

const getCloseWindow = (state: fromAccount.State): IAccountWindow => {
  if (state && state.account && state.account.closeWindow) {
    return state.account.closeWindow
  }
  return null
}

export const selectAccountState: MemoizedSelector<object, fromAccount.State> =
  createFeatureSelector<fromAccount.State>(FeatureKeys.Account)

export const selectStartDate: MemoizedSelector<object, Moment> = createSelector(
  selectAccountState,
  getStartDate
)

export const selectConfiguration: MemoizedSelector<object, IAccountWindow> = createSelector(
  selectAccountState,
  getConfiguration
)

export const selectConversionStatus: MemoizedSelector<object, IConversionStatus> = createSelector(
  selectAccountState,
  getConversionStatus
)

export const selectNeedsPaAttestation: MemoizedSelector<object, boolean> = createSelector(
  selectAccountState,
  getNeedsPenAttestation
)

export const selectProfile: MemoizedSelector<object, IProfile> = createSelector(
  selectAccountState,
  getProfile
)

export const selectFipsCode: MemoizedSelector<object, string> = createSelector(
  selectAccountState,
  getFipsCode
)

export const selectMerchantId: MemoizedSelector<object, number> = createSelector(
  selectAccountState,
  getMerchantNumber
)

export const selectBusinessName: MemoizedSelector<object, string> = createSelector(
  selectAccountState,
  getMerchantName
)

export const selectPhoneNumber: MemoizedSelector<object, string> = createSelector(
  selectAccountState,
  getPhone
)

export const selectIsSsuta: MemoizedSelector<object, boolean> = createSelector(
  selectAccountState,
  getUseSSUTA
)

export const selectSsuta: MemoizedSelector<object, IStreamlined> = createSelector(
  selectAccountState,
  getSSUTA
)

export const selectAccountStatus: MemoizedSelector<object, AccountStateEnum> = createSelector(
  selectAccountState,
  getAccountStatus
)

export const selectAppIds: MemoizedSelector<object, string[]> = createSelector(
  selectAccountState,
  getAppIds
)

export const selectServicePlan: MemoizedSelector<object, IServicePlan> = createSelector(
  selectAccountState,
  getServicePlan
)

export const selectDataSource: MemoizedSelector<object, DataSourceEnum> = createSelector(
  selectAccountState,
  getDataSource
)

export const selectAccountError: MemoizedSelector<object, string> = createSelector(
  selectAccountState,
  getError
)

export const selectAccountWorking: MemoizedSelector<object, boolean> = createSelector(
  selectAccountState,
  getWorking
)

export const selectAccountWorkingMessage: MemoizedSelector<object, string> = createSelector(
  selectAccountState,
  getWorkingMessage
)

export const selectPrepayBalance: MemoizedSelector<object, number> = createSelector(
  selectAccountState,
  getPrepayBalance
)

export const selectIsLeaving: MemoizedSelector<object, boolean> = createSelector(
  selectAccountState,
  getLeaving
)

export const selectCloseWindow: MemoizedSelector<object, IAccountWindow> = createSelector(
  selectAccountState,
  getCloseWindow
)
