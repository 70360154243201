import { Action } from '@ngrx/store'
import { IPaAttestation } from 'src/app/attestation/models/pa-attestation.model'
import { AccountStateEnum } from 'src/app/enums/account.enums'
import { BillingFrequencyEnum } from 'src/app/enums/billing.enums'
import { ApiPlanLevelEnum } from 'src/app/enums/service-plan.enums'
import { IPaymentMethod } from 'src/app/payment-method/models/ipayment-method.model'

import { IProfile } from '../models/iprofile.model'
import { IAccount, IConversionStatus } from './../models/iaccount'
import { ICancellationQuestionnaire } from 'src/app/manage-account/models/icancellationQuestionnaire'

export enum AccountActionTypes {
  RequestAccountData = '[Login Effects] Request account data',
  RequestAccountDataLogin = '[Login Effects] Request account data after login',

  RefreshAccountData = '[Account Effects] Refresh account data from api',
  GetAccountForAutoLogin = '[Login Effects] Get account data',

  GetAccountDataSuccess = '[Account Effects] Get account data success',
  GetAccountForAutoLoginSuccess = '[Account Effects] Get account data success for auto login',
  GetAccountDataSuccessRedirect = '[Account Effects] Get account data success with redirect',

  GetAccountDataFailure = '[Account Effects] Get account data failure',

  SaveAccountToStore = '[Login Effects] Save account data',
  SaveAccountStateSuccess = '[Account Effects] Successfully persisted account state',

  UpdateAccountState = '[Shell Component] Update account state',
  UpdateAccountStateFromMerchantStore = '[Merchant Store Effects] Update account state',

  ClearAccountData = '[Login Effects] Clear account data',
  ClearAccountDataSuccess = '[Account Effects] Successfully cleared account data',

  SwitchAccountDataSourceToLocal = '[Login Effects] Switch account data to local',

  InitUpDateConversionStatus = '[Get Started] Trigger update go-live',
  UpdateConversionStatus = '[Account Effects] Update go-live',

  UpdateProfileFromNewUser = '[Update Profile Component] Update profile information',
  UpdateProfileFromAccount = '[Edit Profile Page] Update profile information',

  TurnOnAutomatedCompliance = '[Automated Service Component] Turn on automated compliance',
  TurnOnGrandfatheredSelectiveCompliance = '[Old Selective Compliance Form] Turn on grandfathered selected compliance',
  TurnOnSelectiveCompliance = '[Selective Compliance Component] Turn on selected compliance',

  UpdateServicePlanSuccess = '[Account Effect] Update service plan success',

  AddPrepay = '[Selective Form Component] Update amount to prepay',
  AddPrepaySuccess = '[Account Effects] Update amount to prepay success',

  ExtendConfigurationWindow = '[Extend Configuration Component] Extend configuration window',
  ExtendConfigurationWindowFailed = '[Account Effects] Extend configuration window failed',

  UpdateIndustryClassification = '[Industry Classification Page] Update account industry classification',
  UpdateIndustryClassificationSuccess = '[Account Effects] Update industry classification success',

  CancelAccount = '[Cancellation Page] Submit Cancel Account Request',
  CancelAccountSuccess = '[Account Effects] Cancel Account Request Success',

  ReactivateAccount = '[Account Detail Page] Submit Activate Account Request',
  ReactivateAcountSuccess = '[Account Effects] Reactivate Account Request Success',

  AddPaAttestation = '[QA select] Add PA attestation for testing',
  SubmitPaAttestation = '[Pa Attestation Form] Submit PA Attestation',
}

export class RequestAccountData implements Action {
  readonly type = AccountActionTypes.RequestAccountData
}

export class RequestAccountDataLogin implements Action {
  readonly type = AccountActionTypes.RequestAccountDataLogin
}

export class GetAccountForAutoLogin implements Action {
  readonly type = AccountActionTypes.GetAccountForAutoLogin
}

export class RefreshAccountData implements Action {
  readonly type = AccountActionTypes.RefreshAccountData
}

export class SwitchAccountDataSourceToLocal implements Action {
  readonly type = AccountActionTypes.SwitchAccountDataSourceToLocal
}

export class InitUpDateConversionStatus implements Action {
  readonly type = AccountActionTypes.InitUpDateConversionStatus
}

export class UpdateConversionStatus implements Action {
  readonly type = AccountActionTypes.UpdateConversionStatus
  constructor(public payload: { conversionStatus: IConversionStatus }) {}
}

export class GetAccountDataSuccess implements Action {
  readonly type = AccountActionTypes.GetAccountDataSuccess
  constructor(public payload: { account: IAccount }) {}
}

export class GetAccountForAutoLoginSuccess implements Action {
  readonly type = AccountActionTypes.GetAccountForAutoLoginSuccess
  constructor(public payload: { account: IAccount }) {}
}

export class GetAccountDataSuccessRedirect implements Action {
  readonly type = AccountActionTypes.GetAccountDataSuccessRedirect
  constructor(public payload: { account: IAccount }) {}
}

export class GetAccountDataFailure implements Action {
  readonly type = AccountActionTypes.GetAccountDataFailure
  // constructor(public payload: { account: IAccount }) {}
}

export class UpdateProfileFromNewUser implements Action {
  readonly type = AccountActionTypes.UpdateProfileFromNewUser
  constructor(public payload: { profile: IProfile }) {}
}

export class UpdateProfileFromAccount implements Action {
  readonly type = AccountActionTypes.UpdateProfileFromAccount
  constructor(public payload: { profile: IProfile }) {}
}

export class UpdateAccountState implements Action {
  readonly type = AccountActionTypes.UpdateAccountState
  constructor(public payload: { state: AccountStateEnum }) {}
}

export class UpdateAccountStateFromMerchantStore implements Action {
  readonly type = AccountActionTypes.UpdateAccountStateFromMerchantStore
  constructor(public payload: { state: AccountStateEnum }) {}
}

export class SaveAccountStateSuccess implements Action {
  readonly type = AccountActionTypes.SaveAccountStateSuccess
}

export class SaveAccountToStore implements Action {
  readonly type = AccountActionTypes.SaveAccountToStore
  constructor(public payload: { account: IAccount }) {}
}

export class ClearAccountData implements Action {
  readonly type = AccountActionTypes.ClearAccountData
}

export class ClearAccountDataSuccess implements Action {
  readonly type = AccountActionTypes.ClearAccountDataSuccess
}

export class TurnOnAutomatedCompliance implements Action {
  readonly type = AccountActionTypes.TurnOnAutomatedCompliance
  constructor(public payload: { billingFrequency: BillingFrequencyEnum }) {}
}

export class TurnOnSelectiveCompliance implements Action {
  readonly type = AccountActionTypes.TurnOnSelectiveCompliance
  constructor(public payload: { unregisterSST: boolean }) {}
}

export class TurnOnGrandfatheredSelectiveCompliance implements Action {
  readonly type = AccountActionTypes.TurnOnGrandfatheredSelectiveCompliance
  constructor(
    public payload: {
      billingFrequency: BillingFrequencyEnum
      servicePlan: ApiPlanLevelEnum
    }
  ) {}
}

export class UpdateServicePlanSuccess implements Action {
  readonly type = AccountActionTypes.UpdateServicePlanSuccess
  constructor(public payload: { account: IAccount }) {}
}

export class AddPrepay implements Action {
  readonly type = AccountActionTypes.AddPrepay
  constructor(public payload: { amount: number }) {}
}

export class AddPrepaySuccess implements Action {
  readonly type = AccountActionTypes.AddPrepaySuccess
  constructor(public payload: { account: IAccount }) {}
}

export class UpdateIndustryClassification implements Action {
  readonly type = AccountActionTypes.UpdateIndustryClassification
  constructor(public payload: { code: number }) {}
}

export class UpdateIndustryClassificationSuccess implements Action {
  readonly type = AccountActionTypes.UpdateIndustryClassificationSuccess
  constructor(public payload: { account: IAccount }) {}
}

export class ExtendConfigurationWindow implements Action {
  readonly type = AccountActionTypes.ExtendConfigurationWindow
  constructor(public payload: { paymentMethod: IPaymentMethod }) {}
}
export class ExtendConfigurationWindowFailed implements Action {
  readonly type = AccountActionTypes.ExtendConfigurationWindowFailed
  constructor(public payload: { error: string }) {}
}

export class CancelAccount implements Action {
  readonly type = AccountActionTypes.CancelAccount
  constructor(
    public payload: { unregisterSST: boolean; questionnaire: ICancellationQuestionnaire }
  ) {}
}

export class CancelAccountSuccess implements Action {
  readonly type = AccountActionTypes.CancelAccountSuccess
  constructor(public payload: { account: IAccount }) {}
}

export class ReactivateAccount implements Action {
  readonly type = AccountActionTypes.ReactivateAccount
}

export class ReactivateAcountSuccess implements Action {
  readonly type = AccountActionTypes.ReactivateAcountSuccess
  constructor(public payload: { account: IAccount }) {}
}

export class AddPaAttestation implements Action {
  readonly type = AccountActionTypes.AddPaAttestation
}

export class SubmitPaAttestation implements Action {
  readonly type = AccountActionTypes.SubmitPaAttestation
  constructor(public payload: { form: IPaAttestation }) {}
}

export type AccountActions =
  | RequestAccountData
  | RequestAccountDataLogin
  | RefreshAccountData
  | SwitchAccountDataSourceToLocal
  | GetAccountForAutoLogin
  | GetAccountDataSuccess
  | GetAccountForAutoLoginSuccess
  | GetAccountDataSuccessRedirect
  | GetAccountDataFailure
  | SaveAccountStateSuccess
  | UpdateAccountState
  | UpdateAccountStateFromMerchantStore
  | InitUpDateConversionStatus
  | UpdateConversionStatus
  | UpdateProfileFromNewUser
  | UpdateProfileFromAccount
  | SaveAccountToStore
  | ClearAccountData
  | ClearAccountDataSuccess
  | TurnOnAutomatedCompliance
  | TurnOnSelectiveCompliance
  | TurnOnGrandfatheredSelectiveCompliance
  | UpdateServicePlanSuccess
  | AddPrepay
  | AddPrepaySuccess
  | UpdateIndustryClassification
  | UpdateIndustryClassificationSuccess
  | ExtendConfigurationWindow
  | ExtendConfigurationWindowFailed
  | CancelAccount
  | CancelAccountSuccess
  | ReactivateAccount
  | ReactivateAcountSuccess
  | AddPaAttestation
  | SubmitPaAttestation
