import { ScrollingModule } from '@angular/cdk/scrolling'
import { NgModule } from '@angular/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatRadioModule } from '@angular/material/radio'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSliderModule } from '@angular/material/slider'
import { MatSortModule } from '@angular/material/sort'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { DpDatePickerModule } from 'ng2-date-picker'
import { MatChipsModule } from '@angular/material/chips'

import { InitMaterialModule } from './init-material.module'

@NgModule({
  exports: [
    InitMaterialModule,
    DpDatePickerModule,
    MatTableModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSortModule,
    MatTabsModule,
    MatStepperModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatDatepickerModule,
    MatGridListModule,
    ScrollingModule,
    MatChipsModule,
  ],
})
export class MaterialModule {}
