import { Injectable, OnDestroy } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Store } from '@ngrx/store'
import * as fromUserSelector from 'src/app/user/selectors/user.selectors'
import * as fromUser from 'src/app/user/user.state'
import * as fromSetupSelector from 'src/app/setup/selectors/setup.selectors'
import * as fromSetup from 'src/app/setup/setup.state'

import { AccountService } from '../../account/services/account.service'
import { AccountStateEnum } from '../../enums/account.enums'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { BillingService } from 'src/app/manage-account/services/billing.service'
import { IConversionStatus } from 'src/app/account/models/iaccount'
import { PaymentMethod } from 'src/app/manage-account/models/billing.model'
import { SubSink } from 'subsink'

@Injectable({
  providedIn: 'root',
})
export class AccountStatusGuard implements CanActivate, OnDestroy {
  constructor(
    private userStore: Store<fromUser.State>,
    private accountService: AccountService,
    private router: Router,
    private billingService: BillingService,
    private setupStore: Store<fromSetup.State>
  ) {
    this.setupComplete$
      .pipe(
        tap((setupComplete) => {
          this.setupCompleteSubject$.next(setupComplete)
        })
      )
      .subscribe()
  }
  private subs = new SubSink()

  public isMasquerading$: Observable<boolean> = this.userStore.select(
    fromUserSelector.selectUserIsMasquerading
  )
  public setupComplete$: Observable<boolean> = this.setupStore.select(
    fromSetupSelector.selectSetupComplete
  )
  public setupCompleteSubject$ = new BehaviorSubject<boolean>(null)

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkIfConverted(state.url)
  }

  checkIfConverted(url: string): Observable<boolean> {
    const accountState = this.accountService.getAccountState()
    const conversionStatus = this.accountService.getLocalConversionStatus()
    return this.isMasquerading$.pipe(
      withLatestFrom(this.accountService.getLocalSavedAccount()),
      filter(([isMasquerading, account]) => !!account),
      switchMap(([isMasquerading, account]) => {
        return this.billingService.getCustomerPaymentMethods(account.merchantId).pipe(
          map((paymentMethods) =>
            this.canActivateForConversion(
              accountState,
              conversionStatus,
              isMasquerading,
              paymentMethods,
              false
            )
          ),
          // TODO: Most likely remove this once we fully migrate off old payment methods
          catchError((error) =>
            of(
              this.canActivateForConversion(
                accountState,
                conversionStatus,
                isMasquerading,
                [],
                true
              )
            )
          )
        )
      })
    )
  }

  private canActivateForConversion(
    accountState: AccountStateEnum,
    conversionStatus: IConversionStatus,
    isMasquerading: boolean,
    paymentMethods: PaymentMethod[],
    skipPaymentMethodCheck: boolean
  ): boolean {
    let activate
    const conversion = conversionStatus?.hasCompleteProfile && conversionStatus.hasLocation
    switch (accountState) {
      case AccountStateEnum.Converted:
        activate = true
        break
      case AccountStateEnum.Prospective:
        if (conversion || isMasquerading || this.setupCompleteSubject$.value === true) {
          activate = true
        } else {
          activate = false
          this.router.navigate(['complete-account'])
        }
        break
      case AccountStateEnum.Frozen:
        activate = false
        this.router.navigate(['/go/payment-methods'])
        break
      default:
        activate = true
    }
    return activate
  }
}
