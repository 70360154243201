import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core'
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { BehaviorSubject, Observable } from 'rxjs'
import { SubSink } from 'subsink'

import { IUsStateSelect } from 'src/app/us-states/models/ius-state-select'
import { USStateService } from 'src/app/us-states/services/us-state.service'
import {
  IMerchantReportDownload,
  IReportRequest,
} from 'src/app/download-center/models/imerchant-report-downloads.model'
import * as downloadCenterSelector from '../../selectors/download-center.selectors'
import * as downloadCenterActions from '../../actions/download-center.actions'
import { getOptionsFromLabel, IOption } from 'src/app/utilities/select-box.utilities'
import { ReportTypeEnum, ReportTypeEnumLabel } from 'src/app/enums/report-type.enum'
import { MenuItem } from 'primeng/api'
import { DownloadCenterState } from '../../download-center.state'

const ALL_STATES_OPTION: IUsStateSelect = {
  name: 'All states',
  abbreviation: 'allStates',
  fips: null,
  title: null,
}

@Component({
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePage implements OnInit, OnDestroy {
  public subs = new SubSink()
  public formGroup: UntypedFormGroup
  public reportTypeOptions: IOption[] = getOptionsFromLabel(ReportTypeEnumLabel)
  public canSelectAllStates = true
  public usStates$: Observable<IUsStateSelect[]> = this.stateService.getBasicUsStates()
  public usStateOptions = []
  public readyItemActions: MenuItem[]
  public pendingItemActions: MenuItem[]
  public selectedReport: BehaviorSubject<IMerchantReportDownload> = new BehaviorSubject(null)
  public displayableReports: BehaviorSubject<IMerchantReportDownload[]> = new BehaviorSubject([])
  public showFailedDialog: boolean = false

  constructor(
    private stateService: USStateService,
    private fb: UntypedFormBuilder,
    private downloadCenterStore: Store<DownloadCenterState>
  ) {
    this.formGroup = this.fb.group({
      reportType: [null, Validators.required],
      usState: [null, Validators.required],
      downloadPeriodRange: [null, [Validators.required, this.dateRangeValidator()]],
    })
  }

  public reports$: Observable<IMerchantReportDownload[]> = this.downloadCenterStore.select(
    downloadCenterSelector.selectReports
  )

  public working$: Observable<boolean> = this.downloadCenterStore.select(
    downloadCenterSelector.selectWorking
  )

  ngOnInit(): void {
    this.downloadCenterStore.dispatch(new downloadCenterActions.InitReports())

    this.getUsStatesOptions()

    this.formGroup.get('reportType').valueChanges.subscribe((reportType) => {
      if (reportType) {
        this.handleReportTypeChange(reportType.value)
      }
    })

    this.subs.add(
      this.reports$.subscribe((reports) => {
        if (reports) {
          this.displayableReports.next([...reports])
        }
      })
    )

    const downloadAction = {
      label: 'Download',
      icon: 'pi pi-download',
      command: () => {
        const reportId = this.selectedReport.value.id
        const fileName = this.selectedReport.value.fileName
        this.downloadCenterStore.dispatch(
          new downloadCenterActions.DownloadReport({ reportId, fileName })
        )
      },
    }
    const processAction = {
      label: 'Process',
      icon: 'pi pi-sync',
      command: () => {
        const reportId = this.selectedReport.value.id
        this.downloadCenterStore.dispatch(new downloadCenterActions.ProcessReport({ reportId }))
      },
    }
    const deleteAction = {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => {
        const reportId = this.selectedReport.value.id
        this.downloadCenterStore.dispatch(new downloadCenterActions.DeleteReport({ reportId }))
      },
    }

    this.readyItemActions = [downloadAction, deleteAction]
    this.pendingItemActions = [processAction, deleteAction]
  }

  public getUsStatesOptions() {
    this.subs.add(
      this.usStates$.subscribe((states) => {
        if (this.canSelectAllStates) {
          this.usStateOptions = [ALL_STATES_OPTION, ...states]
        } else {
          this.usStateOptions = states
        }
      })
    )
  }

  public getValidationError(formControlName: string) {
    const field = this.formGroup.get(formControlName)
    const showError = field.invalid && (field.dirty || field.touched)

    if (showError) {
      if (field?.errors?.message) {
        return field.errors.message
      }
      if (field?.errors?.required) {
        return 'is required.'
      }
      return 'is invalid.'
    }

    return
  }

  public handleReportTypeChange(reportType: ReportTypeEnum) {
    this.canSelectAllStates = true
    this.formGroup.get('usState').enable()
    switch (reportType) {
      case ReportTypeEnum.Jurisdiction: {
        this.formGroup.get('usState').setValue(ALL_STATES_OPTION)
        this.formGroup.get('usState').disable()
        break
      }
      case ReportTypeEnum.ConsolidatedTransactionDetail: {
        this.canSelectAllStates = false
        this.formGroup.get('usState').setValue('')
        break
      }
    }
  }

  public requestReport(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched()
      return
    }

    const downloadPeriodRange = this.formGroup.get('downloadPeriodRange').value
    const periodStart = moment(downloadPeriodRange?.[0]).format('YYYYMMDD')
    const periodEnd = moment(downloadPeriodRange?.[1]).format('YYYYMMDD')
    const state = this.formGroup.get('usState').value
    const reportType = this.formGroup.get('reportType').value
    const reportLabel = ReportTypeEnumLabel.get(reportType.value)
    const stateForLabel = state.abbreviation === 'allStates' ? 'All States' : state.abbreviation
    const reportRequest: IReportRequest = {
      FileName: `${stateForLabel} ${reportLabel} ${periodStart}-${periodEnd}`,
      PeriodStart: parseInt(periodStart),
      PeriodEnd: parseInt(periodEnd),
      ReportType: reportType.value,
      State: state.abbreviation === 'allStates' ? null : state.abbreviation,
      URL: null,
    }

    this.downloadCenterStore.dispatch(
      new downloadCenterActions.RequestReport({ request: reportRequest })
    )

    this.formGroup.reset()
  }

  public refresh(): void {
    this.downloadCenterStore.dispatch(new downloadCenterActions.InitReports())
  }

  public toggleFailedDialog() {}

  public getStatus(report: IMerchantReportDownload) {
    if (report.available) {
      return 'ready'
    }
    if (this.hasBeen24Hours(report)) {
      return 'failed'
    }
    return 'pending'
  }

  private hasBeen24Hours(report: IMerchantReportDownload) {
    const now = moment.utc()
    const dateRequested = moment.utc(report.dateRequested)
    const diff = now.diff(dateRequested, 'hours')
    return diff > 24
  }

  private dateRangeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dates = control.value
      if (!dates) {
        return
      }
      if (dates.some((date) => !date)) {
        return { message: 'must have an end date.' }
      }
      const startDate = dates[0] ? moment(dates[0]) : null
      const endDate = dates[1] ? moment(dates[1]) : null

      if (!startDate || !endDate) {
        return
      }

      const months = endDate.diff(startDate, 'months')
      return months > 12 ? { message: 'must be less than 1 year.' } : null
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }
}
