import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { RouterStateSerializer } from '@ngrx/router-store'
import { ShellEnum } from 'src/app/enums/shell.enums'
import { IBreadCrumb } from 'src/app/ui/init-ui/models/ibreadcrumb.models'

import { IRouterState } from './../models/irouterstate'

export class CustomSerializer implements RouterStateSerializer<IRouterState> {
  serialize(routerState: RouterStateSnapshot): IRouterState {
    let route = routerState.root

    const accumulator = new Set<IBreadCrumb>()
    const prefix = new Set<string>()

    while (route.firstChild) {
      if (route.routeConfig && route.routeConfig.path) {
        const item: IBreadCrumb = {
          label: this.toTitleCase(route.data.breadcrumb),
          path: `/${route.routeConfig.path}`,
        }
        prefix.add(route.routeConfig.path)
        if (accumulator.size === 0) {
          accumulator.add(item)
        }
        if (route.children) {
          route.children.map((child: ActivatedRouteSnapshot) => {
            prefix.add(child.routeConfig.path)
            const childItem: IBreadCrumb = {
              label: this.toTitleCase(child.data.breadcrumb),
              path: `/${Array.from(prefix).join('/')}`,
            }
            accumulator.add(childItem)
          })
        }
      }
      route = route.firstChild
    }

    const {
      url,
      root: { queryParams },
    } = routerState

    const { params } = route
    const shell = this.parseShell(url)
    const host = window.location.host
    const title = route.data.title ? route.data.title : route.data.breadcrumb
    const description = route.data.description ? route.data.description : route.data.breadcrumb

    return {
      url,
      title,
      description,
      shell,
      host,
      params,
      queryParams,
      breadcrumbs: Array.from(accumulator) as IBreadCrumb[],
    }
  }
  private parseShell(url: string = ''): ShellEnum {
    if (url.indexOf('/go/') === 0) {
      return ShellEnum.Go
    }
    if (url.indexOf('/get-started/') === 0) {
      return ShellEnum.GetStarted
    }
    if (url.indexOf('/complete-account') === 0) {
      return ShellEnum.Setup
    }
    return ShellEnum.Home
  }

  private toTitleCase(str: string) {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
    })
  }
}
