import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { AlertTypeEnum } from 'src/app/enums/alert.enums'
import { ShellEnum } from 'src/app/enums/shell.enums'
import { SupportCategoryEnum } from 'src/app/enums/support.enums'

import { ISupportPage } from '../../models/isupport-page'

@Component({
  selector: 'tc-support-category',
  templateUrl: './support-category.component.html',
  styleUrls: ['./support-category.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportCategoryComponent implements OnInit {
  constructor(private router: Router) {}
  private maxNumber = 100
  public categoryTitle: string
  public routePrefix: string
  public sortedPages$ = new BehaviorSubject<ISupportPage[]>([])
  public cardType: AlertTypeEnum
  public supportCategory: SupportCategoryEnum
  public showNumber$ = new BehaviorSubject<number>(this.maxNumber)

  @Input('title')
  set setTitle(category: string) {
    this.categoryTitle = category
  }

  @Input('category')
  set setCategory(category: SupportCategoryEnum) {
    this.supportCategory = category
  }

  @Input('maxShow')
  set setMaxItem(max: number) {
    this.maxNumber = max
    this.showNumber$.next(max)
  }

  @Input('pages')
  set setSortedPages(pages: ISupportPage[]) {
    if (pages && pages.length > 0) {
      this.sortedPages$.next(pages.sort(this.compare))
    } else {
      this.sortedPages$.next([])
    }
  }

  @Input('shell')
  set setPrefix(shell: ShellEnum) {
    if (shell === ShellEnum.Go) {
      this.routePrefix = '/go/support/'
      this.cardType = AlertTypeEnum.Primary
      return
    }

    if (shell === ShellEnum.GetStarted || shell === ShellEnum.Setup) {
      this.routePrefix = '/get-started/support/'
      this.cardType = AlertTypeEnum.Accent
      return
    }

    this.routePrefix = '/support/'
    this.cardType = AlertTypeEnum.Accent
  }

  public showPages$: Observable<ISupportPage[]> = combineLatest([
    this.sortedPages$,
    this.showNumber$,
  ]).pipe(map(([pages, max]) => pages.slice(0, max <= 0 ? 100 : max)))

  public showToggle$: Observable<boolean> = this.sortedPages$.pipe(
    map((pages) => pages.length > this.maxNumber)
  )

  public showToggleAll$: Observable<boolean> = this.showNumber$.pipe(
    map((showNumber) => showNumber === this.maxNumber)
  )

  ngOnInit(): void {
    this.showPages$.subscribe()
  }

  public toggleShowAll(): void {
    this.showNumber$.next(1000)
  }

  public toggleShowLess(): void {
    this.showNumber$.next(this.maxNumber)
  }

  private compare(a: ISupportPage, b: ISupportPage) {
    const nameA = a.name ? `${a.fields.highlight ? '  ' : ''}${a.name.toLocaleUpperCase()}` : ''
    const nameB = b.name ? `${b.fields.highlight ? '  ' : ''}${b.name.toLocaleUpperCase()}` : ''
    let comparison = 0
    if (nameA > nameB) {
      comparison = 1
    } else if (nameA < nameB) {
      comparison = -1
    }
    return comparison
  }
}
