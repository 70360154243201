import { initialState, DownloadCenterState } from '../download-center.state'
import {
  DownloadCenterActions,
  DownloadCenterActionTypes,
} from '../actions/download-center.actions'

export function reducer(state = initialState, action: DownloadCenterActions): DownloadCenterState {
  switch (action.type) {
    case DownloadCenterActionTypes.InitReports:
      return {
        ...state,
        working: true,
      }
    case DownloadCenterActionTypes.InitReportsSuccess:
      return {
        ...state,
        reports: action.payload.reports,
        working: false,
      }
    case DownloadCenterActionTypes.InitReportsFailed:
      return {
        ...state,
        working: false,
      }

    case DownloadCenterActionTypes.RequestReport:
      return {
        ...state,
        working: true,
      }
    case DownloadCenterActionTypes.RequestReportSuccess:
    case DownloadCenterActionTypes.RequestReportFailed:
      return {
        ...state,
        working: false,
      }
    case DownloadCenterActionTypes.DeleteReport:
      return {
        ...state,
        working: true,
      }
    case DownloadCenterActionTypes.DeleteReportSuccess:
    case DownloadCenterActionTypes.DeleteReportFailed:
      return {
        ...state,
        working: false,
      }

    case DownloadCenterActionTypes.DownloadReport:
      return {
        ...state,
        working: true,
      }
    case DownloadCenterActionTypes.DownloadReportSuccess:
    case DownloadCenterActionTypes.DownloadReportFailed:
      return {
        ...state,
        working: false,
      }
    default:
      return state
  }
}
