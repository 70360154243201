import { IEditContact } from 'src/app/contact/models/iedit-contact.model'
import { LoginTypeEnum } from 'src/app/enums/login.enum'
import { validateDate } from 'src/app/utilities/date.utilities'

import { IApiToken } from '../models/iapi-auth-token'
import { IApiCurrentUser } from '../models/iapi-current-user'
import { IAuth } from '../models/iauth'
import { IUser } from '../models/iuser'

export class AuthAdapter {
  public mapApiUserToUser(result: IApiCurrentUser, loginType: LoginTypeEnum): IUser {
    const tosTimeStamp = validateDate(result.acceptedTOSDate)
    const hasLatest = result.signedTOSVersion >= result.latestTOSVersion
    const mapped: IUser = {
      username: result.email,
      acceptedTermsOfService: tosTimeStamp ? true : false,
      authority: result.authority,
      firstName: result.firstName,
      lastName: result.lastName,
      id: result.contactID.toString(),
      merchantId: result.merchantID,
      loginType,
      hasLatesestTermsOfService: tosTimeStamp ? hasLatest : false,
      phone: result.phone,
      confirmedEmailDate: result.confirmedEmailDate,
    }
    return mapped
  }

  public mapApiTokentoIAuth(result: IApiToken): [IAuth, string] {
    if (result.error) {
      throw new Error(result.error)
    }
    if (result && result.access_token) {
      const mapped: IAuth = {
        accessToken: result.access_token.value,
        validUntil: result.access_token_validTo,
      }
      return [mapped, result.current_user.contactid]
    }
    throw new Error('Unknown Token Error')
  }

  public mapSingleUseTokentoIAuth(result: IApiToken): IAuth {
    if (result.error) {
      throw new Error(result.error)
    }
    const mapped: IAuth = {
      accessToken: result.access_token.value,
      validUntil: result.access_token_validTo,
    }
    return mapped
  }

  mapUserToEditContact(user: IUser): IEditContact {
    const contact: IEditContact = {
      authority: user.authority,
      email: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      id: user.id,
    }
    return contact
  }
}
