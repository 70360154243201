export enum SupportCategoryEnum {
  All = 'all',
  GetStarted = 'general-get-started',
  Contacts = 'contacts',
  ExemptCertificates = 'exempt-certificates',
  Locations = 'locations',
  MerchantStore = 'merchant-store',
  ManageUsStates = 'manage-us-states',
  PaymentMethods = 'payment-methods',
  Transactions = 'transactions',
  Uploads = 'uploads',
  TaxReporting = 'tax-reporting',
  ServiceInvoice = 'taxcloud-service-invoice',
  TaxInvoice = 'us-state-tax-invoice',
  BusinessProfile = 'business-profile',
  AutomatedCompliance = 'automated-compliance',
  SelectiveCompliance = 'selective-compliance',
  ServicePlan = 'service-plan',
  Billing = 'billing',
  Developer = 'developer',
  SimpleSalesTaxPlugin = 'simple-sales-tax-plugin',
  CoreServices = 'starter-services',
  EnhancedServices = 'premium-services',
}

export const SupportCategoryEnumLabel = new Map<SupportCategoryEnum, string>([
  [SupportCategoryEnum.All, 'All Documents'],
  [SupportCategoryEnum.GetStarted, 'Getting Started'],
  [SupportCategoryEnum.Contacts, 'Manage Users'],
  [SupportCategoryEnum.Locations, 'Locations'],
  [SupportCategoryEnum.MerchantStore, 'Stores'],
  [SupportCategoryEnum.ManageUsStates, 'Manage US States'],
  [SupportCategoryEnum.PaymentMethods, 'Payment Methods'],
  [SupportCategoryEnum.Transactions, 'Transactions'],
  [SupportCategoryEnum.TaxReporting, 'Sales Tax Reports'],
  [SupportCategoryEnum.ServiceInvoice, 'TaxCloud Service Fees'],
  [SupportCategoryEnum.TaxInvoice, 'Tax Remittance Invoice'],
  [SupportCategoryEnum.BusinessProfile, 'Business Profile'],
  [SupportCategoryEnum.AutomatedCompliance, 'Automated Compliance'],
  [SupportCategoryEnum.SelectiveCompliance, 'Selective Compliance'],
  [SupportCategoryEnum.ServicePlan, 'Service Plans'],
  [SupportCategoryEnum.Billing, 'Billing'],
  [SupportCategoryEnum.Developer, 'Developer'],
  [SupportCategoryEnum.Uploads, 'Batch Uploads'],
  [SupportCategoryEnum.ExemptCertificates, 'Exempt Certificates'],
  [SupportCategoryEnum.SimpleSalesTaxPlugin, 'Simple Sales Tax Plugin'],
  [SupportCategoryEnum.EnhancedServices, 'Premium Services'],
  [SupportCategoryEnum.CoreServices, 'Starter Services'],
])
