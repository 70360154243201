import { BillingMessageEnumLabel } from './../../enums/billing.enums'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs'
import { filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import * as fromAppStatus from 'src/app/app-status/app-status.state'
import * as fromAppStatusSelectors from 'src/app/app-status/selectors/app-status.selectors'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'
import { State as AccountState } from 'src/app/account/account.state'
import * as accountSelectors from 'src/app/account/selectors/account.selectors'
import { State as UserStateStore } from 'src/app/user/user.state'
import * as authorizeStoreSelectors from 'src/app/user/selectors/user.selectors'

import { ServiceFeeDatabase } from '../db/service-fees.db'
import { IApiServiceFee } from '../models/iapi-service-fees.models'
import { IServiceFee } from '../models/iservice-fees.models'
import { ServiceFeeAdapter } from '../utilities/service-fee.adapter'
import { BillingMessageEnum } from 'src/app/enums/billing.enums'
import { IUser } from 'src/app/user/models/iuser'

@Injectable({
  providedIn: 'root',
})
export class ServiceFeeService {
  private adapter = new ServiceFeeAdapter()
  private serviceFeeDb = new ServiceFeeDatabase()
  private dataSource = new BehaviorSubject<DataSourceEnum>(DataSourceEnum.Local)
  public serviceFees = new BehaviorSubject<IServiceFee[]>(null)
  public initiated = new BehaviorSubject<boolean>(false)

  constructor(
    private http: HttpClient,
    private appStatus: Store<fromAppStatus.State>,
    private accountStore: Store<AccountState>,
    private userStore: Store<UserStateStore>
  ) {}

  public initServiceFees(): Observable<boolean> {
    return this.initiated.pipe(
      filter((initiated) => !initiated),
      switchMap((_) => this.getServiceFees()),
      tap((fee) => this.serviceFees.next(fee)),
      map((_) => true)
    )
  }

  private getServiceFees(): Observable<IServiceFee[]> {
    return merge(this.getServiceFeeFromApiWhenOnline(), this.getServiceFeeFromLocal()).pipe(
      tap(([fee, source]) => this.dataSource.next(source)),
      tap(([fee, source]) => {
        if (source === DataSourceEnum.API) {
          this.initiated.next(true)
        }
      }),
      map(([fee, source]) => fee)
    )
  }

  public getServiceFee(id: string): Observable<IServiceFee> {
    return this.serviceFeeDb.getFee(id.toUpperCase()).pipe(
      switchMap((fundingEvent) => {
        if (fundingEvent) {
          return of(fundingEvent)
        }
        return this.getServiceFeeFromApi().pipe(
          map(([fundingEvents, _]) => fundingEvents.find((event) => event.id === id))
        )
      })
    )
  }

  public deleteCache(): void {
    this.serviceFeeDb.clearDatabase().pipe(first()).subscribe()
    this.serviceFees.next(null)
  }

  public getBillingMessage(): Observable<BillingMessageEnum> {
    return combineLatest([this.isShopify$, this.getAppIds$]).pipe(
      map(([isShopify, appIds]) => {
        if (appIds.includes(BillingMessageEnumLabel.get(BillingMessageEnum.ShopifyPluginV1))) {
          return BillingMessageEnum.ShopifyPluginV1
        }
        if (isShopify) {
          return BillingMessageEnum.LegacyShopify
        }
        return null
      })
    )
  }

  private getServiceFeeFromApiWhenOnline(): Observable<[IServiceFee[], DataSourceEnum]> {
    return this.appStatus.select(fromAppStatusSelectors.selectOnline).pipe(
      filter((online) => online),
      switchMap(() => this.getServiceFeeFromApi())
    )
  }

  private getServiceFeeFromApi(): Observable<[IServiceFee[], DataSourceEnum]> {
    return this.http.get(`api/finance-tools/fees-details`).pipe(
      map((results: IApiServiceFee[]) =>
        results.map((result) => this.adapter.mapApiServiceFeeToServiceFee(result))
      ),
      tap((results) => this.serviceFeeDb.saveFees(results)),
      map((fees) => [fees, DataSourceEnum.API])
    )
  }

  private getServiceFeeFromLocal(): Observable<[IServiceFee[], DataSourceEnum]> {
    return this.serviceFeeDb.getFees().pipe(
      withLatestFrom(this.dataSource),
      filter(([fees, source]) => {
        if (source === DataSourceEnum.API) {
          return false
        }
        if (fees.length === 0) {
          return false
        }
        return true
      }),
      map(([fees, _]) => [fees, DataSourceEnum.IndexedDB])
    )
  }
  private user$: Observable<IUser> = this.userStore.select(
    authorizeStoreSelectors.selectAuthorizeUser
  )

  private isShopify$: Observable<boolean> = this.user$.pipe(
    filter((user) => !!user),
    map((user) => user.isShopify === true)
  )

  private getAppIds$: Observable<string[]> = this.accountStore
    .select(accountSelectors.selectAppIds)
    .pipe(
      filter((appIds) => !!appIds),
      map((appIds) => appIds)
    )
}
