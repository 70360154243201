import { State, initialState } from './../app-status.state'
import { AppStatusActionTypes, AppStatusActions } from './../actions/app-status.actions'
import { OnlineEnum } from 'src/app/enums/online.enums'

export function reducer(state = initialState, action: AppStatusActions): State {
  switch (action.type) {
    case AppStatusActionTypes.LoadAppStatus:
      return state

    case AppStatusActionTypes.SetAppStatus:
      return {
        ...state,
        online: action.payload.online,
        device: action.payload.device,
      }

    case AppStatusActionTypes.UpdateAppType:
      return {
        ...state,
        appType: action.payload.appType,
      }

    case AppStatusActionTypes.Online:
    case AppStatusActionTypes.AppOnline:
      return {
        ...state,
        online: OnlineEnum.Online,
      }

    case AppStatusActionTypes.Offline:
    case AppStatusActionTypes.AppOffline:
      return {
        ...state,
        online: OnlineEnum.Offline,
      }

    case AppStatusActionTypes.SetDevice:
      return {
        ...state,
        device: action.payload,
      }

    case AppStatusActionTypes.SetInstallable:
      return {
        ...state,
        installable: action.payload,
      }

    case AppStatusActionTypes.SetInstalled:
      return {
        ...state,
        installed: action.payload,
      }

    case AppStatusActionTypes.SetReturnRoute:
      return {
        ...state,
        returnUrl: action.payload.returnUrl,
      }

    default:
      return state
  }
}
