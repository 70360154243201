import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core'

@Component({
  selector: 'tc-direct-file-information',
  templateUrl: './direct-file-information.component.html',
  styleUrls: ['./direct-file-information.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectFileInformationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
