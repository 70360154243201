import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { map, shareReplay, switchMap, tap } from 'rxjs/operators'

import {
  IFilingSearchFilter,
  IApiDirectFilingFormDr15,
  IDirectFilingFormDr15,
  IDirectFilingFormDr15Ez,
  IApiDirectFilingFormDr15Ez,
  IMerchantFiling,
  ISubmissionHistory,
  IAPISubmissionResultFile,
  IAPISubmissionError,
  ISubmissionResultFile,
} from './../models/imerchant-filing'
import { DirectFilingAdapter } from '../utilities/direct-filing-adapter.utilities'
import { DirectFilePathEnum, TaxReturnTypeEnum } from 'src/app/enums/direct-filing.enums'
import { DirectFileDatabase } from './../db/direct-file.db'
import { environment } from 'src/environments/environment'
import { UsStateEnum } from 'src/app/enums/us-state.enums'

@Injectable({
  providedIn: 'root',
})
export class DirectFilingService {
  private db = new DirectFileDatabase()
  private adapter = new DirectFilingAdapter()
  private formCacheMap: Map<string, Observable<IDirectFilingFormDr15 | IDirectFilingFormDr15Ez>> =
    new Map()
  private submissionErrorsCache: Map<string, Observable<IAPISubmissionError[] | []>> = new Map()
  private directFileBaseUrl = environment.directFilingUrl
  public stateEndpoint$ = new BehaviorSubject<string>(null)

  constructor(private http: HttpClient) {}

  get dr15Form(): (id: string) => Observable<IDirectFilingFormDr15> {
    return (id: string) => {
      if (!this.formCacheMap.get(id)) {
        return this.db.getForm<IDirectFilingFormDr15>(id).pipe(
          switchMap((form: IDirectFilingFormDr15) => {
            if (form) {
              this.formCacheMap.set(id, of(form))
              return of(form)
            }
            this.formCacheMap.set(
              id,
              this.getDr15Form(TaxReturnTypeEnum.DR_15, id).pipe(shareReplay(1))
            )
            return this.formCacheMap.get(id) as Observable<IDirectFilingFormDr15>
          })
        )
      }
      return this.formCacheMap.get(id) as Observable<IDirectFilingFormDr15>
    }
  }
  get dr15EzForm(): (id: string) => Observable<IDirectFilingFormDr15Ez> {
    return (id: string) => {
      if (!this.formCacheMap.get(id)) {
        return this.db.getForm<IDirectFilingFormDr15Ez>(id).pipe(
          switchMap((form: IDirectFilingFormDr15Ez) => {
            if (form) {
              this.formCacheMap.set(id, of(form))
              return of(form)
            }
            this.formCacheMap.set(
              id,
              this.getDr15EzForm(TaxReturnTypeEnum.DR_15_EZ, id).pipe(shareReplay(1))
            )
            return this.formCacheMap.get(id) as Observable<IDirectFilingFormDr15Ez>
          })
        )
      }
      return this.formCacheMap.get(id) as Observable<IDirectFilingFormDr15Ez>
    }
  }

  get submissionFileErrors(): (submissionId: string) => Observable<IAPISubmissionError[] | []> {
    return (submissionId: string) => {
      if (!this.submissionErrorsCache.get(submissionId)) {
        this.submissionErrorsCache.set(
          submissionId,
          this.getSubmissionErrors(submissionId).pipe(shareReplay(1))
        )
        return this.submissionErrorsCache.get(submissionId) as Observable<
          IAPISubmissionError[] | []
        >
      }
      return this.submissionErrorsCache.get(submissionId) as Observable<IAPISubmissionError[] | []>
    }
  }

  public getMerchantFilings(filter: IFilingSearchFilter): Observable<IMerchantFiling[]> {
    let url = `${this.directFileBaseUrl}/${this.stateEndpoint$.value}/GetMerchantFilings`
    if (filter) {
      url += `?start=${filter.startPeriod}&end=${filter.endPeriod}`
    }
    return this.http
      .get<IMerchantFiling[]>(url)
      .pipe(
        map((merchantFilings: IMerchantFiling[]) =>
          merchantFilings.map((results) =>
            this.adapter.mapMerchantFilingApiResponseToMerchantFiling(results)
          )
        )
      )
  }
  public getDr15Form(
    returnType: TaxReturnTypeEnum,
    filingId: string
  ): Observable<IDirectFilingFormDr15> {
    const url = `${this.directFileBaseUrl}/${this.stateEndpoint$.value}/GetDr15Form?filingIds=${filingId}`
    return this.http.get<IApiDirectFilingFormDr15>(url).pipe(
      map((filingData) =>
        this.adapter.mapApiFormDr15DetailstoFormDetails(filingData, filingId, returnType)
      ),
      tap((formDr15) => this.db.saveForm(formDr15)),
      map((filingData) => filingData)
    )
  }
  public getDr15EzForm(
    returnType: TaxReturnTypeEnum,
    filingId: string
  ): Observable<IDirectFilingFormDr15Ez> {
    const url = `${this.directFileBaseUrl}/${this.stateEndpoint$.value}/GetDr15EzForm?filingIds=${filingId}`
    return this.http.get<IApiDirectFilingFormDr15Ez>(url).pipe(
      map((filingData) =>
        this.adapter.mapApiFormDr15EzDetailstoFormDetails(filingData, filingId, returnType)
      ),
      tap((formDr15) => this.db.saveForm(formDr15)),
      map((filingData) => filingData)
    )
  }

  public getFilingSubmissionHistory(filingId: string): Observable<ISubmissionHistory> {
    const url = `${this.directFileBaseUrl}/${this.stateEndpoint$.value}/GetSubmissionHistory?filingIds=${filingId}`
    return this.http.get<ISubmissionHistory>(url)
  }

  public downloadSubmissionFile(id: string) {
    const url = `${this.directFileBaseUrl}/${this.stateEndpoint$.value}/DownloadSubmissionFile?id=${id}`
    return this.http.get(url, { responseType: 'blob' })
  }

  public getSubmissionResultsFile(submissionId: string): Observable<ISubmissionResultFile> {
    return this.http
      .get(
        `${this.directFileBaseUrl}/${this.stateEndpoint$.value}/GetSubmissionResults?submissionId=${submissionId}`
      )
      .pipe(map((submissionResultsFile: IAPISubmissionResultFile) => submissionResultsFile))
  }

  public getSubmissionErrors(submissionId: string): Observable<IAPISubmissionError[]> {
    return this.http.get<IAPISubmissionError[]>(
      `${this.directFileBaseUrl}/${this.stateEndpoint$.value}/GetSubmissionErrors?submissionId=${submissionId}`
    )
  }
  public downloadSubmissionResultsFile(id: string) {
    const url = `${this.directFileBaseUrl}/${this.stateEndpoint$.value}/DownloadSubmissionResultsFile?id=${id}`
    return this.http.get(url, { responseType: 'blob' })
  }
  public submitFilings(filingIds: string[]) {
    return this.http.post(
      `${this.directFileBaseUrl}/${this.stateEndpoint$.value}/Submit`,
      filingIds
    )
  }

  public submitTestFilings(filingIds: string[]) {
    return this.http.post(
      `${this.directFileBaseUrl}/${this.stateEndpoint$.value}/TestSubmit`,
      filingIds
    )
  }

  public getMerchantGroupFormSummary(
    formType: TaxReturnTypeEnum,
    merchantFilings: IMerchantFiling[]
  ) {
    switch (formType) {
      case TaxReturnTypeEnum.DR_15:
        return this.downloadDr15Summary(merchantFilings)
      case TaxReturnTypeEnum.DR_15_EZ:
        return this.downloadDr15EzSummary(merchantFilings)
      case TaxReturnTypeEnum.ST_8:
        return this.downloadSt8Summary(merchantFilings)
    }
  }
  public downloadDr15Summary(merchantFilings: IMerchantFiling[]) {
    const url = `${this.directFileBaseUrl}/${this.stateEndpoint$.value}/DownloadDr15Summary`
    return this.http.post(
      url,
      this.adapter.mapFilingIdsForApi(TaxReturnTypeEnum.DR_15, merchantFilings),
      {
        responseType: 'blob',
      }
    )
  }

  public downloadDr15EzSummary(merchantFilings: IMerchantFiling[]) {
    const url = `${this.directFileBaseUrl}/${this.stateEndpoint$.value}/DownloadDr15EzSummary`
    return this.http.post(
      url,
      this.adapter.mapFilingIdsForApi(TaxReturnTypeEnum.DR_15_EZ, merchantFilings),
      {
        responseType: 'blob',
      }
    )
  }
  public downloadSt8Summary(merchantFilings: IMerchantFiling[]) {
    const url = `${this.directFileBaseUrl}/${this.stateEndpoint$.value}/DownloadSt8Summary`
    return this.http.post(
      url,
      this.adapter.mapFilingIdsForApi(TaxReturnTypeEnum.ST_8, merchantFilings),
      {
        responseType: 'blob',
      }
    )
  }

  public getMerchantFilingDetails(
    taxReturnType: TaxReturnTypeEnum,
    filingIds: string
  ): Observable<any> {
    switch (taxReturnType) {
      case TaxReturnTypeEnum.DR_15:
        return this.dr15Form(filingIds).pipe(shareReplay(1))
      case TaxReturnTypeEnum.DR_15_EZ:
        return this.dr15EzForm(filingIds).pipe(shareReplay(1))
      case TaxReturnTypeEnum.ST_8:
        // no mock up of ST-8 form for VA
        return of({ filingIds, taxReturnType })
      default:
        return of(null)
    }
  }

  public getFormTypes(usStateAbbr: string): Array<{ text: string; value: TaxReturnTypeEnum }> {
    switch (usStateAbbr.toUpperCase()) {
      case UsStateEnum.Florida:
        return [
          {
            text: 'DR-15',
            value: TaxReturnTypeEnum.DR_15,
          },
          {
            text: 'DR-15-EZ',
            value: TaxReturnTypeEnum.DR_15_EZ,
          },
        ]
      case UsStateEnum.Virginia:
        return [
          {
            text: 'ST-8',
            value: TaxReturnTypeEnum.ST_8,
          },
        ]
    }
  }

  public setStateEndpoint(usStateAbbr: string) {
    switch (usStateAbbr.toUpperCase()) {
      case UsStateEnum.Florida:
        this.stateEndpoint$.next(DirectFilePathEnum.Florida)
        break
      case UsStateEnum.Virginia:
        this.stateEndpoint$.next(DirectFilePathEnum.Virginia)
        break
    }
  }

  // Returns XML of Form
  public downloadDr15FormXml(filingId: string): Observable<string> {
    const url = `${this.directFileBaseUrl}/${this.stateEndpoint$.value}/DownloadDr15?filingIds=${filingId}`
    return this.http.get(url, { responseType: 'text' })
  }

  public downloadDr15EzFormXml(filingId: string): Observable<string> {
    const url = `${this.directFileBaseUrl}/${this.stateEndpoint$.value}/DownloadDr15Ez?filingIds=${filingId}`
    return this.http.get(url, { responseType: 'text' })
  }
  public downloadSt8Form(filingId: string): Observable<string> {
    const url = `${this.directFileBaseUrl}/${this.stateEndpoint$.value}/DownloadSt8?filingIds=${filingId}`
    return this.http.get(url, { responseType: 'text' })
  }

  clearDirectFileForms() {
    this.db.clearForms()
  }
}
