import { Action } from '@ngrx/store'
import {
  OnboardingCompletedResponse,
  OnboardingDetailsResponse,
  PutOnboardingDetailsRequest,
} from '../models/onboarding-details.model'

export enum SetupActionTypes {
  SetStepperActiveIndex = 'Set Stepper Active Index',
  CompleteSetup = 'Complete Setup',
  SetPendingUsers = 'Set Pending Users',
  SetStepLoading = 'Set step loading',
  SetFinalizeLoading = 'Set finalize loading',
  SetCurrentFormValid = 'Set current form valid',
  ResetState = 'Reset current state',
  SkipLocation = 'Skip location during setup',

  InitOnboardingDetails = 'Init Onboarding Details',
  InitOnboardingDetailsSuccess = 'Init Onboarding Details Success',
  InitOnboardingDetailsFailed = 'Init Onboarding Details Failed',

  PutOnboardingDetails = 'Put Onboarding Details',
  PutOnboardingDetailsSuccess = 'Put Onboarding Details Success',
  PutOnboardingDetailsFailed = 'Put Onboarding Details Failed',

  SetIsIntegrationsOnboarding = 'Set is Integrations Onboarding',

  InitIsOnboardingCompleted = 'Init is onboarding completed',
  InitIsOnboardingCompletedSuccess = 'Init is onboarding completed success',
  InitIsOnboardingCompletedFailed = 'Init is onboarding completed failed',
}

export class SetStepperActiveIndex implements Action {
  readonly type = SetupActionTypes.SetStepperActiveIndex
  constructor(public payload: { activeIndex: number }) {}
}

export class CompleteSetup implements Action {
  readonly type = SetupActionTypes.CompleteSetup
  constructor() {}
}

export class SetPendingUsers implements Action {
  readonly type = SetupActionTypes.SetPendingUsers
  constructor(public payload: { pendingUsers: any[] }) {}
}

export class SetStepLoading implements Action {
  readonly type = SetupActionTypes.SetStepLoading
  constructor(public payload: { loading: boolean }) {}
}

export class SetFinalizeLoading implements Action {
  readonly type = SetupActionTypes.SetFinalizeLoading
  constructor(public payload: { loading: boolean }) {}
}

export class SetCurrentFormValid implements Action {
  readonly type = SetupActionTypes.SetCurrentFormValid
  constructor(public payload: { valid: boolean }) {}
}

export class ResetCurrentState implements Action {
  readonly type = SetupActionTypes.ResetState
  constructor() {}
}

export class InitOnboardingDetails implements Action {
  readonly type = SetupActionTypes.InitOnboardingDetails
  constructor(public payload: { suppress404Error: boolean }) {}
}

export class InitOnboardingDetailsSuccess implements Action {
  readonly type = SetupActionTypes.InitOnboardingDetailsSuccess
  constructor(public payload: { onboardingDetailsResponse: OnboardingDetailsResponse }) {}
}

export class InitOnboardingDetailsFailed implements Action {
  readonly type = SetupActionTypes.InitOnboardingDetailsFailed
}

export class PutOnboardingDetails implements Action {
  readonly type = SetupActionTypes.PutOnboardingDetails
  constructor(public payload: { putOnboardingDetailsRequest: PutOnboardingDetailsRequest }) {}
}

export class PutOnboardingDetailsSuccess implements Action {
  readonly type = SetupActionTypes.PutOnboardingDetailsSuccess
  constructor(public payload: { onboardingDetailsResponse: OnboardingDetailsResponse }) {}
}

export class PutOnboardingDetailsFailed implements Action {
  readonly type = SetupActionTypes.PutOnboardingDetailsFailed
}

export class SkipLocation implements Action {
  readonly type = SetupActionTypes.SkipLocation
}

export class SetIsIntegrationsOnboarding implements Action {
  readonly type = SetupActionTypes.SetIsIntegrationsOnboarding
  constructor(public payload: { isIntegrationsOnboarding: boolean }) {}
}

export class InitIsOnboardingCompleted implements Action {
  readonly type = SetupActionTypes.InitIsOnboardingCompleted
}

export class InitIsOnboardingCompletedSuccess implements Action {
  readonly type = SetupActionTypes.InitIsOnboardingCompletedSuccess
  constructor(public payload: { onboardingCompletedResponse: OnboardingCompletedResponse }) {}
}

export class InitIsOnboardingCompletedFailed implements Action {
  readonly type = SetupActionTypes.InitIsOnboardingCompletedFailed
}

export type SetupActions =
  | SetStepperActiveIndex
  | CompleteSetup
  | SetPendingUsers
  | SetStepLoading
  | SetFinalizeLoading
  | SetCurrentFormValid
  | ResetCurrentState
  | InitOnboardingDetails
  | InitOnboardingDetailsSuccess
  | InitOnboardingDetailsFailed
  | PutOnboardingDetails
  | PutOnboardingDetailsSuccess
  | PutOnboardingDetailsFailed
  | SkipLocation
  | SetIsIntegrationsOnboarding
  | InitIsOnboardingCompleted
  | InitIsOnboardingCompletedSuccess
  | InitIsOnboardingCompletedFailed
