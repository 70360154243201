<section>
  <tc-working-spinner [message]="message$ | async" [opened]="working$ | async">
  </tc-working-spinner>
  <tc-latest-terms-of-service class="margin_sm"></tc-latest-terms-of-service>
  <div>
    <tc-accept-tos-form (acceptChange)="agreedToTermsOfService($event)"></tc-accept-tos-form>
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1rem">
      <button
        data-cy="acceptTosButton"
        [disabled]="(agreeCheckValue$ | async) === false"
        mat-button
        tcColor="success"
        (click)="acceptLatestTermsOfService()"
        tcColorDark
      >
        ACCEPT
      </button>
    </div>
  </div>
</section>
