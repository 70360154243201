import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { Observable } from 'rxjs'

import { AddPaAttestationDialog } from '../dialog/add-pa-attestation/add-pa-attestation.dialog'
import { IPaAttestation } from '../models/pa-attestation.model'
import { PaAdapterUtility } from '../utilities/pa-adapter.utility'

@Injectable({
  providedIn: 'root',
})
export class AttestationService {
  helper = new PaAdapterUtility()
  private bottomSheetRef: MatBottomSheetRef

  constructor(
    private http: HttpClient,
    private bottomSheet: MatBottomSheet
  ) {}

  public submitPaAttestation(paAttestation: IPaAttestation): Observable<any> {
    return this.http.post('api/messages/attest/pa-1843', this.helper.mapFormToApi(paAttestation))
  }

  public openPaAttestationDialog(): void {
    this.bottomSheetRef = this.bottomSheet.open(AddPaAttestationDialog)
  }

  public closePaAttestationDialog(): void {
    if (this.bottomSheetRef) {
      this.bottomSheetRef.dismiss()
    }
  }
}
