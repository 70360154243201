import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { tap } from 'rxjs/operators'
import { LoginEnum } from 'src/app/enums/login.enum'
import * as userSelector from 'src/app/user/selectors/user.selectors'
import { State as UserState } from 'src/app/user/user.state'

@Component({
  templateUrl: './start.page.html',
  styleUrls: ['./start.page.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartPage implements OnDestroy {
  sub: Subscription
  constructor(
    private router: Router,
    private userStore: Store<UserState>
  ) {
    this.sub = this.userStore
      .select(userSelector.selectLoginStatus)
      .pipe(
        tap((login) => {
          if (login === LoginEnum.LoggedOut) {
            router.navigate(['/home'])
            return
          }
          router.navigate(['/sign-in'])
        })
      )
      .subscribe()
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }
}
