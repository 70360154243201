export enum FeatureKeys {
  Authorize = 'authorize',
  Mail = 'mails',
  Contacts = 'contacts',
  Account = 'account',
  Locations = 'locations',
  AppStatus = 'appStatus',
  Route = 'route',
  PaymentMethods = 'payment-methods',
  PaymentMethodsNew = 'payment-methods-new',
  Transactions = 'transactions',
  MerchantStores = 'merchant-stores',
  UsStates = 'us-states',
  Notification = 'notifications',
  Billing = 'billing',
  Setup = 'setup',
  DownloadCenter = 'download-center',
}
