<section>
  <tc-working-spinner [message]="'searching...'" [opened]="working$ | async"> </tc-working-spinner>

  <div class="margin_sm">
    <div fxLayout="column" fxLayoutAlign="start center">
      <form [formGroup]="formGroup" style="max-width: 400px">
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Support Category</mat-label>
          <mat-select formControlName="category">
            <mat-option *ngFor="let option of options" [value]="option.value">
              {{option.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Search</mat-label>
          <input matInput type="text" formControlName="search" />
        </mat-form-field>
      </form>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
        <tc-dev-docs-button></tc-dev-docs-button>
      </div>
    </div>

    <ng-container *ngIf="category.value !=='all' || search.value; then showSearch else showAll">
    </ng-container>

    <ng-template #showSearch>
      <div data-cy="showSearch">
        <tc-support-category
          [title]="(search.value.length > 0) ? 'Search: '+search.value : categoryName "
          [category]="category"
          [pages]="sortedPages$ | async"
          [shell]="shell$ | async"
        >
        </tc-support-category>
      </div>
    </ng-template>

    <ng-template #showAll>
      <div
        data-cy="showBrowse"
        fxLayout="row wrap"
        fxLayoutAlign="space-between stretch"
        fxLayoutGap="1rem"
      >
        <div *ngFor="let page of allPages$ | async" fxFlex="100%" fxFlex.gt-sm="31%">
          <tc-support-category
            data-cy="allCategoryItem"
            [title]="page[0]"
            [maxShow]="4"
            [category]="page[1]"
            [pages]="page[2]"
            [shell]="shell$ | async"
          >
          </tc-support-category>
        </div>
      </div>
    </ng-template>
  </div>
</section>
