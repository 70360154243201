import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ListSupportPage } from '../pages/list-support/list-support.page'
import { SupportDetailPage } from '../pages/support-detail/support-detail.page'

const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: '',
      title: 'Knowledge Base',
      description: 'Get the most out of TaxCloud',
    },
    children: [
      {
        path: '',
        data: {
          breadcrumb: '',
        },
        component: ListSupportPage,
      },
      {
        path: ':slug',
        data: {
          breadcrumb: 'details',
        },
        component: SupportDetailPage,
      },
    ],
  },
]

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SupportRoutesModule {}
