import { DataSourceEnum } from '../enums/dataSource.enums'
import { LoginTypeEnum } from '../enums/login.enum'
import { UserDb } from './db/user.db'
import { IAuth } from './models/iauth'
import { IPasswordReset } from './models/ipassword-reset.model'
import { IUser } from './models/iuser'

const userDb = new UserDb()

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface State {
  user?: IUser
  auth?: IAuth
  error?: string
  working: string
  isLoading: boolean
  dataSource: DataSourceEnum
  loginType: LoginTypeEnum
  passwordReset?: IPasswordReset
  emailToVerify?: string
}

export const initialState: State = {
  isLoading: false,
  auth: userDb.getAuth(),
  user: userDb.getUser(),
  working: '',
  dataSource: DataSourceEnum.Local,
  loginType: LoginTypeEnum.Default,
}
