<div class="margin_l_sm" fxLayout="row wrap" fxLayoutAlign="space-between start" fxLayoutGap="1rem">
  <div class="mat-display-3">Currently Collecting Sales Tax</div>
  <tc-refresh-us-states-button></tc-refresh-us-states-button>
</div>

<div
  fxLayout="row"
  fxLayoutGap="1rem"
  fxLayoutAlign="space-around"
  fxLayout.lt-md="column-reverse"
  fxLayoutAlign.lt-md="flex-start"
>
  <div fxFlex="70" fxFlex.lt-md="100">
    <ng-container *ngIf="isWorking$ | async; else showMap">
      <div fxLayout="row" fxLayoutAlign="center center" style="min-height: 400px">
        <mat-spinner color="primary"></mat-spinner>
      </div>
    </ng-container>

    <ng-template #showMap>
      <tc-us-map
        [selectedStates]="collectingUsStates$ | async"
        [disableHover]="true"
        (clickState)="clickState($event)"
      >
      </tc-us-map>
    </ng-template>
  </div>
  <div
    class="margin_t_sm"
    fxLayout="column"
    fxLayout.lt-md="row wrap"
    fxLayoutAlign.lt-md="space-around"
    fxLayoutGap="1rem"
  >
    <div class="mat-display-1">Collecting Type</div>
    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="1rem">
      <div class="square" [style.backgroundColor]="collectingColor"></div>
      <div>Collecting</div>
    </div>

    <ng-container
      *ngIf="isAutomatedServicePlan$ | async; then isAutomated; else isSelective"
    ></ng-container>

    <ng-template #isAutomated>
      <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="1rem">
        <div class="square" [style.backgroundColor]="notCollectingColor"></div>
        <div>Below Threshold</div>
      </div>
    </ng-template>

    <ng-template #isSelective>
      <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="1rem">
        <div class="square" [style.backgroundColor]="notCollectingColor"></div>
        <div>Not Collecting</div>
      </div>
    </ng-template>

    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="1rem">
      <div class="square" [style.backgroundColor]="needToCollect"></div>
      <div>Need To Collect</div>
    </div>
  </div>
</div>
