<div class="flex flex-column align-items-center">
  <i
    *ngIf="iconType === 'basic'"
    class="pi {{ primeIcon }} text-4xl"
    [ngClass]="severity === 'error' ? 'text-red-500' : 'text-green-500'"
  ></i>
  <div
    *ngIf="iconType === 'emoji'"
    class="flex justify-content-center align-items-center w-3rem h-3rem border-circle text-2xl"
    [ngClass]="severity === 'error' ? 'bg-red-100' : 'bg-green-100'"
  >
    {{ emojiIcon }}
  </div>

  <h1 class="font-medium text-2xl my-3 line-height-2">{{ title }}</h1>

  <p class="m-0 text-center text-sm">
    <ng-container [ngTemplateOutlet]="body"></ng-container>
  </p>

  <div *ngIf="footer" class="w-full">
    <p-divider></p-divider>
    <ng-container [ngTemplateOutlet]="footer"></ng-container>
  </div>
</div>
