import { Directive, ElementRef, Input, OnInit } from '@angular/core'
import { FeatureFlagService } from 'src/app/root/services/feature-flag.service'

@Directive({
  selector: '[tcRemoveIfFeatureOff]',
})
export class FeatureOffDirective implements OnInit {
  @Input('tcRemoveIfFeatureOff') featureName: string

  constructor(
    private el: ElementRef,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() {
    if (this.featureFlagService.featureOff(this.featureName)) {
      this.el.nativeElement.parentNode.removeChild(this.el.nativeElement)
    }
  }
}
