import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { filter, first, map, pluck, tap } from 'rxjs/operators'
import * as fromAccount from 'src/app/account/account.state'
import * as fromAccountSelectors from 'src/app/account/selectors/account.selectors'
import { State as AppStatusState } from 'src/app/app-status/app-status.state'
import { selectIsEmbedded } from 'src/app/app-status/selectors/app-status.selectors'
import { AccountStateEnum } from 'src/app/enums/account.enums'
import { LoginEnum } from 'src/app/enums/login.enum'
import { SupportCategoryEnum, SupportCategoryEnumLabel } from 'src/app/enums/support.enums'
import * as fromTransactionActions from 'src/app/transaction/actions/transaction.actions'
import { State as TransactionState } from 'src/app/transaction/transaction.store'
import { InitStatesGetStartedShell } from 'src/app/us-states/actions/us-states.actions'
import { USStateService } from 'src/app/us-states/services/us-state.service'
import { State as UsStateState } from 'src/app/us-states/us-states.store'
import { Logout } from 'src/app/user/actions/login.actions'
import * as userSelector from 'src/app/user/selectors/user.selectors'
import * as fromUserSelector from 'src/app/user/selectors/user.selectors'
import { State as UserState } from 'src/app/user/user.state'
import { getOptionsFromLabel, IOption } from 'src/app/utilities/select-box.utilities'
import { SubSink } from 'subsink'
import { environment } from 'src/environments/environment'

interface ILayout {
  mode: string
  disableClose: boolean
  opened: boolean
}

@Component({
  templateUrl: './shell-get-started.component.html',
  styleUrls: ['./shell-get-started.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellGetStartedComponent implements OnInit, OnDestroy {
  layout$: BehaviorSubject<ILayout> = new BehaviorSubject({
    mode: 'side',
    disableClose: false,
    opened: false,
  })

  options: IOption[] = getOptionsFromLabel(SupportCategoryEnumLabel)
  subs = new SubSink()
  public publicSiteUrl = environment.publicSiteUrl

  constructor(
    private router: Router,
    private userStore: Store<UserState>,
    private breakpointObserver: BreakpointObserver,
    private usStateService: USStateService,
    private transactionStore: Store<TransactionState>,
    private usStateStore: Store<UsStateState>,
    private accountStore: Store<fromAccount.State>,
    private appStatusStore: Store<AppStatusState>
  ) {
    this.usStateService.getUsStatesFromPublic().pipe(first()).subscribe()
  }

  isSmallPage$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium])
    .pipe(
      pluck('matches'),
      map((match) => !match)
    )

  isSignedIn$: Observable<boolean> = this.userStore
    .select(userSelector.selectLoginStatus)
    .pipe(map((status) => status === LoginEnum.LoggedIn))

  public isEmbeddedApp$: Observable<boolean> = this.appStatusStore.select(selectIsEmbedded)

  public businessName$: Observable<string> = this.accountStore.select(
    fromAccountSelectors.selectBusinessName
  )

  public merchantId$: Observable<number> = this.accountStore.select(
    fromAccountSelectors.selectMerchantId
  )

  public isMasquerading$: Observable<boolean> = this.userStore.select(
    fromUserSelector.selectUserIsMasquerading
  )

  accountStatus$: Observable<AccountStateEnum> = this.accountStore.select(
    fromAccountSelectors.selectAccountStatus
  )

  status$: Observable<LoginEnum> = this.userStore.select(fromUserSelector.selectLoginStatus)

  public isLoggedIn$: Observable<boolean> = this.status$.pipe(
    map((status) => status === LoginEnum.LoggedIn)
  )

  ngOnInit(): void {
    this.subs.add(
      combineLatest([this.accountStatus$, this.isLoggedIn$])
        .pipe(
          filter(([accountStatus$, isLoggedIn]) => !!isLoggedIn),
          map(([accountStatus$, isLoggedIn]) => accountStatus$),
          tap((accountStatus$) => {
            this.transactionStore.dispatch(
              new fromTransactionActions.UpdateFiltersShell({ filter: { isTest: true } })
            )
          }),
          // tap(_ => this.notificationStore.dispatch(new InitNotifications())),
          tap((_) => this.usStateStore.dispatch(new InitStatesGetStartedShell()))
        )
        .subscribe()
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  public toggle(): void {
    const current: ILayout = this.layout$.value
    if (current.disableClose) {
      this.layout$.next({ ...current, opened: true })
    } else {
      this.layout$.next({ ...current, opened: !current.opened })
    }
  }

  public goHome(): void {
    this.router.navigate(['/'])
  }

  public signOut(): void {
    this.userStore.dispatch(new Logout())
  }

  public goToWelcome(): void {
    this.router.navigate(['/get-started'])
  }

  public goToSetUp(): void {
    this.router.navigate(['/get-started/set-up'])
  }

  public goToPricing(): void {
    const url = new URL(`${environment.publicSiteUrl}/pricing`)
    window.open(url.toString(), '_blank')
  }

  public goToSupport(): void {
    const url = new URL(`${environment.publicSiteUrl}/support`)
    window.open(url.toString(), '_blank')
  }
}
