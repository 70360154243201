import { adapter } from '../mail.store'
import * as fromMail from '../mail.store'
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store'
import { FeatureKeys } from '../../enums/store.enums'
import { IMailFilter } from '../models/imailFilter'
import M from 'minimatch'
// import { State } from './../mail.store';

const getFilteredIds = (state: fromMail.State): string[] => {
  if (!state.filteredIds) {
    return []
  }
  return state.filteredIds
}

const getListViewLoaded = (state: fromMail.State): boolean => {
  return state.listLoaded
}

const getMailFilters = (state: fromMail.State): IMailFilter => {
  return state.filter
}

const getNextPage = (state: fromMail.State): number => {
  return state.nextPage
}

const getHasNext = (state: fromMail.State): boolean => {
  return state.hasNext
}

const getFilterAndPage = (state: fromMail.State): [IMailFilter, number] => {
  return [state.filter, state.nextPage]
}

const getMailIsLoading = (state: fromMail.State): boolean => {
  return state.isLoading
}

export const selectMailState: MemoizedSelector<object, fromMail.State> =
  createFeatureSelector<fromMail.State>(FeatureKeys.Mail)

export const selectMailFilter: MemoizedSelector<object, IMailFilter> = createSelector(
  selectMailState,
  getMailFilters
)

export const selectFilteredIds: MemoizedSelector<object, string[]> = createSelector(
  selectMailState,
  getFilteredIds
)

export const selectListDataLoaded: MemoizedSelector<object, boolean> = createSelector(
  selectMailState,
  getListViewLoaded
)

export const selectNextPage: MemoizedSelector<object, number> = createSelector(
  selectMailState,
  getNextPage
)

export const selectHasNextPage: MemoizedSelector<object, boolean> = createSelector(
  selectMailState,
  getHasNext
)

export const selectFilterAndNextPage: MemoizedSelector<object, [IMailFilter, number]> =
  createSelector(selectMailState, getFilterAndPage)

export const selectGetMailIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectMailState,
  getMailIsLoading
)

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors<fromMail.State>(selectMailState)
