import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { merge, Observable, of } from 'rxjs'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'
import { State as AppStatusState } from 'src/app/app-status/app-status.state'
import * as appStatusSelectors from 'src/app/app-status/selectors/app-status.selectors'
import { CmsPageTypeEnum } from 'src/app/enums/cms.enums'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'
import { SupportCategoryEnum } from 'src/app/enums/support.enums'
import { CmsService } from 'src/app/root/services/cms.service'
import { ISupportPage, ISupportPageAll } from 'src/app/support/models/isupport-page'

import { SupportDatabase } from '../db/support.db'
import { SupportAdapter } from '../utilities/support-adapter.utility'

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  private adapter = new SupportAdapter()
  private db = new SupportDatabase()
  private sessionCache: Map<SupportCategoryEnum, ISupportPage[]> = new Map()

  constructor(
    private http: HttpClient,
    private cmsService: CmsService,
    private appStatus: Store<AppStatusState>
  ) {}

  public getAllPages(): Observable<Map<SupportCategoryEnum, ISupportPage[]>> {
    // if return to page and has session cache, return session cache
    if (this.sessionCache.size > 0) {
      return of(this.sessionCache)
    }

    return merge(this.getPagesFromDb(), this.getPagesFromCmsIfOnline()).pipe(
      filter(([source, _]) => {
        if (source === DataSourceEnum.API) {
          return true
        }
        return this.sessionCache.size === 0
      }),
      map(([_, pages]) => pages),
      tap((pages) => (this.sessionCache = pages))
    )
  }

  public getPagesByCategory(category: SupportCategoryEnum): Observable<ISupportPage[]> {
    return merge(
      this.getCategoryIfOnline(category)
      // this.getCategoryFromDb(category)
    ).pipe(map(([source, pages]) => pages))
  }

  public getPage(slug: string): Observable<ISupportPage> {
    return merge(
      this.getPageFromCmsIfOnline(slug)
      // this.getPageFromDb(slug)
    ).pipe(
      map(([source, pages]) => pages),
      catchError(() => {
        const page: ISupportPage = {
          category: SupportCategoryEnum.All,
          fields: {
            body: '',
            highlight: false,
            is_new: false,
          },
          name: 'Missing Page',
          slug: '',
          type: CmsPageTypeEnum.Missing,
        }
        return of(page)
      })
    )
  }

  public searchSupport(search: string = ''): Observable<ISupportPage[]> {
    const allpages: ISupportPage[][] = []

    Array.from(this.sessionCache.values()).forEach((category) => {
      allpages.push(category)
    })

    const flattened = allpages.reduce((acc, val) => acc.concat(val), [])
    const deduplicated = Array.from(new Map(flattened.map((item) => [item.slug, item])).values())

    const filtered = deduplicated.filter((page) => {
      // guard for any nulls
      if (!page.name) {
        return false
      }
      if (!page.fields) {
        return false
      }
      if (!page.fields.body) {
        return false
      }

      return this.searchEach(search, page)
    })

    return of(filtered)
  }

  private getPagesFromDb(): Observable<[DataSourceEnum, Map<SupportCategoryEnum, ISupportPage[]>]> {
    return this.db.getAllPageAsMap().pipe(map((pages) => [DataSourceEnum.IndexedDB, pages]))
  }

  private getPagesFromCmsIfOnline(): Observable<
    [DataSourceEnum, Map<SupportCategoryEnum, ISupportPage[]>]
  > {
    return this.appStatus.select(appStatusSelectors.selectOnline).pipe(
      filter((online) => !!online),
      switchMap(() => this.getAllPagesFromCms()),
      map((pages) => [DataSourceEnum.API, pages])
    )
  }

  private getAllPagesFromCms(): Observable<Map<SupportCategoryEnum, ISupportPage[]>> {
    return this.cmsService.getPages<ISupportPageAll>(CmsPageTypeEnum.Support).pipe(
      map((pages: ISupportPageAll[]) => this.adapter.mapAllPagesToSupportCache(pages)),
      tap((mappedPages) => {
        const pages: ISupportPage[] = []
        mappedPages.forEach((category) => category.forEach((page) => pages.push(page)))
        this.db.savePages(pages)
      })
    )
  }

  private getCategoryFromDb(
    category: SupportCategoryEnum
  ): Observable<[DataSourceEnum, ISupportPage[]]> {
    return this.db
      .getPagesFromCategory(category)
      .pipe(map((pages) => [DataSourceEnum.IndexedDB, pages]))
  }

  private getCategoryIfOnline(
    category: SupportCategoryEnum
  ): Observable<[DataSourceEnum, ISupportPage[]]> {
    return this.appStatus.select(appStatusSelectors.selectOnline).pipe(
      filter((online) => !!online),
      switchMap(() => this.getCategoryFromCms(category)),
      map((pages) => [DataSourceEnum.API, pages])
    )
  }

  private getCategoryFromCms(category: SupportCategoryEnum): Observable<ISupportPage[]> {
    const field = `fields.support_category.category=${category}&`
    return this.cmsService.getPages<ISupportPage>(CmsPageTypeEnum.Support, field)
  }

  private getPageFromCmsIfOnline(slug: string): Observable<[DataSourceEnum, ISupportPage]> {
    return this.appStatus.select(appStatusSelectors.selectOnline).pipe(
      filter((online) => !!online),
      switchMap(() => this.getPageFromCms(slug)),
      map((page) => [DataSourceEnum.API, page])
    )
  }

  private getPageFromCms(slug: string): Observable<ISupportPage> {
    return this.cmsService.getPage<ISupportPage>(slug, CmsPageTypeEnum.Support)
  }

  private getPageFromDb(slug: string): Observable<[DataSourceEnum, ISupportPage]> {
    return this.db.getPage(slug).pipe(map((page) => [DataSourceEnum.IndexedDB, page]))
  }

  private searchEach(search: string, page: ISupportPage) {
    const words = search.split(' ')
    // find all instances of all words
    const regex = new RegExp(`\\b(?:${words.join('|')})\\b`, 'gi')
    const text = `${page.name} ${page.fields.body} ${page.type}`.toLowerCase()
    const match = text.match(regex) || []

    // if each word is found
    return new Set(match).size === new Set(words).size
  }
}
