import { Update } from '@ngrx/entity'
import { Action } from '@ngrx/store'
import { TransactionStateEnum, TransactionUpdateEnum } from 'src/app/enums/transaction.enums'
import { IMerchantStore } from 'src/app/merchant-store/models/imerchant-store.model'

import { ITransactionFilter } from '../models/itransaction-filter.model'
import { ITransaction } from './../models/itransaction.model'

export enum TransactionActionTypes {
  UpdateTransactionDetail = '[Transaction List Page] Update transaction detail',
  UpdateTransactionDetailSuccess = '[Transaction Effects] Update Transaction',

  InitTransactions = '[Go Shell] Init transactions',
  InitTransactionsSuccess = '[Transaction Effects] Init transactions success',

  InitTransactionsFromMasquerade = '[Login Effects] init transactions on masquerade',

  InitTestTransactions = '[Get Started Shell] Init with test transactions',
  InitTestTransactionsSuccess = '[Transaction Effects] Init test transactions success',

  RefreshTransactions = '[List Transaction] Init refresh Transactions',
  RefreshTransactionsSuccess = '[Transaction Effect] Refresh transactions success',

  UpdateFilters = '[Transaction Filter Component] Update filters',
  UpdateFiltersGetStarted = '[Get Started Test Component] Update filters to preview test transactions',
  UpdateFiltersShell = '[Shell Page] Save filters',

  SkipGetFilteredTransactions = '[Transaction Effects] Skip getting transactions (not different filters)',
  GetFilteredTransactions = '[Transaction Effects] Get filtered transactions',
  GetFilteredTransacgtionsSuccess = '[Transaction Effects] Git filtered transactions success',

  LoadAdditionalTransactions = '[Transaction List Page] Load more tansactions',
  LoadAdditionalTransactionsSuccess = '[Transaction Effects] Load more tansactions success',

  SaveLastUpdated = '[Transaction Effects] Save last time SQL transactions table was updated',

  DownloadTransactions = '[Transaction List Page] Download filtered transactions',
  DownloadTransactionsComplete = '[Transaction Effects] Download transactions completed',

  ReturnTransaction = '[Transaction Detail Page] Return entire order',
  CaptureTransaction = '[Transaction Detail Page] Capture transaction',
  TransactionChanged = '[Transaction Effects] A transaction has changed',
  ClearTransactions = '[Transaction Service] Clear Transactions',
  ImportTransactions = '[Import Store Button] import transactions',
  ImportTransactionsSuccess = '[Store Effects] import transactions success',
}

export class UpdateTransactionDetail implements Action {
  readonly type = TransactionActionTypes.UpdateTransactionDetail
  constructor(public payload: { id: string }) {}
}

export class UpdateTransactionDetailSuccess implements Action {
  readonly type = TransactionActionTypes.UpdateTransactionDetailSuccess
  constructor(public payload: { transaction: Update<ITransaction> }) {}
}

export class InitTransactions implements Action {
  readonly type = TransactionActionTypes.InitTransactions
}

export class InitTransactionsSuccess implements Action {
  readonly type = TransactionActionTypes.InitTransactionsSuccess
  constructor(
    public payload: {
      transactions: ITransaction[]
      message: any
      filingIds: string[]
      complete: boolean
    }
  ) {}
}

export class InitTestTransactions implements Action {
  readonly type = TransactionActionTypes.InitTestTransactions
}

export class InitTestTransactionsSuccess implements Action {
  readonly type = TransactionActionTypes.InitTestTransactionsSuccess
  constructor(
    public payload: {
      transactions: ITransaction[]
      message: any
      filingIds: string[]
      complete: boolean
    }
  ) {}
}

export class UpdateFilters implements Action {
  readonly type = TransactionActionTypes.UpdateFilters

  constructor(public payload: { filter: ITransactionFilter }) {}
}

export class UpdateFiltersGetStarted implements Action {
  readonly type = TransactionActionTypes.UpdateFiltersGetStarted

  constructor(public payload: { filter: ITransactionFilter }) {}
}

export class UpdateFiltersShell implements Action {
  readonly type = TransactionActionTypes.UpdateFiltersShell

  constructor(public payload: { filter: ITransactionFilter }) {}
}

export class RefreshTransactions implements Action {
  readonly type = TransactionActionTypes.RefreshTransactions
}

export class RefreshTransactionsSuccess implements Action {
  readonly type = TransactionActionTypes.RefreshTransactionsSuccess
  constructor(
    public payload: {
      transactions: ITransaction[]
      message: any
      filingIds: string[]
      complete: boolean
    }
  ) {}
}

export class SaveLastUpdated implements Action {
  readonly type = TransactionActionTypes.SaveLastUpdated
  constructor(public payload: { lastUpdated: string }) {}
}

export class GetFilteredTransactions implements Action {
  readonly type = TransactionActionTypes.GetFilteredTransactions

  constructor(public payload: { filter: ITransactionFilter }) {}
}

export class LoadAdditionalTransactions implements Action {
  readonly type = TransactionActionTypes.LoadAdditionalTransactions
}

export class LoadAdditionalTransactionsSuccess implements Action {
  readonly type = TransactionActionTypes.LoadAdditionalTransactionsSuccess
  constructor(
    public payload: {
      transactions: ITransaction[]
      message: any
      filingIds: string[]
      complete: boolean
    }
  ) {}
}

export class ImportTransactions implements Action {
  readonly type = TransactionActionTypes.ImportTransactions
  constructor(public payload: { merchantStore: IMerchantStore }) {}
}

export class ImportTransactionsSuccess implements Action {
  readonly type = TransactionActionTypes.ImportTransactionsSuccess
}

export class DownloadTransactions implements Action {
  readonly type = TransactionActionTypes.DownloadTransactions
  constructor(public payload: { isSummary: boolean; isConsolidated?: boolean }) {}
}

export class DownloadTransactionsComplete implements Action {
  readonly type = TransactionActionTypes.DownloadTransactionsComplete
}

export class CaptureTransaction implements Action {
  readonly type = TransactionActionTypes.CaptureTransaction

  constructor(public payload: { transactionId: string; cartId: string; useLookupDate: boolean }) {}
}

export class ReturnTransaction implements Action {
  readonly type = TransactionActionTypes.ReturnTransaction

  constructor(public payload: { transactionId: string; returnedDate: string }) {}
}

export class TransactionChanged implements Action {
  readonly type = TransactionActionTypes.TransactionChanged
  constructor(
    public payload: { id: string; type: TransactionStateEnum; result: TransactionUpdateEnum }
  ) {}
}

export class GetFilteredTransacgtionsSuccess implements Action {
  readonly type = TransactionActionTypes.GetFilteredTransacgtionsSuccess
  constructor(
    public payload: {
      transactions: ITransaction[]
      message: any
      filingIds: string[]
      complete: boolean
    }
  ) {}
}

export class SkipGetFilteredTransactions implements Action {
  readonly type = TransactionActionTypes.SkipGetFilteredTransactions
}

export class ClearTransactions implements Action {
  readonly type = TransactionActionTypes.ClearTransactions
}

export type TransactionActions =
  | UpdateTransactionDetail
  | UpdateTransactionDetailSuccess
  | InitTransactions
  | InitTransactionsSuccess
  | InitTestTransactions
  | InitTestTransactionsSuccess
  | UpdateFilters
  | UpdateFiltersGetStarted
  | UpdateFiltersShell
  | GetFilteredTransactions
  | RefreshTransactions
  | RefreshTransactionsSuccess
  | SaveLastUpdated
  | SkipGetFilteredTransactions
  | ImportTransactions
  | ImportTransactionsSuccess
  | DownloadTransactions
  | DownloadTransactionsComplete
  | LoadAdditionalTransactions
  | LoadAdditionalTransactionsSuccess
  | TransactionChanged
  | ReturnTransaction
  | GetFilteredTransacgtionsSuccess
  | ClearTransactions
