import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { DataSourceEnum } from '../enums/dataSource.enums'
import { IMerchantStore } from './models/imerchant-store.model'

export const adapter: EntityAdapter<IMerchantStore> = createEntityAdapter<IMerchantStore>({
  selectId: (model) => model.id,
  sortComparer: (a: IMerchantStore, b: IMerchantStore): number => {
    const aName = a.name
      ? `${a.isDisabled ? '' : a.isLive ? '  ' : ' '}${a.name.trim().toString().toLowerCase()}`
      : ''
    const bName = b.name
      ? `${b.isDisabled ? '' : b.isLive ? '  ' : ' '}${b.name.trim().toString().toLowerCase()}`
      : ''
    return aName.localeCompare(bName)
  },
})

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface State extends EntityState<IMerchantStore> {
  initialLoadComlete: boolean
  source: DataSourceEnum
  working: string
}

export const initialState: State = adapter.getInitialState({
  initialLoadComlete: false,
  source: DataSourceEnum.Local,
  working: '',
})
