import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { BehaviorSubject, merge, Observable, of } from 'rxjs'
import { filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import * as fromAppStatus from 'src/app/app-status/app-status.state'
import * as fromAppStatusSelectors from 'src/app/app-status/selectors/app-status.selectors'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'

import { TaxFundingDatabase } from '../db/tax-funding.db'
import { IApiTaxFunding } from '../models/iapi-tax-funding.models'
import { ITaxFunding } from '../models/itax-funding.models'
import { TaxFundingAdapter } from '../utilities/tax-funding.adapter'

@Injectable({
  providedIn: 'root',
})
export class TaxFundingService {
  private fundingAdapter = new TaxFundingAdapter()
  private fundingDb = new TaxFundingDatabase()
  private dataSource = new BehaviorSubject<DataSourceEnum>(DataSourceEnum.Local)
  public taxFundings = new BehaviorSubject<ITaxFunding[]>(null)
  public initiated = new BehaviorSubject<boolean>(false)

  constructor(
    private http: HttpClient,
    private appStatus: Store<fromAppStatus.State>
  ) {}

  public initTaxFundings(): Observable<boolean> {
    return this.initiated.pipe(
      filter((initiated) => !initiated),
      switchMap((_) => this.getTaxFundings()),
      tap((funding) => this.taxFundings.next(funding)),
      map((_) => true)
    )
  }

  private getTaxFundings(): Observable<ITaxFunding[]> {
    return merge(this.getTaxFundingFromApiWhenOnline(), this.getTaxFundingFromLocal()).pipe(
      tap(([funding, source]) => this.dataSource.next(source)),
      tap(([funding, source]) => {
        if (source === DataSourceEnum.API) {
          this.initiated.next(true)
        }
      }),
      map(([funding, source]) => funding)
    )
  }

  public getTaxFunding(id: string): Observable<ITaxFunding> {
    return this.fundingDb.getEvent(id).pipe(
      switchMap((fundingEvent) => {
        if (fundingEvent) {
          return of(fundingEvent)
        }
        return this.getTaxFundingFromApi().pipe(
          map(([fundingEvents, _]) => fundingEvents.find((event) => event.id === id))
        )
      })
    )
  }

  public deleteCache(): void {
    this.fundingDb.clearDatabase().pipe(first()).subscribe()
    this.taxFundings.next(null)
  }

  private getTaxFundingFromApiWhenOnline(): Observable<[ITaxFunding[], DataSourceEnum]> {
    return this.appStatus.select(fromAppStatusSelectors.selectOnline).pipe(
      filter((online) => online),
      switchMap(() => this.getTaxFundingFromApi())
    )
  }

  private getTaxFundingFromApi(): Observable<[ITaxFunding[], DataSourceEnum]> {
    return this.http.get(`api/finance-tools/funding-details?fundingSource=0`).pipe(
      map((results: IApiTaxFunding[]) =>
        results.map((result) => this.fundingAdapter.mapApiTaxFundingToTaxFunding(result))
      ),
      tap((results) => this.fundingDb.saveEvents(results)),
      map((fundings) => [fundings, DataSourceEnum.API])
    )
  }

  private getTaxFundingFromLocal(): Observable<[ITaxFunding[], DataSourceEnum]> {
    return this.fundingDb.getEvents().pipe(
      withLatestFrom(this.dataSource),
      filter(([fundings, source]) => {
        if (source === DataSourceEnum.API) {
          return false
        }
        if (fundings.length === 0) {
          return false
        }
        return true
      }),
      map(([fundings, _]) => [fundings, DataSourceEnum.IndexedDB])
    )
  }
}
