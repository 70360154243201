import { IMerchantReportDownload } from './models/imerchant-report-downloads.model'

export interface DownloadCenterState {
  reports: IMerchantReportDownload[]
  working: boolean
}

export const initialState: DownloadCenterState = {
  reports: [],
  working: true,
}
