import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'tc-shell-setup',
  templateUrl: './shell-setup.component.html',
  styleUrls: ['./shell-setup.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellSetupComponent implements OnInit {
  public publicSiteUrl = environment.publicSiteUrl

  constructor() {}

  ngOnInit(): void {
    document.body.style.backgroundImage = null
    document.body.style.backgroundSize = null
  }

  public goHome(): void {
    const url = new URL(`${this.publicSiteUrl}`)
    window.open(url.toString(), '_self')
  }
}
