export enum BlogCategoryEnum {
  CartIntegration = 'integration',
  CompanyNews = 'company-news',
  PressRelease = 'press-release',
  StateNews = 'state-news',
  TaxChat = 'tax-chat',
  InternalNews = 'internal-news',
}

export const BlogCategoryEnumLabel = new Map<BlogCategoryEnum, string>([
  [BlogCategoryEnum.CartIntegration, 'Cart Integration'],
  [BlogCategoryEnum.CompanyNews, 'TaxCloud News'],
  [BlogCategoryEnum.PressRelease, 'TaxCloud Press Release'],
  [BlogCategoryEnum.StateNews, 'State News'],
  [BlogCategoryEnum.TaxChat, 'TaxCloud Chat'],
])
