import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core'
import { Store } from '@ngrx/store'
import { UpdateAppType } from 'src/app/app-status/actions/app-status.actions'
import { State as AppState } from 'src/app/app-status/app-status.state'
import { selectAppType } from 'src/app/app-status/selectors/app-status.selectors'
import { AppTypeEnum, AppTypeEnumLabel } from 'src/app/enums/app.enums'
import { getOptionsFromLabel, IOption } from 'src/app/utilities/select-box.utilities'

@Component({
  selector: 'tc-toggle-app-type',
  templateUrl: './toggle-app-type.component.html',
  styleUrls: ['./toggle-app-type.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleAppTypeComponent {
  options: IOption[] = getOptionsFromLabel(AppTypeEnumLabel)

  constructor(private store: Store<AppState>) {}

  @Output() appTypeChanged = new EventEmitter<AppTypeEnum>()

  appType$ = this.store.select(selectAppType)

  public toggleAppType(event) {
    const appType = event.value
    this.store.dispatch(new UpdateAppType({ appType }))
    this.appTypeChanged.emit(appType)
  }
}
