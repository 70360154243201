import { Component, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'tc-card-actions',
  templateUrl: './card-actions.component.html',
  styleUrls: ['./card-actions.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class CardActionsComponent {
  constructor() {}
}
