import { FundingSourceEnumLabel } from 'src/app/enums/funding.enums'
import {
  PaymentStatusEnum,
  PaymentStatusEnumAlert,
  PaymentStatusEnumIcon,
  PaymentStatusEnumLabel,
} from 'src/app/enums/invoice.enums'
import { validateDate } from 'src/app/utilities/date.utilities'

import { IApiServiceFee, ICategory } from '../models/iapi-service-fees.models'
import { IServiceFee, IServiceFeeItem } from '../models/iservice-fees.models'
import { DateFormatEnum } from 'src/app/enums/date.enums'
import moment from 'moment'

export class ServiceFeeAdapter {
  constructor() {}
  public mapApiServiceFeeToServiceFee(results: IApiServiceFee): IServiceFee {
    const { other, states, summary } = results
    const rejectedOn = validateDate(summary.rejectedOn)
    const resolvedOn = validateDate(summary.resolvedOn)
    const sentOn = validateDate(summary.sentOn)
    const submittedOn = validateDate(summary.submittedOn)
    const paymentStatus = this.parsePaymentState(!!submittedOn, !!resolvedOn, !!resolvedOn)

    const fee: IServiceFee = {
      amount: summary.amount,
      payment: {
        status: paymentStatus,
        label: PaymentStatusEnumLabel.get(paymentStatus),
        icon: PaymentStatusEnumIcon.get(paymentStatus),
        alert: PaymentStatusEnumAlert.get(paymentStatus),
      },
      convenienceFee: summary.convenienceFee,
      createdOn: validateDate(summary.createdOn),
      fundingSource: summary.fundingSource,
      fundingSourceDisplay: `...${summary.lastFour}`,
      fundingSourceLabel: FundingSourceEnumLabel.get(summary.fundingSource),
      id: summary.invoiceId,
      month: moment(summary.servicePeriod).format(DateFormatEnum.Month),
      period: summary.servicePeriod,
      rejectedOn,
      resolvedOn,
      sentOn,
      sentTo: summary.sentTo,
      serviceFees: other.categorized.map((item) => this.mapCategoryToItem(item)),
      usStates: states.categorized.map((item) => this.mapCategoryToItem(item)),
      summary: this.createSummaryArray(results),
      submittedOn,
      subtotal: summary.subtotal,
      tax: summary.tax,
      total: summary.total,
    }

    return fee
  }

  private parsePaymentState(
    submitted: boolean,
    resolved: boolean,
    rejected: boolean
  ): PaymentStatusEnum {
    if (resolved) {
      return PaymentStatusEnum.Resolved
    }
    if (rejected) {
      return PaymentStatusEnum.Rejected
    }
    return PaymentStatusEnum.Pending
  }

  private createSummaryArray(results: IApiServiceFee): IServiceFeeItem[] {
    const items: IServiceFeeItem[] = []
    const { summary, states, other } = results

    if (summary.convenienceFee !== 0) {
      items.push(this.createSummaryItem('Convience Fee', '', summary.convenienceFee))
    }

    if (summary.outstandingBalance !== 0) {
      items.push(this.createSummaryItem('Outstanding Balance', '', summary.outstandingBalance))
    }

    if (states.total !== 0 || states.amountAdjusted !== 0) {
      const isAdjusted = states.amountAdjusted !== 0

      if (!isAdjusted) {
        items.push(this.createSummaryItem('State Fees', '', states.subtotal))
      } else {
        items.push({
          icon: '',
          isAdjusted: true,
          adjusted: {
            amount: states.amountAdjusted,
            date: null,
            message: null,
          },
          amount: states.subtotal,
          name: 'State Fees',
          id: null,
          link: null,
          notes: null,
        })
      }
    }

    if (other.total !== 0 || other.amountAdjusted !== 0) {
      const isAdjusted = other.amountAdjusted !== 0
      if (!isAdjusted) {
        items.push(this.createSummaryItem('Service Fees', '', other.subtotal))
      } else {
        items.push({
          icon: '',
          isAdjusted: true,
          adjusted: {
            amount: other.amountAdjusted,
            date: null,
            message: null,
          },
          amount: other.subtotal,
          name: 'Service Fees',
          id: null,
          link: null,
          notes: null,
        })
      }
    }

    return items
  }

  private createSummaryItem(name: string, icon: string, amount: number): IServiceFeeItem {
    return {
      icon: '',
      isAdjusted: false,
      adjusted: {
        amount: null,
        date: null,
        message: null,
      },
      amount,
      name,
      id: null,
      link: null,
      notes: null,
    }
  }

  private mapCategoryToItem(category: ICategory): IServiceFeeItem {
    const adjustedOn = validateDate(category.amountAdjustedOn)
    const isAdjusted = adjustedOn ? true : false

    const item: IServiceFeeItem = {
      isAdjusted,
      adjusted: {
        amount: isAdjusted ? category.amountAdjusted : null,
        message: isAdjusted ? category.amountAdjustedBecauseDisplay : null,
        date: adjustedOn,
      },
      amount: category.amount,
      id: category.id,
      name: category.item,
      icon: category.categoryAbbr,
      link: category.url,
      notes: category.notes,
    }
    return item
  }
}
