import { ChangeDetectionStrategy, Component } from '@angular/core'
import { Store } from '@ngrx/store'
import { State } from 'src/app/us-states/us-states.store'

import { RefreshUsStates } from '../../actions/us-states.actions'

@Component({
  selector: 'tc-refresh-us-states-button',
  templateUrl: './refresh-us-states-button.component.html',
  styleUrls: ['./refresh-us-states-button.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefreshUsStatesButtonComponent {
  constructor(private usStateStore: Store<State>) {}

  public refreshUsStates(): void {
    this.usStateStore.dispatch(new RefreshUsStates())
  }
}
