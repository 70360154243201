import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { filter, first, map, tap } from 'rxjs/operators'
import { State as AccountState } from 'src/app/account/account.state'
import * as accountSelectors from 'src/app/account/selectors/account.selectors'
import { AlertTypeEnum } from 'src/app/enums/alert.enums'
import { LoginEnum } from 'src/app/enums/login.enum'
import * as userActions from 'src/app/user/actions/login.actions'
import * as userSelectors from 'src/app/user/selectors/user.selectors'
import { State as UserState } from 'src/app/user/user.state'

import { TosService } from '../../services/terms-of-service.service'

@Component({
  selector: 'tc-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsOfServiceComponent implements OnInit {
  private pdfBlob: Blob
  public pdf$ = new BehaviorSubject<string>(null)
  public type = AlertTypeEnum.Primary
  public height = '400px'
  public agreeCheckValue$ = new BehaviorSubject<boolean>(false)

  constructor(
    private tosService: TosService,
    private userStore: Store<UserState>,
    private accountStore: Store<AccountState>
  ) {}

  @Input('alertType')
  set setColor(color: AlertTypeEnum) {
    this.type = color
  }

  public acceptedTos$: Observable<boolean> = this.userStore.select(
    userSelectors.selectHasAcceptedTermsOfService
  )

  public isLoggedIn$: Observable<boolean> = this.userStore
    .select(userSelectors.selectLoginStatus)
    .pipe(map((status) => status === LoginEnum.LoggedIn))

  public needsToAcceptTos$: Observable<boolean> = combineLatest([
    this.isLoggedIn$,
    this.acceptedTos$,
  ]).pipe(map(([signedIn, hasAcceptedTos]) => signedIn && !hasAcceptedTos))

  public userName$: Observable<string> = combineLatest([
    this.acceptedTos$,
    this.userStore.select(userSelectors.selectFullName),
  ]).pipe(
    filter(([acceptedTos, _]) => !!acceptedTos),
    map(([_, user]) => user)
  )

  public businessName$: Observable<string> = combineLatest([
    this.acceptedTos$,
    this.accountStore.select(accountSelectors.selectBusinessName),
  ]).pipe(
    filter(([acceptedTos, _]) => !!acceptedTos),
    map(([_, businessName]) => businessName)
  )

  ngOnInit(): void {
    this.tosService
      .getTermsOfService()
      .pipe(
        first(),
        tap((file) => {
          if (file) {
            this.pdfBlob = new Blob([file], { type: 'application/pdf' })
            if (typeof FileReader !== 'undefined') {
              const reader = new FileReader()
              reader.onload = (e: any) => {
                this.pdf$.next(e.target.result)
              }
              reader.readAsArrayBuffer(this.pdfBlob)
            }
          }
        })
      )
      .subscribe()
  }

  public showPdfInNewWindow(): void {
    const fileURL = URL.createObjectURL(this.pdfBlob)
    window.open(fileURL)
  }

  public agreedToTermsOfService(value: boolean) {
    this.agreeCheckValue$.next(value)
  }

  public acceptTermsOfService(): void {
    this.userStore.dispatch(new userActions.AcceptTermsOfService())
  }
}
