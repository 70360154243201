<section class="pb-6">
  <p-card>
    <h1 class="my-0 text-3xl">Download Center</h1>
    <p class="mb-5 mt-1">Use this page to request and download reports</p>

    <p-panel header="Request report">
      <form [formGroup]="formGroup" class="formgrid grid gap-2 row-gap-3 py-4 lg:w-8">
        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <p-dropdown
              inputId="reportType"
              [options]="reportTypeOptions"
              formControlName="reportType"
              optionLabel="label"
              styleClass="w-full"
              placeholder="Select report"
            ></p-dropdown>
            <label for="reportType">Select report</label>
          </span>
          <div *ngIf="getValidationError('reportType') as error">
            <span class="text-xs text-red-600">Report type {{error}}</span>
          </div>
        </div>

        <div class="field col-12 md:col-4">
          <span class="p-float-label">
            <p-dropdown
              inputId="usState"
              [options]="usStateOptions"
              formControlName="usState"
              optionLabel="name"
              styleClass="w-full"
              placeholder="Select state"
            ></p-dropdown>
            <label for="usState">Select state</label>
          </span>
          <div *ngIf="getValidationError('usState') as error">
            <span class="text-xs text-red-600">State {{error}}</span>
          </div>
        </div>

        <div class="field col-12 md:col-6">
          <span class="p-float-label">
            <p-calendar
              inputId="downloadPeriodRange"
              selectionMode="range"
              formControlName="downloadPeriodRange"
              [readonlyInput]="true"
              styleClass="w-full"
              view="month"
              dateFormat="MM, yy"
            ></p-calendar>
            <label for="downloadPeriodRange">Date range</label>
          </span>
          <div *ngIf="getValidationError('downloadPeriodRange') as error">
            <span class="text-xs text-red-600">Date range {{error}}</span>
          </div>
        </div>

        <div class="field col-12 md:col-4">
          <p-button
            (click)="requestReport()"
            icon="pi pi-copy"
            iconPos="left"
            label="Request report"
          ></p-button>
        </div>
      </form>
    </p-panel>
  </p-card>

  <tc-working-spinner [message]="'loading'" [opened]="working$ | async"> </tc-working-spinner>

  <p-message
    severity="info"
    styleClass="my-4 w-full"
    text="Requests may take up to 24 hours to process."
  ></p-message>

  <p-card styleClass="table-card">
    <p-table
      [value]="(displayableReports | async)"
      [paginator]="true"
      [rows]="10"
      class="table"
      styleClass="p-datatable-sm"
      sortField="dateRequested"
      [sortOrder]="-1"
    >
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
          <p>Request History</p>
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-refresh"
            (click)="refresh()"
            class="p-button-rounded p-button-secondary p-button-text p-button-lg"
            pTooltip="Refresh History"
            tooltipPosition="bottom"
          ></button>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr class="table-header">
          <th pSortableColumn="id">Report ID <p-sortIcon field="id"></p-sortIcon></th>
          <th pSortableColumn="reportTypeLabel">
            Report Type <p-sortIcon field="reportTypeLabel"></p-sortIcon>
          </th>
          <th pSortableColumn="state">State <p-sortIcon field="state"></p-sortIcon></th>
          <th>Date Range Requested</th>
          <th pSortableColumn="dateRequested">
            Requested On <p-sortIcon field="dateRequested"></p-sortIcon>
          </th>
          <th pSortableColumn="available">Status <p-sortIcon field="available"></p-sortIcon></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-report>
        <tr>
          <td>{{ report.id }}</td>
          <td>{{ report.reportTypeLabel }}</td>
          <td>{{ !report.state || report.state === 'allStates' ? 'All States' : report.state }}</td>
          <td>
            {{(report.periodsDisplay[0] | date: 'MMMM, yyyy') + ' - ' +
            (report.periodsDisplay[report.periodsDisplay.length - 1] | date: 'MMMM, yyyy')}}
          </td>
          <td>{{report.dateRequested | date}}</td>
          <td *ngIf="getStatus(report); let status">
            <span *ngIf="status !== 'failed'">
              <p-chip
                [label]="status === 'ready' ? 'Ready' : 'Pending'"
                [styleClass]="status === 'ready' && 'ready-chip'"
              ></p-chip>
            </span>
            <span *ngIf="status === 'failed'">
              <p-chip
                role="button"
                label="Failed"
                styleClass="failed-chip"
                icon="pi pi-info-circle"
                (click)="showFailedDialog = true"
              ></p-chip>
            </span>
          </td>
          <td>
            <p-menu
              #menu
              [model]="report.available ? readyItemActions : pendingItemActions"
              [popup]="true"
              appendTo="body"
              (onShow)="selectedReport.next(report)"
            ></p-menu>
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-ellipsis-v"
              (click)="menu.toggle($event)"
              class="p-button-rounded p-button-secondary p-button-text"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="6">No request history</td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</section>

<p-dialog
  [(visible)]="showFailedDialog"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  appendTo="body"
  contentStyleClass="px-6 pb-6 text-center"
  styleClass="w-11 sm:w-30rem"
>
  <i class="pi pi-times-circle text-red-500 text-6xl"></i>

  <h2 class="font-bold text-3xl">Something went wrong</h2>

  <p-divider styleClass="my-4"></p-divider>

  <p class="text-lg">We apologize, but there was an issue with your request.</p>
  <p class="text-lg mb-5">
    Please try submitting it again or
    <a href="mailto:service@taxcloud.com" class="no-underline">contact our support</a> for
    assistance.
  </p>

  <button
    pButton
    label="Close"
    (click)="showFailedDialog = false"
    class="p-button-outlined w-full"
  ></button>
</p-dialog>
