import { animate, style, transition, trigger } from '@angular/animations'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { TaxCloudColorEnum } from 'src/app/enums/color.enums'

import { IDisplayUsState } from '../../models/idisplay-us-state'
import { IStateShapeData } from '../../models/istate-shape-data'
import { USStateService } from '../../services/us-state.service'

@Component({
  selector: 'tc-us-map',
  templateUrl: './us-map.component.html',
  styleUrls: ['./us-map.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class UsMapComponent {
  showToolTip: boolean
  memCache: Map<string, IStateShapeData & IDisplayUsState>

  constructor(private usStateService: USStateService) {}

  mappedState$: Observable<Map<string, IStateShapeData & IDisplayUsState>> =
    this.usStateService.getUsStateShapeData()

  showStates$ = new BehaviorSubject<IDisplayUsState[]>([])

  @Input()
  set selectedStates(ids: IDisplayUsState[]) {
    this.showStates$.next(ids)
  }

  @Input() hoverColor: TaxCloudColorEnum = TaxCloudColorEnum.Primary_300
  @Input() disableHover = false

  @Output() clickState = new EventEmitter<string>()
  @Output() hoverState = new EventEmitter<string>()

  combine$ = combineLatest([this.mappedState$, this.showStates$]).pipe(
    map(([shapes, show]) => {
      const data = new Map(shapes)
      if (!Array.isArray(show)) {
        return Array.from(data.values())
      }

      show.forEach((state) => {
        const value = data.get(state.abbreviation)
        data.set(state.abbreviation, { ...value, ...state, selected: true })
      })
      this.memCache = data
      return Array.from(data.values())
    })
  )

  mouseEnter(id: string) {
    this.hoverState.emit(id)
    const state = this.memCache.get(id)
    const doc = document.getElementById(id)
    doc.style['stroke-width'] = '1.999999'
    if (state.selected && this.disableHover === false) {
      doc.style.fill = this.hoverColor
    }
  }

  mouseLeave(id: string) {
    this.hoverState.emit(null)
    const state = this.memCache.get(id)
    const doc = document.getElementById(id)
    doc.style['stroke-width'] = '0.970631'
    doc.style.fill = state.fillColor ? state.fillColor : TaxCloudColorEnum.Hover
  }

  onClick(id: string) {
    this.clickState.emit(id)
  }
}
