import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { INotification } from './models/inotification.model'

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface State extends EntityState<INotification> {
  // additional entities state properties
}

export const adapter: EntityAdapter<INotification> = createEntityAdapter<INotification>()

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
})
