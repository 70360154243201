import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Update } from '@ngrx/entity'
import { Store } from '@ngrx/store'
import { BehaviorSubject, Observable } from 'rxjs'
import {
  DirectFilingFrequencyEnumLabel,
  DirectFilingTaxTypeEnumLabel,
} from 'src/app/enums/direct-filing.enums'
import { IOption, getOptionsFromLabel } from 'src/app/utilities/select-box.utilities'
import { SubSink } from 'subsink'

import * as usStateActions from '../../actions/us-states.actions'
import { IUSState } from '../../models/ius-state'
import { State as LocationStoreState } from 'src/app/location/location.store'
import * as fromLocationSelectors from 'src/app/location/selectors/location-selector'
import { ILocation } from 'src/app/location/models/ilocation'
import { State as UsStateStoreState } from '../../us-states.store'
import { UsStateAdapter } from '../../utilities/us-state-adapter.utilities'
import { InitLocations } from 'src/app/location/actions/location.actions'
import userflow from 'userflow.js'

@Component({
  selector: 'tc-filing-us-state-form',
  templateUrl: './filing-us-state-form.component.html',
  styleUrls: ['./filing-us-state-form.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilingUsStateFormComponent implements OnInit {
  usState$ = new BehaviorSubject<IUSState>(null)
  stateFormGroup: UntypedFormGroup
  frequencyOptions: IOption[] = getOptionsFromLabel(DirectFilingFrequencyEnumLabel)
  taxTypeOptions: IOption[] = getOptionsFromLabel(DirectFilingTaxTypeEnumLabel)
  toggleState = new UntypedFormControl('', Validators.required)
  stateLicenseId = new UntypedFormControl('', Validators.required)
  path$ = new BehaviorSubject<string>('')
  idConstraintMsg = ''
  idValidatorPattern
  private adapter = new UsStateAdapter()
  private subs = new SubSink()

  constructor(
    private fb: UntypedFormBuilder,
    private usStateStore: Store<UsStateStoreState>,
    private router: Router,
    private locationStore: Store<LocationStoreState>,
    private currentRoute: ActivatedRoute
  ) {
    this.stateFormGroup = this.fb.group({
      stateLicenseId: this.stateLicenseId,
      frequency: [''],
      taxType: [''],
      formSelected: [''],
      regAddressId: [''],
    })

    this.toggleState.disable()
    locationStore.dispatch(new InitLocations())
  }

  locations$: Observable<ILocation[]> = this.locationStore.select(fromLocationSelectors.selectAll)
  @Input() masquerading: boolean

  @Input('usState')
  set setUsState(state: IUSState) {
    if (!state) {
      return
    }
    this.usState$.next(state)
    this.stateFormGroup.patchValue({
      stateLicenseId: state.stateLicense.id,
      frequency: state.stateLicense.filingFrequency,
      taxType: state.stateLicense.type,
      formSelected: state.stateLicense.formSelected,
    })
    if (state.stateLicense.locationId > 0) {
      this.stateFormGroup.patchValue({ regAddressId: state.stateLicense.locationId })
    }
  }
  ngOnInit(): void {
    const usState = this.usState$.value
    const stateLicenseContraint = this.adapter.stateLicensePattern(usState.abbreviation)
    if (this.adapter.needRegAddress(usState.abbreviation)) {
      this.stateFormGroup.controls.regAddressId.setValidators([Validators.required])
      this.stateFormGroup.controls.regAddressId.updateValueAndValidity()
    }
    if (stateLicenseContraint) {
      this.idValidatorPattern = stateLicenseContraint[0]
      this.idConstraintMsg = stateLicenseContraint[1]
      this.stateLicenseId.setValidators([
        Validators.required,
        Validators.pattern(this.idValidatorPattern),
      ])
      this.stateLicenseId.updateValueAndValidity()
      if (this.stateFormGroup.get('stateLicenseId').value) {
        this.stateFormGroup.get('stateLicenseId').markAsTouched()
      }
    }
  }
  public cancel(): void {
    this.backToList()
  }

  public turnOnCollect() {
    this.updateCollect(true)
    userflow.track('state_turned_on')
  }

  public turnOffCollect() {
    this.updateCollect(false)
  }

  public backToList() {
    this.router.navigate(['../'], {
      relativeTo: this.currentRoute,
    })
  }

  private updateCollect(collecting: boolean) {
    const usState: IUSState = { ...this.usState$.value }
    const form = this.stateFormGroup.getRawValue()
    const { stateLicense } = usState
    const update: Update<IUSState> = {
      id: this.usState$.value.abbreviation,
      changes: {
        collecting,
        stateLicense: {
          ...stateLicense,
          id: form.stateLicenseId,
          filingFrequency: form.frequency,
          type: form.taxType,
          formSelected: form.formSelected,
        },
        collectingType: this.adapter.mapCollectingType({ ...usState, collecting }),
      },
    }
    if (form.regAddressId) {
      update.changes.stateLicense.locationId = form.regAddressId
    }

    this.usStateStore.dispatch(new usStateActions.UpdateBasicFilingLocal({ update }))
    this.backToList()
  }
}
