import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import * as fromActions from '../actions/notification.actions'
import { NotificationActionTypes } from '../actions/notification.actions'
import { Observable } from 'rxjs'
import { switchMap, first, map } from 'rxjs/operators'
import { Action } from '@ngrx/store'
import { NotificationService } from '../services/notification.service'

@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationService
  ) {}

  loadContacts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.InitNotifications>(NotificationActionTypes.InitNotifications),
      switchMap((_) => {
        return this.notificationService.getNotifications().pipe(
          map((messages) => new fromActions.UpsertNotifications({ messages }))
          // catchError(error =>
          //  of( 'error')
          // )
        )
      })
    )
  )
}
