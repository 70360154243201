<div class="margin_sm" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1rem">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap=".5rem">
    <div>Select</div>
    <mat-icon style="color: #51a0d5">system_update</mat-icon>
    <div>below</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap=".5rem">
    <mat-icon style="color: #51a0d5">add_box</mat-icon>
    <div>Add to Home Screen</div>
  </div>
</div>
