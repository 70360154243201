import { ChangeDetectionStrategy, Component } from '@angular/core'
import { TaxCloudColorEnum } from 'src/app/enums/color.enums'
import { IntegrationCartEnumLabel } from 'src/app/enums/merchant-store.enums'
import { ThemeEnum } from 'src/app/enums/theme.enums'
import { getOptionsFromLabel, IOption } from 'src/app/utilities/select-box.utilities'

@Component({
  selector: 'tc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  backgroundColorDark = TaxCloudColorEnum.Primary_700
  backgroundColorDarkest = TaxCloudColorEnum.Primary_900
  white = TaxCloudColorEnum.White
  lightColor = TaxCloudColorEnum.Primary_200
  darkTheme = ThemeEnum.Dark
  integrations: IOption[] = getOptionsFromLabel(IntegrationCartEnumLabel)
  public legacySignIn(): void {
    const url = new URL(`${location}`)
    url.pathname = 'legacy/dashboard'
    window.open(url.toString(), '_self')
  }
}
