import { SupportCategoryEnum } from 'src/app/enums/support.enums'

import { ISupportPage, ISupportPageAll } from '../models/isupport-page'

export class SupportAdapter {
  public mapAllPagesToSupportCache(
    pages: ISupportPageAll[]
  ): Map<SupportCategoryEnum, ISupportPage[]> {
    const dict = {}
    const mapDict: Map<SupportCategoryEnum, ISupportPage[]> = new Map()

    pages.forEach((page) => {
      const { fields } = page

      if (fields.support_category) {
        fields.support_category.forEach((item) => {
          const cat = item.category as SupportCategoryEnum
          if (!dict[cat]) {
            dict[cat] = [{ ...page, category: cat }]
          } else {
            dict[cat].push({ ...page, category: cat })
          }
        })
      }
    })

    Object.entries(dict).forEach(([key, value]) => {
      mapDict.set(key as SupportCategoryEnum, value as ISupportPage[])
    })
    return mapDict
  }
}
