import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { PdfViewerModule } from 'ng2-pdf-viewer'

import { InitMaterialModule } from '../material/init-material.module'
import { UiModule } from '../ui/ui/ui.module'
import { AcceptTosFormComponent } from './components/accept-tos-form/accept-tos-form.component'
import { TermsOfServiceWarningComponent } from './components/terms-of-service-warning/terms-of-service-warning.component'
import { TermsOfServiceComponent } from './components/terms-of-service/terms-of-service.component'
import { TermsOfServiceDialog } from './dialogs/terms-of-service/terms-of-service.dialog'
import { ViewTermsOfServicePage } from './pages/view-terms-of-service/view-terms-of-service.page'
import { TosRoutesModule } from './routers/tos.routes'
import { AcceptTermsOfServiceButtonComponent } from './components/accept-terms-of-service-button/accept-terms-of-service-button.component'
import { LatestTermsOfServiceComponent } from './components/latest-terms-of-service/latest-terms-of-service.component'
import { DownloadTermsOfServiceButtonComponent } from './components/download-terms-of-service-button/download-terms-of-service-button.component'
import { LatestTermsOfServiceDialog } from './dialogs/latest-terms-of-service/latest-terms-of-service.dialog'
import { ViewLatestTermsOfServicePage } from './pages/view-latest-terms-of-service/view-latest-terms-of-service.page'

@NgModule({
  declarations: [
    TermsOfServiceComponent,
    AcceptTosFormComponent,
    TermsOfServiceWarningComponent,
    TermsOfServiceDialog,
    ViewTermsOfServicePage,
    AcceptTermsOfServiceButtonComponent,
    LatestTermsOfServiceComponent,
    DownloadTermsOfServiceButtonComponent,
    LatestTermsOfServiceDialog,
    ViewLatestTermsOfServicePage,
  ],
  imports: [
    CommonModule,
    PdfViewerModule,
    TosRoutesModule,
    InitMaterialModule,
    ReactiveFormsModule,
    UiModule,
  ],
  exports: [
    TermsOfServiceComponent,
    TermsOfServiceWarningComponent,
    AcceptTermsOfServiceButtonComponent,
    LatestTermsOfServiceComponent,
    DownloadTermsOfServiceButtonComponent,
  ],
})
export class TermsOfServiceModule {}
