<ng-container *ngIf="isLoggedIn$ | async; else showAuthorizing">
  <section class="single-card-page">
    <tc-change-password-form></tc-change-password-form>
  </section>
</ng-container>

<ng-template #showAuthorizing>
  <ng-container *ngIf="isLoading$ | async; then showWorking else showError"></ng-container>
  <ng-template #showWorking>
    <div class="progress-spinner">
      <p-progressSpinner animationDuration="1s" styleClass="text-primary"></p-progressSpinner>
    </div>
  </ng-template>

  <ng-template #showError>
    <ng-container
      *ngIf="isOneTimeLogin$ | async; then oneTimeLoginError else loginError"
    ></ng-container>
    <ng-template #oneTimeLoginError>
      <div class="mx-5">
        <p-message
          *ngIf="errorMessage$ | async as errorMessage"
          styleClass="w-full"
          severity="error"
          text="{{errorMessage}}"
        ></p-message>
      </div>
    </ng-template>
    <ng-template #loginError>
      <div class="flex flex-column justify-content-center align-items-center mx-5 gap-3 w-full">
        <p-message
          styleClass="w-full"
          class="w-full"
          severity="error"
          text="You are not logged in"
        ></p-message>
        <button pButton pRipple label="Sign in" class="p-button w-1" (click)="goSignIn()"></button>
      </div>
    </ng-template>
  </ng-template>
</ng-template>
