import { Component } from '@angular/core'
import * as fromRouter from '@ngrx/router-store'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import * as fromRouterSelectors from 'src/app/root/selectors/root.selectors'

import { IBreadCrumb } from '../../models/ibreadcrumb.models'

@Component({
  selector: 'tc-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.sass'],
})
export class BreadcrumbComponent {
  constructor(private route: Store<fromRouter.RouterReducerState<any>>) {
    this.route
      .select(fromRouterSelectors.selectBreadcrumbs)
      .pipe(
        tap((breadcrumbs) => {
          document.title = `TaxCloud ${breadcrumbs.map((crumb) => crumb.label).join(' ')}`
        })
      )
      .subscribe()
  }

  breadcrumbs$: Observable<IBreadCrumb[]> = this.route.select(fromRouterSelectors.selectBreadcrumbs)
}
