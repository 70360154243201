import { DateFormatEnum } from 'src/app/enums/date.enums'
import { environment } from './../../../environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { filter, map, tap, catchError } from 'rxjs/operators'
import { IGeminiAppRoute } from '../models/igemini-app-route'
import { v4 as uuidv4 } from 'uuid'
import { Store } from '@ngrx/store'
import { State as UserState } from 'src/app/user/user.state'
import { State as AccountState } from 'src/app/account/account.state'
import {
  selectImpersonatorId,
  selectLoginStatus,
  selectTokenClaims,
  selectUserId,
} from 'src/app/user/selectors/user.selectors'
import { SubSink } from 'subsink'
import { combineLatest, Observable, of } from 'rxjs'
import { selectMerchantId } from 'src/app/account/selectors/account.selectors'
import { LoginEnum } from 'src/app/enums/login.enum'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

@Injectable({
  providedIn: 'root',
})
export class GeminiService implements OnDestroy {
  private uuid: string
  private userAgent: string
  private isLoggedIn: boolean
  private tokenClaims: object = null
  private subs = new SubSink()
  private metaData = {
    schema_name: 'app_routes',
    deployment_stage: this.configureDeploymentStage(),
    trigger_origin: 'app_taxcloud_client',
  }
  private userId: string = null
  private accountId: string = null
  private impersonatorId: string = null
  private cookieId: string
  private readonly APP_ROUTE_LOCATION = 'gemini_service_send_gemini_app_route'

  constructor(
    private http: HttpClient,
    private userStore: Store<UserState>,
    private accountStore: Store<AccountState>
  ) {
    this.uuid = uuidv4()
    this.userAgent = window.navigator?.userAgent
    this.verifyGeminiCookie()
    this.subs.add(
      combineLatest([
        this.userId$,
        this.tokenClaims$,
        this.merchantId$,
        this.impersonatorId$,
        this.isLoggedIn$,
      ])
        .pipe(
          tap(([userId, claims, merchantId, impersonatorId, isLoggedIn]) => {
            this.userId = userId
            this.tokenClaims = claims
            this.accountId = merchantId
            this.impersonatorId = impersonatorId
            this.isLoggedIn = isLoggedIn
          })
        )
        .subscribe()
    )
  }
  userId$: Observable<string> = this.userStore.select(selectUserId)
  private isLoggedIn$: Observable<boolean> = this.userStore
    .select(selectLoginStatus)
    .pipe(map((status) => status === LoginEnum.LoggedIn))
  tokenClaims$: Observable<object> = this.userStore.select(selectTokenClaims).pipe(
    filter((claims) => !!claims),
    tap((claims) => {
      this.tokenClaims = claims
    })
  )
  merchantId$: Observable<string> = this.accountStore.select(selectMerchantId).pipe(
    filter((merchantId) => !!merchantId),
    map((merchantId) => merchantId.toString())
  )

  impersonatorId$: Observable<string> = this.userStore.select(selectImpersonatorId)

  /*****
   ### Endpoint to send app route data to Gemini via API. ###
   An event that is fired whenever the route changes for a user in the app.
   As the app is a SPA, this will generally be when the user is navigating
   between major application components or altering parameters.
   */
  public sendGeminiAppRoute(): void {
    const location = window.location
    const path = location.pathname || null
    const hash = location.hash || null
    const search = location.search || null
    const referrer = window.document?.referrer || null
    const cookieId = this.getGeminiCookie()
    const geminiAppRoute: IGeminiAppRoute = {
      metadata: {
        ...this.metaData,
        triggered_at: dayjs().utc().format(DateFormatEnum.SqlTime24Hrs),
        uuid: this.uuid,
        user_agent: this.userAgent,
        token_claims: this.tokenClaims,
        trigger_location: this.APP_ROUTE_LOCATION,
      },
      identity: {
        ip_address: null,
        cookie_id: cookieId,
        account_id: this.accountId,
        user_id: this.userId,
        impersonator_id: this.impersonatorId,
        other_ids: null,
      },
      attributes: {
        path,
        hash_path: hash,
        search,
        referrer,
      },
      annotations: null,
    }
    const url = `api/gemini-relayer/upload-app-route-event${this.isLoggedIn ? '' : '-unauthorized'}`
    this.http
      .post(url, `'${JSON.stringify(geminiAppRoute)}'`, {
        headers: new HttpHeaders({
          'Content-Type': 'text/json',
        }),
      })
      .pipe(catchError((err) => of(console.log(err))))
      .subscribe()
  }

  private generateGeminiCookie() {
    const uuid = uuidv4()
    document.cookie = `gemini=${uuid}; path=/; domain=${environment.cookieDomain};`
    localStorage.setItem('gemini', uuid)
  }

  public getGeminiCookie() {
    let tempCookie = this.cookieId || localStorage.getItem('gemini') || null

    if (tempCookie === 'null') {
      this.generateGeminiCookie()
      tempCookie = this.getGeminiCookieDocumentValue()
    }

    return tempCookie
  }

  private checkGeminiCookieExists() {
    const exists = document.cookie.split(';').some((item) => item.trim().startsWith('gemini='))
    const currUuid = this.getGeminiCookieDocumentValue()
    return exists && currUuid !== 'null'
  }

  private getGeminiCookieDocumentValue() {
    return document.cookie
      .split('; ')
      .find((row) => row.startsWith('gemini='))
      ?.split('=')[1]
  }

  private verifyGeminiCookie() {
    if (this.checkGeminiCookieExists()) {
      this.updateGeminiCookie()
    } else {
      this.generateGeminiCookie()
    }
    this.cookieId = this.getGeminiCookieDocumentValue()
  }

  private updateGeminiCookie() {
    // updates cookie to have the correct path and domain
    const currUuid = this.getGeminiCookieDocumentValue()
    document.cookie = `gemini=${currUuid}; path=/; domain=${environment.cookieDomain};`
    localStorage.setItem('gemini', this.getGeminiCookieDocumentValue())
  }

  private configureDeploymentStage() {
    let envName = 'development'
    if (environment.production) {
      if (environment.name === 'prod') {
        envName = 'production'
      }
      if (environment.name === 'staging') {
        envName = 'staging'
      }
    }
    return envName
  }
  public ngOnDestroy() {
    this.subs.unsubscribe()
  }
}
