import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core'

const SEVERITY_TO_PRIME_ICONS = {
  success: 'pi-check-circle',
  error: 'pi-exclamation-triangle',
}

const SEVERITY_TO_EMOJI_ICONS = {
  success: '🎉',
  error: '🆘',
}

@Component({
  selector: 'tc-result-message',
  templateUrl: './result-message.component.html',
  styleUrls: ['./result-message.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultMessageComponent implements OnInit {
  primeIcon: string
  emojiIcon: string

  @Input() title: string

  @Input() body: TemplateRef<any>

  @Input() footer?: TemplateRef<any>

  @Input() severity: 'success' | 'error'

  @Input() iconType: 'basic' | 'emoji'

  ngOnInit(): void {
    switch (this.iconType) {
      case 'basic':
        this.primeIcon = SEVERITY_TO_PRIME_ICONS[this.severity]
        break
      case 'emoji':
        this.emojiIcon = SEVERITY_TO_EMOJI_ICONS[this.severity]
        break
    }
  }
}
