import moment from 'moment'
import { AccountStateEnum } from 'src/app/enums/account.enums'
import { AttestationEnum } from 'src/app/enums/attestations.enums'
import { BillingFrequencyEnum } from 'src/app/enums/billing.enums'
import { CorpTypeEnum } from 'src/app/enums/profile.enums'
import {
  ApiPlanLevelEnum,
  ServicePlanTypeEnum,
  ServicePlanTypeEnumLabel,
} from 'src/app/enums/service-plan.enums'
import { IApiStore } from 'src/app/merchant-store/models/iapi-store'
import { validateDate } from 'src/app/utilities/date.utilities'

import { AccountDatabase } from '../db/account.db'
import { IAccount, IApiGoLiveStatus, IConversionStatus } from '../models/iaccount'
import { IApiMerchant, IApiPaymentPlan } from '../models/iapi-merchant'
import { IProfile } from '../models/iprofile.model'
import { IServicePlan } from '../models/iservice-plan'
import { ICancellationQuestionnaire } from 'src/app/manage-account/models/icancellationQuestionnaire'

export class AccountAdapter {
  db: AccountDatabase
  constructor(db: AccountDatabase) {
    this.db = db
  }

  public mapProfileToApiProfile(profile: IProfile): any {
    return {
      name: profile.businessName,
      corpName: profile.corpName,
      corpType: profile.corpType,
      ein: profile.ein,
      stateIncorporated: profile.stateIncorporated,
    }
  }

  public mapApiAccountResultToAccount(
    data: IApiMerchant,
    stores?: IApiStore[],
    appIDs = [],
    locations = []
  ): IAccount {
    if (!data) {
      throw new Error('Missing merchant data')
    }

    let accountState: AccountStateEnum = AccountStateEnum.Prospective

    const configEnd = validateDate(data.configurationWindowEnd)
    let daysLeft = 0

    if (configEnd) {
      daysLeft = moment(data.configurationWindowEnd).diff(moment(), 'days')
    }

    if (stores) {
      accountState = this.hasGoLiveStore(stores)
        ? AccountStateEnum.Converted
        : AccountStateEnum.Prospective
    }

    if (accountState === AccountStateEnum.Prospective) {
      if (daysLeft <= 0) {
        accountState = AccountStateEnum.Suspended
      }
    }

    let attestations: AttestationEnum[] = []

    if (accountState === AccountStateEnum.Converted && data.needsPAAttestation) {
      attestations = [AttestationEnum.Pennsylvania]
      accountState = AccountStateEnum.Attestation
    }

    const profile: IProfile = {
      businessName: data.name,
      corpName: data.corpName,
      corpType: data.corpType as CorpTypeEnum,
      ein: data.ein,
      industryClassificationCode: data.naics,
      stateIncorporated: data.stateIncorporated,
    }

    const convert: IAccount = {
      attestations,
      merchantId: data.merchantID,
      createdTimestamp: validateDate(data.createdOn),
      lastModifiedTimestamp: validateDate(data.lastModified),
      // naics: data.naics,
      essProfileId: data.essProfileID,
      prepayBlance: data.currentPrepayBalance,
      conversionStatus: {
        hasCompleteProfile: this.onboardingProfileComplete(profile),
        hasCompleteTransaction: data.hasLookup && data.hasCapture,
        hasPaymentMethod: data.hasPaymentMethod,
        hasStore: true,
        hasLocation: locations?.length > 0,
      },
      servicePlan: this.mapApiPaymentPlanToServicePlan(this.getSubsetApiPaymentPlan(data)),
      phone: data.phone,
      profile,
      streamlined: {
        id: data.sstpID,
        date: validateDate(data.sstDate),
        datePast120Days: data.sstDatePast120Days,
      },
      serviceFee: data.serviceFee,
      uspsID: data.uspsID,
      useInvoicing: data.useInvoicing,
      accountState,
      appIDs,
    }

    convert.configurationWindow = {
      begin: validateDate(data.configurationWindowBegin),
      end: validateDate(data.configurationWindowEnd),
      daysLeft: daysLeft > 0 ? daysLeft : 0,
    }

    if (data.accountStatus) {
      let cancelDate = validateDate(data.disabledTimestamp)
      if (!cancelDate) {
        cancelDate = validateDate(data.accountStatus.cancellationDate)
      }

      if (cancelDate) {
        const today = moment().format()
        const cancelDaysLeft = moment(cancelDate).diff(today, 'days')
        convert.closeWindow = {
          end: cancelDate,
          begin: today,
          daysLeft: cancelDaysLeft,
        }
        if (cancelDaysLeft >= 0) {
          convert.accountState = AccountStateEnum.Leaving
        } else {
          convert.accountState = AccountStateEnum.Closed
        }
      }
    }

    return convert
  }

  private getSubsetApiPaymentPlan(api: IApiMerchant): IApiPaymentPlan {
    return {
      useSSUTA: api.useSSUTA,
      paymentPlanAnnualFee: api.paymentPlanAnnualFee,
      paymentPlanDescription: api.paymentPlanDescription,
      paymentPlanLabel: api.paymentPlanLabel,
      paymentPlanLabel2: api.paymentPlanLabel2,
      paymentPlanMonthlyFee: api.paymentPlanMonthlyFee,
      paymentPlanType: api.paymentPlanType,
      paymentPlanType2: api.paymentPlanType2,
      paymentPlanTypeYearly: api.paymentPlanTypeYearly,
    }
  }

  private mapApiPaymentPlanToServicePlan(api: IApiPaymentPlan): IServicePlan | null {
    const type = this.parseServicePlanType(api)
    if (!type) {
      return null
    }

    const converted: IServicePlan = {
      type,
      label: ServicePlanTypeEnumLabel.get(type),
    }

    if (type === ServicePlanTypeEnum.OldSelective) {
      let level: ApiPlanLevelEnum
      switch (api.paymentPlanType) {
        case 1:
          level = ApiPlanLevelEnum.Level_1
          break

        case 3:
          level = ApiPlanLevelEnum.Level_2
          break

        case 4:
          level = ApiPlanLevelEnum.Level_3
          break

        case 5:
          level = ApiPlanLevelEnum.Level_4
          break

        case 6:
          level = ApiPlanLevelEnum.Level_5
          break

        case 7:
          level = ApiPlanLevelEnum.Level_6
          break

        case 10:
          level = ApiPlanLevelEnum.Level_7
          break

        default:
          throw Error('Unknown Old Selective Plan Level')
      }

      const billingFrequency = api.paymentPlanTypeYearly
        ? BillingFrequencyEnum.Annually
        : BillingFrequencyEnum.Monthly
      const monthlyPrice =
        billingFrequency === BillingFrequencyEnum.Annually
          ? api.paymentPlanAnnualFee
          : api.paymentPlanAnnualFee
      const apiCount = api.paymentPlanDescription
        ? parseInt(api.paymentPlanDescription.split(' ')[2].split(',').join(''), 10)
        : -1

      converted.details = {
        billingFrequency,
        monthlyPrice,
        level,
        apiCount,
      }
    }

    return converted
  }

  private onboardingProfileComplete(profile: IProfile): boolean {
    if (!profile) {
      return false
    }
    return !!profile.businessName && !!profile.corpName
  }

  public mapGoLiveApiToConversionStatus(goLive: IApiGoLiveStatus): IConversionStatus {
    const conversionStatus: IConversionStatus = {
      hasCompleteProfile: goLive.hasStateOfIncorp && !!goLive.hasEin && !!goLive.hasEntitytype,
      hasLocation: goLive.hasLocations,
      hasCompleteTransaction: goLive.hasLookup && goLive.hasCapture,
      hasPaymentMethod: goLive.hasPayment,
    }

    return conversionStatus
  }

  private parseServicePlanType(data: IApiPaymentPlan): ServicePlanTypeEnum {
    const newPlan = data.paymentPlanType2 === 3 ? true : false
    const oldPlan = data.paymentPlanType === null ? 0 : data.paymentPlanType + 1

    if (data.useSSUTA) {
      return ServicePlanTypeEnum.Automated
    }

    if (newPlan) {
      return ServicePlanTypeEnum.Selective
    }

    if (oldPlan >= 2) {
      return ServicePlanTypeEnum.OldSelective
    }
  }

  public hasGoLiveStore(stores: IApiStore[]): boolean {
    const anyLive = stores.find((store) => store.isLive)
    return !!anyLive
  }
  public mapQuestionnaireToApiQuestionnaire(questionnaire: ICancellationQuestionnaire): any {
    return {
      MainReason: questionnaire.mainReason,
      CsatReuse: questionnaire.likelyFutureUse,
      CsatRecommend: questionnaire.likelyRecommendation,
      AlternativeSelection: questionnaire.alternativeCompany,
      TechIssue: questionnaire.techReason,
      OtherReason: questionnaire.otherReason,
      ServiceFeedback: questionnaire.serviceFeedback,
      AllowContact: questionnaire.allowContact,
    }
  }
}
