import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  Observable,
  catchError,
  combineLatest,
  filter,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs'
import * as actions from '../actions/setup.actions'
import { SetupActionTypes } from '../actions/setup.actions'
import { Router } from '@angular/router'
import * as locationActions from 'src/app/location/actions/location.actions'
import { LocationActionTypes } from 'src/app/location/actions/location.actions'
import { Action, Store } from '@ngrx/store'
import { State as SetupState } from 'src/app/setup/setup.state'
import * as setupSelectors from 'src/app/setup/selectors/setup.selectors'
import { State as AccountState } from 'src/app/account/account.state'
import * as accountActions from 'src/app/account/actions/account.actions'
import { AccountActionTypes } from 'src/app/account/actions/account.actions'
import { SetupService } from '../services/setup.service'
import {
  OnboardingCompletedResponse,
  OnboardingDetailsResponse,
} from '../models/onboarding-details.model'
import * as accountSelectors from 'src/app/account/selectors/account.selectors'
import { NotificationService } from 'src/app/notification/services/notification.service'

@Injectable()
export class SetupEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private setupStore: Store<SetupState>,
    private accountStore: Store<AccountState>,
    private setupService: SetupService,
    private notificationService: NotificationService
  ) {}

  completeSetup$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.CompleteSetup>(SetupActionTypes.CompleteSetup),
        tap(() => {
          this.setupStore.dispatch(new actions.ResetCurrentState())
          this.router.navigate(['/go/dashboard'])
        })
      ),
    { dispatch: false }
  )

  locationActionSuccess$: Observable<Action> = createEffect(() =>
    combineLatest([
      this.actions$.pipe(
        ofType<
          | locationActions.AddLocationProspectiveSuccess
          | locationActions.UpdateLocationProspectiveSuccess
          | locationActions.AddLocationProspectiveFailed
          | locationActions.UpdateLocationProspectiveFailed
          | actions.SkipLocation
        >(
          LocationActionTypes.AddLocationProspectiveSuccess,
          LocationActionTypes.UpdateLocationProspectiveSuccess,
          LocationActionTypes.AddLocationProspectiveFailed,
          LocationActionTypes.UpdateLocationProspectiveFailed,
          SetupActionTypes.SkipLocation
        )
      ),
      this.actions$.pipe(
        ofType<actions.PutOnboardingDetailsSuccess | actions.PutOnboardingDetailsFailed>(
          SetupActionTypes.PutOnboardingDetailsSuccess,
          SetupActionTypes.PutOnboardingDetailsFailed
        )
      ),
    ]).pipe(
      withLatestFrom(this.setupStore.select(setupSelectors.selectStepLoading)),
      filter(([action, stepLoading]) => stepLoading === true),
      tap(() => this.accountStore.dispatch(new accountActions.RefreshAccountData())),
      switchMap(([action, stepLoading]) => {
        return of(new actions.SetStepLoading({ loading: false }))
      })
    )
  )

  InitOnboardingDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitOnboardingDetails>(SetupActionTypes.InitOnboardingDetails),
      switchMap((action) =>
        this.setupService.getSetupDetails(action.payload.suppress404Error).pipe(
          map(
            (onboardingDetailsResponse: OnboardingDetailsResponse) =>
              new actions.InitOnboardingDetailsSuccess({ onboardingDetailsResponse })
          ),
          catchError((error) => of(new actions.InitOnboardingDetailsFailed()))
        )
      )
    )
  )

  PutOnboardingDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.PutOnboardingDetails>(SetupActionTypes.PutOnboardingDetails),
      switchMap((action) =>
        this.setupService.putSetupDetails(action.payload.putOnboardingDetailsRequest).pipe(
          map(
            (onboardingDetailsResponse: OnboardingDetailsResponse) =>
              new actions.PutOnboardingDetailsSuccess({ onboardingDetailsResponse })
          ),
          catchError((error) => of(new actions.PutOnboardingDetailsFailed()))
        )
      )
    )
  )

  PutOnboardingDetailsSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.PutOnboardingDetailsSuccess>(SetupActionTypes.PutOnboardingDetailsSuccess),
      withLatestFrom(
        this.setupStore.select(setupSelectors.selectStepLoading),
        this.setupStore.select(setupSelectors.selectStepperActiveIndex)
      ),
      filter(([action, stepLoading, activeIndex]) => activeIndex >= 2), // First step handles loading via locationActionSuccess effect.
      switchMap(([action, stepLoading, activeIndex]) => {
        return of(new actions.SetStepLoading({ loading: false }))
      })
    )
  )

  InitIsOnboardingCompleted$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitIsOnboardingCompleted>(SetupActionTypes.InitIsOnboardingCompleted),
      switchMap((action) =>
        this.setupService.getSetupCompleted().pipe(
          map(
            (onboardingCompletedResponse: OnboardingCompletedResponse) =>
              new actions.InitIsOnboardingCompletedSuccess({ onboardingCompletedResponse })
          ),
          catchError((error) => of(new actions.InitIsOnboardingCompletedFailed()))
        )
      )
    )
  )

  /**
   * This method handles the redirect for users who haven't completed onboarding yet
   * NOTE this is a separate check & redirect from the shell guard and account effects
   * (which handles the check for having a location & complete profile)
   */
  InitIsOnboardingCompletedSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<
          actions.InitIsOnboardingCompletedSuccess | accountActions.GetAccountDataSuccessRedirect
        >(
          SetupActionTypes.InitIsOnboardingCompletedSuccess,
          AccountActionTypes.GetAccountDataSuccessRedirect
        ),
        withLatestFrom(
          this.setupStore.select(setupSelectors.selectIsOnboardingCompleted),
          this.accountStore.select(accountSelectors.selectAccountState)
        ),
        tap(([action, isOnboardingCompleted, accountState]) => {
          const dateCutoff = new Date('2024-06-04') // Don't want to push this for older accounts
          if (
            new Date() >= dateCutoff &&
            isOnboardingCompleted === false &&
            !!accountState.account
          ) {
            // this.notificationService.showWarning({
            //   title: 'Please complete your setup details to continue.',
            // })
            // this.router.navigate(['complete-account'])
          }
        })
      ),
    { dispatch: false }
  )
}
