import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { DataSourceEnum } from '../enums/dataSource.enums'
import { IContact as Contact, IContact } from './models/icontact.model'

export const adapter: EntityAdapter<IContact> = createEntityAdapter<IContact>({
  selectId: (model) => model.contactId.toString(),
  sortComparer: (a: IContact, b: IContact): number => {
    const aName = a.fullName ? `${a.fullName.toString()}`.toLowerCase() : ''
    const bName = b.fullName ? `${b.fullName.toString()}`.toLowerCase() : ''
    return aName.localeCompare(bName.toString())
  },
})

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface State extends EntityState<Contact> {
  initialLoadComlete: boolean
  source: DataSourceEnum
  working: string
  error: string
}

export const initialState: State = adapter.getInitialState({
  initialLoadComlete: false,
  source: DataSourceEnum.Local,
  working: '',
  error: '',
})
