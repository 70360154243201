import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Update } from '@ngrx/entity'
import { Action, Store } from '@ngrx/store'
import moment from 'moment'
import { Observable, of } from 'rxjs'
import {
  catchError,
  exhaustMap,
  filter as rxjsFilter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import { DateFormatEnum } from 'src/app/enums/date.enums'
import { TransactionStateEnum, TransactionUpdateEnum } from 'src/app/enums/transaction.enums'
import { DownloadService } from 'src/app/root/services/download.service'
import { isEquivalent } from 'src/app/utilities/object.utilities'

import { TransactionActionTypes as ActionTypes } from '../actions/transaction.actions'
import * as actions from '../actions/transaction.actions'
import { ITransaction } from '../models/itransaction.model'
import { TransactionService } from '../services/transaction.service'
import * as transactionSelectors from './../selectors/transaction.selector'
import { State as TransactionState } from './../transaction.store'

@Injectable()
export class TransactionEffects {
  constructor(
    private actions$: Actions,
    private transactionService: TransactionService,
    private transactionStore: Store<TransactionState>,
    private downloadService: DownloadService
  ) {}

  initTransactions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitTransactions>(ActionTypes.InitTransactions),
      withLatestFrom(this.transactionStore.select(transactionSelectors.selectFilter)),
      switchMap(([_, filter]) => {
        return this.transactionService.getTransactions(filter, 1, 40).pipe(
          withLatestFrom(this.transactionStore.select(transactionSelectors.selectTotal)),
          rxjsFilter(([transactions, total]) => total === 0),
          map(
            ([{ transactions, filingIds, message, complete }, total]) =>
              new actions.InitTransactionsSuccess({
                filingIds,
                message,
                transactions,
                complete,
              })
          )
        )
      }),
      catchError((error) => {
        return of(new actions.ClearTransactions())
      })
    )
  )

  initWithTestTransactions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitTestTransactions>(ActionTypes.InitTestTransactions),
      switchMap((action) => {
        return this.transactionService
          .getTransactions({ month: moment().format(DateFormatEnum.Month), isTest: true })
          .pipe(
            map(
              ({ transactions, filingIds, message, complete }) =>
                new actions.InitTestTransactionsSuccess({
                  filingIds,
                  message,
                  transactions,
                  complete,
                })
            )
          )
      })
    )
  )

  updateFilter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.UpdateFilters | actions.UpdateFiltersShell | actions.UpdateFiltersGetStarted>(
        ActionTypes.UpdateFilters,
        ActionTypes.UpdateFiltersShell,
        ActionTypes.UpdateFiltersGetStarted
      ),
      switchMap((action) =>
        this.transactionStore.select(transactionSelectors.selectFilter).pipe(
          map((stored) => {
            if (isEquivalent(stored, action.payload.filter)) {
              return new actions.SkipGetFilteredTransactions()
            } else {
              return new actions.GetFilteredTransactions({ filter: action.payload.filter })
            }
          })
        )
      )
    )
  )

  getFilteredTransactions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.GetFilteredTransactions>(ActionTypes.GetFilteredTransactions),
      withLatestFrom(this.transactionStore.select(transactionSelectors.selectQuery)),
      switchMap(([_, query]) => {
        return this.transactionService.getTransactions(query.filter, 1, query.limit).pipe(
          map(
            ({ transactions, filingIds, message, complete }) =>
              new actions.GetFilteredTransacgtionsSuccess({
                filingIds,
                message,
                transactions,
                complete,
              })
          )
        )
      })
    )
  )

  getLastUpdated$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.GetFilteredTransacgtionsSuccess>(ActionTypes.GetFilteredTransacgtionsSuccess),
      switchMap(() => {
        return this.transactionService
          .getLastUpdated()
          .pipe(map((lastUpdated: string) => new actions.SaveLastUpdated({ lastUpdated })))
      })
    )
  )

  refreshTransactions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.RefreshTransactions>(ActionTypes.RefreshTransactions),
      withLatestFrom(this.transactionStore.select(transactionSelectors.selectQuery)),
      switchMap(([_, query]) => {
        return this.transactionService.getTransactions(query.filter, 1, query.limit).pipe(
          map(
            ({ transactions, filingIds, message, complete }) =>
              new actions.RefreshTransactionsSuccess({
                filingIds,
                message,
                transactions,
                complete,
              })
          )
        )
      })
    )
  )

  downloadTransactions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.DownloadTransactions>(ActionTypes.DownloadTransactions),
      withLatestFrom(this.transactionStore.select(transactionSelectors.selectDownloadFilter)),
      exhaustMap(([action, query]) =>
        this.transactionService.getDownloadTransactions(
          query.filter,
          action.payload.isSummary,
          action.payload.isConsolidated
        )
      ),
      tap(([name, blob]) => this.downloadService.downloadBlob(blob, name)),
      map((_) => new actions.DownloadTransactionsComplete())
    )
  )

  getMoreTransactions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LoadAdditionalTransactions>(ActionTypes.LoadAdditionalTransactions),
      withLatestFrom(this.transactionStore.select(transactionSelectors.selectQuery)),
      switchMap(([_, query]) => {
        return this.transactionService.getTransactions(query.filter, query.page, query.limit).pipe(
          map(
            ({ transactions, filingIds, message, complete }) =>
              new actions.LoadAdditionalTransactionsSuccess({
                filingIds,
                message,
                transactions,
                complete,
              })
          )
        )
      })
    )
  )

  importTransactionsFromLinkedStore$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ImportTransactions>(ActionTypes.ImportTransactions),
      switchMap((action) =>
        this.transactionService.importTransactionsFromLinkedStore(action.payload.merchantStore)
      ),
      map(() => new actions.ImportTransactionsSuccess())
    )
  )

  captureTransaction$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.CaptureTransaction>(ActionTypes.CaptureTransaction),
      switchMap((action) => {
        return this.transactionService
          .captureTransaction(
            action.payload.transactionId,
            action.payload.cartId,
            action.payload.useLookupDate
          )
          .pipe(
            map(([id, result]) => {
              const update: Update<ITransaction> = {
                id,
                changes: {
                  update: result,
                },
              }
              return new actions.UpdateTransactionDetailSuccess({ transaction: update })
            })
          )
      })
    )
  )

  returnTransaction$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ReturnTransaction>(ActionTypes.ReturnTransaction),
      switchMap((action) => {
        return this.transactionService
          .returnTransaction(action.payload.transactionId, action.payload.returnedDate)
          .pipe(
            map(([id, result]) => {
              const update: Update<ITransaction> = {
                id,
                changes: {
                  update: result,
                },
              }
              return new actions.UpdateTransactionDetailSuccess({ transaction: update })
            }),
            catchError((e: HttpErrorResponse) => {
              const update: Update<ITransaction> = {
                id: action.payload.transactionId,
                changes: {
                  update: {
                    message: e.message,
                    result: TransactionUpdateEnum.Error,
                    type: TransactionStateEnum.Returned,
                  },
                },
              }
              return of(new actions.UpdateTransactionDetailSuccess({ transaction: update }))
            })
          )
      })
    )
  )
}
