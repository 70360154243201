<div
  fxLayout="column"
  fxLayoutAlign="start stretch"
  class="container mat-typography h-full"
  *ngIf="layout$ | async as layout"
>
  <mat-toolbar
    class="mat-elevation-z3"
    [style.marginTop.px]="0"
    [style.min-height]="(isSmallPage$ | async) ? '64px' : '128px'"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
      <button *ngIf="isSmallPage$ | async" mat-icon-button data-cy="toggle-menu">
        <mat-icon (click)="toggle()">menu</mat-icon>
      </button>
      <h1><span class="primary-color">Tax</span><span class="accent-color">Cloud</span></h1>
      <ng-container *ngIf="isMasquerading$ | async">
        <div>Masquerading as {{ businessName$ | async }} {{ merchantId$ | async }}</div>
      </ng-container>
    </div>

    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex fxLayout="row" fxLayoutAlign="center center">
        <div *ngIf="(isSmallPage$ | async) === false" role="navigation">
          <ng-container *ngIf="(isEmbeddedApp$ | async) === false">
            <button mat-button (click)="goHome()">HOME</button>
          </ng-container>

          <button mat-button (click)="goToWelcome()">WELCOME</button>
          <button mat-button (click)="goToSetUp()">SET UP</button>
          <button mat-button (click)="goToPricing()">PRICING</button>
          <button mat-button (click)="goToSupport()">SUPPORT</button>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1rem">
        <tc-sign-out-button></tc-sign-out-button>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav
      class="mat-typography"
      [mode]="'over'"
      [fixedInViewport]="true"
      [fixedBottomGap]="0"
      [fixedTopGap]="64"
      [opened]="layout.opened"
      style="width: 304px"
      role="navigation"
    >
      <mat-nav-list>
        <ng-container *ngIf="(isEmbeddedApp$ | async) === false">
          <div>
            <a mat-list-item class="padding_h_sm" (click)="toggle()" [routerLink]="'/home'">
              <mat-icon class="margin_r_sm">home</mat-icon>
              <span>Home</span>
            </a>
          </div>
        </ng-container>

        <div>
          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle()"
            [routerLink]="'/get-started/welcome'"
          >
            <mat-icon class="margin_r_sm">help_outline</mat-icon>
            <span>Welcome</span>
          </a>
        </div>

        <div>
          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle()"
            [routerLink]="'/get-started/set-up'"
          >
            <mat-icon class="margin_r_sm">settings</mat-icon>
            <span>Set-up</span>
          </a>
        </div>

        <div>
          <a mat-list-item class="padding_h_sm" (click)="goToPricing(); toggle()">
            <mat-icon class="margin_r_sm">attach_money</mat-icon>
            <span>Pricing</span>
          </a>
        </div>

        <div>
          <a mat-list-item class="padding_h_sm" (click)="goToSupport(); toggle()">
            <mat-icon class="margin_r_sm">support</mat-icon>
            <span>Support</span>
          </a>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <div
        class="main-router-outet"
        [style.height]="(isSmallPage$ | async) ? 'calc(100vh - 76px)' : 'calc(100vh - 151px)'"
      >
        <tc-breadcrumb></tc-breadcrumb>
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
