import { ValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms'

export const passwordConfirmValidator: ValidatorFn = (
  control: UntypedFormGroup
): ValidationErrors | null => {
  const name = control.get('password')
  const alterEgo = control.get('confirm')

  return name && alterEgo && name.value !== alterEgo.value ? { notConfirmed: true } : null
}
