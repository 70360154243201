import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { DataSourceEnum } from '../enums/dataSource.enums'
import { ILocation } from './models/ilocation'

export const adapter: EntityAdapter<ILocation> = createEntityAdapter<ILocation>({
  selectId: (model) => model.locationId,
})

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface State extends EntityState<ILocation> {
  initialLoadComlete: boolean
  source: DataSourceEnum
  working: string
  error?: string
}

export const initialState: State = adapter.getInitialState({
  initialLoadComlete: false,
  source: DataSourceEnum.Local,
  working: '',
})
