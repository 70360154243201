import { Action } from '@ngrx/store'
import { IMerchantReportDownload, IReportRequest } from '../models/imerchant-report-downloads.model'

export enum DownloadCenterActionTypes {
  InitReports = 'Init Reports',
  InitReportsSuccess = 'Init Reports Success',
  InitReportsFailed = 'Init Reports Failed',

  RequestReport = 'Request Report',
  RequestReportSuccess = 'Request Report Success',
  RequestReportFailed = 'Request Report Failed',

  ProcessReport = 'Process Report',
  ProcessReportSuccess = 'Process Report Success',
  ProcessReportFailed = 'Process Report Failed',

  DeleteReport = 'Delete Report',
  DeleteReportSuccess = 'Delete Report Success',
  DeleteReportFailed = 'Delete Report Failed',

  DownloadReport = 'Download Report',
  DownloadReportSuccess = 'Download Report Success',
  DownloadReportFailed = 'Download Report Failed',
}

export class InitReports implements Action {
  readonly type = DownloadCenterActionTypes.InitReports
  constructor() {}
}

export class InitReportsSuccess implements Action {
  readonly type = DownloadCenterActionTypes.InitReportsSuccess
  constructor(public payload: { reports: IMerchantReportDownload[] }) {}
}

export class InitReportsFailed implements Action {
  readonly type = DownloadCenterActionTypes.InitReportsFailed
  constructor() {}
}

export class RequestReport implements Action {
  readonly type = DownloadCenterActionTypes.RequestReport
  constructor(public payload: { request: IReportRequest }) {}
}

export class RequestReportSuccess implements Action {
  readonly type = DownloadCenterActionTypes.RequestReportSuccess
  constructor() {}
}

export class RequestReportFailed implements Action {
  readonly type = DownloadCenterActionTypes.RequestReportFailed
  constructor() {}
}

export class ProcessReport implements Action {
  readonly type = DownloadCenterActionTypes.ProcessReport
  constructor(public payload: { reportId: number }) {}
}

export class ProcessReportSuccess implements Action {
  readonly type = DownloadCenterActionTypes.ProcessReportSuccess
  constructor() {}
}

export class ProcessReportFailed implements Action {
  readonly type = DownloadCenterActionTypes.ProcessReportFailed
  constructor() {}
}

export class DeleteReport implements Action {
  readonly type = DownloadCenterActionTypes.DeleteReport
  constructor(public payload: { reportId: number }) {}
}

export class DeleteReportSuccess implements Action {
  readonly type = DownloadCenterActionTypes.DeleteReportSuccess
  constructor() {}
}

export class DeleteReportFailed implements Action {
  readonly type = DownloadCenterActionTypes.DeleteReportFailed
  constructor() {}
}

export class DownloadReport implements Action {
  readonly type = DownloadCenterActionTypes.DownloadReport
  constructor(public payload: { reportId: number; fileName: string }) {}
}

export class DownloadReportSuccess implements Action {
  readonly type = DownloadCenterActionTypes.DownloadReportSuccess
  constructor() {}
}

export class DownloadReportFailed implements Action {
  readonly type = DownloadCenterActionTypes.DownloadReportFailed
  constructor() {}
}

export type DownloadCenterActions =
  | InitReports
  | InitReportsSuccess
  | InitReportsFailed
  | RequestReport
  | RequestReportSuccess
  | RequestReportFailed
  | ProcessReport
  | ProcessReportSuccess
  | ProcessReportFailed
  | DeleteReport
  | DeleteReportSuccess
  | DeleteReportFailed
  | DownloadReport
  | DownloadReportSuccess
  | DownloadReportFailed
