import { ChangeDetectionStrategy, Component } from '@angular/core'
import { MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { AppStatusService } from 'src/app/app-status/services/app-status.service'

@Component({
  templateUrl: './ios-install.dialog.html',
  styleUrls: ['./ios-install.dialog.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IosInstallDialog {
  constructor(private bottomSheetRef: MatBottomSheetRef<AppStatusService>) {}

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss()
    event.preventDefault()
  }
}
