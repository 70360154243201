export enum TaxCloudColorEnum {
  Primary = '#0066FF',
  Primary_300 = '#4d94ff',
  Primary_200 = '#80b3ff',
  Primary_600 = '#0052cc',
  Primary_700 = '#0041a3',
  Primary_800 = '#00317a',
  Primary_900 = '#002051',
  Accent = '#ff6600',
  Accent_300 = '#ff944d',
  Accent_900 = '#ff3800',
  Error = '#f44336',
  Success = '#4CAF50',
  Secondary = '#9E9E9E',
  Hover = 'F5F5F5',
  Warning = '#ffb900',
  Question = '#212121',
  White = '#FFFFFF',
  Disabled = '#B0B0B0',
  DisabledBackground = '#F0F0F0',
}
