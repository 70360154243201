import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { BehaviorSubject, Observable, combineLatest } from 'rxjs'
import { State as SetupState } from 'src/app/setup/setup.state'
import * as setupActions from 'src/app/setup/actions/setup.actions'
import {
  selectCurrentFormValid,
  selectPendingUsers,
  selectStepperActiveIndex,
} from 'src/app/setup/selectors/setup.selectors'
import { SubSink } from 'subsink'
import { CommonModule } from '@angular/common'
import { SharedPrimeNgModule } from 'src/app/primeng/shared-primeng.module'
import { SetupService } from '../../services/setup.service'

@Component({
  selector: 'tc-setup-stepper-actions',
  standalone: true,
  imports: [CommonModule, SharedPrimeNgModule],
  templateUrl: './setup-stepper-actions.component.html',
  styleUrls: ['./setup-stepper-actions.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetupStepperActionsComponent implements OnInit, OnDestroy {
  private subs = new SubSink()

  constructor(
    private setupStore: Store<SetupState>,
    private setupService: SetupService
  ) {}

  public stepperActiveIndexSubject$: BehaviorSubject<number> = new BehaviorSubject(null)
  public pendingUsersSubject$: BehaviorSubject<any[]> = new BehaviorSubject(null)

  public stepperActiveIndex$: Observable<number> = this.setupStore.select(selectStepperActiveIndex)
  public pendingUsers$: Observable<any[]> = this.setupStore.select(selectPendingUsers)

  public nextButtonText$: BehaviorSubject<string> = new BehaviorSubject('Next')

  public currentFormValid$: Observable<boolean> = this.setupStore.select(selectCurrentFormValid)

  ngOnInit(): void {
    this.subs.add(
      this.stepperActiveIndex$.subscribe((activeIndex: number) => {
        this.stepperActiveIndexSubject$.next(activeIndex)

        const LASTSTEPINDEX = 2
        const buttonText = activeIndex === LASTSTEPINDEX ? 'Finish' : 'Next'
        this.nextButtonText$.next(buttonText)
      })
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  next() {
    this.setupService.setSubmittedFormIndex(this.stepperActiveIndexSubject$.value)
    this.setupStore.dispatch(
      new setupActions.SetStepperActiveIndex({
        activeIndex: Math.min(3, this.stepperActiveIndexSubject$.value + 1),
      })
    )
  }

  back() {
    this.setupStore.dispatch(
      new setupActions.SetStepperActiveIndex({
        activeIndex: Math.max(0, this.stepperActiveIndexSubject$.value - 1),
      })
    )
  }
}
