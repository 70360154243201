import * as fromRouter from '@ngrx/router-store'
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store'
import { ShellEnum } from 'src/app/enums/shell.enums'
import { FeatureKeys } from 'src/app/enums/store.enums'
import { IBreadCrumb } from 'src/app/ui/init-ui/models/ibreadcrumb.models'

import { IRouterState } from '../models/irouterstate'

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface State {
  router: fromRouter.RouterReducerState<any>
}

export const selectRouter = createFeatureSelector<State, fromRouter.RouterReducerState<any>>(
  'router'
)

const {
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = fromRouter.getSelectors(selectRouter)

const getBreadcrumbs = (route: fromRouter.RouterReducerState<IRouterState>): IBreadCrumb[] => {
  if (route && route.state) {
    return route.state.breadcrumbs
  }
  return []
}

const getShell = (route: fromRouter.RouterReducerState<IRouterState>): ShellEnum => {
  if (route && route.state) {
    return route.state.shell
  }
  return ShellEnum.Home
}

const getTitle = (route: fromRouter.RouterReducerState<IRouterState>): string => {
  if (route && route.state) {
    return route.state.title
  }
  return ''
}

const getDescription = (route: fromRouter.RouterReducerState<IRouterState>): string => {
  if (route && route.state) {
    return route.state.description
  }
  return ''
}

const getHost = (route: fromRouter.RouterReducerState<IRouterState>): string => {
  if (route && route.state) {
    return route.state.host
  }
  return ''
}

export const selectRouteState: MemoizedSelector<
  object,
  fromRouter.RouterReducerState<IRouterState>
> = createFeatureSelector<fromRouter.RouterReducerState<IRouterState>>(FeatureKeys.Route)

export const selectBreadcrumbs: MemoizedSelector<object, IBreadCrumb[]> = createSelector(
  selectRouteState,
  getBreadcrumbs
)

export const selectShell: MemoizedSelector<object, ShellEnum> = createSelector(
  selectRouteState,
  getShell
)

export const selectHost: MemoizedSelector<object, string> = createSelector(
  selectRouteState,
  getHost
)

export const selectTitle: MemoizedSelector<object, string> = createSelector(
  selectRouteState,
  getTitle
)

export const selectDescription: MemoizedSelector<object, string> = createSelector(
  selectRouteState,
  getDescription
)
