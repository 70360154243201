import { SetupActions, SetupActionTypes } from '../actions/setup.actions'
import { initialState, State } from '../setup.state'

export function reducer(state = initialState, action: SetupActions): State {
  switch (action.type) {
    case SetupActionTypes.SetStepperActiveIndex:
      return {
        ...state,
        activeIndex: action.payload.activeIndex,
      }
    case SetupActionTypes.CompleteSetup:
      return {
        ...state,
        setupComplete: true,
      }
    case SetupActionTypes.SetPendingUsers:
      return {
        ...state,
        pendingUsers: action.payload.pendingUsers,
      }
    case SetupActionTypes.SetStepLoading:
      return {
        ...state,
        stepLoading: action.payload.loading,
      }
    case SetupActionTypes.SetFinalizeLoading:
      return {
        ...state,
        finalizeLoading: action.payload.loading,
      }
    case SetupActionTypes.SetCurrentFormValid:
      return {
        ...state,
        currentFormValid: action.payload.valid,
      }
    case SetupActionTypes.ResetState:
      return initialState
    case SetupActionTypes.InitOnboardingDetailsSuccess:
      return {
        ...state,
        onboardingDetails: action.payload.onboardingDetailsResponse.merchantOnboardingDetails,
        platforms: action.payload.onboardingDetailsResponse.merchantOnboardingPlatforms,
        taxNeeds: action.payload.onboardingDetailsResponse.merchantOnboardingTaxNeeds,
      }
    case SetupActionTypes.PutOnboardingDetailsSuccess:
      return {
        ...state,
        onboardingDetails: action.payload.onboardingDetailsResponse.merchantOnboardingDetails,
        platforms: action.payload.onboardingDetailsResponse.merchantOnboardingPlatforms,
        taxNeeds: action.payload.onboardingDetailsResponse.merchantOnboardingTaxNeeds,
      }
    case SetupActionTypes.SetIsIntegrationsOnboarding:
      return {
        ...state,
        isIntegrationsOnboarding: action.payload.isIntegrationsOnboarding,
      }
    case SetupActionTypes.InitIsOnboardingCompletedSuccess:
      return {
        ...state,
        isOnboardingCompleted: action.payload.onboardingCompletedResponse.completed,
      }
    default:
      return state
  }
}
