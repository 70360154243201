export enum PaymentMethodTypeEnum {
  CreditCard = 'credit-card',
  Checking = 'checking',
  Saving = 'saving',
}
export enum AccountHolderTypeEnum {
  Unknown = 0,
  Business = 1,
  Personal = 2,
}

export const PaymentMethodTypeEnumLabel = new Map<PaymentMethodTypeEnum, string>([
  [PaymentMethodTypeEnum.CreditCard, 'Credit Card'],
  [PaymentMethodTypeEnum.Checking, 'Checking Account'],
  [PaymentMethodTypeEnum.Saving, 'Savings Account'],
])

export enum PaymentTypeEnum {
  Both = 'both',
  Fee = 'fee',
  Remittance = 'remittance',
}

export const PaymentTypeEnumLabel = new Map<PaymentTypeEnum, string>([
  [PaymentTypeEnum.Both, 'Both Services and Tax Remittance'],
  [PaymentTypeEnum.Fee, 'Only Service Fees'],
  [PaymentTypeEnum.Remittance, 'Only Remittance'],
])
