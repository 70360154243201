import { HttpClient, HttpContext, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import {
  Customer,
  CustomerUsage,
  CustomerUsageReport,
  Entitlement,
  Invoice,
  InvoicePdfLink,
  PaymentMethod,
  PlanDetail,
  PlanSubscription,
  SubscriptionEntitlements,
} from '../models/billing.model'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { PaymentMethodType, PaymentRole } from '../enums/payments.enums'
import { SUPPRESS_ON_404_ERROR_NOTIFY } from 'src/app/root/api-interceptor'
import { SubscriptionPlanTier } from '../enums/suscriptions.enums'
import { Moment } from 'moment'
import { KEY_VIEWED_TRIAL_EXPIRING } from '../constants/local-storage.constants'

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  private billingServiceUrl = environment.billingServiceUrl
  constructor(private http: HttpClient) {}

  // Customers & Payment Methods
  getCustomerPaymentMethods(customerId: number): Observable<PaymentMethod[]> {
    return this.http
      .get(`${this.billingServiceUrl}/customers/${customerId}/payment-methods`)
      .pipe(map((paymentMethods: PaymentMethod[]) => paymentMethods))
  }

  addPaymentMethod(
    customerId: number,
    tokenId: any,
    paymentSourceType: PaymentMethodType
  ): Observable<PaymentMethod> {
    return this.http
      .post(`${this.billingServiceUrl}/customers/${customerId}/payment-methods`, {
        paymentSourceType: paymentSourceType,
        tokenId: tokenId,
      })
      .pipe(map((paymentMethod: PaymentMethod) => paymentMethod))
  }

  deletePaymentMethod(customerId: number, id: string): Observable<any> {
    return this.http.delete(
      `${this.billingServiceUrl}/customers/${customerId}/payment-methods/${id}`
    )
  }

  getCustomerSubscription(
    customerId: number,
    suppress404Error: boolean = false,
    hasScheduledChanges: boolean = false
  ): Observable<PlanSubscription> {
    const context = new HttpContext()
    context.set(SUPPRESS_ON_404_ERROR_NOTIFY, suppress404Error)
    return this.http
      .get(
        `${this.billingServiceUrl}/customers/${customerId}/subscription?scheduled_changes=${hasScheduledChanges}`,
        { context }
      )
      .pipe(map((subscription: PlanSubscription) => subscription))
  }

  getAchUploadSession(customerId: number): Observable<string> {
    return this.http
      .get(`${this.billingServiceUrl}/customers/${customerId}/payment-methods/ach-upload-session`)
      .pipe(map((res: any) => res.billingRequestFlowId))
  }

  getCustomerProfile(customerId: number): Observable<Customer> {
    return this.http
      .get(`${this.billingServiceUrl}/customers/${customerId}/`)
      .pipe(map((res: Customer) => res))
  }

  updateCustomerProfile(customerId: number, customer: Partial<Customer>): Observable<Customer> {
    return this.http
      .put(`${this.billingServiceUrl}/customers/${customerId}`, customer)
      .pipe(map((res: Customer) => res))
  }

  assignPaymentRole(
    customerId: number,
    paymentSourceId: string,
    paymentRole: PaymentRole
  ): Observable<null> {
    return this.http
      .put(`${this.billingServiceUrl}/customers/${customerId}/assign-payment-role`, {
        paymentSourceId,
        paymentRole,
      })
      .pipe(map((res) => null))
  }

  getCustomerUsage(
    customerId: number,
    periodStart: Moment,
    periodEnd: Moment
  ): Observable<CustomerUsage> {
    return this.http
      .get(`${this.billingServiceUrl}/customers/${customerId}/usage`, {
        params: new HttpParams({
          fromObject: {
            periodStart: periodStart.toISOString(),
            periodEnd: periodEnd.toISOString(),
          },
        }),
      })
      .pipe(map((usage: CustomerUsage) => usage))
  }

  getCustomerUsageReports(customerId: number): Observable<CustomerUsageReport[]> {
    return this.http
      .get(`${this.billingServiceUrl}/customers/${customerId}/usage_reports`)
      .pipe(map((reports: CustomerUsageReport[]) => reports))
  }

  getCustomerUsageReport(customerId: number, id: string): Observable<CustomerUsageReport> {
    return this.http
      .get(`${this.billingServiceUrl}/customers/${customerId}/usage_reports/${id}`)
      .pipe(map((report: CustomerUsageReport) => report))
  }

  // Subscriptions
  getSubscription(subscriptionId: string): Observable<PlanSubscription> {
    return this.http
      .get(`${this.billingServiceUrl}/subscription/${subscriptionId}`)
      .pipe(map((subscription: PlanSubscription) => subscription))
  }

  putSubscription(
    customerId: number,
    priceId: string,
    quantity: number,
    isFreeTrial: boolean = false
  ): Observable<PlanSubscription> {
    return this.http
      .put(`${this.billingServiceUrl}/customers/${customerId}/subscription`, {
        priceId,
        quantity,
        isFreeTrial,
      })
      .pipe(map((subscription: PlanSubscription) => subscription))
  }

  cancelSubscription(customerId: number, priceId: string): Observable<PlanSubscription> {
    return this.http
      .put(`${this.billingServiceUrl}/customers/${customerId}/subscription/cancel`, { priceId })
      .pipe(map((subscription: PlanSubscription) => subscription))
  }

  // Subscription Entitlements
  getSubscriptionEntitlements(subscriptionId: string): Observable<SubscriptionEntitlements[]> {
    return this.http
      .get(`${this.billingServiceUrl}/subscriptions/${subscriptionId}/entitlements`)
      .pipe(map((entitlements: SubscriptionEntitlements[]) => entitlements))
  }

  getEntitlements(entityId: string): Observable<Entitlement[]> {
    return this.http
      .get(`${this.billingServiceUrl}/entitlements?entityId=${entityId}`)
      .pipe(map((entitlements: Entitlement[]) => entitlements))
  }

  // Invoices
  getInvoice(invoiceId: string): Observable<Invoice> {
    return this.http
      .get(`${this.billingServiceUrl}/invoices/${invoiceId}`)
      .pipe(map((invoice: Invoice) => invoice))
  }

  getInvoices(customerId: number): Observable<Invoice[]> {
    return this.http
      .get(`${this.billingServiceUrl}/customers/${customerId}/invoices`)
      .pipe(map((invoices: Invoice[]) => invoices))
  }

  getInvoicePdfUrl(invoiceId: string): Observable<string> {
    return this.http
      .get(`${this.billingServiceUrl}/invoices/${invoiceId}/pdf`)
      .pipe(map((invoicePdfRes: InvoicePdfLink) => invoicePdfRes.pdfLink))
  }

  // Plans
  getPlan(planId: SubscriptionPlanTier): Observable<PlanDetail> {
    return this.http
      .get(`${this.billingServiceUrl}/plans/${planId}`)
      .pipe(map((planDetail: PlanDetail) => planDetail))
  }

  setViewedTrialExpiringDialog(type: 'expiring_soon' | 'expired'): void {
    localStorage.setItem(KEY_VIEWED_TRIAL_EXPIRING, type)
  }

  getViewedTrialExpiringDialog(): 'expiring_soon' | 'expired' | null {
    const item = localStorage.getItem(KEY_VIEWED_TRIAL_EXPIRING)
    if (item !== 'expiring_soon' && item !== 'expired') {
      return null
    }
    return item
  }
}
