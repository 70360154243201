<div [ngClass]="borderCss" class="wrapper mat-typography">
  <div [ngClass]="colorCss" class="header color margin_l_sm margin_t_sm">
    <mat-icon class="margin_r_sm">{{icon}}</mat-icon>
    Terms of Service
  </div>

  <tc-terms-of-service (acceptChange)="onAcceptChange($event)"> </tc-terms-of-service>
  <div></div>

  <div class="action margin_b_sm padding_t_sm margin_r_sm">
    <button
      mat-button
      [ngClass]="colorCss"
      [disabled]="(acceptTermsOfService$ | async) === (false)"
      (click)="confirm()"
    >
      {{confirmButton}}
    </button>
    <button mat-button (click)="cancel()">{{cancelButton}}</button>
  </div>
</div>
