import { Component, Input } from '@angular/core'
import { AlertTypeEnum } from '../../../../../enums/alert.enums'

@Component({
  selector: 'tc-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.sass'],
})
export class CardHeaderComponent {
  background = `${AlertTypeEnum.Information.toString()}-background-dark`
  constructor() {}

  @Input('type')
  set setInput(type: string) {
    if (type === 'primary') {
      type = 'information'
    }
    this.background = `${type}-background-dark`
  }
}
