import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, catchError, map, mergeMap, of, switchMap } from 'rxjs'
import { DownloadCenterState } from '../download-center.state'
import * as actions from '../actions/download-center.actions'
import { DownloadCenterActionTypes } from '../actions/download-center.actions'
import { DownloadCenterService } from '../services/download-center.service'
import { NotificationService } from 'src/app/notification/services/notification.service'

const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again later or contact support.'

@Injectable()
export class DownloadCenterEffects {
  constructor(
    private actions$: Actions,
    private downloadCenterStore: Store<DownloadCenterState>,
    private downloadCenterService: DownloadCenterService,
    private notificationService: NotificationService
  ) {}

  InitReports$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitReports>(DownloadCenterActionTypes.InitReports),
      switchMap(() =>
        this.downloadCenterService.getReportRequests().pipe(
          map((reports) => new actions.InitReportsSuccess({ reports })),
          catchError(() => of(new actions.InitReportsFailed()))
        )
      )
    )
  )

  RequestReport$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.RequestReport>(DownloadCenterActionTypes.RequestReport),
      switchMap(({ payload: { request } }) =>
        this.downloadCenterService.addReportRequest(request).pipe(
          mergeMap(() => {
            this.notificationService.showSuccess({
              title: 'Report requested. Requests may take up to 24 hours to process.',
            })
            this.downloadCenterStore.dispatch(new actions.InitReports())
            return this.downloadCenterService.processRequests(1).pipe(
              map(() => new actions.RequestReportSuccess()),
              catchError(() => of(new actions.RequestReportFailed()))
            )
          }),
          catchError(() => of(new actions.RequestReportFailed()))
        )
      )
    )
  )

  ProcessReport$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ProcessReport>(DownloadCenterActionTypes.ProcessReport),
      switchMap(({ payload: { reportId } }) =>
        this.downloadCenterService.processRequest(reportId).pipe(
          map((response) => {
            if (this.responseIsError(response)) {
              this.notificationService.showError({
                title: DEFAULT_ERROR_MESSAGE,
              })
              return new actions.DeleteReportFailed()
            }
            this.downloadCenterStore.dispatch(new actions.InitReports())
            return new actions.ProcessReportSuccess()
          }),
          catchError(() => of(new actions.ProcessReportFailed()))
        )
      )
    )
  )

  DeleteReport$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.DeleteReport>(DownloadCenterActionTypes.DeleteReport),
      switchMap(({ payload: { reportId } }) =>
        this.downloadCenterService.deleteRequest(reportId).pipe(
          map((response) => {
            if (this.responseIsError(response)) {
              this.notificationService.showError({
                title: DEFAULT_ERROR_MESSAGE,
              })
              return new actions.DeleteReportFailed()
            }
            this.notificationService.showSuccess({
              title: 'Report deleted.',
            })
            this.downloadCenterStore.dispatch(new actions.InitReports())
            return new actions.DeleteReportSuccess()
          }),
          catchError(() => of(new actions.DeleteReportFailed()))
        )
      )
    )
  )

  DownloadReport$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.DownloadReport>(DownloadCenterActionTypes.DownloadReport),
      switchMap(({ payload: { reportId, fileName } }) =>
        this.downloadCenterService.downloadRequest(reportId, fileName).pipe(
          map(() => {
            this.notificationService.showSuccess({
              title: 'Your report download started.',
            })
            return new actions.DownloadReportSuccess()
          }),
          catchError(() => of(new actions.DownloadReportFailed()))
        )
      )
    )
  )

  private responseIsError(response: string) {
    return response.toLowerCase().includes('invalid')
  }
}
