import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import * as fromRouter from '@ngrx/router-store'
import { Store } from '@ngrx/store'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, first, map, pluck, tap } from 'rxjs/operators'
import { IRouterState } from 'src/app/root/models/irouterstate'
import * as fromRouterSelectors from 'src/app/root/selectors/root.selectors'

import { ResetPasswordRequest } from '../../actions/login.actions'
import * as authSelectors from '../../selectors/user.selectors'
import { State as AuthState } from '../../user.state'
import { SubSink } from 'subsink'

@Component({
  templateUrl: './password.page.html',
  styleUrls: ['./password.page.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordPage implements OnInit, OnDestroy {
  private subs = new SubSink()
  formGroup: UntypedFormGroup
  email: UntypedFormControl = new UntypedFormControl('', Validators.email)

  constructor(
    private route: Store<fromRouter.RouterReducerState<IRouterState>>,
    private router: Router,
    private fb: UntypedFormBuilder,
    private authStore: Store<AuthState>
  ) {
    this.formGroup = this.fb.group({
      email: this.email,
    })

    window.addEventListener(
      'hashchange',
      (_) => {
        this.watchHashUpdate()
      },
      false
    )

    this.watchHashUpdate()
    this.oneTimePassword$
      .pipe(
        first(),
        tap((token) => {
          if (token) {
            this.router.navigate([`/password/reset`], { queryParams: { token } })
          }
        })
      )
      .subscribe()
  }

  private oneTimePassword$: Observable<string> = this.route
    .select(fromRouterSelectors.selectRouteState)
    .pipe(
      pluck('state', 'queryParams'),
      map((queryParams) => {
        if (queryParams.x) {
          return queryParams.x
        }
        return null
      }),
      catchError((e) => of(null))
    )

  public isWorking$: Observable<boolean> = this.authStore.select(
    authSelectors.selectAuthorizeIsLoading
  )

  public isPasswordResetEmailSuccess$: Observable<boolean> = this.authStore.select(
    authSelectors.selectPasswordRequestSuccess
  )

  public submittedEmail$: Observable<string> = this.authStore.select(
    authSelectors.selectPasswordResetEmail
  )

  public emailBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject(null)

  public resendEmail(): void {
    this.formGroup.patchValue({ email: this.emailBehaviorSubject.value })
    this.resetPassword()
  }

  public resetPassword(): void {
    const email = this.email.value
    this.authStore.dispatch(new ResetPasswordRequest({ email }))
  }

  public goHome(): void {
    this.router.navigate(['/go'])
  }

  public login(): void {
    this.router.navigate(['/sign-in'])
  }

  ngOnInit(): void {
    this.subs.add(
      this.submittedEmail$.subscribe((email: string) => {
        if (email) {
          this.emailBehaviorSubject.next(email)
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  private watchHashUpdate() {
    if (!location.hash) {
      return
    }

    const token: string = location.hash.substring(1)

    this.router.navigate([`/password/reset`], { queryParams: { token } })
  }
}
