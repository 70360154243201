import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'

import { FeatureKeys } from '../enums/store.enums'
import { AccountEffects } from './effects/account.effects'
import * as fromAccountReducers from './reducers/account.reducer'

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FeatureKeys.Account, fromAccountReducers.reducer),
    EffectsModule.forFeature([AccountEffects]),
  ],
})
export class AccountModule {}
