import { OnboardingDetails, Platforms, TaxNeeds } from './models/onboarding-details.model'

export interface State {
  activeIndex: number
  setupComplete: boolean
  pendingUsers: any[]
  stepLoading: boolean
  finalizeLoading: boolean
  currentFormValid: boolean
  onboardingDetails: OnboardingDetails
  taxNeeds: TaxNeeds[]
  platforms: Platforms[]
  isIntegrationsOnboarding: boolean
  isOnboardingCompleted: boolean
}

export const initialState: State = {
  activeIndex: 0,
  setupComplete: false,
  pendingUsers: [],
  stepLoading: false,
  finalizeLoading: false,
  currentFormValid: false,
  onboardingDetails: null,
  taxNeeds: null,
  platforms: null,
  isIntegrationsOnboarding: false,
  isOnboardingCompleted: null,
}
