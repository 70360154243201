import { ChangeDetectionStrategy, Component } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'tc-not-found',
  templateUrl: './not-found.page.html',
  styleUrls: ['./not-found.page.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {
  constructor(private router: Router) {}

  public goDashboard(): void {
    this.router.navigate(['/go/dashboard'])
  }
}
