import { MessageActions, NotificationActionTypes } from '../actions/notification.actions'
import { adapter, initialState, State } from '../notification.state'

export function reducer(state = initialState, action: MessageActions): State {
  switch (action.type) {
    case NotificationActionTypes.UpsertNotification: {
      return adapter.upsertOne(action.payload.message, state)
    }

    case NotificationActionTypes.UpsertNotifications: {
      return adapter.upsertMany(action.payload.messages, state)
    }

    case NotificationActionTypes.UpdateNotification: {
      return adapter.updateOne(action.payload.message, state)
    }

    case NotificationActionTypes.DeleteNotification: {
      return adapter.removeOne(action.payload.id, state)
    }

    case NotificationActionTypes.ClearNotifications: {
      return adapter.removeAll(state)
    }

    default: {
      return state
    }
  }
}
