import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'
import { FeatureKeys } from 'src/app/enums/store.enums'

import { adapter, State } from '../us-states.store'

const getDataSource = (state: State): DataSourceEnum => {
  return state.source
}

const getISWorking = (state: State): boolean => {
  return state.working.length > 0
}

const getWorkingMessage = (state: State): string => {
  return state.working
}

export const selectUsStateState: MemoizedSelector<object, State> = createFeatureSelector<State>(
  FeatureKeys.UsStates
)

export const selectDataSource: MemoizedSelector<object, DataSourceEnum> = createSelector(
  selectUsStateState,
  getDataSource
)

export const selectIsWorking: MemoizedSelector<object, boolean> = createSelector(
  selectUsStateState,
  getISWorking
)

export const selectWorkingMessage: MemoizedSelector<object, string> = createSelector(
  selectUsStateState,
  getWorkingMessage
)

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectUsStateState)
