import * as _moment from 'moment'
// eslint-disable-next-line no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment'
const moment = _rollupMoment || _moment

export function validateDate(date: string): string | null {
  const test = moment(date)
  if (!test.isValid) {
    return null
  }
  if (test.isBefore(moment('0002-01-01'))) {
    return null
  }
  if (test.isAfter(moment('9998-01-01'))) {
    return null
  }
  return date
}

export function epochToDate(epoch: number | string): Date {
  return new Date(Number(epoch) * 1000)
}
