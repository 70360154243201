<div fxLayout="column" fxLayoutGap="1rem">
  <tc-alert *ngIf="(isAdmin$ | async) === false" type="warning">
    Only Administrators can update state settings.
  </tc-alert>

  <tc-terms-of-service-warning *ngIf="(hasTermsOfService$ | async) === false">
  </tc-terms-of-service-warning>

  <ng-container *ngIf="isLoading$ | async; then loading; else loaded"></ng-container>

  <ng-template #loading>
    <ngx-skeleton-loader
      count="20"
      appearance="line"
      [theme]="{
        height: '150px',
        border: '1rem solid white'
      }"
    >
    </ngx-skeleton-loader>
  </ng-template>

  <ng-template #loaded>
    <ul tcList [@fadeIn] data-cy="manage-states-list">
      <li
        *ngFor="let report of allStates$ | async; last as isLast; trackBy: trackByFn"
        [data-cy]="report.name"
        [attr.collecting]="report.collecting"
        tcListItem
        fxLayout="row wrap"
        fxLayoutAlign="start center"
        fxLayoutGap="1rem"
        fxLayoutAlign.lt-md="space-between center"
        [alert]="
          report.collecting ? 'primary' : report.collectingType === shouldCollect ? 'danger' : null
        "
        lastItem="{{ isLast }}"
      >
        <tc-display-us-state
          fxFlex.lt-sm
          [stateName]="report.name"
          [stateAbbreviation]="report.abbreviation"
        >
        </tc-display-us-state>
        <div
          fxFlex
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayout.lt-sm="column"
          fxLayoutAlign.lt-sm="space-between start"
          fxLayoutGap="1rem"
        >
          <!-- <div></div> -->

          <div
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="1rem"
            fxFlex="100%"
            style="align-items: center; text-align: center"
          >
            <ng-container
              *ngIf="report.economicNexus.ordersThreshold > 0; then andOr; else only"
            ></ng-container>
            <ng-template #andOr>
              <div>
                The {{ report.name }} economic nexus threshold is based on volume of completed
                transactions ({{ report.economicNexus.ordersThreshold }})
                <strong>{{ report.economicNexus.orThresholds ? 'or' : 'and' }}</strong> the value of
                total sales ({{ report.economicNexus.salesThreshold | currency }})
                {{
                  report.economicNexus.useTrailingTwelveMonths
                    ? 'over the trailing 12 months.'
                    : 'in the most recent calendar year.'
                }}
              </div>
              <div tcColor="danger" *ngIf="report.collectingType === shouldCollect">
                Your account has completed {{ report.economicNexus.totalOrders }} transactions
                valued at {{ report.economicNexus.totalSales | currency }} in {{ report.name }} over
                this period.
              </div>
            </ng-template>
            <ng-template #only>
              <div *ngIf="report.economicNexus.salesThreshold > 0">
                The {{ report.name }} economic nexus threshold is based on the value of total sales
                ({{ report.economicNexus.salesThreshold | currency }})
                {{
                  report.economicNexus.useTrailingTwelveMonths
                    ? 'over the trailing 12 months.'
                    : 'in the most recent calendar year.'
                }}
              </div>
              <div tcColor="danger" *ngIf="report.collectingType === shouldCollect">
                Your account has completed {{ report.economicNexus.totalSales | currency }} of
                transactions in {{ report.name }} over this period.
              </div>
            </ng-template>
            <div fxLayoutAlign="space-between" fxLayoutGap="1rem">
              <div>
                <span class="mat-body-strong">Order</span> Threshold:
                {{ report.economicNexus.ordersThreshold | number }}
              </div>
              <div>Current: {{ report.economicNexus.totalOrders | number }}</div>
            </div>
            <div fxLayoutAlign="space-between" fxLayoutGap="1rem">
              <div>
                <span class="mat-body-strong">Sales</span> Threshold:
                {{ report.economicNexus.salesThreshold | currency }}
              </div>
              <div>Current: {{ report.economicNexus.totalSales | currency }}</div>
            </div>
          </div>

          <button mat-button tcColor="primary" [routerLink]="report.abbreviation" tcColorDark>
            MANAGE
          </button>
        </div>
      </li>
    </ul>
  </ng-template>
</div>
