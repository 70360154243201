<div class="h-screen w-screen flex justify-content-center pt-8">
  <section class="content flex flex-column text-center mt-8">
    <h1 class="title">404</h1>
    <h2 class="font-bold text-black-alpha-90">Page Not Found :(</h2>
    <p class="mb-4 line-height-3">
      We're sorry, but the page you're looking for cannot be found.<br />
      If you believe this is an error, please
      <a href="mailto:service@taxcloud.com">contact our support team</a> for assistance.
    </p>
    <div>
      <a pButton label="Back to Homepage" class="p-button-sm no-underline" href="/home"></a>
    </div>
  </section>
</div>
