<div class="mb-1">Password must at least contain:</div>
<ul class="m-0 p-0 list-none">
  <li *ngFor="let requirement of passwordRequirements">
    <i
      [ngClass]="
        requirement.isValid(password)
          ? 'pi pi-check-circle text-teal-800'
          : 'pi pi-times-circle text-red-600'
      "
    ></i>
    <span [ngClass]="requirement.isValid(password) ? 'text-teal-800' : 'text-red-600'">
      {{ requirement.description }}
    </span>
  </li>
</ul>
