import { adapter } from '../notification.state'
import { MemoizedSelector, createFeatureSelector } from '@ngrx/store'
import { State } from '../notification.state'
import { FeatureKeys } from 'src/app/enums/store.enums'

export const selectMessageState: MemoizedSelector<object, State> = createFeatureSelector<State>(
  FeatureKeys.Notification
)

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectMessageState)
