import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import moment, { Moment } from 'moment'

import { DateFormatEnum } from '../enums/date.enums'
import { ITransactionFilter } from './models/itransaction-filter.model'
import { ITransaction } from './models/itransaction.model'

export const adapter: EntityAdapter<ITransaction> = createEntityAdapter<ITransaction>({
  selectId: (model) => model.id,
  sortComparer: (a: ITransaction, b: ITransaction): number => {
    const aLastAction = a.lastActionDate ? a.lastActionDate.toString() : ''
    const bLastAction = b.lastActionDate ? b.lastActionDate.toString() : ''
    return bLastAction.localeCompare(aLastAction)
  },
})

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface State extends EntityState<ITransaction> {
  initialLoadComlete: boolean
  working: boolean
  downloading: boolean
  message: { title: string; paragraphs: string[] }
  filingIds: any[]
  page: number
  limit: number
  complete: boolean
  filter: ITransactionFilter
  detailId: string | null
  lastUpdated: string | null
}

export const initialState: State = adapter.getInitialState({
  initialLoadComlete: false,
  working: true,
  downloading: false,
  page: 1,
  limit: 150,
  message: { title: '', paragraphs: [''] },
  filingIds: [],
  complete: false,
  filter: getDefaultFilter(),
  detailId: null,
  lastUpdated: null,
})

export function getDefaultFilter(): ITransactionFilter {
  const date: Moment = moment()
  const month = date.format(DateFormatEnum.Month)

  const initFilter: ITransactionFilter = {
    month,
  }

  return initFilter
}
