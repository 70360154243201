export enum AlertTypeEnum {
  Information = 'information',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Danger = 'danger',
  Question = 'question',
  Primary = 'primary',
  Accent = 'accent',
  Secondary = 'secondary',
  BlogAlert = 'blog-alert',
}

export enum AlertResultEnum {
  Confirm = 'CONFIRM',
  Cancel = 'CANCEL',
}
