import { Injectable } from '@angular/core'
import {
  ApplicationInsights,
  IEventTelemetry,
  IExceptionTelemetry,
} from '@microsoft/applicationinsights-web'
import { environment } from 'src/environments/environment'

import { NotificationService } from '../../notification/services/notification.service'

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsights.instrumentationKey,
      enableAutoRouteTracking: true,
    },
  })

  constructor(private notify: NotificationService) {
    this.appInsights.loadAppInsights()
  }

  logEvent(name: string, properties?: any, measurements?: any): void {
    const tel: IEventTelemetry = {
      name,
      properties,
      measurements,
    }

    if (environment.logging.appInsights) {
      this.appInsights.trackEvent(tel)
    }
    if (environment.logging.console) {
      if (properties) {
        console.log(`${name}: `, properties || null)
      } else {
        console.log(name)
      }
    }
  }

  logError(exception: Error): void {
    const tel: IExceptionTelemetry = {
      exception,
    }
    if (environment.logging.appInsights) {
      this.appInsights.trackException(tel)
    }

    if (environment.logging.console) {
      console.error(exception)
    }

    if (environment.logging.appShowError) {
      this.notify.showError({ title: exception.message || 'Something broke' })
    }
  }
}
