import moment, { Moment } from 'moment'
import { IAddress } from 'src/app/address/models/iaddress'
import { DateFormatEnum } from 'src/app/enums/date.enums'
import { ServicePlanTypeEnum } from 'src/app/enums/service-plan.enums'
import {
  JurisdictionTypeEnum,
  TransactionStateEnum,
  TransactionStateEnumLabel,
  TransactionUpdateEnum,
} from 'src/app/enums/transaction.enums'
import { validateDate } from 'src/app/utilities/date.utilities'

import { IApiSalesTotal } from '../models/iapi-sales-total'
import {
  IApiCartItem,
  IServiceResultTransaction,
  ITransactionApiFilter,
} from '../models/iapi-transactions.models'
import { IJurisditionReport } from '../models/ijuridiction-report.model'
import { ISalesTotal } from '../models/isales-total'
import { ITransactionCartItem } from '../models/itransaction-cart-item.model'
import { ITransactionDetail } from '../models/itransaction-detail.model'
import { ITransactionFilter } from '../models/itransaction-filter.model'
import { ITransactionUpdate } from '../models/itransaction-update'
import { ITransaction } from '../models/itransaction.model'

export class TransactionAdapter {
  static mapFilterToDownloadApiFilter(
    filter: ITransactionFilter,
    isSummary: boolean,
    isConsolidated: boolean
  ): ITransactionApiFilter {
    const apiFilter = {
      ...this.parseFilter(filter),
      showLineItems: (!isSummary).toString(),
    }
    return isConsolidated
      ? { ...apiFilter, suppressOrigDifferentMonth: isConsolidated.toString() }
      : apiFilter
  }

  static mapUtcTimeToLocalTime(utc: string): string {
    const local = moment.utc(utc).local().format()
    return local
  }

  static mapFilterToApiFilter(
    filter: ITransactionFilter = {},
    page: number = 1,
    pageLimit: number = 250
  ): ITransactionApiFilter {
    const apiFilter: ITransactionApiFilter = { page: page.toString(), limit: pageLimit.toString() }

    return {
      ...apiFilter,
      ...this.parseFilter(filter),
    }
  }

  static mapApiTransactionDetailToTransationDetail(api: any): ITransactionDetail {
    const basic = this.mapApiTransactionsToTransactions(api)
    const origin: IAddress = {
      address1: api.originAddress1,
      address2: api.originAddress2,
      city: api.originCity,
      stateAbbreviation: api.originState,
      zip5: api.originZipCode,
      zip4: '',
    }
    const jurisdictionReport = this.mapTransactionDetailToJurisdictionReport(api.transactionDetails)
    const cartItems = this.mapTransactionDetailToCartReport(api.transactionDetails)
    return { ...basic, origin, jurisdictionReport, cartItems }
  }

  static mapApiTransactionsToTransactions(api: IServiceResultTransaction): ITransaction {
    const lookupDate = validateDate(api.transactionOn)
    const authorizedDate = validateDate(api.authorizedOn)
    const capturedDate = validateDate(api.capturedOn)
    const returnedDate = validateDate(api.returnedOn)

    const state = returnedDate
      ? TransactionStateEnum.Returned
      : capturedDate
        ? TransactionStateEnum.Caputured
        : authorizedDate
          ? TransactionStateEnum.Authorized
          : TransactionStateEnum.Lookup

    const stateLabel = TransactionStateEnumLabel.get(state)
    const destination: IAddress = {
      address1: api.shipToAddress1,
      address2: api.shipToAddress2,
      city: api.shipToCity,
      stateAbbreviation: api.shipToState,
      zip5: api.shipToZipCode,
      zip4: '',
    }

    return {
      id: api.id,
      cartId: api.cartID,
      customerId: api.customerID,
      orderId: api.orderID,
      hitCount: api.hitcount,
      state,
      stateLabel,
      total: api.total,
      totalSales: api.totalSales,
      totalTax: api.totalTax,
      isAdjusted: api.hasAdjustment,
      isLate: api.isLate,
      adjustedTax: api.adjustedTax,
      lastActionDate: validateDate(api.lastAction),
      lookupDate,
      authorizedDate,
      capturedDate,
      returnedDate,
      destination,
      exemptCertificateId: api.exemptCertificateID,
    }
  }

  static mapTransactionDetailToCartReport(transactions: IApiCartItem[]): ITransactionCartItem[] {
    return transactions
      .map((item) => {
        return {
          id: item.itemID,
          index: parseInt(item.cartItemIndex.toString(), 10),
          quantity: item.qty,
          subtotal: item.subTotal,
          price: item.itemPrice,
          ticId: item.tic,
          hasAdjustment: item.hasAdjustment,
          adjustedRate: item.adjustedRate,
          adjustedTax: item.adjustedTax,
          returnedDate: validateDate(item.returnedOn),
          tax: item.taxAmount,
          rate: item.totalRate,
          total: item.total,
        }
      })
      .sort((a, b) => a.index - b.index)
  }

  static mapTransactionDetailToJurisdictionReport(
    transactions: IApiCartItem[]
  ): IJurisditionReport[] {
    const jurMap = new Map()
    const jurMapItem = {
      type: '',
      items: [],
    }

    transactions.map((item) => {
      if (item.stateTax) {
        if (!jurMap.has(JurisdictionTypeEnum.State)) {
          jurMap.set(JurisdictionTypeEnum.State, {
            type: JurisdictionTypeEnum.State,
            label: item.stateName,
            order: 1,
            items: [
              {
                ticId: item.tic,
                rate: item.stateRate,
              },
            ],
          })
        } else {
          const cached = jurMap.get(JurisdictionTypeEnum.State)

          jurMap.set(JurisdictionTypeEnum.State, {
            ...cached,
            ...{
              items: cached.items
                .concat({
                  ticId: item.tic,
                  rate: item.stateRate,
                })
                .filter(
                  (element, index, self) =>
                    self.findIndex(
                      (prop) => prop.ticId === element.ticId && prop.rate === element.rate
                    ) === index
                ),
            },
          })
        }
      }

      if (item.countyCode) {
        if (!jurMap.has(JurisdictionTypeEnum.County)) {
          jurMap.set(JurisdictionTypeEnum.County, {
            type: JurisdictionTypeEnum.County,
            label: `${item.countyName} COUNTY` || 'Unknown County',
            order: 2,
            items: [
              {
                ticId: item.tic,
                rate: item.countyRate,
              },
            ],
          })
        } else {
          const cached = jurMap.get(JurisdictionTypeEnum.County)

          jurMap.set(JurisdictionTypeEnum.County, {
            ...cached,
            ...{
              items: cached.items
                .concat({
                  ticId: item.tic,
                  rate: item.countyRate,
                })
                .filter(
                  (element, index, self) =>
                    self.findIndex(
                      (prop) => prop.ticId === element.ticId && prop.rate === element.rate
                    ) === index
                ),
            },
          })
        }
      }

      if (item.cityCode) {
        if (!jurMap.has(JurisdictionTypeEnum.City)) {
          jurMap.set(JurisdictionTypeEnum.City, {
            type: JurisdictionTypeEnum.City,
            label: item.cityName || 'Unknown City',
            order: 3,
            items: [
              {
                ticId: item.tic,
                rate: item.cityRate,
              },
            ],
          })
        } else {
          const cached = jurMap.get(JurisdictionTypeEnum.City)

          jurMap.set(JurisdictionTypeEnum.City, {
            ...cached,
            ...{
              items: cached.items
                .concat({
                  ticId: item.tic,
                  rate: item.cityRate,
                })
                .filter(
                  (element, index, self) =>
                    self.findIndex(
                      (prop) => prop.ticId === element.ticId && prop.rate === element.rate
                    ) === index
                ),
            },
          })
        }
      }

      if (item.otherCode && item.otherCode !== item.cityCode) {
        if (!jurMap.has(JurisdictionTypeEnum.Other)) {
          jurMap.set(JurisdictionTypeEnum.Other, {
            type: JurisdictionTypeEnum.Other,
            label: item.otherName || 'Unknown Other',
            order: 4,
            items: [
              {
                ticId: item.tic,
                rate: item.otherRate,
              },
            ],
          })
        } else {
          const cached = jurMap.get(JurisdictionTypeEnum.Other)

          jurMap.set(JurisdictionTypeEnum.Other, {
            ...cached,
            ...{
              items: cached.items
                .concat({
                  ticId: item.tic,
                  rate: item.otherRate,
                })
                .filter(
                  (element, index, self) =>
                    self.findIndex(
                      (prop) => prop.ticId === element.ticId && prop.rate === element.rate
                    ) === index
                ),
            },
          })
        }
      }
    })

    return Array.from(jurMap.values()).sort((a, b) =>
      a.order > b.order ? 1 : b.order > a.order ? -1 : 0
    )
  }

  private static parseFilter(filter: ITransactionFilter = {}) {
    if (typeof filter !== 'object') {
      filter = {}
    }

    const apiFilter: ITransactionApiFilter = {}
    const transactionTypes = []
    const { isLookup, isCaptured, isTest, filingId, deltaId, uploadId, month } = filter

    if (filter.filingId) {
      apiFilter.filingIds = filter.filingId.toString()
    }
    if (filter.deltaId) {
      apiFilter.deltaIds = filter.deltaId.toString()
    }
    if (filter.usState) {
      apiFilter.state = filter.usState.toString()
    }
    if (filter.isTest) {
      apiFilter.testTransactions = filter.isTest.toString()
    }
    if (filter.isOffline) {
      apiFilter.offline = filter.isOffline.toString()
    }
    if (filter.isLate) {
      apiFilter.lateTransactions = filter.isLate.toString()
    }
    if (filter.search) {
      apiFilter.search = filter.search.toString()
    }
    if (filter.isCaptured) {
      transactionTypes.push('captured')
    }
    if (filter.isAuthorized) {
      transactionTypes.push('authorized')
    }
    if (filter.isLookup) {
      transactionTypes.push('lookup')
    }
    if (filter.isReturned) {
      transactionTypes.push('returned')
    }

    if (transactionTypes.length > 0) {
      apiFilter.transactionTypes = transactionTypes.join(',')
    }

    let hasMonth = month ? true : false

    if (!filingId && !deltaId && !uploadId && !month) {
      hasMonth = true
    }

    let m: Moment

    if (hasMonth) {
      if (!month) {
        m = moment()
      } else {
        m = moment(month, DateFormatEnum.Month)
      }

      if (filter.startDay) {
        apiFilter.createdAtMin = m.date(filter.startDay).format(DateFormatEnum.Day)
      } else {
        apiFilter.createdAtMin = m.date(1).format(DateFormatEnum.Day)
      }

      if (filter.endDay) {
        apiFilter.createdAtMax = m.date(filter.endDay).format(DateFormatEnum.Day)
      } else {
        apiFilter.createdAtMax = m.endOf('month').format(DateFormatEnum.Day)
      }
    }
    return apiFilter
  }

  static mapTransactionUpdateResponse(
    response: string,
    type: TransactionStateEnum
  ): ITransactionUpdate {
    let result: TransactionUpdateEnum

    switch (type) {
      case TransactionStateEnum.Caputured:
        result = this.parseCapturedResult(response)
        break

      case TransactionStateEnum.Returned:
        result = this.parseCapturedResult(response)
        break

      default: {
        result = TransactionUpdateEnum.Error
      }
    }

    const update: ITransactionUpdate = {
      message: response,
      result,
      type,
    }

    return update
  }

  static parseCapturedResult(response: string): TransactionUpdateEnum {
    return TransactionUpdateEnum.Success
  }

  static parseReturnedResult(response: string): TransactionUpdateEnum {
    return response.indexOf('successfully') > -1
      ? TransactionUpdateEnum.Success
      : response.indexOf('Cannot') > -1
        ? TransactionUpdateEnum.Redundent
        : TransactionUpdateEnum.Error
  }

  static mapApiSalesTotalToSalesTotal(api: IApiSalesTotal): ISalesTotal {
    const { result } = api
    return {
      month: result.period,
      label: moment(result.period, DateFormatEnum.Day).format(DateFormatEnum.DisplayMonthYear),
      planType:
        result.merchantACDuringPeriod === 'True'
          ? ServicePlanTypeEnum.Automated
          : ServicePlanTypeEnum.Selective,
      totalDetail: {
        memberStates: {
          label: 'TaxCloud Member',
          tax: result.totalTaxFilingResponsibilityMember,
          sales: result.totalSalesFilingResponsibilityMember,
        },
        directStates: {
          label: 'TaxCloud Direct',
          tax: result.totalTaxFilingResponsibilityDirect,
          sales: result.totalSalesFilingResponsibilityDirect,
        },
        merchantStates: {
          label: 'Merchant Responsibility',
          tax:
            result.totalTaxFilingResponsibilityMerchant +
            result.totalTaxFilingResponsibilityUnknown,
          sales:
            result.totalSalesFilingResponsibilityMerchant +
            result.totalSalesFilingResponsibilityUnknown,
        },
      },
    }
  }
}
