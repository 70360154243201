import { TaxCloudColorEnum } from 'src/app/enums/color.enums'
import { IStateShapeData } from '../models/istate-shape-data'

export function convertShapeDataToDictionary(
  data: IStateShapeData[]
): Map<string, IStateShapeData> {
  return new Map(
    data.map((item) => [
      item.abbreviation,
      {
        ...item,
        fillColor: TaxCloudColorEnum.Hover,
        selected: false,
      },
    ])
  )
}
