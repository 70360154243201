import Dexie from 'dexie'
import { Observable, from } from 'rxjs'
import { IMail } from './../models/imail'

export class MailDatabase extends Dexie {
  private mails: Dexie.Table<IMail, number>

  constructor() {
    super('MailDB')
    this.version(1).stores({
      mails: 'documentId,merchantName,contactName,discoveryDate',
    })
    this.mails = this.table('mails')
  }

  public saveMails(mails: IMail[]): Observable<number> {
    return from(this.mails.bulkPut(mails))
  }

  public updateMail(mail: IMail) {
    return from(this.mails.put(mail))
  }

  public clearMails(): Observable<void> {
    return from(this.mails.clear())
  }

  public getMails(pageNumber: number, limit: number): Observable<IMail[]> {
    return from(
      this.mails
        .orderBy('discoveryDate')
        .reverse()
        .offset(pageNumber * limit - limit)
        .limit(limit)
        .toArray()
    )
  }

  public getMail(documentId: string): Observable<IMail | undefined> {
    return from(this.mails.get({ documentId }))
  }

  public async findMail(name: string) {
    return this.mails.where('contactName').startsWithIgnoreCase(name)
  }
}
