<section>
  <p-card class="register-card" styleClass="border-round-xl shadow-4">
    <div
      class="flex flex-column w-full justify-content-center align-items-center"
      *ngIf="(shouldLogOut$ | async) === true
      && isRegisterFormSubmitted===false"
    >
      <p-message
        styleClass="my-4"
        severity="warn"
        text="You cannot create a new account while signed in, please sign out before registering."
      ></p-message>
      <button pButton pRipple label="Sign out" class="p-button mb-4" (click)="logout()"></button>
    </div>
    <div
      *ngIf="(shouldLogOut$ | async) === false && (emailToVerify$ | async) === undefined"
      class="panel-conntainer grid-nogutter border-round"
    >
      <ng-container *ngIf="(isLoading$ | async) || isRegisterFormSubmitted; else showRegister">
        <div class="progress-spinner">
          <p-progressSpinner animationDuration="1s" styleClass="text-primary"></p-progressSpinner>
        </div>
      </ng-container>
      <ng-template #showRegister>
        <div
          class="hidden testimonial-panel md:flex flex-wrap justify-content-center align-items-center border-round col-12 md:col-6"
        >
          <div class="flex flex-column">
            <h3 class="font-bold w-full">Try TaxCloud for Free!</h3>
            <p>First-time signups receive a 30-day trial of our Premium subscription plan!</p>
            <p>
              Join thousands of businesses that have unlocked features designed to make sales tax
              compliance a breeze:
            </p>
            <ul class="list-none p-0 flex flex-column gap-3">
              <li class="flex flex-row align-items-center">
                <i class="pi pi-check-circle text-green-500 mr-2" style="font-size: 1.2rem"></i>
                <div class="flex flex-column p-2">
                  <p class="m-0 mb-1 font-medium text-sm">Automated Tax Calculations</p>
                  <p class="m-0 text-sm">Ensure every transaction is accurate and compliant.</p>
                </div>
              </li>
              <li class="flex flex-row align-items-center">
                <i class="pi pi-check-circle text-green-500 mr-2" style="font-size: 1.2rem"></i>
                <div class="flex flex-column p-2">
                  <p class="m-0 mb-1 font-medium text-sm">Effortless Tax Filing</p>
                  <p class="m-0 text-sm">Meet all your deadlines without stress.</p>
                </div>
              </li>
              <li class="flex flex-row align-items-center">
                <i class="pi pi-check-circle text-green-500 mr-2" style="font-size: 1.2rem"></i>
                <div class="flex flex-column p-2">
                  <p class="m-0 mb-1 font-medium text-sm">Comprehensive Reporting</p>
                  <p class="m-0 text-sm">Keep your business audit-ready with detailed reports.</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="companies-container">
            <p class="font-medium text-center">
              Join 2,000+ companies that have automated their sales tax compliance with TaxCloud.
            </p>
            <div class="flex gap-4 justify-content-center align-items-center">
              <img width="60px" src="/assets/images/company-logo-3.png" />
              <img width="59px" height="24px" src="/assets/images/company-logo-1.png" />
              <img width="66px" height="16px" src="/assets/images/company-logo-2.png" />
              <img width="60px" src="/assets/images/company-logo-4.png" />
            </div>
          </div>
        </div>
        <div class="register-panel border-round col-12 md:col-6">
          <div class="register-panel-content grid flex justify-content-center p-5 md:p-6">
            <div class="col-12 flex justify-content-center">
              <img height="24px" src="/assets/icons/taxcloud-logo-text.svg" alt="TaxCloud" />
            </div>
            <h2 class="col-12 flex justify-content-center font-bold text-800 my-3">
              Create Your Free Account
            </h2>
            <div class="col-12 flex flex-column justify-content-center align-items-center">
              <p-chip styleClass="bg-blue-100 py-1 px-3 mb-2"
                ><i class="pi pi-credit-card"></i>&nbsp;<span class="text-xs font-medium cc-chip"
                  >No credit card required</span
                ></p-chip
              >
              <p-divider class="w-full"></p-divider>
            </div>
            <tc-register-form
              class="col-12"
              [preFillForm]="email$ | async"
              (changeRegistration)="register($event)"
              (goToLogin)="login()"
            >
            </tc-register-form>
            <!-- <p-button
              (click)="registerWithGoogle()"
              class="flex justify-content-center"
              styleClass="p-button-text p-0 mb-4 mt-2"
            >
              <ng-template pTemplate="content">
                <img alt="google-sign-up" src="assets/svg/google-sign-in/web_light_sq_SU.svg" />
              </ng-template>
            </p-button> -->
          </div>
        </div>
      </ng-template>
    </div>
  </p-card>
</section>
