import { Injectable } from '@angular/core'
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'
import { filter, first, tap } from 'rxjs/operators'
import { AccountStateEnum } from 'src/app/enums/account.enums'
import * as userSelectors from 'src/app/user/selectors/user.selectors'
import * as accountSelectors from 'src/app/account/selectors/account.selectors'
import { State as UserState } from 'src/app/user/user.state'
import { State as AccountState } from 'src/app/account/account.state'
import { TosService } from './../../terms-of-service/services/terms-of-service.service'

@Injectable({
  providedIn: 'root',
})
export class TermsOfServiceGuard implements CanActivate {
  private hasLatestTos$: Observable<boolean> = this.userStore.pipe(
    select(userSelectors.selectHasLatestTermsOfService)
  )

  private isInternal$: Observable<boolean> = this.userStore.pipe(
    select(userSelectors.selectIsInternalUser)
  )

  private isMasquerading$: Observable<boolean> = this.userStore.pipe(
    select(userSelectors.selectUserIsMasquerading)
  )

  private accountStatus$: Observable<AccountStateEnum> = this.accountStore.pipe(
    select(accountSelectors.selectAccountStatus)
  )
  constructor(
    private userStore: Store<UserState>,
    private accountStore: Store<AccountState>,
    private router: Router
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkIfHasLatestTos(state.url)
  }

  checkIfHasLatestTos(url: string): boolean {
    let activate = true
    combineLatest([this.hasLatestTos$, this.isInternal$, this.isMasquerading$, this.accountStatus$])
      .pipe(
        filter(([hasLatestTos, isInternal, isMasquerading, accountStatus]) => !!accountStatus),
        first(),
        tap(([hasLatestTos, isInternal, isMasquerading, accountStatus]) => {
          if (
            !hasLatestTos &&
            accountStatus === AccountStateEnum.Converted &&
            !isInternal &&
            !isMasquerading
          ) {
            activate = false
            this.router.navigate(['/tos/latest'])
          } else {
            activate = true
          }
        })
      )
      .subscribe()

    return activate
  }
}
