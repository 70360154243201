import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { from, Observable, merge } from 'rxjs'
import {
  exhaustMap,
  map,
  pluck,
  tap,
  filter,
  switchMap,
  withLatestFrom,
  first,
} from 'rxjs/operators'
import * as fromAppStatus from 'src/app/app-status/app-status.state'
import * as fromAppStatusSelectors from 'src/app/app-status/selectors/app-status.selectors'

import { IApiLocation } from '../models/iapi-location'
import { ILocationUpdate } from '../models/ilocationupdate'
import { LocationAdapter } from '../utility/location-adapter.utility'
import { USStateService } from './../../us-states/services/us-state.service'
import * as fromLocationActions from './../actions/location.actions'
import { LoctionDatabase } from './../db/location.db'
import * as fromLocation from './../location.store'
import { ILocation } from './../models/ilocation'
import * as fromLocationSelectors from './../selectors/location-selector'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'
import { selectLocationDataSource } from './../selectors/location-selector'

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  localDb = new LoctionDatabase()
  adapter = new LocationAdapter(this.stateService)
  constructor(
    private http: HttpClient,
    private locationStore: Store<fromLocation.State>,
    private appStatus: Store<fromAppStatus.State>,
    private stateService: USStateService
  ) {}

  public getLocations(): Observable<[ILocation[], DataSourceEnum]> {
    return merge(this.getLocationsFromApiWhenOnline(), this.getLocationsFromLocal())
  }

  public refreshLocations(): Observable<ILocation[]> {
    return this.getLocationsFromApi().pipe(map((result) => result[0]))
  }

  public addLocation(addLocation: ILocationUpdate): Observable<[ILocation[], DataSourceEnum]> {
    return this.http
      .post('api/locations/write', this.adapter.mapLocationUpdateToLocationApi(addLocation))
      .pipe(
        map((results: IApiLocation[]) =>
          results.map((result) => this.adapter.mapApiToLocation(result))
        ),
        tap((locations: ILocation[]) => this.localDb.saveLocations(locations)),
        map((locations) => [locations, DataSourceEnum.API])
      )
  }

  public editLocation(id: string | number): Observable<[ILocation[], DataSourceEnum]> {
    return this.locationStore
      .pipe(
        select(fromLocationSelectors.selectEntities),
        pluck(id),
        map((location) => this.adapter.mapLocationToLocationApi(location)),
        exhaustMap((location) => {
          return this.http.post('/api/locations/write', location)
        })
      )
      .pipe(
        map((results: IApiLocation[]) =>
          results.map((result) => this.adapter.mapApiToLocation(result))
        ),
        tap((locations: ILocation[]) => this.localDb.saveLocations(locations)),
        map((locations) => [locations, DataSourceEnum.API])
      )
  }

  public removeLocation(id: string): Observable<[ILocation[], DataSourceEnum]> {
    return this.http.post('/api/locations/remove', { locationId: id }).pipe(
      map((results: IApiLocation[]) =>
        results.map((result) => this.adapter.mapApiToLocation(result))
      ),
      tap((locations: ILocation[]) => this.localDb.saveLocations(locations)),
      map((locations) => [locations, DataSourceEnum.API])
    )
  }

  public clearLocations(): void {
    this.locationStore.dispatch(new fromLocationActions.ClearLocations())
    this.localDb.clearLocations().pipe(first()).subscribe()
  }

  private getLocationsFromApiWhenOnline(): Observable<[ILocation[], DataSourceEnum]> {
    return this.appStatus.select(fromAppStatusSelectors.selectOnline).pipe(
      filter((online) => online),
      switchMap(() => this.getLocationsFromApi())
    )
  }

  private getLocationsFromApi(): Observable<[ILocation[], DataSourceEnum]> {
    return this.http.post('api/locations', null).pipe(
      map((results: IApiLocation[]) =>
        results.map((result) => this.adapter.mapApiToLocation(result))
      ),
      tap((locations: ILocation[]) => this.localDb.saveLocations(locations)),
      map((locations) => [locations, DataSourceEnum.API])
    )
  }

  private getLocationsFromLocal(): Observable<[ILocation[], DataSourceEnum]> {
    return this.localDb.getLocations().pipe(
      withLatestFrom(this.locationStore.select(selectLocationDataSource)),
      filter(([locations, source]) => {
        if (source === DataSourceEnum.API) {
          return false
        }
        if (locations.length === 0) {
          return false
        }
        return true
      }),
      map(([locations, _]) => [locations, DataSourceEnum.IndexedDB])
    )
  }
}
