import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { SocialUser } from '@abacritt/angularx-social-login'
import moment from 'moment'
import { Observable, of } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { IApiContactResponse } from 'src/app/contact/models/iapi-contact-response.model'
import { IContact } from 'src/app/contact/models/icontact.model'
import { ContactService } from 'src/app/contact/service/contact.service'
import { ContactAdapter } from 'src/app/contact/utilities/contact-adapter.utilities'
import { DashboardService } from 'src/app/dashboard/services/dashboard.service'
import { DeveloperService } from 'src/app/developer/services/developer.service'
import { ExemptionCertificateService } from 'src/app/exemption-certificate/services/exemption-certificate.service'
import { DirectFilingService } from 'src/app/direct-filing/services/direct-filing.service'
import { ServiceFeeService } from 'src/app/invoice/services/service-fee.service'
import { TaxFundingService } from 'src/app/invoice/services/tax-funding.service'
import { LocationService } from 'src/app/location/services/location.service'
import { MailService } from 'src/app/mail/services/mail.service'
import { MerchantStoreService } from 'src/app/merchant-store/services/merchant-store.service'
import { PaymentMethodService } from 'src/app/payment-method/services/payment-method.service'
import { TransactionService } from 'src/app/transaction/services/transaction.service'
import { USStateService } from 'src/app/us-states/services/us-state.service'

import { UserDb } from '../db/user.db'
import { IApiToken } from '../models/iapi-auth-token'
import { IApiCurrentUserResult } from '../models/iapi-current-user'
import { IAuth } from '../models/iauth'
import { AuthAdapter } from '../utilities/auth-adapter.utilities'
import { LoginAdapter } from '../utilities/login-adapter.utilities'
import { AccountService } from './../../account/services/account.service'
import { LoginEnum, LoginTypeEnum } from './../../enums/login.enum'
import { IUser } from './../../user/models/iuser'
import { IUserSimple } from '../models/iuser-simple'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentUser: IUser | null
  adapter = new LoginAdapter()
  authAdapter = new AuthAdapter()

  db = new UserDb()
  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private contact: ContactService,
    private location: LocationService,
    private mail: MailService,
    private paymentMethod: PaymentMethodService,
    private transactionService: TransactionService,
    private dashboardService: DashboardService,
    private taxFundingService: TaxFundingService,
    private serviceFeeService: ServiceFeeService,
    private usStateService: USStateService,
    private merchantStoreService: MerchantStoreService,
    private certificateService: ExemptionCertificateService,
    private developerService: DeveloperService,
    private statesService: USStateService,
    private directFileService: DirectFilingService
  ) {}

  public login(username: string, password: string, storeInfoGuid = ''): Observable<[IUser, IAuth]> {
    this.clearLocalData()
    this.clearLocalStorage()

    let url = 'api/account/login'
    if (storeInfoGuid) {
      url += `?storeinfoguid=${storeInfoGuid}`
    }
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/x-www-form-urlencoded',
      }),
    }
    const formData = new FormData()
    formData.set('username', username)
    formData.set('password', password)

    return this.http.post(url, formData, httpOptions).pipe(
      tap((_) => this.db.saveLoginType(LoginTypeEnum.Default)),
      map((data) => this.adapter.getUserAndAuthFromReturn(data))
    )
  }

  /**
   * This login method is reserved for logging in for integration logins.
   */
  public login2(
    username: string,
    password: string,
    storeInfoGuid = ''
  ): Observable<[IUserSimple, IAuth]> {
    this.clearLocalData()
    this.clearLocalStorage()

    let url = 'api/account/login2'
    if (storeInfoGuid) {
      url += `?storeinfoguid=${storeInfoGuid}`
    }
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/x-www-form-urlencoded',
      }),
    }
    const formData = new FormData()
    formData.set('username', username)
    formData.set('password', password)

    return this.http.post(url, formData, httpOptions).pipe(
      tap((_) => this.db.saveLoginType(LoginTypeEnum.Default)),
      map((data) => this.adapter.getSimpleUserAndAuthFromReturn(data))
    )
  }

  public federatedLogin(user: SocialUser): Observable<[IUser, IAuth]> {
    this.clearLocalData()
    this.clearLocalStorage()

    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/x-www-form-urlencoded',
      }),
    }
    const formData = new FormData()
    formData.set('token', user.idToken)

    return this.http.post(`api/account/loginGoogleOAuth`, formData, httpOptions).pipe(
      tap((_) => this.db.saveLoginType(LoginTypeEnum.Default)),
      map((data) => this.adapter.getUserAndAuthFromReturn(data))
    )
  }

  public oneTimeLogin(token: string): Observable<IAuth> {
    this.clearLocalData()
    this.clearLocalStorage()

    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/x-www-form-urlencoded',
      }),
    }
    const formData = new FormData()
    formData.set('x', token)

    return this.http.post(`api/account/onetimelogin`, formData, httpOptions).pipe(
      map((result: IApiToken) => {
        return this.authAdapter.mapSingleUseTokentoIAuth(result)
      })
    )
  }

  public masquerade(merchantId: string, impersonatorId: string): Observable<[IUser, IAuth]> {
    this.clearLocalData()
    this.clearLocalStorage()

    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/x-www-form-urlencoded',
      }),
    }
    const formData = new FormData()
    formData.set('companyId', merchantId)

    return this.http.post('api/account/masquerade', formData, httpOptions).pipe(
      tap((_) => this.db.saveLoginType(LoginTypeEnum.Masquerade)),
      map((data) => this.adapter.getUserAndAuthFromReturn(data, impersonatorId))
    )
  }

  public getUserForAuto(id: string): Observable<IUser> {
    id = id || this.db.getUserId()
    if (id === '') {
      throw new Error('unknown userId')
    }

    return this.http
      .get(`api/contact/${id}`)
      .pipe(
        map((result: IApiCurrentUserResult) =>
          this.authAdapter.mapApiUserToUser(result.data, this.db.getLoginType())
        )
      )
  }

  public getUser(id?: string): Observable<IUser> {
    id = id || this.db.getUserId()
    if (id === '') {
      throw new Error('unknown userId')
    }

    return this.http.get(`api/contact/${id}`).pipe(
      map((result: IApiCurrentUserResult) =>
        this.authAdapter.mapApiUserToUser(result.data, this.db.getLoginType())
      ),
      tap((user) => this.db.updateUser(user))
    )
  }

  // TODO update this so with own backend entry point

  public updateUser(user: IUser): Observable<IContact[]> {
    const contact = this.authAdapter.mapUserToEditContact(user)
    const contactAdapter = new ContactAdapter()

    return this.http
      .post('api/contacts/write', contactAdapter.mapIEditContactToContactBody(contact))
      .pipe(
        map((contacts: IApiContactResponse[]) =>
          contacts.map((results) => contactAdapter.mapContactApiResponseToContact(results))
        )
      )
  }

  public resetPassword(
    token: string,
    password: string,
    confirmPassword: string
  ): Observable<[IUser, IAuth]> {
    const body = {
      guid: token,
      password,
      passwordConfirm: confirmPassword,
    }

    return this.http.post('api/account/ResetPassword', body).pipe(
      tap((_) => this.db.saveLoginType(LoginTypeEnum.Default)),
      map((data) => this.adapter.getUserAndAuthFromReturn(data))
    )
  }
  public autoLogin(token: string): Observable<[IAuth, string]> {
    this.clearLocalData()
    this.clearLocalStorage()

    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/x-www-form-urlencoded',
      }),
    }

    const formData = new FormData()
    formData.set('x', token)

    return this.http
      .post(`api/account/onetimeloginmarketplace`, formData, httpOptions)
      .pipe(map((result: IApiToken) => this.authAdapter.mapApiTokentoIAuth(result)))
  }

  public logout(): Observable<void> {
    // logging out clears everything
    this.clearLocalStorage()
    this.clearLocalData()
    return of()
  }

  public clearLocalData(): void {
    this.location.clearLocations()
    this.contact.clearContacts()
    this.mail.clearMails()
    this.paymentMethod.clearPaymentMethods()
    this.transactionService.clearTransactions()
    this.dashboardService.clearDashboards()
    this.taxFundingService.deleteCache()
    this.serviceFeeService.deleteCache()
    this.usStateService.clearUsStates()
    this.merchantStoreService.deleteCache()
    this.certificateService.deleteCache()
    this.developerService.deleteCache()
    this.statesService.clearUsStates()
    this.directFileService.clearDirectFileForms()
  }

  public lock(): Observable<[IUser, IAuth]> {
    this.db.removeAuth()
    this.accountService.clearLocalSavedAccountData()
    return of([this.db.getUser(), null])
  }
  private clearLocalStorage() {
    if (localStorage.getItem('gemini')) {
      this.preserveGeminiId()
    } else {
      localStorage.clear()
    }
  }
  private preserveGeminiId() {
    // This is initially set from gemini.service as a fall-back to setting a gemini cookie.
    const geminiCookieId = localStorage.getItem('gemini')
    localStorage.clear()
    localStorage.setItem('gemini', geminiCookieId)
  }

  public isLoggedIn(): LoginEnum {
    const user: IUser = this.db.getUser()
    const auth: IAuth = this.db.getAuth()

    if (!user) {
      return LoginEnum.LoggedOut
    }
    if (!user.username) {
      return LoginEnum.LoggedOut
    }
    if (!auth) {
      return LoginEnum.Locked
    }
    if (!auth.validUntil) {
      return LoginEnum.Locked
    }
    if (!moment(auth.validUntil).isAfter(moment())) {
      return LoginEnum.Locked
    }
    return LoginEnum.LoggedIn
  }

  public getLocalSavedUserAndAuth(): Observable<[IUser, IAuth]> {
    return of(this.db.getUserAndAuth())
  }

  public getLocalSavedUser(): Observable<IUser> {
    return of(this.db.getUser())
  }

  public getToken(): string {
    return this.db.getToken()
  }

  public acceptTermsOfService(): Observable<object> {
    return this.http.post(`api/account/accept-tos`, {})
  }

  public resetPasswordRequest(email: string): Observable<any> {
    return this.http.post(`api/account/ForgotPassword`, { email })
  }

  public saveUserAndAuthToLocal(user: IUser, auth: IAuth): Observable<[IUser, IAuth]> {
    return of(this.db.saveUserAndAuth(user, auth))
  }

  public saveUserToLocal(user: IUser): Observable<IUser> {
    return of(this.db.saveUser(user))
  }

  public saveAuthToLocal(auth: IAuth): Observable<IAuth> {
    return of(this.db.saveAuth(auth))
  }

  public updateLocalStorage(update: any): Observable<IUser> {
    return of(this.db.updateUser(update))
  }
}
