<div *ngIf="status$ | async as status">
  <ng-container [ngSwitch]="status">
    <ng-container *ngSwitchCase="loggedIn">
      <button
        mat-button
        data-cy="lock-menu-button"
        aria-label="Lock"
        [matMenuTriggerFor]="lockMenu"
      >
        <mat-icon>lock_open</mat-icon>
        <span>LOCK</span>
      </button>

      <mat-menu #lockMenu>
        <button *ngIf="showLock$ | async" data-cy="lock-button" mat-menu-item (click)="lock()">
          <mat-icon>lock</mat-icon>
          <span>Lock</span>
        </button>

        <button mat-menu-item (click)="logout()">
          <mat-icon>power_settings_new</mat-icon>
          <span>Sign out</span>
        </button>
      </mat-menu>
    </ng-container>

    <ng-container *ngSwitchCase="locked">
      <button mat-button data-cy="logout-button" aria-label="Lock" (click)="logout()">
        SIGN OUT
      </button>
    </ng-container>
  </ng-container>
</div>
