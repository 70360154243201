import { Directive, ElementRef, OnInit, Input } from '@angular/core'
import { ThemeEnum } from 'src/app/enums/theme.enums'

@Directive({
  selector: '[tcLink]',
})
export class LinkDirective implements OnInit {
  isDark = false

  constructor(private el: ElementRef) {}

  @Input('tcLink')
  set aDark(theme: ThemeEnum | null) {
    if (theme && theme === ThemeEnum.Dark) {
      this.isDark = true
    } else {
      this.isDark = false
    }
  }

  ngOnInit() {
    const style = this.el.nativeElement.style
    if (this.isDark) {
      style.color = 'var(--primary-color-200)'
    } else {
      style.color = 'var(--primary-color)'
    }

    style.cursor = 'pointer'
    style.fontSize = '16px'
    style.textDecoration = 'none'
  }
}
