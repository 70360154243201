import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'

import { FeatureKeys } from '../../enums/store.enums'
import * as fromContact from '../contact.store'
import { adapter } from './../contact.store'

const getIsContactsLoaded = (state: fromContact.State): boolean => {
  if (state.source !== DataSourceEnum.API) {
    return false
  }
  return true
}

const getIsWorking = (state: fromContact.State): boolean => {
  return !!state.working
}

const getWorkingMessage = (state: fromContact.State): string => {
  return state.working
}

const getHasError = (state: fromContact.State): boolean => {
  return !!state.error
}

const getErrorMessage = (state: fromContact.State): string => {
  return state.error
}

const getContactsSource = (state: fromContact.State): DataSourceEnum => {
  return state.source
}

export const selectContactState: MemoizedSelector<object, fromContact.State> =
  createFeatureSelector<fromContact.State>(FeatureKeys.Contacts)

export const initialContactDataLoaded: MemoizedSelector<object, boolean> = createSelector(
  selectContactState,
  getIsContactsLoaded
)

export const selectContactsDataSource: MemoizedSelector<object, DataSourceEnum> = createSelector(
  selectContactState,
  getContactsSource
)

export const selectIsWorking: MemoizedSelector<object, boolean> = createSelector(
  selectContactState,
  getIsWorking
)

export const selectWorkingMessage: MemoizedSelector<object, string> = createSelector(
  selectContactState,
  getWorkingMessage
)

export const selectHasError: MemoizedSelector<object, boolean> = createSelector(
  selectContactState,
  getHasError
)

export const selectErrorMessage: MemoizedSelector<object, string> = createSelector(
  selectContactState,
  getErrorMessage
)

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectContactState)
