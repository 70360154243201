import { DataSourceEnum } from '../enums/dataSource.enums'
import { AccountDatabase } from './db/account.db'
import { IAccount } from './models/iaccount'

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface State {
  account?: IAccount
  source: DataSourceEnum
  working?: string
  error?: string
}

export const initialState: State = {
  account: new AccountDatabase().getAccount(),
  source: DataSourceEnum.Local,
}
