import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store'
import { DownloadCenterState } from '../download-center.state'
import { IMerchantReportDownload } from '../models/imerchant-report-downloads.model'

const getReports = (state: DownloadCenterState): IMerchantReportDownload[] => {
  return state.reports
}

const getWorking = (state: DownloadCenterState): boolean => {
  return state.working
}

export const selectState: MemoizedSelector<object, DownloadCenterState> =
  createFeatureSelector<DownloadCenterState>('download-center')

export const selectReports: MemoizedSelector<object, IMerchantReportDownload[]> = createSelector(
  selectState,
  getReports
)

export const selectWorking: MemoizedSelector<object, boolean> = createSelector(
  selectState,
  getWorking
)
