import { Directive, ElementRef, OnInit } from '@angular/core'

@Directive({
  selector: '[tcList]',
})
export class ListDirective implements OnInit {
  constructor(private el: ElementRef) {}

  ngOnInit() {
    const style = this.el.nativeElement.style
    style.listStyleType = 'none'
    style.padding = '0'
  }
}
