<tc-card>
  <tc-card-header [type]="cardType">
    <tc-card-title>{{ categoryTitle }}</tc-card-title>
  </tc-card-header>
  <tc-card-content>
    <ul data-cy="supportItem" tcList *ngIf="showPages$ | async as showPages">
      <li
        *ngFor="let page of showPages; last as isLast"
        tcListItem
        isLink="true"
        [routerLink]="routePrefix + page.slug"
        lastItem="{{ isLast }}"
      >
        <div class="mat-display-1">{{ page.name }}</div>
      </li>
    </ul>
    <ng-container *ngIf="showToggle$ | async">
      <ng-container *ngIf="showToggleAll$ | async; else showLess">
        <div class="margin_sm" fxLayout="row" fxLayoutAlign="center center">
          <button
            data-cy="showMoreButton"
            mat-icon-button
            aria-label="Show all support items for this category"
            matTooltip="Show all"
            [tcColor]="cardType"
            (click)="toggleShowAll()"
          >
            <mat-icon>expand_more</mat-icon>
          </button>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #showLess>
      <div class="margin_sm" fxLayout="row" fxLayoutAlign="center center">
        <button
          data-cy="showLessButton"
          mat-icon-button
          aria-label="Show fewer support items for this category"
          matTooltip="Show less"
          [tcColor]="cardType"
          (click)="toggleShowLess()"
        >
          <mat-icon>expand_less</mat-icon>
        </button>
      </div>
    </ng-template>
  </tc-card-content>
</tc-card>
