import { IApiAccount } from 'src/app/account/models/iapi-account'
import { LoginTypeEnum } from 'src/app/enums/login.enum'

import { IApiCurrentUser } from '../models/iapi-current-user'
import { IAuth } from '../models/iauth'
import { IUser } from '../models/iuser'
import { IUserSimple } from '../models/iuser-simple'

export class LoginAdapter {
  public getUserAndAuthFromReturn(data: any, impersonatorId = ''): [IUser, IAuth] {
    const account = data.account as IApiAccount
    const merchant = account.merchant
    const user: IApiCurrentUser = data.account.merchant.currentUser
    const isMasquerade = account.masquerade || false
    const loginType = isMasquerade ? LoginTypeEnum.Masquerade : LoginTypeEnum.Default
    let returnUser: IUser = {
      id: user.contactID.toString(),
      loginType,
      firstName: user.firstName,
      lastName: user.lastName,
      authority: user.authority,
      merchantId: data.account.merchant.merchantID,
      username: user.email,
      acceptedTermsOfService: user.hasAcceptedTOS,
      hasLatesestTermsOfService: user.hasAcceptedTOS
        ? user.signedTOSVersion >= user.latestTOSVersion
        : false,
      phone: user.phone,
      isShopify: account.isShopify,
      confirmedEmailDate: user.confirmedEmailDate,
    }
    if (impersonatorId) {
      returnUser = { ...returnUser, impersonatorId }
    }

    const returnAuth: IAuth = {
      accessToken: data.access_token,
      validUntil: data.access_token_validTo,
    }

    return [returnUser, returnAuth]
  }

  public getSimpleUserAndAuthFromReturn(data: any, impersonatorId = ''): [IUserSimple, IAuth] {
    let returnUserSimple: IUserSimple = {
      accountDisabled: data.accountDisabled,
      accountExists: data.accountExists,
      apiKey: data.apiKey,
      apiLoginId: data.apiLoginId,
      contactId: data.contactId,
      email: data.email,
      firstName: data.firstName,
      isShopify: data.isShopify,
      lastName: data.lastName,
      marketplaceAuthToken: data.marketplaceAuthToken,
      marketplaceId: data.marketplaceId,
      marketplaceMerchantId: data.marketplaceMerchantId,
      marketplaceSellerId: data.marketplaceSellerId,
      merchantId: data.merchantId,
    }
    const returnAuth: IAuth = {
      accessToken: data.access_token,
      validUntil: data.access_token_validTo,
    }

    return [returnUserSimple, returnAuth]
  }
}
