export enum ReportTypeEnum {
  TransactionDetail = 1,
  Jurisdiction = 2,
  ConsolidatedTransactionDetail = 3,
}

export const ReportTypeEnumLabel = new Map<ReportTypeEnum, string>([
  [ReportTypeEnum.TransactionDetail, 'Transaction Detail Report'],
  [ReportTypeEnum.Jurisdiction, 'Jurisdiction Report'],
  [ReportTypeEnum.ConsolidatedTransactionDetail, 'Consolidated Transaction Detail Report'],
])
