<tc-working-spinner [message]="'getting terms of service'" [opened]="(pdf$ | async) === null">
</tc-working-spinner>

<tc-card>
  <tc-card-header [type]="type">
    <tc-card-title>Terms Of Service</tc-card-title>
  </tc-card-header>
  <tc-card-content>
    <div
      data-cy="showAcceptedMessage"
      *ngIf="acceptedTos$ | async"
      class="margin_b_sm"
      tcAlert="primary"
      fxLayout="row wrap"
      fxLayoutAlign="start"
      fxLayoutGap="5px"
    >
      <div *ngIf="userName$ | async as fullName">
        <div>{{ fullName }}</div>
      </div>
      <div>has accepted Terms Of Service on behalf of</div>
      <div>{{ businessName$ | async }}</div>
    </div>

    <div [style.height]="height" class="tos-window">
      <div *ngIf="pdf$ | async as pdf">
        <pdf-viewer
          [src]="pdf"
          [render-text]="true"
          [show-borders]="false"
          [fit-to-page]="true"
          [style.height]="height"
          [style.display]="'block'"
        >
        </pdf-viewer>
      </div>
    </div>
    <div *ngIf="needsToAcceptTos$ | async">
      <tc-accept-tos-form (acceptChange)="agreedToTermsOfService($event)"></tc-accept-tos-form>
    </div>
  </tc-card-content>
  <tc-card-actions>
    <div *ngIf="needsToAcceptTos$ | async">
      <button
        data-cy="acceptTosButton"
        [disabled]="(agreeCheckValue$ | async) === false"
        mat-button
        tcColor="success"
        (click)="acceptTermsOfService()"
        tcColorDark
      >
        ACCEPT
      </button>
    </div>
    <button
      mat-button
      [disabled]="(pdf$ | async) === null"
      tcColor="accent"
      tcColorDark
      matTooltip="Print or Download TOS"
      aria-label="Export PDF to print or download from your browser"
      (click)="showPdfInNewWindow()"
    >
      PRINT
    </button>
  </tc-card-actions>
</tc-card>
