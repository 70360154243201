<tc-working-spinner [message]="'getting terms of service'" [opened]="(pdf$ | async) === null">
</tc-working-spinner>

<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1rem">
  <div [style.height]="height" class="tos-window">
    <div *ngIf="pdf$ | async as pdf">
      <pdf-viewer
        [src]="pdf"
        [render-text]="true"
        [show-borders]="false"
        [fit-to-page]="true"
        [style.height]="height"
        [style.display]="'block'"
      >
      </pdf-viewer>
    </div>
  </div>

  <button
    mat-button
    [disabled]="(pdf$ | async) === null"
    tcColor="accent"
    tcColorDark
    matTooltip="Print or Download TOS"
    aria-label="Export PDF to print or download from your browser"
    (click)="showPdfInNewWindow()"
  >
    PRINT
  </button>
</div>
