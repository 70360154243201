// https://apidocs.chargebee.com/docs/api/subscriptions#subscription_status
export enum SubscriptionStateEnum {
  InTrial = 'in_trial',
  Active = 'active',
  Future = 'future',
  NonRenewing = 'non_renewing',
  Paused = 'paused',
  Cancelled = 'cancelled',
  Transferred = 'transferred',
}
