import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { IBlogPage } from 'src/app/blog/models/iblog.models'
import { BlogCategoryEnum } from 'src/app/enums/blog.enums'
import { CmsPageTypeEnum } from 'src/app/enums/cms.enums'
import { GetStartedCategoryEnum } from 'src/app/enums/get-started.enums'

import { ICmsPage } from '../models/icms-page'
import { IGetStartedMessage } from '../models/iget-started-message'
import { IIntegrationPage } from '../models/iintegration-page'

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  private getStartedMessageCache: Map<GetStartedCategoryEnum, IGetStartedMessage> = new Map()
  constructor(private http: HttpClient) {}

  public getIntegrationPages(): Observable<IIntegrationPage[]> {
    return this.getPages<IIntegrationPage>(CmsPageTypeEnum.CartIntegration).pipe(
      map((pages) => pages.sort(this.compare))
    )
  }

  public getStateGuides(): Observable<IBlogPage[]> {
    return this.http
      .get(`cms/posts/?page=1&page_size=100&category_slug=${BlogCategoryEnum.StateNews}`)
      .pipe(map((result: any) => result.data)) as Observable<IBlogPage[]>
  }

  public getGetStartedMessages(): Observable<Map<GetStartedCategoryEnum, IGetStartedMessage>> {
    if (this.getStartedMessageCache.size > 0) {
      return of(this.getStartedMessageCache)
    }

    return this.getPages<IGetStartedMessage>(CmsPageTypeEnum.GetStartedMessage).pipe(
      map((messages: IGetStartedMessage[]) => {
        messages.forEach((message) => {
          const category = message.slug as GetStartedCategoryEnum

          this.getStartedMessageCache.set(category, message)
        })
        return this.getStartedMessageCache
      })
    )
  }

  public getPages<T>(type: CmsPageTypeEnum, field: string = ''): Observable<T[]> {
    return this.http
      .get(`cms/pages/${type}/?${field}page_size=100`)
      .pipe(map((result: any) => result.data)) as Observable<T[]>
  }

  public getPage<T>(slug: string, type?: CmsPageTypeEnum): Observable<T> {
    return this.http
      .get(`cms/pages/${type || '*'}/${slug}`)
      .pipe(map((result: any) => result.data)) as Observable<T>
  }

  private compare(a: ICmsPage, b: ICmsPage) {
    const nameA = a.name ? a.name.toLocaleUpperCase() : ''
    const nameB = b.name ? b.name.toLocaleUpperCase() : ''
    let comparison = 0
    if (nameA > nameB) {
      comparison = 1
    } else if (nameA < nameB) {
      comparison = -1
    }
    return comparison
  }
}
