import { Action } from '@ngrx/store'

import { IBankAccount } from '../models/ibank-account'
import { ICreditCard } from '../models/icreditcard'
import { IPaymentMethod } from './../models/ipayment-method.model'

export enum PaymentMethodActionTypes {
  InitLoadPaymentMethods = '[List Payment Method Page] Init load payment methods',
  RefreshPaymentMethods = '[Payment Method Service] Refresh payment methods',
  SaveCreditCard = '[Add Credit Card Page] Save credit card',
  SaveBankAccount = '[Add Bank Account Page] Save bank account',
  MakePaymentMethodDefault = '[Paymentment list page] Make payment method default',
  RemovePaymentMethod = '[Paymentment list page] Remove payment method',
  UpsertPaymentMethods = '[Payment Method Service] Upsert payment methods',
  ClearPaymentMethods = '[Payment Method Service] Clear payment methods',
}

export class InitLoadPaymentMethods implements Action {
  readonly type = PaymentMethodActionTypes.InitLoadPaymentMethods
}

export class RefreshPaymentMethods implements Action {
  readonly type = PaymentMethodActionTypes.RefreshPaymentMethods
}

export class SaveCreditCard implements Action {
  readonly type = PaymentMethodActionTypes.SaveCreditCard

  constructor(public payload: { creditCard: ICreditCard }) {}
}

export class SaveBankAccount implements Action {
  readonly type = PaymentMethodActionTypes.SaveBankAccount

  constructor(public payload: { bankAccount: IBankAccount }) {}
}

export class MakePaymentMethodDefault implements Action {
  readonly type = PaymentMethodActionTypes.MakePaymentMethodDefault

  constructor(public payload: { paymentMethod: IPaymentMethod }) {}
}

export class RemovePaymentMethod implements Action {
  readonly type = PaymentMethodActionTypes.RemovePaymentMethod

  constructor(public payload: { paymentMethod: IPaymentMethod }) {}
}

export class UpsertPaymentMethods implements Action {
  readonly type = PaymentMethodActionTypes.UpsertPaymentMethods

  constructor(public payload: { paymentMethods: IPaymentMethod[] }) {}
}

export class ClearPaymentMethods implements Action {
  readonly type = PaymentMethodActionTypes.ClearPaymentMethods
}

export type PaymentMethodActions =
  | InitLoadPaymentMethods
  | RefreshPaymentMethods
  | SaveCreditCard
  | SaveBankAccount
  | MakePaymentMethodDefault
  | RemovePaymentMethod
  | UpsertPaymentMethods
  | ClearPaymentMethods
