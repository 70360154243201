import { AlertTypeEnum } from './alert.enums'

export enum PaymentStatusEnum {
  Pending = 'pending',
  Resolved = 'resolved',
  Rejected = 'rejected',
}

export const PaymentStatusEnumLabel = new Map<PaymentStatusEnum, string>([
  [PaymentStatusEnum.Pending, 'Pending Payment'],
  [PaymentStatusEnum.Resolved, 'Payment Completed'],
  [PaymentStatusEnum.Rejected, 'Payment Rejected'],
])

export const PaymentStatusEnumIcon = new Map<PaymentStatusEnum, string>([
  [PaymentStatusEnum.Pending, 'schedule'],
  [PaymentStatusEnum.Resolved, 'done'],
  [PaymentStatusEnum.Rejected, 'warning'],
])

export const PaymentStatusEnumAlert = new Map<PaymentStatusEnum, AlertTypeEnum>([
  [PaymentStatusEnum.Pending, AlertTypeEnum.Accent],
  [PaymentStatusEnum.Resolved, AlertTypeEnum.Success],
  [PaymentStatusEnum.Rejected, AlertTypeEnum.Error],
])
