import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { BehaviorSubject, combineLatest, merge, Observable } from 'rxjs'
import { distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators'
import { State as AppState } from 'src/app/app-status/app-status.state'
import * as fromAppStatusSelectors from 'src/app/app-status/selectors/app-status.selectors'
import { DateFormatEnum } from 'src/app/enums/date.enums'

import { DashboardDatabase } from '../db/dashboard.db'
import { IApiDashboardApiCalls, IApiDashboardApiReport } from '../models/iapi-dashboard-api.models'
import { IApiDashboardStates, IApiStatesData } from '../models/iapi-dashboard-states.models'
import {
  IApiDashboardSummary,
  IApiDashboardSummaryAttributes,
} from '../models/iapi-dashboard-summary.models'
import { IDashboard } from '../models/idashboard.models'
import { DashboardAdapter } from '../utilities/dashboard-summary.adapter'

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  public data$: BehaviorSubject<IDashboard> = new BehaviorSubject(null)
  public working: BehaviorSubject<boolean> = new BehaviorSubject(false)
  private period: BehaviorSubject<string> = new BehaviorSubject(null)
  public month: BehaviorSubject<string> = new BehaviorSubject(null)
  private adapter = new DashboardAdapter()
  private db = new DashboardDatabase()

  constructor(
    private http: HttpClient,
    private appStatus: Store<AppState>
  ) {
    this.period
      .pipe(
        distinctUntilChanged(),
        filter((period) => !!period),
        tap((_) => this.working.next(true)),
        switchMap((period) => {
          return merge(this.getDashboardIfOnline(period), this.getDashboardFromLocal(period))
        }),
        tap((results) => this.data$.next(results)),
        tap((results) => this.db.updateDashboard(results)),
        tap((_) => this.working.next(false))
      )
      .subscribe()
  }

  public getTaxHolidays(period: string) {
    return this.http
      .post<any[]>(`api/dashboard/sales-tax-holidays/${period}01`, {})
      .pipe(map((holidays) => holidays.map((holiday) => this.adapter.mapSalesTaxHolidays(holiday))))
  }

  updatePeriod(period: string): void {
    this.period.next(`${period}01`)
  }

  public data(): BehaviorSubject<IDashboard> {
    return this.data$
  }

  public clearDashboards() {
    return this.db.clearDatabase().pipe(first()).subscribe()
  }

  private getDashboardFromLocal(period: string): Observable<IDashboard> {
    return this.db.getDashboard(period).pipe(filter((dashboard) => !!dashboard))
  }

  private getDashboardIfOnline(period: string): Observable<IDashboard> {
    return this.appStatus.select(fromAppStatusSelectors.selectOnline).pipe(
      filter((online) => online),
      switchMap(() => this.getDashboard(period))
    )
  }

  private getDashboard(period: string) {
    return combineLatest([
      this.getSummary$(period),
      this.getStates$(period),
      this.getApiActivity$(period),
    ]).pipe(
      map(([[month, summary], states, api]) =>
        this.adapter.mapApiDashbordSummaryToDashboardSummary(month, summary, states, api)
      ),
      tap((results) =>
        this.month.next(moment(results.month, DateFormatEnum.Day).format(DateFormatEnum.Month))
      )
    )
  }

  private getSummary$(period: string): Observable<[string, IApiDashboardSummaryAttributes]> {
    return this.http
      .post(`api/dashboard/totals/${period}`, null)
      .pipe(map((results: IApiDashboardSummary) => [results.meta.month, results.data.attributes]))
  }

  private getStates$(period: string): Observable<IApiStatesData> {
    return this.http.post(`api/dashboard/tax-states/${period}`, null).pipe(
      map((results: IApiDashboardStates) => {
        return {
          states: results.data.attributes.states,
          totals: results.data.attributes.totals,
          month: results.meta.month,
        }
      })
    )
  }

  private getApiActivity$(period: string): Observable<IApiDashboardApiReport> {
    return this.http.post(`api/dashboard/api-activity/${period}`, null).pipe(
      map((results: IApiDashboardApiCalls) => {
        return {
          month: results.meta.month,
          data: results.data.attributes,
        }
      })
    )
  }
}
