import { Component, OnInit, Input } from '@angular/core'
import { AlertTypeEnum } from '../../../../enums/alert.enums'

@Component({
  selector: 'tc-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.sass'],
})
export class AlertComponent implements OnInit {
  type: AlertTypeEnum = AlertTypeEnum.Information
  icon: string
  dark = false
  constructor() {}

  @Input('type')
  set setInput(type: string) {
    this.type = type as AlertTypeEnum
  }

  @Input('dark')
  set setDark(dark: string) {
    this.dark = true
  }

  ngOnInit() {
    switch (this.type) {
      case AlertTypeEnum.Success:
        this.icon = 'check'
        break
      case AlertTypeEnum.Warning:
        this.icon = 'warning'
        break
      case AlertTypeEnum.Danger:
        this.icon = 'report'
        break
      case AlertTypeEnum.Error:
        this.icon = 'report_problem'
        break
      case AlertTypeEnum.Information:
        this.icon = 'info'
        break
      case AlertTypeEnum.Question:
        this.icon = 'contact_support'
        break
      default:
        this.icon = 'info'
    }
  }
}
