import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core'
import { Observable, BehaviorSubject, combineLatest, of } from 'rxjs'
import { Store } from '@ngrx/store'
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators'
import { SubSink } from 'subsink'
import { HttpErrorResponse } from '@angular/common/http'

import { UsStateEnum } from 'src/app/enums/us-state.enums'
import { State as UserState } from 'src/app/user/user.state'
import { USStateService } from 'src/app/us-states/services/us-state.service'
import { DirectFilingRequestLimit } from 'src/app/enums/direct-filing.enums'
import { State as AccountState } from 'src/app/account/account.state'
import * as accountSelector from 'src/app/account/selectors/account.selectors'
import { IPublicUsState } from 'src/app/us-states/models/ipublic-us-states'
import { IDirectFileRequest } from 'src/app/us-states/models/idirect-filing.models'
import { Router } from '@angular/router'
import { IUser } from 'src/app/user/models/iuser'
import * as authorizeStoreSelectors from 'src/app/user/selectors/user.selectors'

@Component({
  templateUrl: './direct-file-manager.page.html',
  styleUrls: ['./direct-file-manager.page.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectFileManagerPage implements OnInit, OnDestroy {
  public nonSsutaUsStates$ = new BehaviorSubject<IPublicUsState[]>(null)
  public hasPendingRequest$ = new BehaviorSubject<boolean>(false)
  public hasPermission$ = new BehaviorSubject<boolean>(true)
  public limitReached$ = new BehaviorSubject<boolean>(false)
  public latestRequest$ = new BehaviorSubject<IDirectFileRequest>(null)
  public hasError$ = new BehaviorSubject<boolean>(false)
  public errorMessage$ = new BehaviorSubject<string>('')
  public successMessage$ = new BehaviorSubject<boolean>(false)
  public isLoading$ = new BehaviorSubject<boolean>(false)
  public working$ = new BehaviorSubject<boolean>(false)
  public subs = new SubSink()
  constructor(
    private usStateService: USStateService,
    private accountStore: Store<AccountState>,
    private userStore: Store<UserState>,
    private router: Router
  ) {}
  public merchantId$: Observable<number> = this.accountStore.select(
    accountSelector.selectMerchantId
  )
  public allStates$: Observable<IPublicUsState[]> = this.usStateService.getUsStatesFromPublic()
  public isLimitReached$: Observable<boolean> = this.usStateService.checkRequestLimit().pipe(
    map((requestLimit) => {
      if (requestLimit === DirectFilingRequestLimit.LIMIT_REACHED) {
        this.limitReached$.next(true)
        return true
      }
      return false
    })
  )

  public user$: Observable<IUser> = this.userStore.select(
    authorizeStoreSelectors.selectAuthorizeUser
  )

  public isShopifyUser$: Observable<boolean> = this.user$.pipe(
    filter((user) => !!user),
    map((user) => user.isShopify === true)
  )

  public getRequestHistory$: Observable<IDirectFileRequest[]> = this.merchantId$.pipe(
    switchMap((merchantId) => this.usStateService.getDirectFileRequests(merchantId)),
    catchError((err: HttpErrorResponse) => of(this.handleError(err)))
  )

  public nonSsuta$: Observable<IPublicUsState[]> = this.allStates$.pipe(
    map((states) => states.filter((state) => state.isSsuta === false)),
    map((states) => states.filter((state) => this.isSalesTaxState(state))),
    map((states) => states.map((usState) => ({ ...usState, requestDirectFile: false }))),
    tap((states) => this.nonSsutaUsStates$.next(states))
  )

  public getLatestRequest$ = combineLatest([
    this.getRequestHistory$,
    this.nonSsuta$,
    this.isLimitReached$,
  ]).pipe(
    filter(([requests, nonSsuta]) => !!requests && !!nonSsuta),
    map(([requests, _]) => requests),
    map((requests) => requests.sort(this.compare)[0]),
    tap((latestRequest) => {
      if (latestRequest) {
        this.latestRequest$.next(latestRequest)
        const stateAbbreviations = latestRequest.states.split(',')
        this.mapCurrentSelections(stateAbbreviations)
        if (!latestRequest.processed) {
          this.hasPendingRequest$.next(true)
        }
      }
      this.isLoading$.next(false)
    })
  )
  ngOnInit(): void {
    this.isLoading$.next(true)
    this.subs.add(
      this.isShopifyUser$
        .pipe(
          tap((isShopifyUser) => {
            if (!isShopifyUser) {
              this.router.navigate(['/go/dashboard'])
            } else {
              this.getLatestRequest$.pipe(first()).subscribe()
            }
          })
        )
        .subscribe()
    )
  }
  public submit() {
    this.working$.next(true)
    let serviceCall: Observable<IDirectFileRequest>
    const statesList = this.nonSsutaUsStates$.value
    const hasPendingRequest = this.hasPendingRequest$.value
    const limitReached = this.limitReached$.value
    const stateAbbreviations = []
    const latestRequest = this.latestRequest$.value
    statesList.map((usState) => {
      if (usState.requestDirectFile) {
        stateAbbreviations.push(usState.abbreviation)
      }
    })
    // TODO Remove update existing request logic
    if (hasPendingRequest && !latestRequest.processed) {
      const updatedRequest = { ...latestRequest, states: stateAbbreviations.toString() }
      serviceCall = this.getObservable(updatedRequest)
    } else {
      serviceCall = this.getObservable(null, stateAbbreviations, limitReached)
    }
    serviceCall
      .pipe(
        first(),
        tap(() => {
          this.successMessage$.next(true)
          this.working$.next(false)
        }),
        catchError((err: HttpErrorResponse) => of(this.handleError(err)))
      )
      .subscribe()
  }
  public updateStateSelection(checked: boolean, stateAbbr: string): void {
    const statesList = this.nonSsutaUsStates$.value
    const statesListUpdate = statesList.map((state) => {
      if (stateAbbr === state.abbreviation) {
        if (checked) {
          return { ...state, requestDirectFile: true }
        } else {
          return { ...state, requestDirectFile: false }
        }
      }
      return state
    })
    this.nonSsutaUsStates$.next(statesListUpdate)
  }
  private mapCurrentSelections(stateAbbr: string[]): void {
    const statesList = this.nonSsutaUsStates$.value
    const latestRequest = this.latestRequest$.value
    const statesListUpdate: IPublicUsState[] = statesList.map((state) => {
      if (stateAbbr.find((stateAbbreviation) => stateAbbreviation === state.abbreviation)) {
        if (latestRequest.processed && !latestRequest.rejected) {
          return { ...state, requestDirectFile: true, isEnrolledInDf: true }
        }
        return { ...state, requestDirectFile: true }
      }
      return { ...state, requestDirectFile: false }
    })
    this.nonSsutaUsStates$.next(statesListUpdate)
  }
  private getObservable(
    updatedRequest?: IDirectFileRequest,
    stateAbbreviations: string[] = [],
    limitReached = false
  ): Observable<IDirectFileRequest> {
    return this.usStateService.requestDirectFileStates(stateAbbreviations.toString(), limitReached)
    // TODO Remove update existing request logic
    // return updatedRequest ? this.usStateService.updateDirectFileStates(updatedRequest) :
    // this.usStateService.requestDirectFileStates(stateAbbreviations.toString(), limitReached);
  }

  private isSalesTaxState(state: IPublicUsState): boolean {
    if (
      state.abbreviation === UsStateEnum.Montana ||
      state.abbreviation === UsStateEnum.Oregon ||
      state.abbreviation === UsStateEnum.Delaware ||
      state.abbreviation === UsStateEnum.NewHampshire ||
      state.abbreviation === UsStateEnum.AmericanSamoa ||
      state.abbreviation === UsStateEnum.NorthernMarianaIslands
    ) {
      return false
    } else {
      return true
    }
  }
  private compare(a: IDirectFileRequest, b: IDirectFileRequest) {
    let comparison = 0
    if (a.id < b.id) {
      comparison = 1
    } else if (a.id > b.id) {
      comparison = -1
    }
    return comparison
  }

  private handleError(error: HttpErrorResponse) {
    this.isLoading$.next(false)
    this.working$.next(false)
    this.hasError$.next(true)
    if (error.error) {
      this.errorMessage$.next(error.error)
      return error.error
    }
    this.errorMessage$.next(error.message)
    return error.message || 'Unknown error'
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }
}
