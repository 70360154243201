import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  templateUrl: './setup.page.html',
  styleUrls: ['./setup.page.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetupPage implements OnInit {
  constructor(private router: Router) {
    window.addEventListener(
      'hashchange',
      (_) => {
        this.watchHashUpdate()
      },
      false
    )

    this.watchHashUpdate()
  }

  ngOnInit(): void {}

  private watchHashUpdate() {
    if (!location.hash) {
      return
    }
    const token: string = location.hash.substring(3)
    const pathName = location.pathname
    if (pathName.indexOf('/setup/email') === 0) {
      this.router.navigate([`/setup/confirm-email`], { queryParams: { token } })
    } else {
      this.router.navigate([`/password/reset`], { queryParams: { token } })
    }
  }
}
