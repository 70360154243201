import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { select, Store } from '@ngrx/store'
import { tap } from 'rxjs/operators'
import { State as AccountState } from 'src/app/account/account.state'
import * as fromAccountSelectors from 'src/app/account/selectors/account.selectors'
import * as fromUserSelectors from 'src/app/user/selectors/user.selectors'
import { State as UserState } from 'src/app/user/user.state'
import { SubSink } from 'subsink'

@Component({
  selector: 'tc-accept-tos-form',
  templateUrl: './accept-tos-form.component.html',
  styleUrls: ['./accept-tos-form.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptTosFormComponent implements OnInit, OnDestroy {
  today = new Date()
  agreeForm: UntypedFormGroup
  agree = new UntypedFormControl(false)
  subs = new SubSink()

  constructor(
    private accountStore: Store<AccountState>,
    private userStore: Store<UserState>,
    private fb: UntypedFormBuilder
  ) {
    this.agreeForm = fb.group({
      agree: this.agree,
    })
  }
  @Output() acceptChange = new EventEmitter<boolean>()

  userName$ = this.userStore.pipe(select(fromUserSelectors.selectFullName))

  merchantName$ = this.accountStore.pipe(select(fromAccountSelectors.selectBusinessName))

  ngOnInit(): void {
    this.subs.add(
      this.agree.valueChanges.pipe(tap((agree) => this.acceptChange.emit(agree))).subscribe()
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }
}
