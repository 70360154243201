export enum FundingSourceEnum {
  Unknown = 0,
  Bank = 1,
  CreditCard = 2,
}

export const FundingSourceEnumLabel = new Map<FundingSourceEnum, string>([
  [FundingSourceEnum.Unknown, 'Unknown'],
  [FundingSourceEnum.Bank, 'Bank'],
  [FundingSourceEnum.CreditCard, 'Credit Card'],
])
