import { Directive, ElementRef, Input, OnInit, Renderer2, HostListener } from '@angular/core'
import { AlertTypeEnum } from '../../../../enums/alert.enums'
import { BehaviorSubject } from 'rxjs'

@Directive({
  selector: '[tcAlert]',
})
export class AlertDirective implements OnInit {
  messageType: AlertTypeEnum = AlertTypeEnum.Information
  isDark = false
  isLink$: BehaviorSubject<boolean> = new BehaviorSubject(false)

  constructor(
    private el: ElementRef,
    private render: Renderer2
  ) {}

  @Input('tcAlert')
  set message(message: string) {
    this.messageType = message as AlertTypeEnum
  }

  @Input('dark')
  set dark(dark: boolean) {
    this.isDark = dark
  }

  @Input('tcAlertLink')
  set isLink(isLink: boolean) {
    this.isLink$.next(isLink)
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.isLink$.value) {
      this.render.addClass(this.el.nativeElement, 'hover-background')
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.render.removeClass(this.el.nativeElement, 'hover-background')
  }

  ngOnInit() {
    const style = this.el.nativeElement.style
    const dark = this.isDark
    style.borderRadius = '3px'
    style.padding = '1rem'
    if (this.isLink$.value) {
      style.cursor = 'pointer'
    } else {
      style.cursor = 'unset'
    }

    switch (this.messageType) {
      case AlertTypeEnum.Information:
      case AlertTypeEnum.Primary:
        if (dark) {
          this.render.addClass(this.el.nativeElement, 'primary-background-dark')
          style.color = 'white'
          style.borderLeft = 'unset'
          break
        }
        this.render.addClass(this.el.nativeElement, 'primary-background')
        style.color = 'var(--information-text-color)'
        style.borderLeft = '5px solid var(--information-text-color)'
        break
      case AlertTypeEnum.Accent:
        if (dark) {
          this.render.addClass(this.el.nativeElement, 'accent-background-dark')
          style.color = 'white'
          style.borderLeft = 'unset'
          break
        }
        this.render.addClass(this.el.nativeElement, 'accent-background')
        style.color = 'var(--accent-text-color)'
        style.borderLeft = '5px solid var(--accent-text-color)'
        break

      case AlertTypeEnum.Error:
      case AlertTypeEnum.Danger:
        if (dark) {
          this.render.addClass(this.el.nativeElement, 'error-background-dark')
          style.color = 'white'
          style.borderLeft = 'unset'
          break
        }
        this.render.addClass(this.el.nativeElement, 'error-background')
        style.color = 'var(--error-text-color)'
        style.borderLeft = '5px solid var(--error-text-color)'
        break

      case AlertTypeEnum.Success:
        if (dark) {
          this.render.addClass(this.el.nativeElement, 'success-background-dark')
          style.color = 'white'
          style.borderLeft = 'unset'
          break
        }
        this.render.addClass(this.el.nativeElement, 'success-background')
        style.color = 'var(--success-text-color)'
        style.borderLeft = '5px solid var(--success-text-color)'
        break

      case AlertTypeEnum.Question:
        if (dark) {
          this.render.addClass(this.el.nativeElement, 'question-background-dark')
          style.color = 'white'
          style.borderLeft = 'unset'
          break
        }
        this.render.addClass(this.el.nativeElement, 'question-background')
        style.color = 'var(--question-text-color)'
        style.borderLeft = '5px solid var(--question-text-color)'
        break

      case AlertTypeEnum.Warning:
        if (dark) {
          this.render.addClass(this.el.nativeElement, 'warning-background-dark')
          style.color = 'white'
          style.borderLeft = 'unset'
          break
        }
        this.render.addClass(this.el.nativeElement, 'warning-background')
        style.color = 'var(--warning-text-color)'
        style.borderLeft = '5px solid var(--warning-text-color)'
        break

      default:
        if (dark) {
          this.render.addClass(this.el.nativeElement, 'information-background-dark')
          style.color = 'white'
          style.borderLeft = 'unset'
          break
        }
        this.render.addClass(this.el.nativeElement, 'information-background')
        style.color = 'var(--information-text-color)'
        style.borderLeft = '5px solid var(--information-color)'
    }
  }
}
