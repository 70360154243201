import { Component, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'tc-card-content',
  templateUrl: './card-content.component.html',
  styleUrls: ['./card-content.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class CardContentComponent {
  constructor() {}
}
