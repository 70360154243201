import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { AlertTypeEnum } from 'src/app/enums/alert.enums'

@Directive({
  selector: '[tcListItem]',
})
export class ListItemDirective implements OnInit, OnDestroy {
  isLink = false
  lastItem = false
  alert$ = new BehaviorSubject<AlertTypeEnum>(null)
  sub: Subscription

  constructor(private el: ElementRef) {}

  @Input('lastItem')
  set setLast(lastItem: string) {
    this.lastItem = lastItem === 'true' ? true : false
  }

  @Input('isLink')
  set setLink(isLink: string) {
    this.isLink = isLink === 'true' ? true : false
  }

  @Input('alert')
  set setAlert(alert: AlertTypeEnum) {
    this.alert$.next(alert)
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.isLink) {
      this.highlight('var(--hover-color)')
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.isLink) {
      this.highlight(null)
    }
  }

  ngOnInit() {
    const style = this.el.nativeElement.style
    const wideBorderSize = '.5rem'
    const narrowBorderSize = '.05rem'
    style.listStyleType = 'none'
    style.margin = '0'
    style.padding = '1rem'
    style.borderTop = '1.5px solid var(--divider-color)'
    style.borderRight = `${narrowBorderSize} solid var(--divider-color)`

    if (this.isLink) {
      style.cursor = 'pointer'
    } else {
      style.cursor = 'unset'
    }
    if (this.lastItem) {
      style.borderBottom = `${narrowBorderSize} solid var(--divider-color)`
    } else {
      style.borderBottom = null
    }
    this.sub = this.alert$
      .pipe(
        map((alert) => {
          switch (alert) {
            case AlertTypeEnum.Danger:
              style.borderLeft = `${wideBorderSize} solid var(--error-color)`
              break

            case AlertTypeEnum.Warning:
              style.borderLeft = `${wideBorderSize} solid var(--warning-color)`
              break

            case AlertTypeEnum.Primary:
            case AlertTypeEnum.Information:
              style.borderLeft = `${wideBorderSize} solid var(--information-color)`
              break

            case AlertTypeEnum.Accent:
              style.borderLeft = `${wideBorderSize} solid var(--accent-color)`
              break

            case AlertTypeEnum.Success:
              style.borderLeft = `${wideBorderSize} solid var(--success-color)`
              break

            case AlertTypeEnum.Question:
              style.borderLeft = `${wideBorderSize} solid var(--question-color)`
              break

            default:
              style.borderLeft = `${narrowBorderSize} solid var(--divider-color)`
              style.paddingLeft = '1.45rem'
          }
        })
      )
      .subscribe()
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  private highlight(color: string) {
    this.el.nativeElement.style.backgroundColor = color
  }
}
