<div class="h-full" *ngIf="layout$ | async as layout">
  <mat-toolbar
    [color]="(isMasquerading$ | async) ? 'accent' : 'primary'"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    style="height: 72px"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
      <button mat-icon-button data-cy="toggle-menu">
        <mat-icon (click)="toggleMenu()">menu</mat-icon>
      </button>
      <h1>Tax<span class="accent-color">Cloud</span></h1>
      <div *ngIf="isMasquerading$ | async">
        Masquerading as {{ businessName$ | async }} ({{ merchantId$ | async }})
        <span class="mat-body-strong" *ngIf="closeDate$ | async as closeDate">
          {{ 'Account Closed: ' + (closeDate | date) }}
        </span>
      </div>
    </div>

    <!-- <div *ngIf="showUpgradeButton$ | async" class="free-plan-upgrade-action">
      <p>You are currently on a free plan with limited features</p>
      <button (click)="goToServicePlan()" mat-button tcColor="accent" tcColorDark>Upgrade</button>
    </div> -->

    <div *ngIf="showMustSubscribe$ | async" class="flex justify-content-center align-items-center">
      <i class="pi pi-exclamation-triangle text-xl"></i>
      <span class="font-light text-base">
        &nbsp; Your TaxCloud plan has expired.
        <span class="text-lg font-bold underline">Choose a plan</span>
        to regain access to your data
      </span>
    </div>

    <div
      *ngIf="isFreeTrialSubscription$ | async"
      class="hidden md:flex flex-row align-items-center"
    >
      <p class="upgrade-now-text">
        You have <b>{{ freeTrialRemainingDays$ | async }} days left</b> on your trial
      </p>
      <div class="ml-4">
        <a
          pButton
          routerLink="/go/account/edit-plan"
          class="p-button-outlined upgrade-now-button"
          label="Upgrade Now"
        ></a>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
      <div [tcRemoveIfFeatureOff]="'message-center'" *ngIf="showNotifications$ | async">
        <button mat-button (click)="messageSideBar.toggle()">
          <mat-icon [matBadge]="numberOfMessages$ | async">notifications</mat-icon>
          <span class="cdk-visually-hidden">
            You have {{ numberOfMessages$ | async }} Notifications
          </span>
        </button>
      </div>

      <ng-container *ngIf="isAutoAuth$ | async; then showAuto; else showDefault"> </ng-container>

      <ng-template #showAuto></ng-template>
      <ng-template #showDefault>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap=".5rem">
          <div *ngIf="isLarge$ | async">
            <button
              *ngIf="isInstallable$ | async"
              (click)="installApp()"
              mat-icon-button
              matTooltip="Install App"
            >
              <mat-icon class="margin_r_sm">get_app</mat-icon>
            </button>
          </div>

          <tc-sign-out-button></tc-sign-out-button>
        </div>
      </ng-template>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav
      class="mat-typography"
      [mode]="layout.mode"
      [disableClose]="layout.disableClose"
      [opened]="layout.opened"
      (closed)="close()"
      style="width: 304px"
      role="navigation"
    >
      <mat-nav-list>
        <mat-expansion-panel
          *ngIf="isLoggedIn$ | async"
          class="mat-elevation-z0 padding_h_sm padding_t_sm"
          [(expanded)]="accountExpandPanelOpen"
          dense
        >
          <mat-expansion-panel-header class="items">
            <div class="padding_sm" fxLayout="row" fxLayoutAlign="start center">
              <div class="initial">{{ firstInitial | async }}</div>
              <div class="margin_l_sm">
                <div>{{ fullName | async }}</div>
                <div>{{ authority | async }}</div>
              </div>
            </div>
          </mat-expansion-panel-header>
          <mat-nav-list>
            <a
              mat-list-item
              class="padding_h_sm"
              (click)="toggle(); accountExpandPanelOpen = false"
              [routerLink]="'/go/user'"
            >
              <mat-icon class="margin_r_sm">account_circle</mat-icon>
              <span>User</span>
            </a>
            <a
              mat-list-item
              class="padding_h_sm"
              (click)="toggle(); accountExpandPanelOpen = false"
              [routerLink]="'/go/account'"
            >
              <mat-icon class="margin_r_sm">verified_user</mat-icon>
              <span>Account</span>
            </a>
          </mat-nav-list>
        </mat-expansion-panel>

        <a
          *ngIf="(isAutoAuth$ | async) === false"
          mat-list-item
          class="padding_h_sm"
          (click)="toggle()"
          [routerLink]="'/go/home'"
        >
          <mat-icon class="margin_r_sm">home</mat-icon>
          <span>Home</span>
        </a>

        <mat-expansion-panel
          class="mat-elevation-z0 padding_h_sm"
          [(expanded)]="transactionsExpandPanelOpen"
          dense
        >
          <mat-expansion-panel-header class="items">
            <mat-icon class="margin_r_sm">shopping_cart</mat-icon>
            <span>Transactions</span>
          </mat-expansion-panel-header>

          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle(); transactionsExpandPanelOpen = false"
            [routerLink]="'/go/transactions'"
          >
            <mat-icon class="margin_r_sm">list</mat-icon>
            <span>Reports</span>
          </a>

          <a
            mat-list-item
            *ngIf="(isEmbeddedApp$ | async) === false"
            class="padding_h_sm"
            (click)="toggle(); transactionsExpandPanelOpen = false"
            [routerLink]="'/go/transactions/upload'"
          >
            <mat-icon class="margin_r_sm">cloud_upload</mat-icon>
            <span>Uploads</span>
          </a>

          <a
            mat-list-item
            *ngIf="(isEmbeddedApp$ | async) === false"
            class="padding_h_sm"
            (click)="toggle(); transactionsExpandPanelOpen = false"
            [routerLink]="'/go/transactions/add'"
          >
            <mat-icon class="margin_r_sm">add</mat-icon>
            <span>Add</span>
          </a>
        </mat-expansion-panel>

        <a mat-list-item class="padding_h_sm" (click)="toggle()" [routerLink]="'/go/tax-reporting'">
          <mat-icon class="margin_r_sm">gavel</mat-icon>
          <span>Tax Reporting</span>
        </a>

        <a mat-list-item class="padding_h_sm" (click)="toggle()" [routerLink]="'/go/stores'">
          <mat-icon class="margin_r_sm">store</mat-icon>
          <span>Stores</span>
        </a>

        <mat-expansion-panel
          [tcRemoveIfFeatureOff]="'invoices'"
          class="mat-elevation-z0 padding_h_sm"
          [(expanded)]="invoicesExpandPanelOpen"
          dense
        >
          <mat-expansion-panel-header class="items">
            <mat-icon class="margin_r_sm">receipt</mat-icon>
            <span>Treasury</span>
          </mat-expansion-panel-header>
          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle(); invoicesExpandPanelOpen = false"
            [routerLink]="'/go/invoices/tax-funding'"
          >
            <mat-icon class="margin_r_sm">account_balance</mat-icon>
            <span>Tax Fundings</span>
          </a>
          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle(); invoicesExpandPanelOpen = false"
            [routerLink]="'/go/invoices/service-fees'"
          >
            <mat-icon class="margin_r_sm">account_balance_wallet</mat-icon>
            <span>Service Fees (Legacy)</span>
          </a>
        </mat-expansion-panel>

        <mat-expansion-panel
          class="mat-elevation-z0 padding_h_sm"
          [(expanded)]="settingsExpandPanelOpen"
          dense
        >
          <mat-expansion-panel-header class="items">
            <mat-icon class="margin_r_sm">settings</mat-icon>
            <span>Settings</span>
          </mat-expansion-panel-header>

          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle(); settingsExpandPanelOpen = false"
            [routerLink]="'/go/contacts'"
          >
            <mat-icon class="margin_r_sm">contacts</mat-icon>
            <span>Contacts</span>
          </a>
          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle(); settingsExpandPanelOpen = false"
            [routerLink]="'/go/locations'"
          >
            <mat-icon class="margin_r_sm">business</mat-icon>
            <span>Locations</span>
          </a>
          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle(); settingsExpandPanelOpen = false"
            [routerLink]="'/go/payment-methods'"
            [queryParams]="{ 'pick-plan': 'true' }"
          >
            <mat-icon class="margin_r_sm">payments</mat-icon>
            <span>Payment methods</span>
          </a>

          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle(); settingsExpandPanelOpen = false"
            [routerLink]="'/go/account/service-plan'"
            [queryParams]="{ 'pick-plan': isFreeTrialSubscription$ | async }"
          >
            <mat-icon class="margin_r_sm">attach_money</mat-icon>
            <span>Plans & Billing</span>
          </a>

          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle(); settingsExpandPanelOpen = false"
            [routerLink]="'/go/states-management'"
          >
            <mat-icon class="margin_r_sm">dns</mat-icon>
            <span>Manage States</span>
          </a>

          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle(); settingsExpandPanelOpen = false"
            [routerLink]="'/go/exemption-certificates'"
          >
            <mat-icon class="margin_r_sm">money_off</mat-icon>
            <span>Exemption Certificates</span>
          </a>
        </mat-expansion-panel>
        <a
          mat-list-item
          class="padding_h_sm"
          *ngIf="isInternal$ | async"
          (click)="toggle()"
          [routerLink]="'/go/internal'"
        >
          <mat-icon class="margin_r_sm">build</mat-icon>
          <span>Internal Tools</span>
        </a>

        <div>
          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle()"
            [href]="publicSiteUrl + '/support'"
            target="_blank"
          >
            <mat-icon class="margin_r_sm">support</mat-icon>
            <span>Support</span>
          </a>
        </div>

        <mat-expansion-panel
          class="mat-elevation-z0 padding_h_sm"
          [(expanded)]="developerPanelOpen"
          dense
        >
          <mat-expansion-panel-header class="items">
            <mat-icon class="margin_r_sm">code</mat-icon>
            <span>Developer</span>
          </mat-expansion-panel-header>

          <!-- <a mat-list-item
            class="padding_h_sm"
            (click)="toggle()"
            [routerLink]="'/go/blog'">
            <mat-icon class="margin_r_sm">feed</mat-icon>
            <span>Latest News <mat-icon *ngIf="blogAlert$ | async">new_releases</mat-icon></span> 
          </a> -->

          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle(); developerPanelOpen = false"
            [routerLink]="'/go/developer/reports'"
          >
            <mat-icon class="margin_r_sm">insert_chart_outline</mat-icon>
            <span>API Reports</span>
          </a>

          <!-- <a mat-list-item 
            class="padding_h_sm"
            (click)="toggle(); developerPanelOpen=false; goDevSupport()">
            <mat-icon class="margin_r_sm">help_outline</mat-icon>
            <span>Support</span>
          </a> -->

          <a
            mat-list-item
            class="padding_h_sm"
            [routerLink]="'/developer'"
            target="_blank"
            rel="noopener"
          >
            <mat-icon class="margin_r_sm">article</mat-icon>
            <span>Documentation</span>
          </a>
        </mat-expansion-panel>

        <a
          mat-list-item
          class="padding_h_sm"
          (click)="toggle(); developerPanelOpen = false"
          [routerLink]="'/go/download-center'"
        >
          <mat-icon class="margin_r_sm">download</mat-icon>
          <span>Download Center</span>
        </a>

        <div *ngIf="showLogoutButton$ | async">
          <a mat-list-item class="padding_h_sm" (click)="logout()">
            <mat-icon class="margin_r_sm">power_settings_new</mat-icon>
            <span>Logout</span>
          </a>
        </div>

        <div *ngIf="(isLarge$ | async) === false && (isInstallable$ | async)">
          <a mat-list-item class="padding_h_sm" (click)="installApp()">
            <mat-icon class="margin_r_sm">get_app</mat-icon>
            <span>Install App</span>
          </a>
        </div>
      </mat-nav-list>

      <div
        class="margin_sm flex-1"
        [tcRemoveIfFeatureOff]="'qa-helpers'"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="1rem"
      >
        <div>QA TOOLS</div>
        <tc-toggle-account-state (accountStatusChanged)="toggle()"></tc-toggle-account-state>
        <tc-toggle-app-type (appTypeChanged)="toggle()"></tc-toggle-app-type>
        <tc-select-user-auth></tc-select-user-auth>
      </div>

      <div
        *ngIf="isFreeTrialSubscription$ | async"
        class="bg-primary-100 border-round p-3 m-3 text-center"
      >
        Current Plan: <b>Premium Trial</b>
        <button
          pButton
          class="p-button-outlined p-button-sm bg-white w-full mt-3"
          label="Upgrade Now"
          routerLink="/go/account/edit-plan"
        ></button>
      </div>
    </mat-sidenav>
    <mat-sidenav
      *ngIf="msgCenter"
      [tcRemoveIfFeatureOff]="'message-center'"
      class="notification-nav"
      style="width: 500px; max-width: 80%"
      [mode]="layout.mode"
      position="end"
      role="navigation"
      #messageSideBar
    >
      <div fxLayout="column" fxLayoutGap="1rem" fxFill [tcColor]="'question'" tcColorDark>
        <!-- <div fxFlex></div> -->
        <tc-alert
          *ngFor="let message of messages$ | async; last as isLast"
          [type]="message.type"
          class="margin_h_sm margin_t_sm"
        >
          <div fxLayout="row" style="width: 100%" fxLayoutAlign="space-between">
            <div fxFlex>
              <div class="mat-display-1">
                {{ message.label }}
              </div>
              <div class="mat-body">
                {{ message.message }}
              </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="space-between end">
              <button mat-icon-button (click)="deleteNotice(message.id)" [tcColor]="message.type">
                <mat-icon>close</mat-icon>
              </button>
              <button
                mat-icon-button
                [tcColor]="message.type"
                [routerLink]="message.link"
                (click)="messageSideBar.close()"
              >
                <mat-icon>arrow_forward</mat-icon>
              </button>
            </div>
          </div>
        </tc-alert>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="main-router-outet">
        <tc-breadcrumb></tc-breadcrumb>
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
