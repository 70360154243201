import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink'

import { ApiInterceptor } from '../api-interceptor'
import { PageNotFoundComponent } from 'src/app/go/pages/not-found/not-found.page'
import { ShellPublicBasicComponent } from 'src/app/public/shell-public-basic/shell-public-basic.component'

const appRoutes: Routes = [
  {
    path: '**',
    data: {
      breadcrumb: '404 Not Found',
    },
    component: ShellPublicBasicComponent,
    children: [
      {
        path: '',
        component: PageNotFoundComponent,
      },
    ],
  },
]

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: QuicklinkStrategy,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }],
  exports: [RouterModule],
})
export class RootRoutingModule {}
