import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login'
import { environment } from 'src/environments/environment'

import { FeatureKeys } from '../enums/store.enums'
import { InitMaterialModule } from '../material/init-material.module'
import { InitUiModule } from '../ui/init-ui/init-ui.module'
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component'
import { RegisterFormOldComponent } from './components/register-form-old/register-form-old.component'
import { RegisterFormComponent } from './components/register-form/register-form.component'
import { LoginEffects } from './effects/login.effects'
import { LoginPage } from './pages/login/login.page'
import { PasswordPage } from './pages/password/password.page'
import { RegisterPage } from './pages/register/register.page'
import { ResetPasswordPage } from './pages/reset-password/reset-password.page'
import { SetupPage } from './pages/setup/setup.page'
import { StartPage } from './pages/start/start.page'
import * as fromLogin from './reducers/login.reducer'
import { ConfirmEmailPage } from './pages/confirm-email/confirm-email.page'
import { SharedPrimeNgModule } from '../primeng/shared-primeng.module'
import { UiModule } from '../ui/ui/ui.module'
import { VerifyEmailDialogComponent } from './components/verify-email-dialog/verify-email-dialog.component'
import { PasswordRequirementsComponent } from './components/password-requirements/password-requirements.component'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InitMaterialModule,
    FormsModule,
    InitUiModule,
    SocialLoginModule,
    StoreModule.forFeature(FeatureKeys.Authorize, fromLogin.reducer),
    EffectsModule.forFeature([LoginEffects]),
    SharedPrimeNgModule,
    UiModule,
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.federatedAuth.google, {
              oneTapEnabled: false,
              scopes: 'profile email',
            }),
          },
        ],
        onError: (err) => {
          console.error(err)
        },
      } as SocialAuthServiceConfig,
    },
  ],
  declarations: [
    LoginPage,
    PasswordPage,
    ResetPasswordPage,
    ChangePasswordFormComponent,
    RegisterPage,
    SetupPage,
    RegisterFormComponent,
    RegisterFormOldComponent,
    StartPage,
    ConfirmEmailPage,
    VerifyEmailDialogComponent,
    PasswordRequirementsComponent,
  ],
})
export class UserModule {}
