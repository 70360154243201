import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { DataSourceEnum } from '../enums/dataSource.enums'
import { IUSState } from './models/ius-state'

export const adapter: EntityAdapter<IUSState> = createEntityAdapter<IUSState>({
  selectId: (model) => model.abbreviation,
  sortComparer: (a: IUSState, b: IUSState): number => {
    const aName = a.name ? `${a.name.toString()}`.toLowerCase() : ''
    const bName = b.name ? `${b.name.toString()}`.toLowerCase() : ''
    return aName.localeCompare(bName)
  },
})

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface State extends EntityState<IUSState> {
  source: DataSourceEnum
  working: string
}

export const initialState: State = adapter.getInitialState({
  isTouched: true,
  isLoading: false,
  source: DataSourceEnum.Local,
  working: '',
})
