<section class="max-w-28rem">
  <ng-container *ngIf="isWorking$ | async; then showWorking else showPage"></ng-container>
  <ng-template #showWorking>
    <div class="progress-spinner">
      <p-progressSpinner animationDuration="1s" styleClass="text-primary"></p-progressSpinner>
    </div>
  </ng-template>
  <ng-template #showPage>
    <ng-container
      *ngIf="isPasswordResetEmailSuccess$ | async; then showEmailSent else showForm"
    ></ng-container>
    <ng-template #showEmailSent>
      <p-card class="w-full text-center" styleClass="shadow-none sm:shadow-4">
        <h3 class="mt-0 font-bold login-title">Check your email</h3>
        <p-divider></p-divider>
        <p class="mb-4">We've sent password reset instructions to:</p>
        <h4 class="font-bold mb-6">{{submittedEmail$ | async}}</h4>
        <p>Didn't get the email?</p>
        <p class="mb-6">
          Check your spam folder or
          <button
            pButton
            (click)="resendEmail()"
            class="p-button-text p-0"
            label="Resend the email"
          ></button>
        </p>
        <p class="text-center mb-6">
          Need Help? <a tcLink href="mailto:service@taxcloud.com">Contact Support</a>
        </p>
        <a tcLink [routerLink]="'/sign-in'">Back to Log In</a>
      </p-card>
    </ng-template>
    <ng-template #showForm>
      <p-card class="w-full" styleClass="shadow-none sm:shadow-4">
        <h3 class="mt-0 font-bold text-center login-title">Password Reset</h3>
        <p-divider></p-divider>
        <p class="text-center">
          Please enter the email address that you used when creating your account.
        </p>
        <form class="mt-2" [formGroup]="formGroup">
          <div class="formgrid grid">
            <div class="field col-12 mb-4 mt-2">
              <input
                class="w-full"
                placeholder="Email*"
                autocomplete="email"
                formControlName="email"
                pInputText
                required
              />
            </div>
          </div>
        </form>
        <button
          pButton
          pRipple
          type="submit"
          label="Reset My Password"
          class="p-button w-full mt-0 mb-4"
          (click)="resetPassword()"
          [disabled]="formGroup.invalid"
        ></button>
        <div class="flex justify-content-center">
          <button
            pButton
            pRipple
            label="Back to Log In"
            class="p-button-text p-0"
            (click)="login()"
          ></button>
        </div>
      </p-card>
    </ng-template>
  </ng-template>
</section>
