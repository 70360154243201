import Dexie from 'dexie'
import { from, Observable } from 'rxjs'

import { IServiceFee } from '../models/iservice-fees.models'

export class ServiceFeeDatabase extends Dexie {
  private fees: Dexie.Table<IServiceFee, string>

  constructor() {
    super('ServiceFeeDB')
    this.version(1).stores({
      fees: 'id, month',
    })
    this.fees = this.table('fees')
  }

  public saveFees(fees: IServiceFee[]): Observable<string> {
    return from(this.fees.clear().then((_) => this.fees.bulkPut(fees)))
  }

  public clearDatabase(): Observable<void> {
    return from(this.fees.clear())
  }

  public getFees(): Observable<IServiceFee[]> {
    return from(this.fees.orderBy('month').reverse().toArray())
  }

  public getFee(id: string): Observable<IServiceFee | undefined> {
    return from(this.fees.get({ id }))
  }

  public removeFee(id: string): Observable<any> {
    return from(this.fees.delete(id))
  }
}
