export enum TransactionStateEnum {
  Lookup = 'lookup',
  Authorized = 'authorized',
  Caputured = 'captured',
  Returned = 'returned',
}

export const TransactionStateEnumLabel = new Map<TransactionStateEnum, string>([
  [TransactionStateEnum.Lookup, 'Lookup'],
  [TransactionStateEnum.Authorized, 'Authorized'],
  [TransactionStateEnum.Caputured, 'Captured'],
  [TransactionStateEnum.Returned, 'Returned'],
])

export enum JurisdictionTypeEnum {
  State = 'state',
  County = 'county',
  City = 'city',
  Other = 'other',
}

export enum UploadTransactionDataTypeEnum {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  DELIVERED = 'delivered',
  STATE = 'state',
}

export enum UploadTransactionErrorType {
  INVALID_ROW_LENGTH = 'INVALID_ROW_LENGTH',
  NOT_POSITIVE = 'NOT_POSITIVE',
  OVER_MAX = 'OVER_MAX',
  LENGTH_EXCEPTION = 'LENGTH_EXCEPTION',
  NULL_EXCEPTION = 'NULL_EXCEPTION',
  INVALID_DATE = 'INVALID_DATE',
  INVALID_STATE = 'INVALID_STATE',
  INVALID_DELIVERED = 'INVALID_DELIVERED',
  DUPLICATE_CART_INDEX = 'DUPLICATE_CART_INDEX',
  INVALID_RETURN_POSITION = 'INVALID_RETURN_POSITION',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export enum TransactionUpdateEnum {
  Success = 'success',
  Redundent = 'redundent',
  Fail = 'fail',
  Error = 'error',
}

// export const TransactionUpdateEnumLabel = new Map<TransactionUpdateEnum, string>([
//     [TransactionUpdateEnum.Success, 'Success: Item will'],
//     [CorpTypeEnum.LimitedLiability, 'Limited Liability Company'],
//     [CorpTypeEnum.Partnership, 'Partnership'],
//     [CorpTypeEnum.SoleProprietor, 'Sole Proprietor'],
//     [CorpTypeEnum.Trust, 'Trust'],
//     [CorpTypeEnum.Government, 'Government'],
//     [CorpTypeEnum.Other, 'Other'],
// ]);
