import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Update } from '@ngrx/entity'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter, map, tap } from 'rxjs/operators'
import { DateFormatEnum } from 'src/app/enums/date.enums'
import {
  DirectFilingFrequencyEnumLabel,
  DirectFilingTaxTypeEnumLabel,
} from 'src/app/enums/direct-filing.enums'
import { getOptionsFromLabel, IOption } from 'src/app/utilities/select-box.utilities'
import { SubSink } from 'subsink'

import { IUSState } from '../../models/ius-state'
import { UsStateAdapter } from '../../utilities/us-state-adapter.utilities'
import * as usStateActions from './../../actions/us-states.actions'
import { State as UsStateStoreState } from './../../us-states.store'

@Component({
  selector: 'tc-direct-filing-form',
  templateUrl: './direct-filing-form.component.html',
  styleUrls: ['./direct-filing-form.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectFilingFormComponent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup
  currentMonth = moment().format(DateFormatEnum.Month)
  startDate = new UntypedFormControl()
  endDate = new UntypedFormControl()
  frequencyOptions: IOption[] = getOptionsFromLabel(DirectFilingFrequencyEnumLabel)
  taxTypeOptions: IOption[] = getOptionsFromLabel(DirectFilingTaxTypeEnumLabel)
  state$ = new BehaviorSubject<IUSState>(null)
  idConstraintMsg = ''
  idValidatorPattern
  private subs = new SubSink()
  private adapter = new UsStateAdapter()

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private usStateStore: Store<UsStateStoreState>
  ) {
    {
      this.formGroup = fb.group({
        login: ['', Validators.required],
        password: ['', Validators.required],
        startDate: this.startDate,
        endDate: this.endDate,
      })
    }
  }

  @Input('usState')
  set setUsState(state: IUSState) {
    if (state) {
      this.state$.next(state)
    } else {
      this.state$.next(null)
    }
  }
  @Input() masquerading = false

  public isCollecting$: Observable<boolean> = this.state$.pipe(map((state) => state?.collecting))

  ngOnInit(): void {
    this.subs.add(
      this.state$
        .pipe(
          filter((state) => !!state),
          map((state) => state.stateLicense),
          tap((license) => {
            this.formGroup.patchValue({
              frequency: license.filingFrequency,
              login: license.login,
              licenseId: license.id,
              taxType: license.type,
              password: license.password,
              formSelected: license.formSelected,
            })
          })
        )
        .subscribe()
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  public cancel(): void {
    this.backToList()
  }

  public turnOnDirectFile() {
    const options = {
      autoFile: true,
      includeStartDate: true,
      includeEndDate: false,
    }
    this.updateDirectFile(options)
  }
  public updateCurrentDirectFile() {
    const state = this.state$.value
    const options = {
      autoFile: state.autoFile ? state.autoFile : false,
      includeStartDate: false,
      includeEndDate: false,
    }
    this.updateDirectFile(options)
  }

  public turnOffDirectFile() {
    const options = {
      autoFile: false,
      includeStartDate: false,
      includeEndDate: true,
    }
    this.updateDirectFile(options)
  }

  public backToList() {
    this.router.navigate(['../'], {
      relativeTo: this.currentRoute,
    })
  }

  private updateDirectFile(options: {
    autoFile: boolean
    includeStartDate: boolean
    includeEndDate: boolean
  }) {
    const usState: IUSState = { ...this.state$.value }
    const form = this.formGroup.getRawValue()
    const { stateLicense } = usState
    const update: Update<IUSState> = {
      id: usState.abbreviation,
      changes: {
        autoFile: options.autoFile,
        stateLicense: {
          ...stateLicense,
          login: form.login,
          password: form.password,
        },
      },
    }
    if (options.includeStartDate) {
      update.changes.autoFileStartDate = moment(form.startDate).format(DateFormatEnum.SqlTime)
    }
    if (options.includeEndDate) {
      // update.changes.autoFileEndDate =  moment(form.endDate).format(DateFormatEnum.SqlTime);
      update.changes.autoFileEndDate = moment(form.endDate)
        .subtract(1, 'days')
        .format(DateFormatEnum.SqlTime)
    }
    this.usStateStore.dispatch(new usStateActions.UpdateDirectFilingLocal({ update }))
    this.backToList()
  }
}
