import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Store } from '@ngrx/store'
import { first, tap } from 'rxjs/operators'
import { State as AccountState } from 'src/app/account/account.state'
import { selectIsLeaving } from 'src/app/account/selectors/account.selectors'

@Injectable({
  providedIn: 'root',
})
export class IsLeavingGuard implements CanActivate {
  constructor(
    private accountStore: Store<AccountState>,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkIfAccountIsLeaving(state.url)
  }

  checkIfAccountIsLeaving(url: string): boolean {
    let activate = false
    this.accountStore
      .select(selectIsLeaving)
      .pipe(
        first(),
        tap((isLeaving) => {
          if (isLeaving) {
            activate = false
            this.router.navigate(['/go/account'])
          } else {
            activate = true
          }
        })
      )
      .subscribe()

    return activate
  }
}
