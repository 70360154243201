import { animate, style, transition, trigger } from '@angular/animations'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'
import { SupportCategoryEnum, SupportCategoryEnumLabel } from 'src/app/enums/support.enums'

import { ISupportPage } from '../../models/isupport-page'
import { SupportService } from '../../services/support.service'

@Component({
  selector: 'tc-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0.1 }),
        animate('400ms ease-in', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class FaqComponent {
  public supportCategory$ = new BehaviorSubject<SupportCategoryEnum>(null)
  public open$ = new BehaviorSubject<boolean>(false)

  constructor(private supportService: SupportService) {}

  public faqs$: Observable<ISupportPage[]> = this.supportCategory$.pipe(
    filter((category) => !!category),
    switchMap((category) => this.supportService.getPagesByCategory(category))
  )

  public categoryLabel$: Observable<string> = this.supportCategory$.pipe(
    filter((category) => !!category),
    map((category) => SupportCategoryEnumLabel.get(category))
  )

  @Input('supportCategory')
  set setCategory(category: SupportCategoryEnum) {
    if (!category) {
      throw Error('FAQ requires a category')
    }
    this.supportCategory$.next(category)
  }

  public toggle(event): void {
    this.open$.next(!this.open$.value)
  }
}
