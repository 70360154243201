import { Directive, ElementRef } from '@angular/core'

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'img' })
export class LazyLoadImageDirective {
  constructor(private el: ElementRef<HTMLImageElement>) {
    const supports = 'loading' in HTMLImageElement.prototype

    if (supports) {
      el.nativeElement.setAttribute('loading', 'lazy')
    }
  }
}
