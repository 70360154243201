import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor() {}

  public downloadCSV(data: string, fileName: string = 'data'): void {
    const name = fileName + '.csv'
    const encodedUri = URL.createObjectURL(new Blob([`${data}`], { type: 'text/csv' }))
    const link = document.createElement('a')

    link.setAttribute('href', encodedUri)
    link.setAttribute('target', '_blank')
    link.setAttribute('download', name)

    const isSafariBrowser =
      navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1
    if (isSafariBrowser) {
      link.setAttribute('target', '_blank')
    }
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  public downloadBlob(blob: Blob, fileName: string = 'data', fileType: string = 'csv'): void {
    const name = `${fileName}.${fileType}`
    const encodedUri = URL.createObjectURL(blob)
    const link = document.createElement('a')

    link.setAttribute('href', encodedUri)
    link.setAttribute('target', '_blank')
    link.setAttribute('download', name)

    const isSafariBrowser =
      navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1
    if (isSafariBrowser) {
      link.setAttribute('target', '_blank')
    }
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  public getExtension(fileName: string): string {
    let extension = 'csv'
    const lastIndex = fileName.lastIndexOf('.')
    if (lastIndex !== -1) {
      extension = fileName.substring(lastIndex + 1)
    }
    return extension
  }
  public removeExtensionInFileName(fileName: string): string {
    let filename = fileName
    const lastIndex = fileName.lastIndexOf('.')
    if (lastIndex !== -1) {
      filename = fileName.substring(0, lastIndex)
    }
    return filename
  }
}
