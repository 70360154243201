export function isEquivalent(a: object, b: object) {
  const aKeys = Object.getOwnPropertyNames(a)
  const bKeys = Object.getOwnPropertyNames(b)

  if (aKeys.length !== bKeys.length) {
    return false
  }

  for (const aKey of aKeys) {
    if (!Object.is(a[aKey], b[aKey])) {
      return false
    }
  }
  return true
}
