import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map, withLatestFrom } from 'rxjs/operators'
import * as appStatusActions from 'src/app/app-status/actions/app-status.actions'
import { State as AppStatusState } from 'src/app/app-status/app-status.state'
import { selectOnline } from 'src/app/app-status/selectors/app-status.selectors'
import { selectLoginStatus } from 'src/app/user/selectors/user.selectors'
import { State as AuthState } from 'src/app/user/user.state'

import { LoginEnum } from '../../enums/login.enum'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authStore: Store<AuthState>,
    private appStatusStore: Store<AppStatusState>,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authStore.select(selectLoginStatus).pipe(
      withLatestFrom(this.appStatusStore.select(selectOnline)),
      map(([loginStatus, online]) => {
        if (!online) {
          return true
        }

        if (loginStatus === LoginEnum.LoggedIn) {
          return true
        }

        this.appStatusStore.dispatch(
          new appStatusActions.SetReturnRoute({ returnUrl: this.getReturnUrl(state) })
        )
        this.router.navigate(['/sign-in'])
        return false
      })
    )
  }

  private getReturnUrl(state: RouterStateSnapshot) {
    const { pathname, searchParams } = new URL(state.url, window.location.origin)
    const queryParams = {}
    for (let [key, value] of searchParams.entries()) {
      queryParams[key] = value
    }

    return {
      pathname,
      queryParams,
    }
  }
}
