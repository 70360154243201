import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { BehaviorSubject, Observable } from 'rxjs'

import { State as UserState } from 'src/app/user/user.state'
import * as userActions from 'src/app/user/actions/login.actions'
import * as userSelector from 'src/app/user/selectors/user.selectors'
import { first, tap } from 'rxjs/operators'

@Component({
  templateUrl: './view-latest-terms-of-service.page.html',
  styleUrls: ['./view-latest-terms-of-service.page.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewLatestTermsOfServicePage implements OnInit {
  public agreeCheckValue$ = new BehaviorSubject<boolean>(false)

  constructor(
    private router: Router,
    private userStore: Store<UserState>
  ) {}
  public working$: Observable<boolean> = this.userStore.select(userSelector.selectWorking)

  public message$: Observable<string> = this.userStore.select(userSelector.selectWorkingMessage)

  public hasLatestTos$: Observable<boolean> = this.userStore.pipe(
    select(userSelector.selectHasLatestTermsOfService)
  )

  public agreedToTermsOfService(value: boolean) {
    this.agreeCheckValue$.next(value)
  }

  public acceptLatestTermsOfService(): void {
    this.userStore.dispatch(new userActions.AcceptLatestTermsOfService())
    this.hasLatestTos$
      .pipe(
        first((hasLatestTos) => !!hasLatestTos),
        tap(() => this.router.navigate(['/go/dashboard']))
      )
      .subscribe()
  }

  ngOnInit(): void {}
}
