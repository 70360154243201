import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'

import { MaterialModule } from '../material/material.module'
import { UiModule } from '../ui/ui/ui.module'
import { SupportCategoryComponent } from './components/support-category/support-category.component'
import { ListSupportPage } from './pages/list-support/list-support.page'
import { SupportDetailPage } from './pages/support-detail/support-detail.page'
import { SupportRoutesModule } from './routes/support.routes'
import { FaqComponent } from './components/faq/faq.component'
import { DevDocsButtonComponent } from './components/dev-docs-button/dev-docs-button.component'

@NgModule({
  declarations: [
    SupportDetailPage,
    ListSupportPage,
    SupportCategoryComponent,
    FaqComponent,
    DevDocsButtonComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, SupportRoutesModule, MaterialModule, UiModule],
  exports: [FaqComponent, DevDocsButtonComponent],
})
export class SupportModule {}
