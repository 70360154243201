import Dexie from 'dexie'
import { Observable, from } from 'rxjs'
import { ISupportPage } from '../models/isupport-page'
import { SupportCategoryEnum, SupportCategoryEnumLabel } from 'src/app/enums/support.enums'
import { SupportCategoryComponent } from '../components/support-category/support-category.component'
import { map } from 'rxjs/operators'

export class SupportDatabase extends Dexie {
  private pages: Dexie.Table<ISupportPage, number>

  constructor() {
    super('SupportDB')
    this.version(1).stores({
      pages: 'slug, category, name',
    })
    this.pages = this.table('pages')
  }

  public savePages(pages: ISupportPage[]): Observable<number> {
    return from(this.pages.clear().then((_) => this.pages.bulkPut(pages)))
  }

  public updatePage(page: ISupportPage) {
    return from(this.pages.put(page))
  }

  public getAllPages(): Observable<ISupportPage[]> {
    return from(this.pages.orderBy('name').toArray())
  }

  public getPagesFromCategory(category: SupportCategoryEnum): Observable<ISupportPage[]> {
    return from(
      this.pages
        .orderBy('name')
        .filter((page) => page.category === category)
        .toArray()
    )
  }

  public getAllPageAsMap(): Observable<Map<SupportCategoryEnum, ISupportPage[]>> {
    return this.getAllPages().pipe(
      map((pages) => {
        const mapped: Map<SupportCategoryEnum, ISupportPage[]> = new Map()
        const memoized = {}
        pages.forEach((page) => {
          if (!memoized[page.category]) {
            memoized[page.category] = [page]
          } else {
            memoized[page.category].push(page)
          }
        })
        Object.entries(memoized).forEach(([key, value]) => {
          mapped.set(key as SupportCategoryEnum, value as ISupportPage[])
        })
        return mapped
      })
    )
  }

  public getPage(slug: string): Observable<ISupportPage | undefined> {
    return from(this.pages.get({ slug }))
  }

  public clearPages(): Observable<void> {
    return from(this.pages.clear())
  }
}
