<section>
  <div class="margin_h_md">
    <button
      *ngIf="isShopifyUser$ | async"
      mat-button
      tcColorDark
      tcColor="primary"
      routerLink="/go/states-management/direct-file-manager"
    >
      MANAGE SHOPIFY DIRECT FILING ENROLLMENT
    </button>
  </div>
  <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="1rem">
    <tc-us-map-collecting class="margin_h_sm" (selectState)="selectState($event)">
    </tc-us-map-collecting>

    <tc-edit-us-states></tc-edit-us-states>
  </div>
  <tc-faq style="padding: 2rem 1rem" [supportCategory]="supportCategory"> </tc-faq>
</section>
