export enum AccountStateEnum {
  Prospective = 'prospective',
  Suspended = 'suspended',
  Converted = 'converted',
  Attestation = 'attestation',
  Frozen = 'frozen',
  Leaving = 'leaving',
  Closed = 'closed',
}

export const AccountStateLabel = new Map<AccountStateEnum, string>([
  [AccountStateEnum.Converted, 'Converted'],
  [AccountStateEnum.Prospective, 'Prospective'],
  [AccountStateEnum.Suspended, 'Suspended'],
  [AccountStateEnum.Attestation, 'Needs Attestation'],
  [AccountStateEnum.Frozen, 'Frozen'],
  [AccountStateEnum.Leaving, 'Leaving'],
  [AccountStateEnum.Closed, 'Closed'],
])
