import { ShellEnum } from './../../enums/shell.enums'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { map, withLatestFrom } from 'rxjs/operators'
import { BlogCategoryEnum } from 'src/app/enums/blog.enums'
import { GetStartedCategoryEnum } from 'src/app/enums/get-started.enums'
import { IGetStartedMessage } from 'src/app/root/models/iget-started-message'

import { IBlogPage } from '../models/iblog.models'
import * as fromRouterSelectors from 'src/app/root/selectors/root.selectors'
import { AlertTypeEnum } from 'src/app/enums/alert.enums'
import { INotification } from 'src/app/notification/models/inotification.model'
import { Store } from '@ngrx/store'
import { IRouterState } from 'src/app/root/models/irouterstate'
import * as fromRouter from '@ngrx/router-store'

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  private getStartedMessageCache: Map<GetStartedCategoryEnum, IGetStartedMessage> = new Map()
  constructor(
    private http: HttpClient,
    private route: Store<fromRouter.RouterReducerState<IRouterState>>
  ) {}

  public getBlogPages(category?: BlogCategoryEnum): Observable<IBlogPage[]> {
    const catParam = `&category_slug=${category}`
    return this.http
      .get(`cms/posts/?page=1&page_size=100&exclude_body=true${category ? catParam : ''}`)
      .pipe(map((result: any) => result.data)) as Observable<IBlogPage[]>
  }

  public getBlogPage(slug: string): Observable<IBlogPage> {
    return this.http
      .get(`cms/posts/${slug}`)
      .pipe(map((result: any) => result.data)) as Observable<IBlogPage>
  }

  public filterBlogs(blogs: Readonly<IBlogPage[]>, shell: ShellEnum): IBlogPage[] {
    let filtered = []
    if (shell === ShellEnum.Go) {
      filtered = [...blogs]
    } else {
      blogs.map((blogEntry) => {
        let isInternal = false
        blogEntry.categories.map((category) => {
          if (category.slug === BlogCategoryEnum.InternalNews) {
            isInternal = true
          }
        })
        if (!isInternal) {
          filtered.push(blogEntry)
        }
      })
    }
    return filtered
  }

  public getBlogAlerts(category?: BlogCategoryEnum): Observable<INotification[] | any[]> {
    const today = new Date()
    return this.getBlogPages(category).pipe(
      withLatestFrom(this.route.select(fromRouterSelectors.selectShell)),
      map(([blogPages, shell]) => {
        const notifications = []
        const prevMonth = new Date(new Date().setMonth(today.getMonth() - 1))
        const filteredBlogs = this.filterBlogs(blogPages, shell)
        const latestBlog = filteredBlogs[0]
        if (latestBlog) {
          const pubDate = new Date(latestBlog.published)
          if (pubDate > prevMonth) {
            notifications.push({
              id: latestBlog.slug,
              label: latestBlog.title,
              link: `/go/blog/${latestBlog.slug}`,
              type: AlertTypeEnum.BlogAlert,
              message: 'New Blog Post',
            })
          }
        }
        return notifications as INotification[]
      })
    )
  }
}
