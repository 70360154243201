export enum FilingResponsibilityEnum {
  Unknown = 0,
  SST = 1,
  Direct = 2,
  Merchant = 3,
}

export const FilingResponsibilityEnumLabel = new Map<FilingResponsibilityEnum, string>([
  [FilingResponsibilityEnum.Unknown, 'Unknown'],
  [FilingResponsibilityEnum.SST, 'TaxCloud SST'],
  [FilingResponsibilityEnum.Direct, 'TaxCloud Direct'],
  [FilingResponsibilityEnum.Merchant, 'Merchant'],
])
