import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { merge, Observable, of } from 'rxjs'
import { filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { State as AppState } from 'src/app/app-status/app-status.state'
import * as statusSelector from 'src/app/app-status/selectors/app-status.selectors'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'

import { ClearUsStates } from '../actions/us-states.actions'
import { UsStateDatabase } from '../db/local-us-states.db'
import { IApiPublicUsState } from '../models/iapi-public-states'
import { IApiUsState } from '../models/iapi-state'
import { IPublicUsState } from '../models/ipublic-us-states'
import { IStateShapeData } from '../models/istate-shape-data'
import { IUsStateSelect } from '../models/ius-state-select'
import * as stateSelector from '../selectors/us-state.selector'
import { convertShapeDataToDictionary } from '../utilities/map-us-states-shape-adapter'
import { UsStateAdapter } from '../utilities/us-state-adapter.utilities'
import usStates from './../data/us-states.data.json'
import { IUSState } from './../models/ius-state'
import { State as UsStateState } from './../us-states.store'
import { DirectFilingRequestLimit } from './../../enums/direct-filing.enums'
import { IDirectFileRequest, IDirectFileRequestUpdate } from '../models/idirect-filing.models'
import { validateDate } from 'src/app/utilities/date.utilities'

@Injectable({
  providedIn: 'root',
})
export class USStateService {
  private db = new UsStateDatabase()
  private adapter = new UsStateAdapter()
  private publicStateCache: Map<string, IPublicUsState> = new Map()

  constructor(
    private http: HttpClient,
    private usStateStore: Store<UsStateState>,
    private appStatus: Store<AppState>
  ) {}

  public getUsStateShapeData(): Observable<Map<string, IStateShapeData>> {
    return this.http
      .get(`cdn/uLeTVoJPRpORLuiUQ2Yy`)
      .pipe(map((results: IStateShapeData[]) => convertShapeDataToDictionary(results)))
  }

  public getSsutaStates(): Observable<IPublicUsState[]> {
    return this.getUsStatesFromPublic().pipe(
      map((states) => states.filter((state) => state.isSsuta))
    )
  }

  public getUsStatesFromPublic(): Observable<IPublicUsState[]> {
    if (this.publicStateCache.size > 0) {
      return of(Array.from(this.publicStateCache.values()))
    }
    return this.http.post(`api/post-wayfair-states`, {}).pipe(
      map((results: IApiPublicUsState[]) => {
        return results.map((result) => this.adapter.mapPublicApiToUsState(result))
      }),
      tap((states) => states.map((state) => this.publicStateCache.set(state.abbreviation, state)))
    )
  }

  public getBasicUsStates(): Observable<IUsStateSelect[]> {
    return of(usStates)
  }

  public getUsStates(): Observable<[IUSState[], DataSourceEnum]> {
    return merge(this.getUsStatesFromApiWhenOnline(), this.getStatesFromLocal())
  }

  public refreshUsStates(): Observable<IUSState[]> {
    return this.getUsStateDataFromApi().pipe(map(([states, _]) => states))
  }

  public clearUsStates(): void {
    this.db.clearUsStates().pipe(first()).subscribe()
    this.usStateStore.dispatch(new ClearUsStates())
  }

  public queryUsStates(name: string = ''): Observable<IUSState[]> {
    return this.db.findUsStateByName(name)
  }

  public getStateFromFips(fips: string = ''): Observable<IUSState | null> {
    return this.db.getUsState(fips)
  }

  public getStateFromAbbreviation(abbreviation: string = ''): Observable<IUSState | null> {
    return this.db.getUsStateByAbbreviation(abbreviation)
  }

  public getCollectingStates() {
    return this.db.getCollectingStates()
  }

  public getAutoFile(): Observable<IUSState[]> {
    return this.db.getAutoFile()
  }

  public getAutoFillNonSSuta(): Observable<IUSState[]> {
    return this.db.getAutoFillNonSSuta()
  }

  public getCollectingNonSSuta(): Observable<IUSState[]> {
    return this.db.getCollectingNonSSuta()
  }

  public getUsStateDataFromApi(): Observable<[IUSState[], DataSourceEnum]> {
    return this.http.get('api/states2').pipe(
      map((results: IApiUsState[]) =>
        results.map((result) => this.adapter.mapStateApiToStateModel(result))
      ),
      tap((allStates: IUSState[]) => this.db.saveUsStates(allStates)),
      map((allStates) => [allStates, DataSourceEnum.API])
    )
  }

  private getUsStatesFromApiWhenOnline(): Observable<[IUSState[], DataSourceEnum]> {
    return this.appStatus.select(statusSelector.selectOnline).pipe(
      filter((online) => online),
      switchMap(() => this.getUsStateDataFromApi())
    )
  }

  private getStatesFromLocal(): Observable<[IUSState[], DataSourceEnum]> {
    return this.db.getUsStates().pipe(
      withLatestFrom(this.usStateStore.select(stateSelector.selectDataSource)),
      filter(([allStates, source]) => {
        if (source === DataSourceEnum.API) {
          return false
        }
        if (usStates.length === 0) {
          return false
        }
        return true
      }),
      map(([allStates, _]) => [allStates, DataSourceEnum.IndexedDB])
    )
  }

  public updateBasicFiling(usState: IUSState) {
    const { stateLicense } = usState
    const body: any = {
      Abbr: usState.abbreviation,
      ID: stateLicense.id,
      TypeID: stateLicense.type,
      FormID: stateLicense.formSelected,
      StateID: stateLicense.stateId,
      FilingFrequency: stateLicense.filingFrequency,
      active: usState.collecting,
      autoFile: false,
      AutoFileChange: false,
      autoFileLoginID: '',
      autoFilePassword: '',
      states: usState.abbreviation,
    }
    if (usState.stateLicense.locationId) {
      body.LocationID = usState.stateLicense.locationId
    }
    return this.http.post(`api/tax-states/writestateconsolidated2`, body)
  }

  public updateDirectFiling(usState: IUSState) {
    const { stateLicense } = usState
    const body: any = {
      Abbr: usState.abbreviation,
      ID: stateLicense.id,
      TypeID: stateLicense.type,
      FormID: stateLicense.formSelected,
      StateID: stateLicense.stateId,
      FilingFrequency: stateLicense.filingFrequency,
      active: true,
      AutoFileChange: true,
      autoFile: usState.autoFile,
      autoFileLoginID: stateLicense.login || '',
      autoFilePassword: stateLicense.password || '',
      states: usState.abbreviation,
    }

    if (usState.autoFileStartDate) {
      body.AutoFileStart = usState.autoFileStartDate
    }
    if (usState.autoFileEndDate) {
      body.AutoFileEnd = usState.autoFileEndDate
    }

    if (usState.stateLicense.locationId) {
      body.LocationID = usState.stateLicense.locationId
    }
    return this.http.post(`api/tax-states/writestateconsolidated2`, body)
  }

  public updateLocalDatabase(usState: IUSState): Observable<number> {
    return this.db.updateUsState(usState.fips, usState)
  }
  public checkRequestLimit(): Observable<DirectFilingRequestLimit> {
    return this.http.get<DirectFilingRequestLimit>('api/shopify/merchantfilingrequestlimit').pipe(
      map((response: string) => {
        if (response.toLowerCase() === 'limit not reached') {
          return DirectFilingRequestLimit.LIMIT_NOT_REACHED
        }
        if (response.toLowerCase() === 'limit reached') {
          return DirectFilingRequestLimit.LIMIT_REACHED
        }
      })
    )
  }
  public requestDirectFileStates(
    statesAbbreviationList: string,
    limitReached = false
  ): Observable<IDirectFileRequest> {
    return this.http.post<IDirectFileRequest>('api/shopify/addmerchantfilingrequest', {
      States: statesAbbreviationList,
      FullQueue: limitReached,
    })
  }
  public updateDirectFileMerchantRequest(
    filingRequest: IDirectFileRequest
  ): Observable<IDirectFileRequest> {
    const update: IDirectFileRequestUpdate = {
      Rejected: filingRequest.rejected,
      MerchantID: filingRequest.merchantID,
      ID: filingRequest.id,
    }
    return this.http.post<IDirectFileRequest>('api/shopify/updatemerchantfilingrequest', update)
  }
  public getDirectFileRequests(merchantId?: number): Observable<IDirectFileRequest[]> {
    let url = 'api/shopify/merchantfilingrequests'
    if (merchantId) {
      url += `?MerchantID=${merchantId}`
    }
    return this.http.get<IDirectFileRequest[]>(url).pipe(
      map((requests) =>
        requests.map((request) => ({
          ...request,
          processedDate: validateDate(request.processedDate),
        }))
      )
    )
  }
}
