import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { State as AccountState } from 'src/app/account/account.state'
import * as accountSelector from 'src/app/account/selectors/account.selectors'
import { TaxCloudColorEnum } from 'src/app/enums/color.enums'
import { ServicePlanTypeEnum, ServicePlanTypeEnumLabel } from 'src/app/enums/service-plan.enums'
import { UsStateCollectionTypeEnum, UsStateEnum } from 'src/app/enums/us-state.enums'
import * as stateActions from 'src/app/us-states/actions/us-states.actions'
import * as fromUsStateSelector from 'src/app/us-states/selectors/us-state.selector'
import { State as UsStateStore } from 'src/app/us-states/us-states.store'

import { IDisplayUsState } from '../../models/idisplay-us-state'

@Component({
  selector: 'tc-us-map-collecting',
  templateUrl: './us-map-collecting.component.html',
  styleUrls: ['./us-map-collecting.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsMapCollectingComponent {
  collectingColor = TaxCloudColorEnum.Primary
  notCollectingColor = TaxCloudColorEnum.Hover
  needToCollect = TaxCloudColorEnum.Error

  constructor(
    private usStateStore: Store<UsStateStore>,
    private accountStore: Store<AccountState>
  ) {
    usStateStore.dispatch(new stateActions.InitStatesCollecting())
  }

  @Output() selectState = new EventEmitter<UsStateEnum>()

  private servicePlan$: Observable<ServicePlanTypeEnum> = this.accountStore
    .select(accountSelector.selectServicePlan)
    .pipe(
      filter((plan) => !!plan),
      map((plan) => plan.type)
    )

  public isWorking$: Observable<boolean> = this.usStateStore.select(
    fromUsStateSelector.selectIsWorking
  )

  public isAutomatedServicePlan$: Observable<boolean> = this.servicePlan$.pipe(
    map((planType) => planType === ServicePlanTypeEnum.Automated)
  )

  public servicePlanDescription$: Observable<string> = this.servicePlan$.pipe(
    map((planType) => ServicePlanTypeEnumLabel.get(planType))
  )

  collectingUsStates$: Observable<IDisplayUsState[]> = this.usStateStore.pipe(
    select(fromUsStateSelector.selectAll),
    map((usStates) =>
      usStates.map((usState): IDisplayUsState => {
        let fillColor: TaxCloudColorEnum
        switch (usState.collectingType) {
          case UsStateCollectionTypeEnum.Collecting:
            fillColor = this.collectingColor
            break
          case UsStateCollectionTypeEnum.NeedToCollect:
            fillColor = this.needToCollect
            break
          default:
            fillColor = this.notCollectingColor
        }
        return {
          abbreviation: usState.abbreviation,
          name: usState.name,
          fips: usState.fips,
          fillColor,
          toolTip: usState.name,
        }
      })
    )
  )

  public clickState(state: UsStateEnum): void {
    this.selectState.emit(state)
  }
}
