import { Actions, createEffect, ofType } from '@ngrx/effects'
import { BillingService } from '../services/billing.service'
import { Action, Store } from '@ngrx/store'
import { State as BillingState } from './../manage-account.state'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { BillingActionTypes } from '../actions/billing.action'
import * as actions from '../actions/billing.action'
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import {
  Customer,
  CustomerUsage,
  CustomerUsageReport,
  Entitlement,
  Invoice,
  PaymentMethod,
  PlanDetail,
  PlanSubscription,
  SubscriptionEntitlements,
} from '../models/billing.model'
import { State as AccountState } from '../../account/account.state'
import * as accountSelector from 'src/app/account/selectors/account.selectors'
import * as billingSelector from 'src/app/manage-account/selectors/billing.selector'
import { NotificationService } from 'src/app/notification/services/notification.service'
import { AccountService } from 'src/app/account/services/account.service'
import userflow from 'userflow.js'
import { State as UserState } from 'src/app/user/user.state'
import * as fromUserSelectors from 'src/app/user/selectors/user.selectors'
import { AuthorityLabel } from 'src/app/enums/authority.enums'
import moment from 'moment'
import { CapitalizeFirstPipe } from 'src/app/ui/ui/pipes/capitalize-first/capitalizeFirst.pipe'
import { SubscriptionStateEnum } from 'src/app/enums/subscription-state.enums'
import LogRocket from 'logrocket'

@Injectable()
export class BillingEffects {
  constructor(
    private actions$: Actions,
    private billingService: BillingService,
    private billingStore: Store<BillingState>,
    private accountStore: Store<AccountState>,
    private notify: NotificationService,
    private accountService: AccountService,
    private userStore: Store<UserState>
  ) {}

  initPaymentMethods$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitPaymentMethods>(BillingActionTypes.InitPaymentMethods),
      withLatestFrom(this.accountStore.select(accountSelector.selectMerchantId)),
      switchMap(([action, merchantId]) =>
        this.billingService.getCustomerPaymentMethods(merchantId).pipe(
          map(
            (paymentMethods: PaymentMethod[]) =>
              new actions.InitPaymentMethodsSuccess({ paymentMethods: paymentMethods || [] })
          ),
          catchError((error) => of(new actions.InitPaymentMethodsFailed()))
        )
      )
    )
  )

  initSubscriptions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitSubscription>(BillingActionTypes.InitSubscription),
      withLatestFrom(
        this.accountStore.select(accountSelector.selectMerchantId),
        this.userStore.select(fromUserSelectors.selectAuthorizeUser),
        this.accountStore.select(accountSelector.selectBusinessName),
        this.accountStore.select(accountSelector.selectStartDate),
        this.userStore.select(fromUserSelectors.selectUserIsMasquerading)
      ),
      switchMap(([action, merchantId, user, businessName, startDate, isMasquerading]) =>
        this.billingService
          .getCustomerSubscription(merchantId, action.payload.suppress404Error)
          .pipe(
            tap((subscription: PlanSubscription) => {
              if (subscription.hasScheduledChanges) {
                this.billingStore.dispatch(
                  new actions.InitPendingSubscription({ suppress404Error: true })
                )
              }
              // Identify user with billing
              const subscriptionItem =
                subscription.subscriptionItems?.length > 0
                  ? subscription.subscriptionItems[0]
                  : null
              if (user && subscriptionItem && !isMasquerading) {
                userflow.identify(user.id, {
                  name: `${user.firstName} ${user.lastName}`,
                  email: user.username,
                  merchantId: user.merchantId,
                  authorityLabel: AuthorityLabel.get(user.authority),
                  signed_up_at: user.confirmedEmailDate, // We don't save the actual created at date so this is the next best thing

                  // billing attributes
                  billingStatus: subscription.status,
                  billingPeriodUnit: subscription.billingPeriodUnit,
                  billingItemId: subscriptionItem.itemPriceId,
                  billingQuantity: subscriptionItem.quantity,
                })
                LogRocket.identify(user.id, {
                  name: `${user.firstName} ${user.lastName}`,
                  email: user.username,

                  // Custom variables
                  merchantId: user.merchantId,
                  merchantStartDate: moment(startDate).format(),
                  confirmedEmailDate: user.confirmedEmailDate,
                  phoneNumber: user.phone,
                  authorityLabel: AuthorityLabel.get(user.authority),

                  // billing attributes
                  billingStatus: subscription.status,
                  billingPeriodUnit: subscription.billingPeriodUnit,
                  billingItemId: subscriptionItem.itemPriceId,
                  billingQuantity: subscriptionItem.quantity,
                })
              }
              if (user && businessName && startDate && !isMasquerading) {
                userflow.group(user.merchantId.toString(), {
                  name: businessName,
                  createdDate: moment(startDate).format(),
                })
              }
            }),
            map(
              (subscription: PlanSubscription) =>
                new actions.InitSubscriptionSuccess({ subscription })
            ),
            catchError((error) => of(new actions.InitSubscriptionFailed()))
          )
      )
    )
  )

  initPendingSubscriptions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitPendingSubscription>(BillingActionTypes.InitPendingSubscription),
      withLatestFrom(this.accountStore.select(accountSelector.selectMerchantId)),
      switchMap(([action, merchantId]) =>
        this.billingService
          .getCustomerSubscription(merchantId, action.payload.suppress404Error, true)
          .pipe(
            map(
              (subscription: PlanSubscription) =>
                new actions.InitPendingSubscriptionSuccess({ subscription })
            ),
            catchError((error) => of(new actions.InitPendingSubscriptionFailed()))
          )
      )
    )
  )

  putSubscription$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.PutSubscription>(BillingActionTypes.PutSubscription),
      withLatestFrom(
        this.accountStore.select(accountSelector.selectMerchantId),
        this.billingStore.select(billingSelector.selectIsEditingPlan)
      ),
      switchMap(([action, merchantId, isEditingPlan]) =>
        this.billingService
          .putSubscription(
            merchantId,
            action.payload.priceId,
            action.payload.quantity,
            action.payload.isFreeTrial
          )
          .pipe(
            map((subscription: PlanSubscription) => {
              if (subscription.status !== SubscriptionStateEnum.InTrial) {
                this.notify.showSuccess({
                  title: `Subscription ${isEditingPlan ? 'updated' : 'created'}`,
                  duration: 3000,
                })
              }
              this.billingStore.dispatch(new actions.SetIsEditingPlan({ isEditingPlan: false }))
              this.billingStore.dispatch(new actions.InitInvoices())
              return new actions.PutSubscriptionSuccess({ subscription })
            }),
            catchError((error) => of(new actions.PutSubscriptionFailed()))
          )
      )
    )
  )

  cancelSubscription$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.CancelSubscription>(BillingActionTypes.CancelSubscription),
      withLatestFrom(this.accountStore.select(accountSelector.selectMerchantId)),
      switchMap(([action, merchantId]) =>
        this.billingService.cancelSubscription(merchantId, action.payload.priceId).pipe(
          map(
            (subscription: PlanSubscription) =>
              new actions.CancelSubscriptionSuccess({ subscription })
          ),
          catchError((error) => of(new actions.CancelSubscriptionFailed()))
        )
      )
    )
  )

  subscribeToTrial$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.SubscribeToTrial>(BillingActionTypes.SubscribeToTrial),
      map(
        () =>
          new actions.PutSubscription({
            priceId: 'Premium-Plan-USD-Monthly',
            quantity: 200,
            isFreeTrial: true,
          })
      )
    )
  )

  addPaymentMethod$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.AddPaymentMethod>(BillingActionTypes.AddPaymentMethod),
      switchMap((action) =>
        this.billingService
          .addPaymentMethod(
            action.payload.customerId,
            action.payload.tokenId,
            action.payload.paymentSourceType
          )
          .pipe(
            map(
              (paymentMethod: PaymentMethod) =>
                new actions.AddPaymentMethodSuccess({ paymentMethod })
            ),
            catchError((error) => of(new actions.AddPaymentMethodFailed()))
          )
      )
    )
  )

  deletePaymentMethod$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.DeletePaymentMethod>(BillingActionTypes.DeletePaymentMethod),
      withLatestFrom(this.accountStore.select(accountSelector.selectMerchantId)),
      switchMap(([action, merchantId]) =>
        this.billingService.deletePaymentMethod(merchantId, action.payload.id).pipe(
          map(() => new actions.DeletePaymentMethodSuccess({ id: action.payload.id })),
          catchError((error) => of(new actions.DeletePaymentMethodFailed()))
        )
      )
    )
  )

  initAchPaymentSession$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitAchUploadPaymentSession>(BillingActionTypes.InitAchUploadPaymentSession),
      withLatestFrom(this.accountStore.select(accountSelector.selectMerchantId)),
      switchMap(([action, merchantId]) =>
        this.billingService.getAchUploadSession(merchantId).pipe(
          map(
            (billingRequestFlowId: string) =>
              new actions.InitAchUploadPaymentSessionSuccess({ billingRequestFlowId })
          ),
          catchError((error) => {
            this.notify.showError({
              title: 'Something went wrong.',
              description: new CapitalizeFirstPipe().transform(
                error?.error?.details ??
                  'Please ensure you have a valid location, otherwise contact support if this issue persists.'
              ),
              duration: 15000,
            })
            return of(new actions.InitAchUploadPaymentSessionFailed())
          })
        )
      )
    )
  )

  initPlan$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitPlan>(BillingActionTypes.InitPlan),
      mergeMap((action) =>
        this.billingService.getPlan(action.payload.tierId).pipe(
          map((plan: PlanDetail) => new actions.InitPlanSuccess({ plan })),
          catchError((error) => of(new actions.InitPlanFailed()))
        )
      )
    )
  )

  initCustomerProfile$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitCustomerProfile>(BillingActionTypes.InitCustomerProfile),
      withLatestFrom(this.accountStore.select(accountSelector.selectMerchantId)),
      switchMap(([action, merchantId]) =>
        this.billingService.getCustomerProfile(merchantId).pipe(
          map(
            (customerProfile: Customer) =>
              new actions.InitCustomerProfileSuccess({ customer: customerProfile })
          ),
          catchError((error) => of(new actions.InitCustomerProfileFailed()))
        )
      )
    )
  )

  initInvoices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitInvoices>(BillingActionTypes.InitInvoices),
      withLatestFrom(this.accountStore.select(accountSelector.selectMerchantId)),
      switchMap(([action, merchantId]) =>
        this.billingService.getInvoices(merchantId).pipe(
          map((invoices: Invoice[]) => new actions.InitInvoicesSuccess({ invoices })),
          catchError((error) => of(new actions.InitInvoicesFailed()))
        )
      )
    )
  )

  assignPaymentRole$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.AssignPaymentRole>(BillingActionTypes.AssignPaymentRole),
      withLatestFrom(this.accountStore.select(accountSelector.selectMerchantId)),
      switchMap(([action, merchantId]) =>
        this.billingService
          .assignPaymentRole(merchantId, action.payload.paymentSourceId, action.payload.paymentRole)
          .pipe(
            map(() => {
              this.billingStore.dispatch(new actions.InitCustomerProfile())
              return new actions.AssignPaymentRoleSuccess()
            }),
            catchError((error) => of(new actions.InitInvoicesFailed()))
          )
      )
    )
  )

  InitInvoicePdfDownload$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitInvoicePdfDownload>(BillingActionTypes.InitInvoicePdfDownload),
      switchMap((action) =>
        this.billingService.getInvoicePdfUrl(action.payload.invoiceId).pipe(
          map((invoiceLink) => {
            window.open(invoiceLink, '_blank')
            return new actions.InitInvoicePdfDownloadSuccess({ invoiceLink })
          }),
          catchError((error) => of(new actions.InitInvoicePdfDownloadFailed()))
        )
      )
    )
  )

  initClearBilling$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ClearBillingState>(BillingActionTypes.ClearBillingState),
      filter((action) => action.payload.fetchNewData),
      tap(() =>
        this.billingStore.dispatch(new actions.InitSubscription({ suppress404Error: true }))
      ),
      map(() => new actions.InitPaymentMethods())
    )
  )

  initCustomerUsage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitGetCustomerUsage>(BillingActionTypes.InitCustomerUsage),
      withLatestFrom(
        this.accountStore.select(accountSelector.selectMerchantId),
        this.accountStore.select(billingSelector.selectSubscription)
      ),
      switchMap(([action, merchantId, subscription]) => {
        const { startDate, endDate } = this.getUsageStartAndEndDates(subscription)
        return this.billingService.getCustomerUsage(merchantId, startDate, endDate).pipe(
          map(
            (customerUsage: CustomerUsage) =>
              new actions.InitGetCustomerUsageSuccess({ customerUsage })
          ),
          catchError((error) => of(new actions.InitGetCustomerUsageFailed()))
        )
      })
    )
  )

  initUsageReports$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitGetUsageReports>(BillingActionTypes.InitUsageReports),
      withLatestFrom(this.accountStore.select(accountSelector.selectMerchantId)),
      switchMap(([action, merchantId]) =>
        this.billingService.getCustomerUsageReports(merchantId).pipe(
          map(
            (usageReports: CustomerUsageReport[]) =>
              new actions.InitGetUsageReportsSuccess({ usageReports })
          ),
          catchError((error) => of(new actions.InitGetUsageReportsFailed()))
        )
      )
    )
  )

  initUsageReportDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitGetUsageReportDetails>(BillingActionTypes.InitUsageReportDetails),
      withLatestFrom(this.accountStore.select(accountSelector.selectMerchantId)),
      switchMap(([action, merchantId]) =>
        this.billingService.getCustomerUsageReport(merchantId, action.payload.usageReportId).pipe(
          map(
            (usageReportDetails: CustomerUsageReport) =>
              new actions.InitGetUsageReportDetailsSuccess({ usageReportDetails })
          ),
          catchError((error) => of(new actions.InitGetUsageReportDetailsFailed()))
        )
      )
    )
  )

  initSubscriptionSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitSubscriptionSuccess>(BillingActionTypes.InitSubscriptionSuccess),
      map(
        (action) =>
          new actions.InitSubscriptionEntitlements({
            subscriptionId: action.payload.subscription.id,
          })
      )
    )
  )

  initSubscriptionEntitlements$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitSubscriptionEntitlements>(BillingActionTypes.InitSubscriptionEntitlements),
      switchMap((action) =>
        this.billingService.getSubscriptionEntitlements(action.payload.subscriptionId).pipe(
          map(
            (entitlements: SubscriptionEntitlements[]) =>
              new actions.InitSubscriptionEntitlementsSuccess({
                entitlements,
              })
          ),
          catchError((error) => of(new actions.InitSubscriptionEntitlementsFailed()))
        )
      )
    )
  )

  initEntitlements$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.InitEntitlements>(BillingActionTypes.InitEntitlements),
      switchMap((action) =>
        this.billingService.getEntitlements(action.payload.subscriptionPlanTier).pipe(
          map(
            (entitlements: Entitlement[]) =>
              new actions.InitEntitlementsSuccess({
                entitlements,
              })
          ),
          catchError((error) => of(new actions.InitEntitlementsFailed()))
        )
      )
    )
  )

  updateBillingInfo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.UpdateBillingInfo>(BillingActionTypes.UpdateBillingInfo),
      withLatestFrom(this.accountStore.select(accountSelector.selectMerchantId)),
      switchMap(([action, merchantId]) =>
        this.billingService.updateCustomerProfile(merchantId, action.payload.customer).pipe(
          map((customer) => {
            this.notify.showSuccess({ title: 'Your billing info has been updated.' })
            return new actions.UpdateBillingInfoSuccess({ customer })
          }),
          catchError((error) => {
            this.notify.showError({ title: 'Something went wrong updating billing info.' })
            return of(new actions.UpdateBillingInfoFailed())
          })
        )
      )
    )
  )

  private getUsageStartAndEndDates(subscription: PlanSubscription) {
    const isYearlySubscription = subscription.billingPeriodUnit === 'year'
    if (isYearlySubscription) {
      return {
        startDate: moment.unix(subscription.currentTermStart),
        endDate: this.getMostRecentSundayMidnightDate(subscription.currentTermEnd),
      }
    }

    return {
      startDate: moment.unix(subscription.currentTermStart),
      endDate: this.getMostRecentMidnightDate(subscription.currentTermEnd),
    }
  }

  private getMostRecentSundayMidnightDate(dateInEpoch: number) {
    const date = this.getMostRecentMidnightDate(dateInEpoch)
    const dayOfWeek = date.day()
    const daysToSubtract = dayOfWeek === 0 ? 7 : dayOfWeek
    const mostRecentSundayMidnight = date.subtract(daysToSubtract, 'days')

    return mostRecentSundayMidnight
  }

  private getMostRecentMidnightDate(dateInEpoch: number) {
    return moment.unix(dateInEpoch).startOf('day')
  }
}
