import { Injectable, ErrorHandler, Injector } from '@angular/core'
import { LoggingService } from '../services/logging.service'

@Injectable({
  providedIn: 'root',
})
export class RootErrorHandler implements ErrorHandler {
  // The ErrorHandler is created before the providers. So we need Injector for dependency injection in our custom error handler class.
  constructor(private injector: Injector) {}

  handleError(error: Error) {
    const logService = this.injector.get(LoggingService)
    logService.logError(error)
  }
}
