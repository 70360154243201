import { BillingActions, BillingActionTypes } from '../actions/billing.action'
import { initialState, State } from '../manage-account.state'

export function reducer(state = initialState, action: BillingActions): State {
  switch (action.type) {
    case BillingActionTypes.InitPaymentMethods:
      return {
        ...state,
        loadingPaymentMethods: true,
      }
    case BillingActionTypes.InitPaymentMethodsSuccess:
      return {
        ...state,
        paymentMethods: action.payload.paymentMethods,
        loadingPaymentMethods: false,
      }
    case BillingActionTypes.InitPaymentMethodsFailed:
      return {
        ...state,
        loadingPaymentMethods: false,
      }
    case BillingActionTypes.AddPaymentMethod:
      return {
        ...state,
        loadingPaymentMethods: true,
      }
    case BillingActionTypes.AddPaymentMethodSuccess:
      return {
        ...state,
        paymentMethods: [...state.paymentMethods, action.payload.paymentMethod],
        loadingPaymentMethods: false,
      }
    case BillingActionTypes.AddPaymentMethodFailed:
      return {
        ...state,
        loadingPaymentMethods: false,
      }
    case BillingActionTypes.DeletePaymentMethod:
      return {
        ...state,
        loadingPaymentMethods: true,
      }
    case BillingActionTypes.DeletePaymentMethodSuccess:
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter((pm) => pm.id !== action.payload.id),
        loadingPaymentMethods: false,
      }
    case BillingActionTypes.DeletePaymentMethodFailed:
      return {
        ...state,
        loadingPaymentMethods: false,
      }
    case BillingActionTypes.InitSubscription:
      return {
        ...state,
        loadingSubscription: true,
      }
    case BillingActionTypes.InitSubscriptionSuccess:
      return {
        ...state,
        subscription: action.payload.subscription,
        loadingSubscription: false,
        isSubscriptionsLoaded: true,
      }
    case BillingActionTypes.InitSubscriptionFailed:
      return {
        ...state,
        loadingSubscription: false,
        isSubscriptionsLoaded: true,
      }
    case BillingActionTypes.InitPendingSubscription:
      return {
        ...state,
      }
    case BillingActionTypes.InitPendingSubscriptionSuccess:
      return {
        ...state,
        pendingSubscription: action.payload.subscription,
      }
    case BillingActionTypes.InitPendingSubscriptionFailed:
      return {
        ...state,
      }
    case BillingActionTypes.PutSubscription:
      return {
        ...state,
        loadingPutSubscription: true,
      }
    case BillingActionTypes.PutSubscriptionSuccess:
      return {
        ...state,
        subscription: action.payload.subscription,
        loadingPutSubscription: false,
        isEditingPlan: false,
      }
    case BillingActionTypes.PutSubscriptionFailed:
      return {
        ...state,
        loadingPutSubscription: false,
      }
    case BillingActionTypes.CancelSubscription:
      return {
        ...state,
        loadingSubscription: true,
      }
    case BillingActionTypes.CancelSubscriptionSuccess:
      return {
        ...state,
        subscription: action.payload.subscription,
        loadingSubscription: false,
      }
    case BillingActionTypes.CancelSubscriptionFailed:
      return {
        ...state,
        loadingSubscription: false,
      }
    case BillingActionTypes.SubscribeToTrial:
      return {
        ...state,
        showWelcomeToTrial: true,
      }
    case BillingActionTypes.CloseWelcomeToTrial:
      return {
        ...state,
        showWelcomeToTrial: false,
      }
    case BillingActionTypes.InitAchUploadPaymentSession:
      return {
        ...state,
      }
    case BillingActionTypes.InitAchUploadPaymentSessionSuccess:
      return {
        ...state,
        billingRequestFlowId: action.payload.billingRequestFlowId,
      }
    case BillingActionTypes.InitAchUploadPaymentSessionFailed:
      return {
        ...state,
      }
    case BillingActionTypes.InitPlan:
      return {
        ...state,
      }
    case BillingActionTypes.InitPlanSuccess:
      return {
        ...state,
        plans: state.plans ? [...state.plans, action.payload.plan] : [action.payload.plan],
      }
    case BillingActionTypes.InitPlanFailed:
      return {
        ...state,
      }
    case BillingActionTypes.setIsEditingPlan:
      return {
        ...state,
        isEditingPlan: action.payload.isEditingPlan,
      }
    case BillingActionTypes.InitCustomerProfile:
      return {
        ...state,
        isLoadingCustomerProfile: true,
      }
    case BillingActionTypes.InitCustomerProfileSuccess:
      return {
        ...state,
        customer: action.payload.customer,
        isLoadingCustomerProfile: false,
      }
    case BillingActionTypes.InitCustomerProfileFailed:
      return {
        ...state,
        isLoadingCustomerProfile: false,
      }
    case BillingActionTypes.InitInvoices:
      return {
        ...state,
        isLoadingInvoices: true,
      }
    case BillingActionTypes.InitInvoicesSuccess:
      return {
        ...state,
        invoices: action.payload.invoices,
        isLoadingInvoices: false,
      }
    case BillingActionTypes.InitInvoicesFailed:
      return {
        ...state,
        isLoadingInvoices: false,
      }
    case BillingActionTypes.AssignPaymentRole:
      return {
        ...state,
        isLoadingPaymentRole: true,
      }
    case BillingActionTypes.AssignPaymentRoleSuccess: {
      return {
        ...state,
        isLoadingPaymentRole: false,
      }
    }
    case BillingActionTypes.AssignPaymentRoleFailed: {
      return {
        ...state,
        isLoadingPaymentRole: false,
      }
    }
    case BillingActionTypes.ClearBillingState: {
      return initialState
    }
    case BillingActionTypes.InitCustomerUsage:
      return {
        ...state,
      }
    case BillingActionTypes.InitCustomerUsageSuccess:
      return {
        ...state,
        customerUsage: action.payload.customerUsage,
      }
    case BillingActionTypes.InitCustomerUsageFailed:
      return {
        ...state,
      }
    case BillingActionTypes.InitUsageReports:
      return {
        ...state,
      }
    case BillingActionTypes.InitUsageReportsSuccess:
      return {
        ...state,
        usageReports: action.payload.usageReports,
      }
    case BillingActionTypes.InitUsageReportsFailed:
      return {
        ...state,
      }
    case BillingActionTypes.InitUsageReportDetails:
      return {
        ...state,
      }
    case BillingActionTypes.InitUsageReportDetailsSuccess:
      return {
        ...state,
        usageReportDetails: action.payload.usageReportDetails,
      }
    case BillingActionTypes.InitUsageReportDetailsFailed:
      return {
        ...state,
      }
    case BillingActionTypes.UpdateBillingInfo:
      return {
        ...state,
        isUpdatingBillingInfo: true,
        updatingBillingInfoSuccess: false,
      }
    case BillingActionTypes.UpdateBillingInfoSuccess:
      return {
        ...state,
        isUpdatingBillingInfo: false,
        customer: action.payload.customer,
        updatingBillingInfoSuccess: true,
      }
    case BillingActionTypes.UpdateBillingInfoFailed:
      return {
        ...state,
        isUpdatingBillingInfo: false,
        updatingBillingInfoSuccess: false,
      }
    case BillingActionTypes.InitSubscriptionEntitlementsSuccess:
      return {
        ...state,
        subscriptionEntitlements: action.payload.entitlements,
      }
    default: {
      return state
    }
  }
}
