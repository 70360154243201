import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'
import { LoginEnum } from 'src/app/enums/login.enum'
import * as userActions from 'src/app/user/actions/login.actions'
import * as userSelector from 'src/app/user/selectors/user.selectors'
import * as appStatusSelector from 'src/app/app-status/selectors/app-status.selectors'
import { State as UserState } from 'src/app/user/user.state'
import { State as AppStatusState } from 'src/app/app-status/app-status.state'
import { map } from 'rxjs/operators'

@Component({
  selector: 'tc-sign-out-button',
  templateUrl: './sign-out-button.component.html',
  styleUrls: ['./sign-out-button.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignOutButtonComponent {
  public loggedOut = LoginEnum.LoggedOut
  public locked = LoginEnum.Locked
  public loggedIn = LoginEnum.LoggedIn

  constructor(
    private userStore: Store<UserState>,
    private appStatusStore: Store<AppStatusState>,
    private router: Router
  ) {}

  @Output() logoutChange = new EventEmitter<boolean>()

  public status$: Observable<LoginEnum> = this.userStore.select(userSelector.selectLoginStatus)

  private isMasquerading$: Observable<boolean> = this.userStore.select(
    userSelector.selectUserIsMasquerading
  )

  private isEmbeddedApp$: Observable<boolean> = this.appStatusStore.select(
    appStatusSelector.selectIsEmbedded
  )

  public showLock$: Observable<boolean> = combineLatest([
    this.isEmbeddedApp$,
    this.isMasquerading$,
  ]).pipe(
    map(([masquerading, embedded]) => {
      if (masquerading) {
        return false
      }
      if (embedded) {
        return false
      }
      return true
    })
  )
  public lock(): void {
    this.userStore.dispatch(new userActions.LockApplication())
    this.router.navigate(['/sign-in'])
  }

  public logout(): void {
    this.userStore.dispatch(new userActions.Logout())
    this.logoutChange.emit(true)
    // this.router.navigate(['/home']);
  }
}
