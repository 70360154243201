import { CommonModule } from '@angular/common'
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'

import { FeatureKeys } from '../enums/store.enums'
import { InitMaterialModule } from '../material/init-material.module'
import { SupportModule } from '../support/support.module'
import { TermsOfServiceModule } from '../terms-of-service/terms-of-service.module'
import { InitUiModule } from '../ui/init-ui/init-ui.module'
import { UiModule } from '../ui/ui/ui.module'
import { DirectFilingFormComponent } from './components/direct-filing-form/direct-filing-form.component'
import { DisplayUsStateComponent } from './components/display-us-state/display-us-state.component'
import { EditUsStatesComponent } from './components/edit-us-states/edit-us-states.component'
import { FilingUsStateFormComponent } from './components/filing-us-state-form/filing-us-state-form.component'
import { RefreshUsStatesButtonComponent } from './components/refresh-us-states-button/refresh-us-states-button.component'
import { StateSelectComponent } from './components/state-select/state-select.component'
import { UsMapAutomatedComplianceComponent } from './components/us-map-automated-compliance/us-map-automated-compliance.component'
import { UsMapCollectingComponent } from './components/us-map-collecting/us-map-collecting.component'
import { UsMapComponent } from './components/us-map/us-map.component'
import { UsStatesEffects } from './effects/us-states.effects'
import { ListCollectingUsStatesPage } from './pages/list-collecting-us-states/list-collecting-us-states.page'
import { UsStateDetailPage } from './pages/us-state-detail/us-state-detail.page'
import { reducer } from './reducers/us-states.reducer'
import { UsStatesRoutesModule } from './routes/us-states.routes'
import { MapStates } from './utilities/map-us-states'
import { SsutaStateSelectComponent } from './components/ssuta-state-select/ssuta-state-select.component'
import { DirectFileManagerPage } from './pages/direct-file-manager/direct-file-manager.page'
import { MultiStateSelectComponent } from './components/multi-state-select/multi-state-select.component'

@NgModule({
  declarations: [
    StateSelectComponent,
    UsMapComponent,
    FilingUsStateFormComponent,
    EditUsStatesComponent,
    UsMapCollectingComponent,
    DisplayUsStateComponent,
    ListCollectingUsStatesPage,
    UsMapAutomatedComplianceComponent,
    RefreshUsStatesButtonComponent,
    DirectFilingFormComponent,
    UsStateDetailPage,
    SsutaStateSelectComponent,
    DirectFileManagerPage,
    MultiStateSelectComponent,
  ],
  imports: [
    CommonModule,
    InitMaterialModule,
    UiModule,
    ReactiveFormsModule,
    FormsModule,
    UsStatesRoutesModule,
    StoreModule.forFeature(FeatureKeys.UsStates, reducer),
    EffectsModule.forFeature([UsStatesEffects]),
    SupportModule,
    TermsOfServiceModule,
  ],
  exports: [
    StateSelectComponent,
    SsutaStateSelectComponent,
    MultiStateSelectComponent,
    UsMapComponent,
    EditUsStatesComponent,
    UsMapCollectingComponent,
    DisplayUsStateComponent,
    UsMapAutomatedComplianceComponent,
    RefreshUsStatesButtonComponent,
  ],
  providers: [MapStates],
  schemas: [NO_ERRORS_SCHEMA],
})
export class UsStatesModule {}
