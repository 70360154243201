import Dexie from 'dexie'
import { Observable, from } from 'rxjs'
import { ILocation } from './../models/ilocation'

export class LoctionDatabase extends Dexie {
  private locations: Dexie.Table<ILocation, number>

  constructor() {
    super('LocationDB')
    this.version(1).stores({
      locations: 'locationId,createdOn, address1,city,discoveryDate,stateFIPSCode',
    })
    this.locations = this.table('locations')
  }

  public saveLocations(locations: ILocation[]): Observable<number> {
    return from(this.locations.clear().then((_) => this.locations.bulkPut(locations)))
  }

  public updateLocation(location: ILocation) {
    return from(this.locations.put(location))
  }

  public getLocations(): Observable<ILocation[]> {
    return from(this.locations.orderBy('createdOn').toArray())
  }

  public getLocation(locationId: string): Observable<ILocation | undefined> {
    return from(this.locations.get({ locationId }))
  }

  public clearLocations(): Observable<void> {
    return from(this.locations.clear())
  }
}
