import { HttpClient, HttpContext } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, map } from 'rxjs'
import {
  OnboardingCompletedResponse,
  OnboardingDetails,
  OnboardingDetailsResponse,
  PutOnboardingDetailsRequest,
} from '../models/onboarding-details.model'
import { SUPPRESS_ON_404_ERROR_NOTIFY } from 'src/app/root/api-interceptor'

@Injectable({
  providedIn: 'root',
})
export class SetupService {
  private submittedFormIndex: BehaviorSubject<number> = new BehaviorSubject(null)
  public getSubmittedFormIndex: Observable<number> = this.submittedFormIndex.asObservable()

  constructor(private http: HttpClient) {}

  setSubmittedFormIndex(formIndex: number) {
    const STEPS = 3
    if (formIndex < 0) {
      throw new Error('Expected positive formIndex value')
    }
    if (formIndex > STEPS - 1) {
      throw new Error(`Expected maximum value of ${STEPS - 1} for formIndex value`)
    }
    this.submittedFormIndex.next(formIndex)
  }

  getSetupDetails(suppress404Error: boolean = false): Observable<OnboardingDetailsResponse> {
    const context = new HttpContext()
    context.set(SUPPRESS_ON_404_ERROR_NOTIFY, suppress404Error)
    return this.http
      .get('api/setup', { context })
      .pipe(map((onboardingDetails: OnboardingDetailsResponse) => onboardingDetails))
  }

  getSetupCompleted(): Observable<OnboardingCompletedResponse> {
    return this.http
      .get('api/setup/is-completed')
      .pipe(map((onboardingDetails: OnboardingCompletedResponse) => onboardingDetails))
  }

  putSetupDetails(request: PutOnboardingDetailsRequest): Observable<OnboardingDetailsResponse> {
    return this.http
      .put('api/setup', request)
      .pipe(map((onboardingDetails: OnboardingDetailsResponse) => onboardingDetails))
  }
}
