import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms'
import { Router } from '@angular/router'
import * as fromRouter from '@ngrx/router-store'
import { select, Store } from '@ngrx/store'
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs'
import { delay, filter, first, map, pluck, tap } from 'rxjs/operators'
import * as fromAppStatusActions from 'src/app/app-status/actions/app-status.actions'
import { State as AppStatusState } from 'src/app/app-status/app-status.state'
import { AppTypeEnum } from 'src/app/enums/app.enums'
import { LoginEnum } from 'src/app/enums/login.enum'
import * as fromRouterSelectors from 'src/app/root/selectors/root.selectors'
import { environment } from 'src/environments/environment'
import { SubSink } from 'subsink'

import * as actions from '../../actions/login.actions'
import { IUser } from '../../models/iuser'
import * as authorizeStoreSelectors from '../../selectors/user.selectors'
import * as fromLogin from '../../user.state'
import { SocialAuthService } from '@abacritt/angularx-social-login'
import { Meta, Title } from '@angular/platform-browser'
import { LoginPageMeta } from '../../constants/user.constants'
import { CanonicalUrlService } from 'src/app/root/services/canonical-url.service'

declare global {
  interface Window {
    google: any
  }
}

@Component({
  selector: 'tc-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.sass'],
})
export class LoginPage implements OnInit, OnDestroy {
  @Output() loginWithGoogle: EventEmitter<any> = new EventEmitter<any>()
  authSubscription!: Subscription
  form: UntypedFormGroup
  unlockForm: UntypedFormGroup
  username = new UntypedFormControl('', [Validators.required, Validators.email])
  password = new UntypedFormControl('', Validators.required)
  lockPassword = new UntypedFormControl('', Validators.required)

  // auto fill does not trigger form validation, so start true
  validForm$: BehaviorSubject<boolean> = new BehaviorSubject(true)

  storeInfoGuid$ = new BehaviorSubject<string>('')
  public publicSiteUrl = environment.publicSiteUrl

  private subs = new SubSink()

  constructor(
    private store: Store<fromLogin.State>,
    private appStatusStore: Store<AppStatusState>,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: Store<fromRouter.RouterReducerState<any>>,
    private authService: SocialAuthService,
    private meta: Meta,
    private titleService: Title,
    private canonicalUrlService: CanonicalUrlService
  ) {
    this.titleService.setTitle('Log in to TaxCloud')
    this.meta.addTags(LoginPageMeta)
    this.canonicalUrlService.setCanonicalURL('https://app.taxcloud.com/sign-in')
    this.form = fb.group({
      username: this.username,
      password: this.password,
    })
    this.unlockForm = fb.group({
      lockPassword: this.lockPassword,
    })
  }

  token$: Observable<string> = this.route.pipe(
    select(fromRouterSelectors.selectRouteState),
    pluck('state', 'queryParams', 'token'),
    filter((token: string) => !!token)
  )

  appType$: Observable<string> = this.route.pipe(
    select(fromRouterSelectors.selectRouteState),
    pluck('state', 'queryParams', 'AppType'),
    filter((type: string) => !!type)
  )

  storeGuid$: Observable<string> = this.route.pipe(
    select(fromRouterSelectors.selectRouteState),
    pluck('state', 'queryParams', 'storeinfoguid'),
    filter((storeInfoGuid: string) => !!storeInfoGuid)
  )

  isLoading: Observable<boolean> = this.store.select(
    authorizeStoreSelectors.selectAuthorizeIsLoading
  )
  fullName: Observable<string> = this.store.select(authorizeStoreSelectors.selectFullName)
  user: Observable<IUser> = this.store.select(authorizeStoreSelectors.selectAuthorizeUser)
  loginStatus: Observable<LoginEnum> = this.store
    .select(authorizeStoreSelectors.selectLoginStatus)
    .pipe(
      tap((status) => {
        if (status === LoginEnum.LoggedIn) {
          this.goDashboard()
        }
      })
    )
  errorMessage$: Observable<string> = this.store.select(
    authorizeStoreSelectors.selectAuthorizeError
  )

  ngOnInit(): void {
    document.body.style.backgroundImage = null
    document.body.style.backgroundSize = null

    this.subs.add(
      this.form.statusChanges
        .pipe(
          map((result) => result === 'VALID'),
          tap((result) => this.validForm$.next(result))
        )
        .subscribe()
    )

    this.token$
      .pipe(
        first(),
        delay(100),
        tap((token: string) => {
          this.store.dispatch(new actions.AutoLoginRequest({ token }))
        })
      )
      .subscribe()

    this.appType$
      .pipe(
        first(),
        delay(100),
        tap((appType: AppTypeEnum) => {
          this.appStatusStore.dispatch(
            new fromAppStatusActions.UpdateAppType({ appType: AppTypeEnum.Embedded })
          )
        })
      )
      .subscribe()

    this.storeGuid$
      .pipe(
        first(),
        delay(100),
        tap((storeInfoGuid: string) => {
          this.storeInfoGuid$.next(storeInfoGuid)
        })
      )
      .subscribe()

    combineLatest([
      this.loginStatus,
      this.route.pipe(
        select(fromRouterSelectors.selectRouteState),
        pluck('state', 'queryParams', 'token')
      ),
    ])
      .pipe(
        first(),
        tap(([signed, token]) => {
          if (token) {
            return
          }

          if (signed === LoginEnum.LoggedIn) {
            this.router.navigate(['/go'])
          }
        })
      )
      .subscribe()

    this.authSubscription = this.authService.authState.subscribe((socialUser) => {
      this.store.dispatch(new actions.FederatedLoginSuccess({ socialUser }))
    })
  }

  ngOnDestroy(): void {
    this.store.dispatch(new actions.CleanUpIsLoading())
    this.subs.unsubscribe()
    this.authSubscription.unsubscribe()
  }

  public submitLogin(): void {
    const loginForm = this.form

    if (!loginForm.valid) {
      return
    }

    const payload: { userName: string; password: string; storeInfoGuid?: string } = {
      userName: loginForm.value.username,
      password: loginForm.value.password,
    }

    if (this.storeInfoGuid$.value) {
      payload.storeInfoGuid = this.storeInfoGuid$.value
    }
    if (payload.storeInfoGuid) {
      this.store.dispatch(new actions.Login2RequestAction(payload))
    } else {
      this.store.dispatch(new actions.LoginRequestAction(payload))
    }
  }

  public logout(): void {
    this.store.dispatch(new actions.Logout())
    this.router.navigate(['/'])
  }

  public goHome(): void {
    const url = new URL(`${this.publicSiteUrl}`)
    window.open(url.toString(), '_self')
  }

  public goDashboard(): void {
    this.router.navigate(['/go/dashboard'])
  }

  public forgotPassword(): void {
    this.router.navigate(['/password'])
  }

  public registerNewAccount(): void {
    if (this.storeInfoGuid$.value) {
      this.router.navigate(['/register'], {
        queryParams: { storeinfoguid: this.storeInfoGuid$.value },
      })
    } else {
      this.router.navigate(['/register'])
    }
  }

  public submitUnlock(): void {
    const unlock = this.unlockForm
    if (!unlock.valid) {
      return
    }
    this.store.dispatch(new actions.UnLockApplication({ password: unlock.value.lockPassword }))
  }

  public createHiddenGoogleWrapper = () => {
    const googleLoginWrapper = document.createElement('div')
    googleLoginWrapper.style.display = 'none'
    googleLoginWrapper.classList.add('custom-google-button')
    document.body.appendChild(googleLoginWrapper)
    window.google.accounts.id.renderButton(googleLoginWrapper, {
      type: 'icon',
      width: 200,
    })

    const googleLoginWrapperButton = googleLoginWrapper.querySelector(
      'div[role=button]'
    ) as HTMLElement

    return {
      click: () => {
        googleLoginWrapperButton?.click()
      },
    }
  }

  public handleGoogleLogin() {
    this.loginWithGoogle.emit(this.createHiddenGoogleWrapper().click())
  }
}
