import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { EffectsModule } from '@ngrx/effects'
import {
  NavigationActionTiming,
  routerReducer,
  RouterState,
  StoreRouterConnectingModule,
} from '@ngrx/router-store'
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store'
import * as fromAccountReducer from 'src/app/account/reducers/account.reducer'
import * as fromAppStateReducer from 'src/app/app-status/reducers/app-status.reducer'
import * as fromNotificationReducer from 'src/app/notification/reducers/notification.reducer'
import * as fromTransactionReducer from 'src/app/transaction/reducers/transaction.reducer'
import * as billingReducer from 'src/app/manage-account/reducers/billing.reducer'
import * as setupReducer from 'src/app/setup/reducers/setup.reducer'
import { environment } from 'src/environments/environment'

import { CustomSerializer } from '../router/root-serializer'
import { UserModule } from './../../user/user.module'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import * as LogRocket from 'logrocket'
import createNgrxMiddleware from 'logrocket-ngrx'

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (
      (environment.name === 'dev' || environment.name === 'staging') &&
      !environment.logging.disableReduxConsole
    ) {
      console.log('state', state)
      console.log('action', action)
    }

    return reducer(state, action)
  }
}
const logrocketMiddleware = createNgrxMiddleware(LogRocket)

export const metaReducers: MetaReducer<any>[] = [debug, logrocketMiddleware]

@NgModule({
  imports: [
    CommonModule,
    UserModule,
    StoreModule.forRoot(
      {
        account: fromAccountReducer.reducer,
        route: routerReducer,
        appStatus: fromAppStateReducer.reducer,
        notifications: fromNotificationReducer.reducer,
        transactions: fromTransactionReducer.reducer,
        billing: billingReducer.reducer,
        setup: setupReducer.reducer,
      },
      { metaReducers }
    ),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      navigationActionTiming: NavigationActionTiming.PostActivation,
      serializer: CustomSerializer,
      routerState: RouterState.Minimal,
    }),
    StoreDevtoolsModule.instrument({ maxAge: 75, logOnly: environment.production }),
  ],
  declarations: [],
})
export class RootStoreModule {}
