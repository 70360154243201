import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { TaxCloudColorEnum } from 'src/app/enums/color.enums'

@Component({
  selector: 'tc-display-us-state',
  templateUrl: './display-us-state.component.html',
  styleUrls: ['./display-us-state.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayUsStateComponent {
  public fillColor$ = new BehaviorSubject<TaxCloudColorEnum>(TaxCloudColorEnum.Primary)
  public abbreviation: string
  public name: string

  constructor() {}
  @Input('stateAbbreviation')
  set setAbbreviation(abbreviation: string) {
    this.abbreviation = abbreviation
  }
  @Input('stateName')
  set setName(name: string) {
    this.name = name
  }

  @Input('fillColor')
  set fillColor(fillColor: TaxCloudColorEnum) {
    if (fillColor) {
      this.fillColor$.next(fillColor)
    }
  }
}
