<div class="mat_v_sm">
  <mat-accordion>
    <mat-expansion-panel
      class="mat-elevation-z0"
      (opened)="open$.next(true)"
      (closed)="open$.next(false)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Frequently Asked Questions </mat-panel-title>
        <mat-panel-description>
          {{ categoryLabel$ | async }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
        <tc-dev-docs-button></tc-dev-docs-button>
      </div>

      <ng-container *ngIf="open$ | async">
        <ul *ngIf="faqs$ | async as faqs" [@fadeIn] tcList>
          <li *ngFor="let faq of faqs$ | async; last as isLast" tcListItem lastItem="{{ isLast }}">
            <div>
              <div *ngIf="faq.fields.is_new" class="new-tag">NEW</div>
              <div
                fxLayout="row"
                fxLayout.lt-md="column"
                fxLayoutAlign="space-between"
                fxLayoutAlign.lt-md="start"
              >
                <div fxFlex.gt-sm="300px" class="mat-display-1">{{ faq.name }}</div>
                <div fxFlex style="padding: 1rem" [innerHtml]="faq.fields.body"></div>
              </div>
            </div>
          </li>
        </ul>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>
