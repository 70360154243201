export enum AuthorityEnum {
  Administrator = 1,
  Financial = 2,
  Technical = 3,
  Auditor = 4,
  User = 5,
}

export const AuthorityLabel = new Map<AuthorityEnum, string>([
  [AuthorityEnum.Administrator, 'Administrator'],
  [AuthorityEnum.Financial, 'Financial'],
  [AuthorityEnum.Technical, 'Technical'],
  [AuthorityEnum.Auditor, 'Auditor'],
  [AuthorityEnum.User, 'User'],
])
