import { Update } from '@ngrx/entity'
import { Action } from '@ngrx/store'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'

import { ILocationUpdate } from '../models/ilocationupdate'
import { ILocation } from './../models/ilocation'

export enum LocationActionTypes {
  InitLocations = '[Location List Page] Init loading locations',
  InitLocationsProspective = '[Get Started Start Testing Page] Init loading locations',
  InitLocationsPaAttestation = '[PA Attestation Page] Init loading locations',
  InitLocationsGetStartedtGoLive = '[Get Started Go Live Page] Init loading locations',

  InitLocationNotNeeded = '[Location Effects] Do not need to init locations',
  InitLocationsSuccess = '[Location Effects] Upsert Locations',

  RefreshLocations = '[Location List Page] Refresh locations',
  RefreshLocationsSuccess = '[Location Effects] Refresh location success',
  RefreshLocationFailure = '[Location Effects] Refresh location failure',

  UpdateLocation = '[Location Edit Page] Update location',
  UpdateLocationProspective = '[Get Started Location Edit Component] Update location',

  DeleteLocation = '[Location Edit Page] Delete location',
  ClearLocations = '[Location] Clear locations',
  AddLocation = '[Location Add Page] Add location',
  AddLocationProspective = '[Get Started Location Edit Component]  Add Location',

  AddLocationProspectiveSuccess = 'Add Location Prospective Success',
  AddLocationProspectiveFailed = 'Add Location Prospective Failed',
  UpdateLocationProspectiveSuccess = 'Update Location Prospective Success',
  UpdateLocationProspectiveFailed = 'Update Location Prospective Failed',
}

export class InitLocations implements Action {
  readonly type = LocationActionTypes.InitLocations
}

export class InitLocationsProspective implements Action {
  readonly type = LocationActionTypes.InitLocationsProspective
}

export class InitLocationsPaAttestation implements Action {
  readonly type = LocationActionTypes.InitLocationsPaAttestation
}

export class InitLocationsGetStartedtGoLive implements Action {
  readonly type = LocationActionTypes.InitLocationsGetStartedtGoLive
}

export class InitLocationNotNeeded implements Action {
  readonly type = LocationActionTypes.InitLocationNotNeeded
}

export class InitLocationsSuccess implements Action {
  readonly type = LocationActionTypes.InitLocationsSuccess

  constructor(public payload: { locations: ILocation[]; source: DataSourceEnum }) {}
}

export class RefreshLocations implements Action {
  readonly type = LocationActionTypes.RefreshLocations
}

export class RefreshLocationsSuccess implements Action {
  readonly type = LocationActionTypes.RefreshLocationsSuccess
  constructor(public payload: { locations: ILocation[] }) {}
}

export class AddLocation implements Action {
  readonly type = LocationActionTypes.AddLocation
  constructor(public payload: { location: ILocationUpdate }) {}
}

export class AddLocationProspective implements Action {
  readonly type = LocationActionTypes.AddLocationProspective
  constructor(public payload: { location: ILocationUpdate }) {}
}

export class UpdateLocation implements Action {
  readonly type = LocationActionTypes.UpdateLocation
  constructor(public payload: { update: Update<ILocation> }) {}
}

export class AddLocationProspectiveSuccess implements Action {
  readonly type = LocationActionTypes.AddLocationProspectiveSuccess
}

export class AddLocationProspectiveFailed implements Action {
  readonly type = LocationActionTypes.AddLocationProspectiveFailed
}

export class UpdateLocationProspective implements Action {
  readonly type = LocationActionTypes.UpdateLocationProspective
  constructor(public payload: { location: Update<ILocation> }) {}
}

export class UpdateLocationProspectiveSuccess implements Action {
  readonly type = LocationActionTypes.UpdateLocationProspectiveSuccess
}

export class UpdateLocationProspectiveFailed implements Action {
  readonly type = LocationActionTypes.UpdateLocationProspectiveFailed
}

export class DeleteLocation implements Action {
  readonly type = LocationActionTypes.DeleteLocation
  constructor(public payload: { id: string }) {}
}

export class ClearLocations implements Action {
  readonly type = LocationActionTypes.ClearLocations
}

export type LocationActions =
  | InitLocations
  | InitLocationsProspective
  | InitLocationsPaAttestation
  | InitLocationsGetStartedtGoLive
  | InitLocationNotNeeded
  | InitLocationsSuccess
  | RefreshLocations
  | RefreshLocationsSuccess
  | AddLocation
  | AddLocationProspective
  | UpdateLocation
  | UpdateLocationProspective
  | DeleteLocation
  | ClearLocations
  | AddLocationProspectiveSuccess
  | UpdateLocationProspectiveSuccess
  | AddLocationProspectiveFailed
  | UpdateLocationProspectiveFailed
