import { Action } from '@ngrx/store'
import { DeviceEnum } from 'src/app/enums/device.enums'
import { State } from '../app-status.state'
import { AppTypeEnum } from 'src/app/enums/app.enums'
import { ReturnUrl } from '../models/return-url'

export enum AppStatusActionTypes {
  LoadAppStatus = '[App Component] trigger set AppStatus',
  SetAppStatus = '[AppStatus Effect] Set initial values for AppStatus',
  SetAppStatusFailed = '[AppStatus Effect] set initial values for AppStatus failed',
  Online = '[Shell Component] change online status to true',
  Offline = '[Shell Component] change online status to false',
  AppOnline = '[Root Effect] change online status to true',
  AppOffline = '[Root Effect] change online status to false',
  SetDevice = '[AppStatus Effect] set device type',
  SetInstallable = '[AppStatus Service] set if app is installable',
  InstallApp = '[Public Shell] install app',
  SetInstalled = '[AppStatus Effect] set if running as PWA standalone',
  SetReturnRoute = '[Root Effect] update return route',
  SkipReturnRoute = '[Root Effect] do not update return route',
  UpdateAppType = '[Login] update app type',
}

export class LoadAppStatus implements Action {
  readonly type = AppStatusActionTypes.LoadAppStatus
}

export class SetAppStatus implements Action {
  readonly type = AppStatusActionTypes.SetAppStatus
  constructor(public payload: State) {}
}

export class Online implements Action {
  readonly type = AppStatusActionTypes.Online
}

export class Offline implements Action {
  readonly type = AppStatusActionTypes.Offline
}

export class AppOnline implements Action {
  readonly type = AppStatusActionTypes.AppOnline
}

export class AppOffline implements Action {
  readonly type = AppStatusActionTypes.AppOffline
}

export class SetDevice implements Action {
  readonly type = AppStatusActionTypes.SetDevice
  constructor(public payload: DeviceEnum) {}
}

export class InstallApp implements Action {
  readonly type = AppStatusActionTypes.InstallApp
}

export class SetInstallable implements Action {
  readonly type = AppStatusActionTypes.SetInstallable
  constructor(public payload: boolean) {}
}

export class SetInstalled implements Action {
  readonly type = AppStatusActionTypes.SetInstalled
  constructor(public payload: boolean) {}
}

export class SetReturnRoute implements Action {
  readonly type = AppStatusActionTypes.SetReturnRoute
  constructor(public payload: { returnUrl: ReturnUrl }) {}
}

export class SkipReturnRoute implements Action {
  readonly type = AppStatusActionTypes.SkipReturnRoute
}

export class UpdateAppType implements Action {
  readonly type = AppStatusActionTypes.UpdateAppType
  constructor(public payload: { appType: AppTypeEnum }) {}
}

export type AppStatusActions =
  | LoadAppStatus
  | SetAppStatus
  | Online
  | Offline
  | AppOnline
  | AppOffline
  | SetDevice
  | InstallApp
  | SetInstallable
  | SetInstalled
  | SetReturnRoute
  | SkipReturnRoute
  | UpdateAppType
